import { Button, Form, InputGroup } from 'react-bootstrap'
import { TrashFill, InfoCircleFill } from 'react-bootstrap-icons'
import Autocomplete from 'components/misc/Dropdown'
import RedditTextField from 'components/misc/RedditTextField'
import { InputAdornment } from '@material-ui/core'

function TableRow(props) {

    const { row, index, visible } = props
    const { state, validations } = props

    const {
        handleChangeArticle,
        getRowStorageQuantity,
        handleQtyChange,
        getRowQuantityDiff,
        getRowAmountUnitDiff,
        getRowPriceDiff,
        handleChangeReason,
        handleRemoveRow,
        handleShowArticle
    } = props

    return (
        <tr key={'r-' + index} style={{ display: visible ? 'table-row' : 'none' }}>
            <td title={row.id || '-'} style={{ width: '3%' }}>
                {index + 1}

                <input type="hidden" name={`rows[${index}][id]`} value={row.id || ''} />
            </td>
            <td style={{ width: '20%' }}>
                {state.data.view_type === 'selected'
                    ?
                    <Autocomplete
                        fullWidth
                        margin="none"
                        size="small"
                        variant="outlined"
                        url="store/autocomplete/articles"
                        inputName="article_name"
                        inputIdName={`rows[${index}][article_id]`}
                        inputValue={row?.article?.article_name || ''}
                        inputIdValue={row.article_id || ''}
                        onChange={data => handleChangeArticle(data, index)}
                        error={Boolean(validations && validations.rows && validations.rows[index] && validations.rows[index].article_id && (validations.rows[index].article_id[0] || validations.rows[index].article_id))}
                        helperText={validations && validations.rows && validations.rows[index] && validations.rows[index].article_id && (validations.rows[index].article_id[0] || validations.rows[index].article_id)}
                        renderText={data => {
                            return (
                                <div style={{ display: 'flex', width: '100%' }}>
                                    <div style={{ width: 'max-content', marginRight: '10px' }}>
                                        {data.id}
                                    </div>
                                    <div>
                                        {data.name}
                                        <br />
                                        {data.article_name}
                                    </div>
                                </div>
                            )
                        }}
                        renderInputText={data => data.article_name}
                        autoFocus
                        listStyle={{
                            minWidth: 'max-content'
                        }}
                        className="quantity-input input-with-right-label"
                    />
                    :
                    <RedditTextField
                        margin="none"
                        size="small"
                        variant="outlined"
                        fullWidth
                        readOnly
                        value={row?.article?.article_name || ''}
                    />
                }
            </td>
            <td>
                <RedditTextField
                    margin="none"
                    size="small"
                    variant="outlined"
                    type="number"
                    step="0.0001"
                    fullWidth
                    value={row?.amount_unit || ''}
                    InputProps={row?.article?.amount_type_short ? {
                        endAdornment: <InputAdornment position="end">{row?.article?.amount_type_short || '-'}</InputAdornment>,
                    } : {}}
                    className="quantity-input input-with-right-label"
                    readOnly
                />
            </td>
            <td>
                <RedditTextField
                    margin="none"
                    size="small"
                    variant="outlined"
                    type="number"
                    step="0.0001"
                    fullWidth
                    value={getRowStorageQuantity(row) != null ? getRowStorageQuantity(row) : ''}
                    InputProps={row?.article?.amount_type_short ? {
                        endAdornment: <InputAdornment position="end">{row?.article?.amount_type_short || '-'}</InputAdornment>,
                    } : {}}
                    className="quantity-input input-with-right-label"
                    readOnly
                />
            </td>
            <td style={{ width: '10%' }}>
                <RedditTextField
                    margin="none"
                    size="small"
                    variant="outlined"
                    type="number"
                    step="0.0001"
                    fullWidth
                    name={`rows[${index}][actual_quantity]`}
                    value={row.actual_quantity !== null ? row.actual_quantity : ''}
                    onChange={e => handleQtyChange(e, index)}
                    error={Boolean(validations && validations.rows && validations.rows[index] && validations.rows[index].actual_quantity && (validations.rows[index].actual_quantity[0] || validations.rows[index].actual_quantity))}
                    helperText={validations && validations.rows && validations.rows[index] && validations.rows[index].actual_quantity && (validations.rows[index].actual_quantity[0] || validations.rows[index].actual_quantity)}
                    InputProps={row?.article?.amount_type_short ? {
                        endAdornment: <InputAdornment position="end">{row?.article?.amount_type_short || '-'}</InputAdornment>,
                    } : {}}
                    className="quantity-input input-with-right-label"
                />
            </td>
            <td>
                <RedditTextField
                    margin="none"
                    size="small"
                    variant="outlined"
                    type="number"
                    step="0.0001"
                    fullWidth
                    value={getRowQuantityDiff(row) !== null ? getRowQuantityDiff(row) : ''}
                    InputProps={row?.article?.amount_type_short ? {
                        endAdornment: <InputAdornment position="end">{row?.article?.amount_type_short || '-'}</InputAdornment>,
                    } : {}}
                    className="quantity-input input-with-right-label"
                    readOnly
                />
            </td>
            <td>
                <RedditTextField
                    margin="none"
                    size="small"
                    variant="outlined"
                    type="number"
                    step="0.0001"
                    fullWidth
                    value={getRowAmountUnitDiff(row) != null ? getRowAmountUnitDiff(row) : ''}
                    className="quantity-input input-with-right-label"
                    readOnly
                />
            </td>
            <td>
                <RedditTextField
                    margin="none"
                    size="small"
                    variant="outlined"
                    type="number"
                    step="0.0001"
                    fullWidth
                    value={getRowPriceDiff(row) != null ? getRowPriceDiff(row) : ''}
                    InputProps={row?.article?.currency?.prefix || row?.article?.currency?.sufix ? {
                        endAdornment: <InputAdornment position="end">{row?.article?.currency?.prefix || row?.article?.currency?.sufix || '-'}</InputAdornment>,
                    } : {}}
                    className="quantity-input input-with-right-label"
                    readOnly
                />
            </td>
            <td style={{ width: '10%' }}>
                <Autocomplete
                    fullWidth
                    margin="none"
                    size="small"
                    variant="outlined"
                    url="store/autocomplete/revision-reasons"
                    inputName="reason_name"
                    inputIdName={`rows[${index}][reason_id]`}
                    inputValue={row?.reason?.name || ''}
                    inputIdValue={row.reason_id || ''}
                    onChange={data => handleChangeReason(data, index)}
                    error={Boolean(validations && validations.rows && validations.rows[index] && validations.rows[index].reason_id && (validations.rows[index].reason_id[0] || validations.rows[index].reason_id))}
                    helperText={validations && validations.rows && validations.rows[index] && validations.rows[index].reason_id && (validations.rows[index].reason_id[0] || validations.rows[index].reason_id)}
                    renderText={data => {
                        return (
                            <div style={{ display: 'flex', width: '100%' }}>
                                <div style={{ marginRight: '10px' }}>
                                    <div style={{ background: data.color, width: 20, height: 20, }}></div>
                                </div>
                                <div>
                                    {data?.translation?.name || data.name}
                                </div>
                            </div>
                        )
                    }}
                    renderInputText={data => data?.translation?.name || data.name}
                    preventInitialLoad={true}
                    className="quantity-input input-with-right-label"
                />
            </td>
            <td className="text-center" style={{ width: '5%' }}>
                <div style={{ display: 'flex', gap: '3px' }}>
                    {row.article &&
                        <Button className="small cancel" style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }} onClick={e => handleShowArticle(row.article?.id)}>
                            <InfoCircleFill />
                        </Button>
                    }
                    {state.data.view_type === 'selected' &&
                        <Button className="remove small" onClick={e => handleRemoveRow(e, index)}>
                        </Button>
                    }
                </div>
            </td>

        </tr>
    )
}

export default TableRow