import { CircularProgress, Button as MaterialButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
    button: {
        position: 'relative'
    },
    loader: {
        position: 'absolute',
        left: 10
    }
}))

function Button(props) {
    const classes = useStyles()

    const text = props.children || props.text || 'Запази';
    const variant = props.variant || 'contained';
    const type = props.type || 'submit';
    const onClick = props.onClick || function () { };
    const loading = props.loading || false;
    const disabled = props.disabled || false
    const size = props.size || 'medium';
    const className = props.className || '';
    const fullWidth = props.fullWidth || false;
    const loadingColor = props.loadingColor || 'primary'
    const form = props.form || null

    return (
        <MaterialButton
            type={type}
            variant={variant}
            color="primary"
            className={`${[classes.button, className].join(' ')} save`}
            size={size}
            fullWidth={fullWidth}
            onClick={e => {
                if (!e.target.classList.contains('menu')) onClick(e)
                else e.preventDefault()
            }}
            disabled={loading || disabled}
            form={form}
        >
            {loading && <CircularProgress color={loadingColor} size={20} className={classes.loader} />}
            {text}
        </MaterialButton>
    );
}

export default Button;