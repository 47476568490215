import { forwardRef, useImperativeHandle } from "react"

//MUI components
import { Button } from "@material-ui/core"

//helpers
import Api from "helpers/Api"
import { useValidation } from "helpers/Validation"
import { useNestedState } from "helpers/NestedState"

//mics
import RedditTextField from "components/misc/RedditTextField"
import SaveButton from "components/misc/Button"

//images
import closeIcon from "assets/img/modals/icons/close.png"
import moment from "moment"

function AddOrEdit(props, ref) {
    const { companyId } = props

    const defaultData = {
        start_number    : "00000001",
    }

    const initialState = {
        id        : null,
        typeId    : null,
        data      : defaultData,
        edit      : false,
    }

    const [state, setState] = useNestedState({
        overlay   : false,
        modal     : false,
        onSuccess : null,
        editable  : false,
        ...initialState
    })

    const [validations, setValidations] = useValidation()

    useImperativeHandle(ref, () => ({
        add: typeId => {
            handleEditable(true)
            show(typeId)
        },
        edit: (data, typeId) => {
            edit(data, typeId)
        },
        hide: () => {
            hide()
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess   : fn,
            }))
        },
    }))

    const show = typeId => {
        setState(prev => ({
            ...prev,
            overlay   : true,
            typeId,
        }))

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                modal : true,
            }))
        }, 50)
    }

    const hide = () => {
        setState(prev => ({
            ...prev,
            modal         : false,
            ...initialState
        }))

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                overlay   : false,
            }))
            handleEditable(false)
            setValidations(null)
        }, 150)
    }

    const handleHide = e => {
        e.preventDefault()

        hide()
    }

    const edit = (data, typeId) => {
        setState(prev => ({
            ...prev,
            id    : data.id,
            typeId,
            data,
            edit  : moment().unix(),
        }))

        show(typeId)
    }

    const handleSave = e => {
        if(!state.editable) return
        
        e.preventDefault()

        setLoading(true)
        setValidations(null)

        const url             = state.id ? "documents/serials/edit" : "documents/serials/add"
        const data            = new FormData(e.target)

        if (state.id) data.append("id", state.id)
        if (companyId) data.append("company_id", companyId)
        if (state.typeId) data.append("type_id", state.typeId)

        Api.post(url, data)
            .then(res => {
                if (typeof state.onSuccess === "function") state.onSuccess(res.data)

                hide()
            })
            .catch(error => {
                const _err    = error.response

                if (_err && _err.status && _err.status === 422) setValidations(_err.data.errors)

            })
            .finally(() => {
                setLoading(false)
            })
    }

    const setLoading = loading => {
        setState((prev) => ({
            ...prev,
            loading,
        }))
    }

    const handleInputChange = e => {
        const { name, value }   = e.target

        setState(name, value, 'data')
        handleEditable(true)
    }

    const handleEditable = editable => {
        setState(prev => ({
            ...prev,
            editable
        }))
    }

    return (
        <div key={state.id} className={`${state.overlay ? "visible" : ""} overlay`}>
            <form
                className={`${state.modal ? "show" : ""} popup-primary small`}
                noValidate
                onSubmit={handleSave}
            >
                <h2 className="head">
                    Сериен номер
                    <img
                        className="close-icon"
                        alt="close"
                        src={closeIcon}
                        onClick={handleHide}
                    />
                </h2>

                <div className="body">
                    <RedditTextField
                        size="small"
                        margin="dense"
                        fullWidth
                        label="Стартов номер"
                        name="start_number"
                        value={state.data.start_number || ""}
                        onChange={handleInputChange}
                        error={Boolean(validations && validations.start_number)}
                        helperText={
                            validations &&
                            validations.start_number &&
                            (validations.start_number[0] || validations.start_number)
                        }
                    />
                </div>
                <div className="footer">
                    <Button className="cancel" onClick={handleHide}>
                        Отказ
                    </Button>
                    <SaveButton
                        loading={state.loading}
                        className={`save ${!state.editable ? 'disabled' : ''}`}
                    />
                </div>
            </form>
        </div>
    )
}

export default forwardRef(AddOrEdit)
