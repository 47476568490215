
import bankPaymentIcon from 'assets/img/app/icons/payment-bank.png'
import postPaymentIcon from 'assets/img/app/icons/payment-post.png'
import cashPaymentIcon from 'assets/img/app/icons/payment-cash.png'
import boricaIcon from 'assets/img/app/icons/borica.png'
import epayIcon from 'assets/img/app/icons/epay.png'
import paypalIcon from 'assets/img/app/icons/paypal.png'
import stripeIcon from 'assets/img/app/icons/stripe.png'
import paypalCardIcon from 'assets/img/app/icons/paypal-card.png'
import paypalLogoIcon from 'assets/img/app/icons/paypal-logo.png'
import easypayLogoIcon from 'assets/img/app/icons/easypay-logo.png'
import stripeLogoIcon from 'assets/img/app/icons/stripe-logo.png'
import boricaLogoIcon from 'assets/img/app/icons/borica-logo.png'

export const PAYMENT_TYPE = {
    BANK    : 2
}

export const PAYMENT_EXTRA_DATA = {
    cash: {
        icon    : postPaymentIcon,
        name    : 'В брой'
    },
    bank: {
        icon    : bankPaymentIcon,
        name    : 'Банков превод'
    },
    cash_on_delivery: {
        icon    : cashPaymentIcon,
        name    : 'Наложен платеж'
    },
    epay: {
        icon    : epayIcon,
        logo    : easypayLogoIcon,
        name    : 'ePay'
    },
    epay_borica: {
        icon    : boricaIcon,
        logo    : boricaLogoIcon,
        name    : 'Borica'
    },
    paypal: {
        icon    : paypalIcon,
        logo    : paypalLogoIcon,
        name    : 'PayPal'
    },
    paypal_card: {
        icon    : paypalCardIcon,
        logo    : paypalCardIcon,
        name    : 'PayPal Card'
    },
    stripe: {
        icon    : stripeIcon,
        logo    : stripeLogoIcon,
        name    : 'Stripe'
    }
}