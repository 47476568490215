import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

//plugins
import { Button, Col, Row, FloatingLabel, Form, } from 'react-bootstrap';

//helpers
import Api from 'helpers/Api'
import { useValidation } from 'helpers/Validation'
import { useNestedState } from 'helpers/NestedState'

//providers
import { useLanguageContext } from 'providers/Language'

//misc
import SaveButton from 'components/misc/Button'
import moment from 'moment'
import RedditTextField from 'components/misc/RedditTextField';

function AddPackageCard(props, ref) {

    const defaultData = {

    }

    const [state, setState] = useNestedState({
        show: false,
        id: null,
        refresh: false,
        data: defaultData,
        loading: false,
        onSuccess: null,
        onEntering: null,
        onExiting: null,
        onClose: null,
    });

    const [validations, setValidations] = useValidation();

    useImperativeHandle(ref, () => ({
        add: (id) => {
            open(id);
        },
        hide: () => {
            hide();
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }));
        }
    }));


    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }));

        onEntering?.();
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }));

        onExiting?.();
    }

    const open = (id) => {
        setState(prev => ({
            ...prev,
            data: defaultData,
            id: id,
        }));

        show();
    }

    const handleClose = e => {

        if (e) {
            e.preventDefault();
        }

        setState(prev => ({
            ...prev,
            data: defaultData,
            id: null,
            subjectId: null,
            subjectType: null
        }));

        setValidations(null);

        if (typeof state.onClose === 'function') {
            hide(state.onClose);
        } else {
            hide();
        }
    }

    const refresh = (reset = true) => {
        setState(prev => ({
            ...prev,
            refresh: moment().unix(),
        }));
    }

    const handleSave = e => {
        e.preventDefault();

        setState(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url = 'store/orders/package-cards/add';

        let data = new FormData(e.target);

        if (state.id) {
            data.append('id', state.id)
        }

        Api.post(url, data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(res => {
            if (typeof state.onSuccess === 'function') {
                state.onSuccess(res.data);
            }

            hide(state.onClose);
        }).catch(error => {
            const _err = error.response;

            if (_err && _err.status && _err.status === 422) {
                setValidations(_err.data.errors);
            }
        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false,
            }));
        });
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(name, value, 'data')
    }

    const handleDateChange = e => {
        const name = e.target.name + '_ymd'
        const value = e.target.value

        setState(name, value, 'data')
    }

    return (
        <>

            <div className={`${state.show ? "visible" : ""} overlay`}>
                <div
                    className={`popup-primary medium ${state.show ? "show" : ""}`}
                >
                    <form onSubmit={handleSave}>
                        <div className='body'>
                            <h3>
                                Прилагане на пакетна карта
                            </h3>
                            <br />
                            <Row>
                                <Col>

                                    <RedditTextField
                                        size="small"
                                        variant="filled"
                                        margin="dense"
                                        label="Номер"
                                        name="num"
                                        value={state.data.num || ''}
                                        onChange={handleInputChange}
                                        error={Boolean(validations && validations.num)}
                                        helperText={validations && validations.num && (validations.num[0] || validations.num)}
                                    />
                                </Col>
                            </Row>

                        </div>
                        <div className="footer">
                            <Button
                                variant="contained"
                                className="cancel"
                                onClick={handleClose}
                            >
                                Отказ
                            </Button>
                            <SaveButton
                                loading={state.loading}
                                className="save"
                                text="Приложи"
                            />
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default forwardRef(AddPackageCard);
