//images
import noRecordsIcon from "assets/img/app/icons/no-records.png"

function NoRescordsFound(props) {
  return (
    <div className="no-records-found" style={props.style || {}}>
      <img src={noRecordsIcon} className="main-image" />

      {props.children ? (
        props.children
      ) : (
        <>
          <h2>Няма налични записи</h2>
          <p>
            {props.description || "Не бяха намерени данни от Вашето търсене"}
          </p>
        </>
      )}
    </div>
  )
}

export default NoRescordsFound
