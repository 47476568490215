import { useState, useImperativeHandle, forwardRef, useEffect } from 'react'
// import { trans } from '../../../_providers/Translation';
import Button from '@material-ui/core/Button';
import SaveButton from '../../../../misc/Button';
import TextField from '@material-ui/core/TextField';


import closeIcon from 'assets/img/modals/icons/close.png'
import { useValidation } from 'helpers/Validation'


//helpers
import Api from 'helpers/Api'

import moment from 'moment'

//misc
import DatePicker from 'components/misc/DatePicker'
import Autocomplete from 'components/misc/Autocomplete'

function MakePaid(props, ref) {

    const [state, setState] = useState({
        id: null,
        data: null,
        overlay: false,
        modal: false,
        params: null,
        onSuccess: null,
        onClose: null,
    });

    const [validations, setValidations] = useValidation();

    useImperativeHandle(ref, () => ({
        open: (id) => {
            handleOpen(id);
        },
        close: () => {
            handleHide();
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }));
        }
    }));

    const handleOpen = id => {
        setState(prev => ({
            ...prev,
            overlay: true,
            id: id
        }));

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                modal: true,
            }))
        }, 50);
    }

    const handleHide = () => {
        setState(prev => ({
            ...prev,
            modal: false,
        }));

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                overlay: false,
            }));
        }, 100);

        resetData();
        setValidations(null);

        if (typeof state.onClose === 'function') state.onClose();
    }

    const resetData = () => {
        setState(prev => ({
            ...prev,
            data: null,
        }))
    }

    const handleDateChange = date => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                date: moment(date).format('YYYY-MM-DD'),
            }
        }));
    }

    const handleSave = e => {
        e.preventDefault();

        setState(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url = 'documents/make-paid';

        let data = new FormData(e.target);

        data.append('id', state.id);

        Api.post(url, data)
            .then(res => {

                if (typeof state.onSuccess === 'function') {
                    state.onSuccess(res.data);
                }

                handleHide();

            })
            .catch(error => {

                let _err = error.response;

                if (_err && _err.status && _err.status === 422) {
                    setValidations(_err.data.errors);
                }

            })
            .finally(() => {
                setState(prev => ({
                    ...prev,
                    loading: false,
                }));
            });
    }

    return (
        <div className={`${state.overlay ? 'visible' : ''} overlay`}>
            <form className={`${state.modal ? 'show' : ''} popup-primary medium`} noValidate onSubmit={handleSave}>
                <h2 className="head">
                    Плащане
                    <img className="close-icon" alt="close" src={closeIcon} onClick={handleHide} />
                </h2>

                <div className="body">
                    <div className="row">
                        <div className="col">
                            <Autocomplete
                                freeSolo={false}
                                variant="standard"
                                size="small"
                                inputPlaceholder="Начин на плащане"
                                inputName={`method`}
                                inputIdName={`method_id`}
                                url="payment-methods/all"
                                getResultData={data => data}
                                getOptionLabel={option => option?.translation?.name || ''}
                                renderOption={option => option?.translation?.name || ''}
                                error={Boolean(validations && validations.method_id && (validations.method_id[0] || validations.method_id))}
                                helperText={validations && validations.method_id && (validations.method_id[0] || validations.method_id)}
                            />
                        </div>
                        <div className="col small">
                            <DatePicker
                                reddit
                                fullWidth
                                label="Дата"
                                name="date"
                                value={state.data?.date || null}
                                onChange={handleDateChange}
                            />
                        </div>
                    </div>


                    <input type="hidden" name="date" value={state.data?.date || ''} />
                </div>
                <div className="footer">
                    <Button
                        className="cancel"
                        onClick={handleHide}
                    >
                        Отказ
                    </Button>
                    <SaveButton
                        loading={state.loading}
                        className="save"
                    />
                </div>
            </form>
        </div>
    )
}

export default forwardRef(MakePaid);