import { forwardRef, useImperativeHandle, useRef } from 'react'
import closeIcon from '../../../../assets/img/modals/icons/close.png'
import { Button } from '@material-ui/core'
import SaveButton from '../../../misc/Button'
import { useNestedState } from '../../../../helpers/NestedState'
import { useClientContext } from '../Provider'
import Form from './Form'
import Vies from './Vies'
import moment from 'moment'

function AddOrEdit(props, ref) {

    const [state, setState] = useNestedState({
        show: false,
        id: null,
        edit: false,
        refresh: false,
        loading: false,
        onSuccess: null,
        onEntering: null,
        onExiting: null,
        onClose: null,
    })

    const context = useClientContext()

    useImperativeHandle(ref, () => ({
        add: () => {
            handleShow()
        },
        edit: (id) => {
            edit(id)
        },
        hide: () => {
            hide()
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }))
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }))
        }
    }))

    const formRef = useRef(null)
    const viesModalRef = useRef(null)

    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }))
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }))
    }

    const handleShow = () => {
        show()
    }

    const handleHide = (e) => {
        e.preventDefault()

        close()
    }

    const close = () => {

        setState(prev => ({
            ...prev,
            id: null,
        }))

        context.resetData()

        if (typeof state.onClose === 'function') {
            hide(state.onClose)
        } else {
            hide()
        }
    }

    const edit = id => {
        setState(prev => ({
            ...prev,
            id: id,
            edit: moment().unix()
        }))

        show()
    }

    const refresh = (reset = true) => {
        setState(prev => ({
            ...prev,
            refresh: moment().unix(),
        }))
    }

    const onSuccess = (data) => {
        if (typeof state.onSuccess === 'function') {
            state.onSuccess(data)
        }

        context.resetData()

        hide()
    }

    return (
        <>
            <Vies
                ref={viesModalRef}
            />

            <div className={`${state.show ? "visible" : ""} overlay`}>
                <div
                    className={`popup-primary medium complex-modal storage-load-form-modal customer-modal ${state.show ? "show" : ""}`}
                >
                    <div className="header sky-blue">
                        <b>
                            Клиент
                        </b>
                        <p>
                            {state.id ? 'Редактирайте' : "Добавете нов"} клиент - юридическо или физическо лице
                        </p>
                    </div>
                    <div className="body">
                        <Form
                            ref={formRef}
                            id={state.id}
                            show={show}
                            hide={hide}
                            onSuccess={onSuccess}
                            viesModalRef={viesModalRef}
                        />
                    </div>

                    <div className="footer">
                        <Button
                            className="cancel"
                            onClick={handleHide}
                        >
                            Отказ
                        </Button>
                        <SaveButton
                            loading={state.loading}
                            className="save"
                            onClick={e => formRef.current?.save(e)}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default forwardRef(AddOrEdit)