//providers
import { useRegisterContext } from "./RegisterProvider"

const useCompanyDataHook = () => {
    const register = useRegisterContext()
    const { companyData } = register

    /**
     * 
     * @returns дали всички данни за фирмата са попълнени. Това ще служи за индикатор, дали потребителя може да премине към следващата стъпка.
     */
    const isCompanyDataFilled = () => {
        let filled = true
        const data = companyData?.data || {}
        const values = Object.values(data)
        if ((values.length === 0) ||
            !data['address'] || !data['mol'] || !data['name'] || !data['settlement']) {
            return filled = false
        }

        values.map((c, i) => {
            if (!c?.length) {
                const currentKey = Object.keys(data)[i]

                if ((currentKey !== 'vatNumber') ^ (currentKey !== 'email') ^ (currentKey !== 'municipalityId') ^ (currentKey !== 'settlementId')) return

                filled = false
            }
        })

        return filled
    }

    const handleChange = e => {
        const { name, value } = e.target
        if (name === 'eik') {
            register.setCompanyDataState(prev => ({
                ...prev,
                eik: name === 'eik' ? value : prev.eik
            }))
        } else {
            register.setCompanyDataState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    [name]: value
                },
            }))
        }
    }

    const handleSearchChange = search => {
        register.setCompanyDataState(prev => ({
            ...prev,
            search
        }))
    }

    const setCompanyDataState = async (data, noEik) => {
        await register.setCompanyDataState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                eik: data.info?.eikegn || null,
                email: data.info?.email || null,
                vatNumber: data.info?.vat_number || null,
                mol: data.info?.mol || null,
                name: data.info?.name || null,
                settlement: data.addresses[0]?.settlement?.name || null,
                address: data.addresses[0]?.address || null,
            },
            eik: noEik ? prev.eik : (data.info?.eikegn || null),
        }))
    }

    const resetCompanyDataState = () => {
        register.setCompanyDataState(prev => ({
            ...prev,
            data: null
        }))
    }

    const handleCompanyDataChange = e => {
        const { name, value } = e.target

        register.setCompanyDataState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: value
            }
        }))
    }

    /**
     * 
     * @returns дали да се визуализират полетата за данни на фирмата
     */
    const areFieldsShown = () => {
        if (companyData.data || ((register.companyData?.eik || '').length >= 9)) {
            return true
        }

        return false
    }

    /**
     * 
     * @returns дали полетата са в режим редакция
     */
    const isEditMode = () => {
        if (companyData.editMode) {
            return true
        }

        return false
    }

    const setEditMode = editMode => {
        register.setCompanyDataState(prev => ({
            ...prev,
            editMode
        }))
    }

    const setLoading = loading => {
        register.setCompanyDataState(prev => ({
            ...prev,
            loading
        }))
    }

    /**
     * 
     * @returns дали има избрана фирма
     */
    const isSelectedCompany = () => {
        if (companyData.data) {
            return true
        }

        return false
    }

    return {
        handleChange,
        isCompanyDataFilled,
        setCompanyDataState,
        handleSearchChange,
        areFieldsShown,
        handleCompanyDataChange,
        setEditMode,
        isEditMode,
        setLoading,
        resetCompanyDataState,
        isSelectedCompany
    }
}

export default useCompanyDataHook