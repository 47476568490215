//librairies
import moment from 'moment'
import { create } from 'zustand'

//helpers
import { getAddOptionsTypes, roundCurrentHourToNearestHalfHour } from 'helpers/Calendar'

const INITIAL_FORM = {
    id: null,
    startDate: moment().format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    isAllDay: false,
    eventType: getAddOptionsTypes()[0].type,
    startHour: roundCurrentHourToNearestHalfHour(moment().format("HH:mm")),
    endHour: roundCurrentHourToNearestHalfHour(
        moment().add(30, "minutes").format("HH:mm")),
    title: "",
    client: {},
    clientField: "",
    tags: []
}
export const useAddToCommunicationsModal = create((set, get) => ({
    show: false,
    form: { ...INITIAL_FORM },
    showModal: (show = true, initialForm = null) => set(state => ({
        show,
        form: {
            ...INITIAL_FORM,
            ...(initialForm || state.form)
        }
    })),
    setForm: (key, value) => set((state) => ({
        ...state,
        form: {
            ...state.form,
            [key]: value,
        }
    })),
    hideModal: () => set({ show: false, form: INITIAL_FORM }),
    getForm: () => get().form,
    getShown: () => get().show
}))