//helpers
import { useQuery as uQ } from 'react-query'

//helpers
import Api from "helpers/Api"

function useFetchClientStore(clientId = null) {
    const { data, isLoading, refetch } = uQ(['store/clients/view', `id=${clientId}`], _fetchData, {
        staleTime: 100,
    })

    async function _fetchData() {
        // if (!clientId) {
        //     console.error('client id is required')

        //     return
        // }

        const res = await Api.get(`store/clients/view?id=${clientId}`)

        return res.data
    }

    function getClient() {
        return data?.client || null
    }

    function getClientAcountsTotal() {
        return data?.accounts_totals || null
    }

    function getClientCurrency() {
        return data?.currency || null
    }

    return {
        getClient,
        getClientAcountsTotal,
        getClientCurrency,
        loadingClient: isLoading,
    }
}

export default useFetchClientStore