//components
import { useCampaignFormTemplate } from "stores/customer/useCampaignFormTemplate"

//stores
import CampaignFormTemplateGroup from "./CampaignFormTemplateGroup"

//libraries
import { Button } from "@material-ui/core"

function AddEditCampaignFormTemplate() {
  const { getGroups, addGroup, removeGroup } = useCampaignFormTemplate()

  return (
    <div className="campaign-form-template">
      {getGroups().map(({ id, title, description, questions }, i) => (
        <div className="row" key={id}>
          <CampaignFormTemplateGroup
            group={{
              id,
              title,
              description,
              questions,
            }}
          />
        </div>
      ))}
      <hr />
      <Button className="add" onClick={addGroup}>
        Нова група
      </Button>
    </div>
  )
}

export default AddEditCampaignFormTemplate
