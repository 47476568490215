import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import ClickAwayListener from 'react-click-away-listener'

//images
import notificationsIcon from 'assets/img/header/icons/notifications.png'

//MUI components
import Tooltip from '@material-ui/core/Tooltip'

import { useSocketNotification } from 'helpers/Socket'
import { useAuthDataContext } from 'providers/Auth'
import Api from 'helpers/Api'
import InfiniteScroll from 'react-infinite-scroller';
import { CircularProgress } from '@material-ui/core'
import { convertDateToText } from 'helpers/Date'
import sound from 'assets/voice-2.ogg'
import Refs from 'Refs'
import moment from 'moment'

let timerInterval;

function Notifications() {

    const auth = useAuthDataContext();

    const [state, setState] = useState({
        show: false,
        loading: false,
        data: [],
        page: 1,
        pages: 0,
        total: 0,
        unread: 0,
        hasMore: false,
        refresh: false,
    })

    const ref = useRef(null);

    useEffect(() => {
        loadCount();
        loadData();
    }, [])

    useEffect(() => {
        if (state.refresh) {
            loadCount();
            loadData(1, true);
        }
    }, [state.refresh])

    useLayoutEffect(() => {
        calcTime();

        timerInterval = setInterval(() => {
            calcTime()
        }, 10000);

        return () => {
            clearInterval(timerInterval);
        }
    }, [state.data]);

    useSocketNotification({
        channel: 'users',
        channelId: auth.getUser()?.getId(),
        callback: (payload) => {
            playSound();

            setState(prev => ({
                ...prev,
                data: [payload, ...prev.data],
                unread: prev.unread + 1,
            }));
        }
    }, [auth]);


    const loadData = (page = 1, reset = false) => {

        if (state.loading && reset === false) {
            return;
        }

        setState(prev => ({
            ...prev,
            loading: true,
        }));

        Api.get('notifications/get', {
            params: {
                page: page,
            }
        })
            .then(res => {
                setState(prev => ({
                    ...prev,
                    data: reset ? res.data.items : prev.data.concat(res.data.items),
                    page: page,
                    pages: res.data.pages,
                    total: res.data.total,
                    hasMore: res.data.has_more,
                    loading: false,
                }));
            });
    }

    const loadCount = () => {
        Api.get('notifications/total')
            .then(res => {
                setState(prev => ({
                    ...prev,
                    unread: res.data
                }));
            });
    }

    const markAsRead = () => {
        Api.post('notifications/mark-as-read')
            .then(() => {
                handleRefresh()
            })
    }

    const handleRefresh = () => {
        setState(prev => ({
            ...prev,
            refresh: moment().unix()
        }))
    }

    const calcTime = () => {
        let els = document.querySelectorAll('.notifications-list .timer');

        let now = moment().toDate();

        els.forEach(el => {
            let date = el.getAttribute('data-date');

            if (date) {
                date = moment().toDate();
            } else {
                date = now;
            }

            let text = convertDateToText(now, date);

            if (text) {
                el.innerHTML = text;
            }

        })
    }

    const playSound = () => {
        const audio = new Audio(sound);

        audio.play();
    }

    const showNotifications = show => {
        setState(prev => ({
            ...prev,
            show
        }))
    }

    const handleNotification = notification => {
        switch (notification.type) {
            case 'blackbox\\store\\notifications\\OrderCreated':
                if (notification.data.order_id) {
                    Refs.getInstance().getRef('order').open(notification.data.order_id);
                }
                break;
        }
    }

    return (

        <div
            className="notifications"
            onClick={() => {
                showNotifications(!state.show)
            }}
        >
            <Tooltip
                title="Преглед на известията"
            >
                <img src={notificationsIcon} />
            </Tooltip>
            {state.unread > 0 &&
                <span className="count">
                    {state.unread}
                </span>
            }
            {state.show ?
                <ClickAwayListener
                    onClickAway={() => {
                        showNotifications(false)
                    }}
                >
                    <div className="notifications-list">
                        <div className="head">
                            <h3>
                                {state.unread} {state.unread === 1 ? 'ново известие' : 'нови известия'}
                                {/* Няма непрочетени известия */}
                            </h3>
                            {state.unread > 0 &&
                                <Tooltip
                                    title="Кликнете, за да маркирате всички известия като прочетени"
                                >
                                    <span
                                        className="link"
                                        onClick={() => {
                                            markAsRead()
                                        }}
                                    >
                                        Маркирай всички като прочетени
                                    </span>
                                </Tooltip>
                            }
                        </div>
                        {state.data.length === 0
                            ?
                            <div className="no-data">
                                Нямате нови известия.
                            </div>
                            :
                            <div className="scroll" ref={ref}>
                                <InfiniteScroll
                                    element="ul"
                                    initialLoad={false}
                                    pageStart={state.page}
                                    loadMore={loadData}
                                    hasMore={state.hasMore}
                                    loader={<div style={{ textAlign: 'center' }}>
                                        <CircularProgress color="inherit" />
                                    </div>}
                                    useWindow={false}
                                    getScrollParent={() => ref.current}
                                >
                                    {state.data.map(notification =>
                                        <Tooltip
                                            key={notification.id}
                                            title="Преглед на известието"
                                        >
                                            <li
                                                className={notification.read ? '' : 'unread'}
                                                onClick={() => {
                                                    handleNotification(notification)
                                                }}
                                            >
                                                <img src={notification.icon} alt="" />
                                                <div className="right">
                                                    <h4>
                                                        {notification.text}
                                                    </h4>
                                                    <time className="timer" data-date={notification.created_at}>

                                                    </time>
                                                </div>
                                            </li>
                                        </Tooltip>
                                    )}

                                </InfiniteScroll>

                            </div>
                        }
                    </div>
                </ClickAwayListener>
                :
                <>
                </>
            }
        </div>

    )
}

export default Notifications
