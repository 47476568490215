function LeftSideMenu({
  tabsArr = [],
  activeTabId,
  setActiveTabId,
  disabled = false,
}) {
  function handleSetActiveTab(tabIndex) {
    setActiveTabId(tabIndex)
  }

  return (
    <aside className={`${disabled ? "disabled" : ""} with-submenues`}>
      {tabsArr.map((t) => (
        <>
          <div className="heading">
            <b>{t.name}</b>
          </div>
          <ul className="left-side-menu">
            {t.innerTabs.map((it) => (
              <li
                key={it.name}
                style={{ color: it.validate ? "red" : "" }}
                className={activeTabId === it.id ? "active" : ""}
                onClick={() => {
                  handleSetActiveTab(it.id)
                }}
              >
                {it.icon ? <img src={it.icon} alt={it.name} /> : <></>}
                {it.name}
              </li>
            ))}
          </ul>
        </>
      ))}
    </aside>
  )
}

export default LeftSideMenu
