import { useState } from "react"

//helpers
import Api from "helpers/Api"

function useMailTemplates() {
    const initialTemplates = {
        data: {},
        loading: true,
        customerData: {}
    }
    const [templatesData, setTemplatesData] = useState({
        ...initialTemplates
    })
    const [selectedTemplate, setSelectedTemplate] = useState(null)
    const [templateAnchorEl, setTemplateAnchorEl] = useState(null)

    function fetchTemplates({
        documentIdsArr,
        endpoint,
    }) {
        Api.post(endpoint, {
            ids: documentIdsArr
        })
            .then(res => {
                const { templates, documents } = res.data

                setSelectedTemplate(res.data.templates[0])
                setTemplatesData(prev => ({
                    ...prev,
                    data: res.data,
                    customerData: _getCustomerInfo(Object.values(documents)[0].customer)
                }))

                setSelectedTemplate(templates[0])
            })
    }

    function _getCustomerInfo(customer) {
        const personName = customer?.address?.mol || ''
        const companyName = customer?.info?.name || ''

        return { personName, companyName }
    }

    function toggleTemplates(e) {
        setTemplateAnchorEl(templateAnchorEl ? null : e.target)
    }

    function hideTemplates() {
        setTemplateAnchorEl(null)
    }

    function getTemplates(documentId) {
        return templatesData.data[documentId]?.templates || templatesData.data.templates || []
    }

    function getActiveTemplate() {
        return selectedTemplate
    }

    function getTemplatesData() {
        return templatesData
    }

    function resetTemplatesData() {
        setTemplatesData(initialTemplates)
    }

    function getActiveTemplateId() {
        return selectedTemplate.id || null
    }

    function getTemplateAnchorEl() {
        return templateAnchorEl
    }

    return {
        fetchTemplates,
        toggleTemplates,
        hideTemplates,
        getTemplates,
        getTemplatesData,
        getActiveTemplate,
        resetTemplatesData,
        getActiveTemplateId,
        setSelectedTemplate,
        getTemplateAnchorEl
    }
}

export default useMailTemplates