import { Link } from 'react-router-dom'

//MUI components
import { Tooltip } from '@material-ui/core'

//providers
import { useDocumentsTabsContext } from 'providers/DocumentsTabs'

//constants
import { INVOICES_TABS } from './constants/tabs'

function Home() {
    const { handleAddNewSubtab, handleSetActiveTabTypeId } = useDocumentsTabsContext()

    return (
        <section id="documents-home">
            <div className="settings-panel">
                <div className="menu">
                    <b>
                        Списък с документи
                    </b>
                    <ul>
                        {INVOICES_TABS.map((t, i) =>
                            t.firstLetter ?
                                <Tooltip
                                    title={`Към ${t.homeListName.toLowerCase()}`}
                                    key={`tab2_${i}`}
                                >
                                    <li
                                        onClick={() => {
                                            handleSetActiveTabTypeId(t.typeId)
                                        }}
                                    >
                                        <span className="image">
                                            {t.firstLetter}
                                        </span>
                                        <span className="text">
                                            {t.homeListName}
                                        </span>
                                    </li>
                                </Tooltip>
                                :
                                <>
                                </>
                        )}
                    </ul>
                </div>
                <div className="content">
                    <h2>
                        Създайте документ
                    </h2>
                    <p>
                        За да създадете нов документ е необходимо да изберете и натиснете един от посочените по долу документи и да започнете въвеждането на данни.
                    </p>
                    {/* <b>
                        Основни модули
                    </b> */}
                    <ul className="main">
                        {INVOICES_TABS.map((t, i) =>
                            t.firstLetter ?
                                <Tooltip
                                    title={t.description}
                                    key={`tab2_${i}`}
                                >
                                    <li
                                        key={i}
                                        onClick={() => {
                                            handleAddNewSubtab({
                                                typeId: t.typeId
                                            })
                                        }}
                                    >
                                        <span className="image">
                                            {t.firstLetter}
                                        </span>
                                        <span className="text">
                                            {t.fullName}
                                        </span>
                                        <p>
                                            {t.info}
                                        </p>
                                    </li>
                                </Tooltip>
                                :
                                <>
                                </>
                        )}
                    </ul>
                </div>
            </div>
        </section>
    )
}

export default Home