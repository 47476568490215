import { useEffect, useState } from "react"

//helpers
import Api from "helpers/Api"

function useFetchIncomesStats(clientId) {
    const [data, setData] = useState({})
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        _fetchData()
    }, [clientId])

    function _fetchData() {
        if (!clientId) {
            console.error('clientId is required')

            return
        }

        setLoading(true)
        Api.get(`store/clients/dashboard/income-stats?client_id=${clientId}`)
            .then(res => {
                _setData(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    function _setData(data) {
        setData(data)
    }

    function getData() {
        return data
    }

    return {
        getData,
        loadingIncomesStats: loading
    }
}

export default useFetchIncomesStats