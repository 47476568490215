import { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';

import Api from '../../../helpers/Api';
import { useAuthDataContext } from '../../../providers/Auth';
import { useParams, useHistory, useLocation, Link as RouterLink } from 'react-router-dom';
import { useQuery } from '../../../helpers/Url';
import SaveButton from '../../misc/Button';

import loader from '../../../assets/img/loader.svg';

import '../../../assets/scss/pages/login.scss';

//images
import brand from '../../../assets/img/app/brand-full.png';
import eyeIcon from '../../../assets/img/login/icons/eye.png';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: '#042731',
    },
    alert: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        backgroundColor: '#042731',
        '&:hover': {
            backgroundColor: '#042731',
        }
    },
}));

let timeout;

function Index() {
    const auth = useAuthDataContext();
    const history = useHistory();
    const params = useParams();
    const query = useQuery();
    const location = useLocation();
    const classes = useStyles();

    const loginToken = query.get('token');
    const redirectUrl = query.get('redirect');

    if (auth.isLogged() && !loginToken) {
        history.replace('/');
    }

    const [state, setState] = useState({
        viewPw: false
    })

    const [loading, setLoading] = useState(false);

    const [validation, setValidation] = useState(false);

    useEffect(() => {
        if (loginToken) {
            loginViaToken();
        }

        appendPressHold()
    }, []);

    const appendPressHold = () => {
        let item = document.querySelector(".view-pw")

        let timerID
        let counter = 0

        let pressHoldEvent = new CustomEvent("pressHold")

        let pressHoldDuration = 50

        item.addEventListener("mousedown", pressingDown, false)
        item.addEventListener("mouseup", notPressingDown, false)
        item.addEventListener("mouseleave", notPressingDown, false)

        item.addEventListener("touchstart", pressingDown, false)
        item.addEventListener("touchend", notPressingDown, false)

        item.addEventListener("pressHold", doSomething, false)

        function pressingDown(e) {
            requestAnimationFrame(timer)

            e.preventDefault()
            viewPw(true)
        }

        function notPressingDown(e) {
            cancelAnimationFrame(timerID)
            counter = 0
            viewPw(false)
        }


        function timer() {
            if (counter < pressHoldDuration) {
                timerID = requestAnimationFrame(timer);
                counter++;
            } else {
                item.dispatchEvent(pressHoldEvent);
            }
        }

        function doSomething(e) {

        }
    }

    const viewPw = viewPw => {
        setState(prev => ({
            ...prev,
            viewPw
        }))
    }

    const handleLogin = e => {

        if (e) {
            e.preventDefault();
        }

        setValidation(false);
        setLoading(true);

        clearTimeout(timeout);

        timeout = setTimeout(() => {
            let url = 'auth/login';

            let data = new FormData(e.target);

            Api.post(url, data)
                .then(res => {

                    if (res.status === 200 && res.data.user) {
                        auth.login(res.data);

                        if (redirectUrl) {
                            history.push(redirectUrl);
                        } else {
                            history.push('/');
                        }
                    }

                }).catch(err => {

                    let _err = err.response;

                    if (_err && _err.status) {
                        if (_err.status === 422) {
                            for (let [input, err] of Object.entries(_err.data.errors)) {
                                setValidation(prev => {
                                    return { ...prev, [input]: err }
                                });
                            }
                        }

                        if (_err.status == 401) {
                            setValidation(true);
                        }
                    }

                }).finally(() => {
                    setLoading(false);
                });
        }, 500);

    }

    // todo
    const loginViaToken = () => {

        auth.logout(false);

        let url = 'partners/login-via-token';

        Api.post(url, {
            token: loginToken
        })
            .then(res => {

                if (res.status === 200 && res.data.user) {
                    auth.login(res.data);
                    history.replace('/');
                }

            }).catch(err => {

                if (err.response && err.response.status) {
                    if (err.response.status === 403) {

                    }
                }

            })
    }

    return (
        <>
            {loginToken
                ?
                <img src={loader} alt="loading" />
                :
                <section id="login">
                    <form className={classes.form} noValidate onSubmit={handleLogin}>
                        <img src={brand} className="logo" />
                        <h1>
                            Здравейте, отново!
                        </h1>
                        <p>
                            Моля, въведете Вашите данни за вход в системата
                        </p>
                        <div className="row" style={{ marginBottom: '0' }}>
                            <div className="col">
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Е-поща"
                                    name="email"
                                    autoComplete="email"
                                    autoFocus
                                    error={validation}
                                    helperText={validation && validation.email && validation.email[0]}
                                />
                            </div>
                        </div>
                        <div className="row" style={{ marginBottom: '0' }}>
                            <div className="col">
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Парола"
                                    type={state.viewPw ? 'text' : 'password'}
                                    id="password"
                                    autoComplete="current-password"
                                    error={validation}
                                />
                                <span
                                    className={`view-pw ${state.viewPw ? 'on' : ''}`}
                                >
                                    <img src={eyeIcon} />
                                </span>
                            </div>
                        </div>
                        <div className="row widgets">
                            <div className="col">
                                <div className="row">
                                    <FormControlLabel
                                        control={<Checkbox name="remember" value="1" color="primary" />}
                                        label="Запомни ме"
                                    />
                                </div>
                            </div>
                            {/* <div className="col forgotten-password">
                                <Link component={RouterLink} to="/password/forgot" variant="body2">
                                    Забравена парола
                                </Link>
                            </div> */}
                        </div>

                        <SaveButton
                            type="submit"
                            fullWidth
                            variant="contained"
                            className={classes.submit}
                            loading={loading}
                            loadingColor="inherit"
                        >
                            Вход
                        </SaveButton>

                        <Link component={RouterLink} to="/register" className="register">
                            Нямате акаунт? Създайте от тук.
                        </Link>
                    </form>
                    <p className="info">
                        Фактурираш онлайн само с няколко клика. Интуитивен дизайн<br /> и много полезни функции за теб. Ако имаш въпроси пиши ни на <a href="mailto:team@docuela.com" className="link">team@docuela.com</a>
                    </p>
                    <p className="footer">
                        © Copyright Docuela 2022. All Rights Reserved.
                    </p>
                </section>
            }
        </>
    )
}

export default Index;
