//misc
import RedditTextField from "components/misc/RedditTextField"

//MUI components
import { Tooltip } from '@material-ui/core'

//images
import clearFilterIcon from 'assets/img/documents/icons/clear-filter-blue.png'

//components
import Period from "./Period"
import DocType from "./DocType"
import Sent from "./Sent"
import Paid from "./Paid"
import PeriodNew from "./PeriodNew"
import Autocomplete from 'components/misc/Dropdown'
import { useQuery } from "helpers/Url"

function Filter(props) {
    const { search, start_date, end_date, period, sent, payment_method_id, status, client_id } = props.filter
    const { typeId } = props

    const query = useQuery()

    const typeIds = props.filter['type_id[]']

    const handleSearch = e => {
        const { name, value } = e.target
        props.handleSearch(name, value)
    }

    const handleChangeClient = data => {
        props.handleSearch({
            client_id: data?.id || '',
        })
    }

    const handleClearFilter = () => {
        props.clearFilter()
    }

    const areAppliedFilters = () => {
        return search || start_date || end_date || sent || payment_method_id || status || period || client_id
    }

    return (
        <>
            <div className="row filter">
                <Tooltip
                    title="Търсене по име на документ"
                >
                    <div className="col search" >
                        <RedditTextField
                            size="small"
                            variant="filled"
                            margin="dense"
                            fullWidth
                            label="Търсене..."
                            name="search"
                            value={search || ''}
                            onChange={handleSearch}
                        />
                    </div>
                </Tooltip>
                <DocType
                    handleSearch={props.handleSearch}
                    typeIds={typeIds}
                    typeId={typeId}
                />
                {/* <Period
                    period={period}
                    dates={{
                        start_date,
                        end_date,
                    }}
                    handleSearch={props.handleSearch}
                /> */}
                <PeriodNew
                    period={period}
                    dates={{
                        start_date,
                        end_date,
                    }}
                    handleSearch={props.handleSearch}
                />
                <Sent
                    sent={sent}
                    handleSearch={props.handleSearch}
                />
                <Paid
                    data={{
                        status,
                        payment_method_id
                    }}
                    handleSearch={props.handleSearch}
                />
                <div
                    className="col"
                >
                    <Autocomplete
                        margin="none"
                        size="small"
                        variant="filled"
                        inputPlaceholder="Клиент"
                        url="store/autocomplete/clients"
                        inputIdName="client_id"
                        inputValue={''}
                        inputIdValue={props.filter.client_id || ''}
                        onChange={handleChangeClient}
                        onInputChange={handleChangeClient}
                        renderText={data => data.info?.name}
                    />
                </div>
                <div
                    className={`col link ${areAppliedFilters() ? '' : 'disabled'}`}
                    onClick={handleClearFilter}
                    style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: 'fit-content',
                        flexDirection: 'row',
                        flex: 'initial'
                    }}
                >
                    <img src={clearFilterIcon} style={{
                        marginRight: '5px'
                    }} />
                    Изчисти
                </div>
            </div>
        </>
    )
}

export default Filter