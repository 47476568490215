//images
import brand from "assets/img/brand-full.jpg"

function AppLoader() {
  return (
    <div className="app-loader">
      <img src={brand} alt="" className="brand" />
    </div>
  )
}

export default AppLoader
