import { useRef, useImperativeHandle, forwardRef } from 'react'
import Form from '../partials/Form'
import { useSubscriptionContext } from '../Provider'

function Index(props, ref) {
    const context = useSubscriptionContext()

    const formRef = useRef(null)

    useImperativeHandle(ref, () => ({
        save: () => {
            formRef.current?.save()
        },
    }))

    return (
        <>
            <Form
                ref={formRef}
                id={props.id}
                show={props.show}
                hide={props.hide}
            />
        </>
    )
}

export default forwardRef(Index)