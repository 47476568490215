import { useHistory } from "react-router-dom"
import moment from "moment"
import { FloatingLabel, Form } from 'react-bootstrap'

//providers
// import { useAuthDataContext } from "providers/Auth"

//helpers
import { useQuery } from 'helpers/Url'
import RedditTextField from "components/misc/RedditTextField"

function Filter(props) {
    const history = useHistory()
    const query = useQuery()

    // const auth = useAuthDataContext();


    const handleDateChange = (date, name) => {
        props.handleSearch(name, moment(date).format('YYYY-MM-DD'))
    }

    const handleSearch = e => {
        const name = e.target.name
        const value = e.target.value

        props.handleSearch(name, value)
    }


    return (
        <div className="row filter">
            <div
                className="col"
            >
                <RedditTextField
                    size="small"
                    variant="filled"
                    margin="dense"
                    label="Търсене..."
                    name="search"
                    value={props.filter.search || ''}
                    onChange={handleSearch}
                />
            </div>
        </div>
    )
}

export default Filter
