export default class Company {

    constructor(data) {
        Object.entries(data).forEach(e => {
            this[e[0]] = e[1];
        });
    }

    getId() {
        return this.id;
    }

    getInfo() {
        return this?.info;
    }

    getName() {
        return this?.info?.name;
    }

    getNumber() {
        return this?.info?.eikegn;
    }

    getVatNumber() {
        return this?.info?.vat_number;
    }

    getAddresses() {
        return this?.addresses || [];
    }

    getBank() {
        return this?.bank;
    }

    getBanks() {
        return this?.banks || [];
    }

    getSetting(key) {
        if (Array.isArray(this.settings)) {
            let setting = this.settings.find(s => s.key === key);

            if (setting) {
                return setting.value;
            }
        }

        return undefined;
    }

    getSettings() {
        let settings = {};

        if (Array.isArray(this.settings)) {
            this.settings.forEach(setting => {
                settings[setting.key] = setting.value;
            });
        }

        return settings;
    }

    hasBank() {
        return this?.has_bank || false;
    }

    hasEpay() {
        return this?.has_epay || false;
    }

    hasPayPal() {
        return this?.has_paypal || false;
    }

    hasStripe() {
        return this?.has_stripe || false;
    }

    getEpayUrl() {
        return this?.webhooks?.epay_url;
    }

    getStripeUrl() {
        return this?.webhooks?.stripe_url;
    }
}