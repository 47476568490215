import { useRef, useState } from "react"

//librarires
import { Menu, MenuItem } from "@material-ui/core"

//providers
import { useAuthDataContext } from "providers/Auth"

//helpers
import { isProd } from "helpers/mode"

function DownloadButtonLayout({
  children = <></>,
  downloadUrl,
  multipleDocumentsIds,
}) {
  const APP_URL = process.env[isProd ? "REACT_APP_URL" : "REACT_APP_URL_DEV"]

  const auth = useAuthDataContext()

  const childrenHolderRef = useRef()

  const [openMenu, setOpenMenu] = useState(false)

  // console.log(downloadUrl)

  function handleDownload(pdfUrl, type) {
    const url = `${pdfUrl}${getType(type)}`

    window.open(url)
  }

  function handleDownloadMultiple(type) {
    if (multipleDocumentsIds.length) {
      let url = `${APP_URL}documents/download?`
      if (multipleDocumentsIds.length) {
        multipleDocumentsIds.map((id, i) => {
          url += `${i === 0 ? "" : "&"}ids[]=${id}`
        })
      }

      url += `${getType(type)}&token=${auth.getToken()}`

      handleDownload(url)
    }
  }

  function getType(type) {
    switch (type) {
      case "copy":
        return "&options[copy]=1"

      case "withcopy":
        return "&options[withcopy]=1"

      default:
        return ""
    }
  }

  function handleOpenMenu() {
    setOpenMenu(true)
  }

  function handleCloseMenu() {
    setOpenMenu(false)
  }

  return (
    <>
      <Menu
        anchorEl={childrenHolderRef.current}
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        keepMounted
        open={openMenu}
        onClose={handleCloseMenu}
        onClick={handleCloseMenu}
      >
        <MenuItem
          onClick={() => {
            const type = "original"
            if (multipleDocumentsIds) {
              handleDownloadMultiple(type)
              return
            }
            handleDownload(downloadUrl, type)
          }}
        >
          Свали оригинал
        </MenuItem>
        <MenuItem
          onClick={() => {
            const type = "copy"
            if (multipleDocumentsIds) {
              handleDownloadMultiple(type)
              return
            }
            handleDownload(downloadUrl, type)
          }}
        >
          Свали копие
        </MenuItem>
        <MenuItem
          onClick={() => {
            const type = "withcopy"

            if (multipleDocumentsIds) {
              handleDownloadMultiple(type)
              return
            }
            handleDownload(downloadUrl, type)
          }}
        >
          Свали oригинал + копие
        </MenuItem>
      </Menu>
      <div
        ref={childrenHolderRef}
        onClick={handleOpenMenu}
        className="print-button-layout"
      >
        {children}
      </div>
    </>
  )
}

export default DownloadButtonLayout
