import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

//plugins
import { Modal, Button, Col, Row, FloatingLabel, Form, Table, Pagination, } from 'react-bootstrap';

//helpers
import Api from 'helpers/Api'
import { useValidation } from 'helpers/Validation'
import { useNestedState } from 'helpers/NestedState'

//providers
import { useLanguageContext } from 'providers/Language'

//misc
import Question from 'components/modals/Question';
import SaveButton from 'components/misc/Button'
import Info from 'components/modals/Info';
import Autocomplete from 'components/misc/Autocomplete';
import HelpInfo from 'components/misc/Info';
import moment from 'moment';
import PriceValue from 'components/partials/PriceValue';
import Loader from 'components/misc/Loader';
import NoDataFound from 'components/misc/NoDataFound';
import TableHeader from 'components/misc/TableHeader';
import TableCol from 'components/misc/TableCol';

function SelectAccountRowStorno(props, ref) {

    const defaultData = [];

    const [state, setState] = useNestedState({
        show: false,
        articleId: null,
        refresh: false,
        data: [],
        pages: 0,
        total: 0,
        loading: false,
        sorting: false,
        filter: {
            page: 1,
        },
        onSuccess: null,
        onEntering: null,
        onExiting: null,
        onClose: null,
        onSave: null,
    });

    const [selected, setSelected] = useState(null);

    const [validations, setValidations] = useValidation();

    const formRef = useRef(null);
    const tableRef = useRef(null);

    useImperativeHandle(ref, () => ({
        open: (articleId) => {
            open(articleId);
        },
        hide: () => {
            hide();
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }));
        },
        onSave: fn => {
            setState(prev => ({
                ...prev,
                onSave: fn
            }));
        }
    }));

    useEffect(() => {
        loading(true);

        if (!state.show) {
            return;
        }

        Api.get('accounts/find-account-row-for-storno', {
            params: {
                article_id: state.articleId,
                ...state.filter,
            }
        }).then(res => {
            setState(prev => ({
                ...prev,
                data: res.data.items,
                total: res.data.total,
                pages: res.data.pages,
                filter: {
                    ...prev.filter,
                    ...res.data.filter,
                },
            }));

            if (res.data.pages > 0 && state.filter.page > res.data.pages) {
                return handlePage({ selected: res.data.pages - 1 });
            }

            loading(false);
            sorting(false);
        });
    }, [state.refresh]);

    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }));
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }));
    }

    const open = (articleId) => {
        setState(prev => ({
            ...prev,
            data: defaultData,
            articleId: articleId,
            refresh: moment().unix()
        }));

        show();
    }

    const handleClose = e => {

        if (e) {
            e.preventDefault();
        }

        setState(prev => ({
            ...prev,
            data: defaultData,
            id: null,
            subjectId: null,
            subjectType: null
        }));

        setValidations(null);

        if (typeof state.onClose === 'function') {
            hide(state.onClose);
        } else {
            hide();
        }
    }

    const refresh = (reset = true) => {
        setState(prev => ({
            ...prev,
            refresh: moment().unix(),
        }));
    }

    const handleSave = e => {
        e.preventDefault();

        if (!selected) {
            return;
        }

        let row = state.data.find(r => r.id === selected);

        let obj = {
            storno_of_account_row_id: row.id,
            current_price: row.current_price,
            discount: row.discount,
        };

        state.onSave(obj);

        hide(state.onClose);
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(name, value, 'data')
    }

    const handleDateChange = e => {
        const name = e.target.name + '_ymd'
        const value = e.target.value

        setState(name, value, 'data')
    }

    const loading = (loading) => {
        setState(prev => ({
            ...prev,
            loading: Boolean(loading)
        }));
    }

    const sorting = (sorting) => {
        setState(prev => ({
            ...prev,
            sorting: Boolean(sorting)
        }));
    }

    const handlePage = page => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: page.selected + 1,
            },
            refresh: moment().unix()
        }));
    }

    const handleSort = (sort, order) => {
        setState(prev => ({
            ...prev,
            sorting: true,
            filter: {
                ...prev.filter,
                sort: sort,
                order: order,
                page: 1,
            },
            refresh: moment().unix()
        }));
    }

    return (
        <>

            <Modal size="lg" centered show={state.show} onHide={handleClose} onEntering={state.onEntering} onExiting={state.onExiting}>
                <form ref={formRef} onSubmit={handleSave}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Изберете сметка за сторниране
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        {state.loading && !state.sorting
                            ?
                            <Loader />
                            :
                            state.data.length === 0 ?
                                <NoDataFound>
                                    <div>
                                        Няма намерена информация!
                                    </div>
                                </NoDataFound>
                                :
                                <>
                                    <Table className="xxl" size="sm" striped bordered ref={tableRef}>
                                        <TableHeader
                                            tableRef={tableRef}
                                            activeSortKey={state.filter.sort}
                                            activeSortDir={state.filter.order}
                                            onSort={(col, dir) => handleSort(col, dir)}
                                        >
                                            <TableCol className="text-center">Избор</TableCol>
                                            <TableCol sortable sortKey="id">Сметка №</TableCol>
                                            <TableCol className="text-right" sortable sortKey="amount">Количество</TableCol>
                                            <TableCol className="text-right" sortable sortKey="storno_amount">Сторнирано</TableCol>
                                            <TableCol className="text-right" sortable sortKey="current_price">Продажна цена</TableCol>
                                            <TableCol sortable sortKey="created_at">Дата на документ</TableCol>
                                            <TableCol sortable sortKey="client">Клиент</TableCol>
                                        </TableHeader>
                                        <tbody>
                                            {state.data.map((r, i) =>
                                                <React.Fragment key={i}>
                                                    <tr >
                                                        <td className="text-center">
                                                            <input
                                                                type="radio"
                                                                name="selected"
                                                                value={r.id}
                                                                checked={selected === r.id}
                                                                onChange={e => setSelected(r.id)}
                                                            />
                                                        </td>
                                                        <td>
                                                            {r.account_id}
                                                        </td>
                                                        <td className="text-right">
                                                            {r.amount} {r.article?.amount_type_short}
                                                        </td>
                                                        <td className="text-right">
                                                            {r.storno_amount || 0} {r.article?.amount_type_short}
                                                        </td>
                                                        <td className="text-right">
                                                            <PriceValue prefix={r.account?.currency?.prefix} sufix={r.account?.currency?.sufix}>{r.current_price}</PriceValue>
                                                        </td>
                                                        <td>
                                                            {r.created_at ? moment(r.created_at).format('DD.MM.YYYY HH:mm') : ''}
                                                        </td>
                                                        <td>
                                                            {r.account?.client?.name}
                                                        </td>
                                                    </tr>
                                                </React.Fragment>
                                            )}
                                        </tbody>
                                    </Table>

                                    <Pagination
                                        className="mt-3"
                                        page={state.filter.page}
                                        pages={state.pages}
                                        total={state.total}
                                        handlePage={handlePage}
                                    />
                                </>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Отказ
                        </Button>
                        <SaveButton
                            disabled={selected === null}
                            className="save"
                            text="Избери"
                        />
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default forwardRef(SelectAccountRowStorno);
