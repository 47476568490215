import { Alert as MaterilAlert } from '@material-ui/lab'

function Alert(props) {
    const title = props.title
    const body = props.body || props.children

    return (
        <MaterilAlert variant="filled" severity="info">
            {title || body}
        </MaterilAlert>
    )

}

export default Alert