import { useEffect, useRef, useState } from 'react'
import Layout from 'components/Layout'
import { useLocation, useHistory, Link } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'
import { cloneDeep } from 'lodash'
import Pagination from 'components/misc/Pagination'
import SnackBar from 'components/misc/SnackBar'
import MenuOptions from 'components/misc/MenuOptions'

//modals
import Question from 'components/modals/Question'

//helpers
import Api from 'helpers/Api'
import { useQuery } from 'helpers/Url'

//providers
import { useAuthDataContext } from 'providers/Auth'
import { useAppContext } from 'providers/App'
import { usePayOrderContext } from './Provider'

//MUI components
import Checkbox from '@material-ui/core/Checkbox'
import Alert from '@material-ui/lab/Alert'
import { Button } from '@material-ui/core'

//images
import checkIcon from 'assets/img/app/icons/check.png'
import crossIcon from 'assets/img/app/icons/close.png'

//partials
import MultipleActions from './partials/MultipleActions'
// import Preview from './partials/Preview'
import AddButton from './partials/AddButton'
import Options from './partials/Options'
import Filter from './partials/Filter'
import Send from 'components/partials/documents/Send'

//misc
import NoSelectedCompany from 'components/misc/NoSelectedCompany'
import moment from 'moment'

let timeout

function Index() {
    const location = useLocation()
    const history = useHistory()
    const query = useQuery()
    const auth = useAuthDataContext()
    const app = useAppContext()
    const deleteModalRef = useRef(null)
    const successSnackbarRef = useRef(null)
    const errorSnackbarRef = useRef(null)
    const sendModalRef = useRef(null)

    // const [state, setState] = useState({
    //     loading: true,
    //     refresh: false,
    //     setFilter: false,
    //     data: [],
    //     pages: 0,
    //     total: 0,
    //     filter: {
    //         page: 1,
    //         company_id: query.get('company_id') || auth?.getUser()?.getCompany()?.id,
    //     },
    // })

    const context = usePayOrderContext();
    const { state, setState } = context;
    const [selected, setSelected] = useState({})

    useEffect(() => {
        if (state.setFilter) {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    page: query.get('page') || 1,
                    start_date: query.get('start_date') || '',
                    end_date: query.get('end_date') || '',
                    // company_id: query.get('company_id') || prev.filter.company_id,
                    search: query.get('search') || ''
                },
                refresh: moment().unix()
            }))
        }
    }, [location.search])

    useEffect(() => {
        Object.entries(state.filter).map(filter => {
            // if (filter[1]) {
            query.set(filter[0], filter[1])
            // }
        })

        if (state.setFilter) {
            history.push('?' + query.toString())
        } else {
            history.replace('?' + query.toString())
        }

    }, [state.setFilter])

    useEffect(() => {
        if (state.refresh) loadData()
    }, [state.refresh])

    useEffect(() => {
        if (state.data.length === 0) {
            loadData()
        }

        return () => {
            context.cleanUp();
        }
    }, [])

    const loadData = () => {
        if (state.forceLoading) {
            loading(true)
        }

        let url = 'documents/payorders/all'

        Object.entries(state.filter).map(filter => {
            url += (url.indexOf('?') > -1 ? '&' : '?') + filter[0] + '=' + filter[1]
        })

        Api.get(url)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    data: res.data.items,
                    total: res.data.total,
                    pages: res.data.pages,
                }))

                if (res.data.pages && res.data.pages < state.filter.page) handleSearch('page', res.data.pages, 0)
            })
            .finally(() => {
                loading(false)
            })
    }

    const loading = loading => {
        setState(prev => ({
            ...prev,
            loading: Boolean(loading),
            forceLoading: true,
        }))
    }

    const refresh = (reset = true, forceLoading = true) => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: reset ? 1 : prev.filter.page
            },
            refresh: moment().unix(),
            forceLoading: forceLoading
        }))
    }

    const success = (msg = null) => {
        app.handleSuccess(msg)
    }

    const error = () => {
        errorSnackbarRef.current.show()
    }

    const handleAdd = () => {
        history.push('payorders/add')
    }

    // update
    const handleEdit = id => {
        history.push(`payorders/edit/${id}`)
    }

    // delete
    const handleDelete = id => {
        const modal = deleteModalRef.current

        modal.open()
        modal.onSuccess(() => {
            handleDestroy(id)
        })
    }

    const handleDestroy = id => {
        Api.post('documents/payorders/delete', {
            id
        }).then(res => {
            if (res.data.success) refresh()
        })
    }

    const handlePreview = data => {
        window.open(data.signed_pdf_url)
    }

    const handleSend = id => {
        let email = null

        // if (!Array.isArray(id)) {
        //     const document = state.data.find(d => Number(d.id) === Number(id))

        //     if (document) {
        //         email = document?.payee?.email
        //     }
        // }

        const modal = sendModalRef.current

        modal.open(id, email)

        modal.onSuccess(() => {
            success()
        })

        modal.onError(() => {
            error()
        })
    }

    const handleSendSelected = () => {
        const ids = Object.keys(selected)

        if (ids.length) handleSend(ids)
    }


    // Search
    const handleSearch = (key, val, delay = 300) => {
        clearTimeout(timeout)

        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                [key]: val,
            },
        }))

        timeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    page: 1
                },
                setFilter: moment().unix()
            }))
        }, delay)
    }

    const handlePage = page => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: page.selected + 1,
            },
            setFilter: moment().unix()
        }))
    }

    const clearFilter = () => {
        return context.clearFilter();
    }

    const handleSelect = document => {
        const data = cloneDeep(selected)

        if (document) {
            if (data[document.id]) {
                delete data[document.id]
            } else {
                data[document.id] = 1
            }
        }

        setSelected(data)
    }

    const handleSelectAll = () => {
        const data = {}

        if (!areAllSelected()) {
            state.data.map(d => {
                data[d.id] = 1
            })
        }

        setSelected(data)
    }

    const getSelectedCount = () => {
        const selectedCount = Object.values(selected).length

        return selectedCount
    }

    const areAllSelected = () => getSelectedCount() === state.data.length

    return (
        <>
            {/* <PageLoader show={state.loading} /> */}
            {getSelectedCount() ?
                <MultipleActions
                    selectedCount={getSelectedCount()}
                    handleSendSelected={handleSendSelected}
                />
                :
                <>
                </>
            }
            <Question
                ref={deleteModalRef}
                mainMessage="Потвърждавам изтриването"
                secondaryMessage="Изтритите от Вас данни не могат да бъдат възстановени."
                agreeBtnText="Изтриване"
                agreeBtnClass="remove"
            />

            <Send
                ref={sendModalRef}
                documentsType="payorders"
            />

            <SnackBar
                ref={successSnackbarRef}
                severity="success"
            // open={true}
            >
                Действието е успешно!
            </SnackBar>

            <SnackBar
                ref={errorSnackbarRef}
                severity="error"
            >
                Нещо се обърка!
            </SnackBar>

            <Layout>
                <section id="documents">
                    <div id="head" className="row space-between">
                        <div className="title">
                            Моите платежни документи
                        </div>
                        <div
                            className="row right"
                            style={{
                                width: 'fit-content'
                            }}
                        >
                            <AddButton />

                        </div>
                    </div>

                    {!auth.getUser().getCompany()
                        ?
                        <NoSelectedCompany />
                        :
                        <div className="panel">
                            <Filter
                                filter={state.filter}
                                handleSearch={handleSearch}
                                clearFilter={clearFilter}
                            />
                            {state.loading
                                ?
                                <Skeleton count={5} height={60} />
                                :
                                state.data.length === 0 ?
                                    <Alert severity="error">
                                        Няма намерена информация!
                                    </Alert>
                                    :
                                    <>
                                        <table className="type-outer">
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <Checkbox
                                                            checked={areAllSelected()}
                                                            onChange={handleSelectAll}
                                                        />
                                                    </th>

                                                    <th>
                                                        Тип
                                                    </th>

                                                    <th>
                                                        Наредител
                                                    </th>

                                                    <th>
                                                        Получател
                                                    </th>

                                                    <th>
                                                        Основание
                                                    </th>

                                                    <th className="text-center">
                                                        Опции
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {state.data.map(document =>
                                                    <tr
                                                        key={'doc-' + document.id}
                                                        onClick={e => {
                                                            if (e.target) {
                                                                if (e.target.classList?.contains('MuiButtonBase-root') || e.target.getAttribute('aria-hidden') === 'true') return
                                                            }
                                                            handleEdit(document.id)
                                                        }}
                                                    >
                                                        <td>
                                                            <Checkbox
                                                                id={`document-cb-${document.id}`}
                                                                checked={selected[document.id] ? true : false}
                                                                color="primary"
                                                                onClick={() => {
                                                                    handleSelect(document)
                                                                }}
                                                            />
                                                        </td>

                                                        <td>
                                                            {document.type?.translation?.short_name}
                                                        </td>

                                                        <td>
                                                            {document?.payer?.name}
                                                        </td>

                                                        <td>
                                                            {document?.payee?.name}
                                                        </td>

                                                        <td>
                                                            {document?.description}
                                                        </td>

                                                        <td align="center">
                                                            <MenuOptions>
                                                                <Options
                                                                    data={document}
                                                                    handleEdit={handleEdit}
                                                                    handleSend={handleSend}
                                                                    handleDelete={handleDelete}
                                                                />
                                                            </MenuOptions>
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>

                                        <Pagination
                                            page={state.filter.page}
                                            pages={state.pages}
                                            handlePage={handlePage}
                                        />
                                    </>
                            }
                        </div>
                    }
                </section>
            </Layout>
        </>
    )
}

export default Index