import { MenuItem } from '@material-ui/core'

//images
import editIcon from 'assets/img/app/icons/edit.png'
import deleteIcon from 'assets/img/app/icons/delete.png'

function Options(props) {
    return (
        <>
            {props.data.allow_edit ?
                <MenuItem onClick={e => props.handleEdit(props.data.id)}>
                    <img src={editIcon} />
                    Редакция
                </MenuItem>
                :
                <>
                </>
            }
            {props.data.allow_delete ?
                <MenuItem onClick={e => props.handleDelete(props.data.id)}>
                    <img src={deleteIcon} />
                    Изтриване
                </MenuItem>
                :
                <>
                </>
            }
        </>
    )
}

export default Options