import { useHistory } from "react-router-dom"

//components
import Layout from "components/Layout"
import BillOperator from "./partials/BillOperator"
import PreviewBillTabs from "./partials/PreviewBillTabs"
import PreviewBillContent from "./partials/PreviewBillContent"

//libraries
import moment from "moment"
import { Button } from "@material-ui/core"

export default function Preview() {
  const navigate = useHistory()

  return (
    <Layout>
      <div className="preview-bill">
        <div className="head-with-heading">
          <div className="left">
            <h3
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              Продажба №1110056489 /{" "}
              <span>{moment().format("DD.MM.YYYY, HH:mm ч.")}</span>
            </h3>
          </div>
          <PreviewBillTabs />
          <BillOperator />
        </div>
        <div
          className="panel"
          style={{
            marginTop: 0,
            position: "relative",
          }}
        >
          <PreviewBillContent />
          <div id="footer">
            <div className="left"></div>
            <div className="right">
              <Button
                className="cancel"
                onClick={() => navigate.push("/accounts")}
              >
                Затвори
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
