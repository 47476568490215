//libraries
import { Button } from "@material-ui/core"

//components
import Pagination from "components/misc/Pagination"
import ComplaintsList from "../partials/ComplaintsList"

function Complaints({ clientId }) {
  // const addOrEditComplaintRef = useRef(null)

  return (
    <>
      <div className="complaints">
        <div className="row">
          <div className="col">
            <h6>Рекламации / Гаранции</h6>
            <div
              className="field"
              style={{
                borderTopLeftRadius: 0,
              }}
            >
              <ComplaintsList />
              <hr />
              <Button
                className="add"
                // onClick={() => addOrEditComplaintRef.current.add()}
              >
                Нова рекламация
              </Button>

              <Pagination page={1} pages={5} handlePage={() => {}} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Complaints
