import { useHistory } from "react-router"
import moment from "moment"
import { setCompany } from '../../../../../requests/User'

//providers
import { useAuthDataContext } from "providers/Auth"

//misc
import DatePicker from 'components/misc/DatePicker'
import RedditTextField from 'components/misc/RedditTextField'

//helpers
import { useQuery } from 'helpers/Url'

//images
import clearFilterIcon from 'assets/img/documents/icons/clear-filter.png'
import PeriodNew from "components/pages/documents/partials/filter/PeriodNew"

function Filter(props) {
    const history = useHistory()
    const query = useQuery()

    const auth = useAuthDataContext()

    const handleCompanyChange = data => {
        if (data && data.id) {
            props.handleSearch('company_id', data.id, 0)

            setCompany(data.id)
                .then(user => {
                    auth.setData(user)
                })

        } else {
            props.handleSearch('company_id', '', 0)
        }
    }

    const handleDateChange = (date, name) => {
        props.handleSearch(name, date)
    }

    const handleSearch = e => {
        const name = e.target.name
        const value = e.target.value

        props.handleSearch(name, value)
    }

    const handleClearFilter = () => {
        history.push(history.location.pathname)
    }

    const areAppliedFilters = () => {
        if (props.filter.search || props.filter.start_date || props.filter.end_date) return true
        else return false
    }

    return (
        <div className="row filter">
            <div
                className="col"
                style={{
                    maxWidth: '300px'
                }}
            >
                <RedditTextField
                    size="small"
                    variant="filled"
                    margin="dense"
                    fullWidth
                    label="Търсене..."
                    name="search"
                    value={props.filter.search || ''}
                    onChange={handleSearch}
                />
            </div>
            <PeriodNew
                period={props.filter.period}
                dates={{
                    start_date: props.filter.start_date || null,
                    end_date: props.filter.end_date || null,
                }}
                handleSearch={props.handleSearch}
            />
            <div
                className={`col link ${areAppliedFilters() ? '' : 'disabled'}`}
                onClick={handleClearFilter}
                style={{
                    justifyContent: 'center',
                    width: 'fit-content',
                    flex: 'initial'
                }}
            >
                <img src={clearFilterIcon} />
            </div>
        </div>
    )
}

export default Filter