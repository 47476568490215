//helpers
import Api from "helpers/Api"

//libraries
import { useQueryClient } from "react-query"

export default function useSaveToCalendar() {
    const queryClient = useQueryClient()

    function saveToCalendar(date, data, successCb, errorCb) {
        const formData = {
            data: {
                ...data,
                date,
            }
        }

        if (data.id) {
            formData.id = data.id
        }

        Api.post(`fake-crm-calendar/${data.id ? "edit" : "add"}-task`, formData)
            .then(() => {
                successCb()
                queryClient.invalidateQueries("fake-crm-calendar")
            })
    }

    return {
        saveToCalendar
    }
}