import React, { useEffect, useRef, useState } from 'react'
import Api from 'helpers/Api'
import { buildSearchParams, useQuery } from 'helpers/Url'
import { useLocation, Link, useHistory } from 'react-router-dom'
import { Alert, Table } from 'react-bootstrap'
import { useAuthDataContext } from 'providers/Auth'
import { Button } from '@material-ui/core'

import Options from './partials/Options'

import Question from 'components/modals/Question'
// import Filter from './partials/Filter'
import AddOrEdit from './partials/AddOrEdit'

//misc
import MenuOptions from 'components/misc/MenuOptions'
import Pagination from 'components/misc/Pagination'
import Loader from 'components/misc/Loader'
import NoDataFound from 'components/misc/NoDataFound'
import moment from 'moment'
import Refs from 'Refs'

let timeout

function Index() {

    const location = useLocation()
    const history = useHistory()
    const query = useQuery()
    const auth = useAuthDataContext()

    const addOrEditModalRef = useRef(null)
    const deleteModalRef = useRef(null)

    const [state, setState] = useState({
        loading: true,
        refresh: false,
        setFilter: false,
        data: [],
        pages: 0,
        total: 0,
        filter: {
            page: 1,
        },
    })

    useEffect(() => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: query.get('page') || 1,
                search: query.get('search') || ''
            },
            refresh: moment().unix()
        }))
    }, [location.search])

    useEffect(() => {
        if (state.setFilter) {
            history.push('?' + buildSearchParams(query, state.filter))
        }

    }, [state.setFilter])

    useEffect(() => {
        if (state.refresh) {
            loadData()
        }
    }, [state.refresh])

    useEffect(() => {
        refresh()
    }, [auth.getUser().getStoreId()])

    const loadData = () => {

        loading(true)

        let url = 'store/article-reviews/get'

        Api.get(url, {
            params: state.filter
        }).then(res => {
            setState(prev => ({
                ...prev,
                data: res.data.items,
                total: res.data.total,
                pages: res.data.pages
            }))

            if (res.data.pages > 0 && state.filter.page > res.data.pages) {
                return handlePage({ selected: res.data.pages - 1 })
            }

            loading(false)

        })
    }

    const loading = (loading) => {
        setState(prev => ({
            ...prev,
            loading: Boolean(loading)
        }))
    }

    const refresh = (reset = true) => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: reset ? 1 : prev.filter.page
            },
            setFilter: moment().unix(),
            refresh: moment().unix(),
        }))
    }

    const handleAdd = () => {
        let modal = addOrEditModalRef.current

        modal.add()

        modal.onSuccess(() => {
            refresh(true)
        })
    }

    // update
    const handleEdit = data => {
        let modal = addOrEditModalRef.current

        modal.edit(data.id)

        modal.onSuccess(() => {
            refresh(false)
        })
    }

    // delete
    const handleDelete = data => {
        let modal = deleteModalRef.current

        modal.open()
        modal.onSuccess(() => {
            handleDestroy(data.id)
        })
    }

    const handleDestroy = id => {
        Api.post('store/article-reviews/delete', {
            id: id
        }).then(res => {
            if (res.data.success) {
                refresh(false)
            }
        })
    }


    // Search
    const handleSearch = (key, val, delay = 300) => {
        clearTimeout(timeout)

        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                [key]: val,
            },
        }))

        timeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    page: 1
                },
                setFilter: moment().unix()
            }))
        }, delay)
    }

    const handlePage = page => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: page.selected + 1,
            },
            setFilter: moment().unix()
        }))
    }

    const handleShowArticle = id => {
        Refs.getInstance().getRef('article').open(id);
    }

    const handleShowClient = id => {
        Refs.getInstance().getRef('client').open(id);
    }

    return (
        <>

            <AddOrEdit
                ref={addOrEditModalRef}
                refresh={refresh}
            />

            <Question
                ref={deleteModalRef}
                mainMessage="Сигурни ли сте?"
                agreeBtnText="Изтриване"
                agreeBtnClass="remove"
            />

            <section id="companies">

                <div id="head">
                    <div className="title">
                        Ревюта
                    </div>
                    {/* <Button className="add" onClick={handleAdd}>
                        Добави
                    </Button> */}
                </div>
                <hr />

                <div className="panel">
                    {/* <Filter
                        filter={state.filter}
                        handleSearch={handleSearch}
                    /> */}

                    {state.loading
                        ?
                        <Loader />
                        :
                        state.data.length === 0 ?
                            <NoDataFound />
                            :
                            <>
                                <table className="type-outer">
                                    <thead>
                                        <tr>
                                            <th className="id">ID</th>
                                            <th>Артикул</th>
                                            <th>Клиент</th>
                                            <th>Рейтинг</th>
                                            <th>Коментар</th>
                                            <th className="options">
                                                Опции
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {state.data.map(c =>
                                            <React.Fragment key={'c-' + c.id}>
                                                <tr

                                                >
                                                    <td className="id">
                                                        {c.id}
                                                    </td>

                                                    <td>
                                                        <span className="link" onClick={e => handleShowArticle(c.article_id)}>
                                                            {c.article_name}
                                                        </span>
                                                    </td>

                                                    <td>
                                                        <span className="link" onClick={e => handleShowClient(c.client_id)}>
                                                            {c.client_name}
                                                        </span>
                                                    </td>

                                                    <td>
                                                        {c.rate}
                                                    </td>

                                                    <td>
                                                        {c.description}
                                                    </td>

                                                    <td className="options" style={{ width: '65px' }}>
                                                        <MenuOptions>
                                                            <Options
                                                                data={c}
                                                                handleEdit={handleEdit}
                                                                handleDelete={handleDelete}
                                                            />
                                                        </MenuOptions>
                                                    </td>
                                                </tr>

                                            </React.Fragment>
                                        )}
                                    </tbody>
                                </table>

                                <Pagination
                                    className="mt-3"
                                    page={state.filter.page}
                                    pages={state.pages}
                                    total={state.total}
                                    handlePage={handlePage}
                                />
                            </>
                    }

                </div>


            </section >
        </>
    )
}

export default Index