import { useState, useImperativeHandle, forwardRef } from 'react'

//helpers
import Api from 'helpers/Api'
import { useValidation } from 'helpers/Validation'

//librarires
import moment from 'moment'
import { MenuItem, InputAdornment, Button, TextField } from '@material-ui/core'

//misc
import RedditSelect from 'components/misc/RedditSelect'
import DatePicker from 'components/misc/DatePicker'
import RedditTextField from 'components/misc/RedditTextField'
import SaveButton from 'components/misc/Button'

//providers
import { useAppContext } from 'providers/App'
import { useAuthDataContext } from 'providers/Auth'

//constants
import { PAYMENT_EXTRA_DATA } from 'constants/PaymentMethod'

//images
import checkFilledIcon from 'assets/img/app/icons/check-filled.png'
import closeIcon from 'assets/img/modals/icons/close.png'

//hooks
import useCheckPaymentAvailability from '../../hooks/useCheckPaymentAvaiability'

function AddPayment({ }, ref) {
    const app = useAppContext()
    const auth = useAuthDataContext()

    const { isPaymentAvailable } = useCheckPaymentAvailability()

    const [state, setState] = useState({
        id: null,
        data: null,
        overlay: false,
        modal: false,
        params: null,
        onSuccess: null,
        onClose: null,
    })
    const [paymentMethods, setPaymentMethods] = useState({
        data: [],
        loading: true,
    })

    const [validations, setValidations] = useValidation()

    useImperativeHandle(ref, () => ({
        open: id => {
            handleOpen(id)
        },
        close: () => {
            handleHide()
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }))
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }))
        }
    }))

    const handleOpen = id => {
        setState(prev => ({
            ...prev,
            overlay: true,
            id
        }))
        loadPaymentMethods()
        setTimeout(() => {
            setState(prev => ({
                ...prev,
                modal: true,
            }))
        }, 50)
    }

    const loadPaymentMethods = async () => {
        const data = await app.getPaymentMethods()

        setPaymentMethods(prev => ({
            ...prev,
            data,
        }))

        setPaymentMethod(data[0])
    }

    const setPaymentMethod = method => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                payment_method_id: method.id,
                paymentmethod: method,
            }
        }))
    }

    const handlePaymentMethodChange = e => {
        const { value } = e.target
        const method = paymentMethods.data.find(p => Number(p.id) === Number(value))

        setPaymentMethod(method)
    }

    const handleHide = () => {
        setState(prev => ({
            ...prev,
            modal: false,
        }))

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                overlay: false,
            }))
        }, 100)

        resetData()
        setValidations(null)

        if (typeof state.onClose === 'function') state.onClose()
    }

    const resetData = () => {
        setState(prev => ({
            ...prev,
            data: null,
        }))
    }

    const handleInputChange = e => {
        const { name } = e.target
        const { value } = e.target

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: value
            }
        }))
    }

    const handleDateChange = date => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                date: moment(date).format('YYYY-MM-DD'),
            }
        }))
    }

    const handleSave = e => {
        e.preventDefault()

        setLoading(true)
        setValidations(null)

        const url = 'documents/add-payment'

        const data = new FormData(e.target)

        data.append('id', state.id)

        Api.post(url, data)
            .then(res => {
                if (typeof state.onSuccess === 'function') state.onSuccess(res.data)

                handleHide()
            })
            .catch(error => {
                const _err = error.response

                if (_err && _err.status && _err.status === 422) setValidations(_err.data.errors)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const setLoading = loading => {
        setState(prev => ({
            ...prev,
            loading,
        }))
    }

    return (
        <div className={`${state.overlay ? 'visible' : ''} overlay`}>
            <form className={`${state.modal ? 'show' : ''} popup-primary medium`} noValidate onSubmit={handleSave}>
                <h2 className="head">
                    Добавяне на частично плащане
                    <img className="close-icon" alt="close" src={closeIcon} onClick={handleHide} />
                </h2>

                <div className="body">
                    <div className="row">
                        <div className="col input-with-right-label" >
                            <TextField
                                variant="outlined"
                                size="small"
                                margin="normal"
                                fullWidth
                                type="number"
                                min="0"
                                label="Сума"
                                name="price"
                                value={state.data?.price || 0}
                                onChange={handleInputChange}
                                error={Boolean(validations && validations.price)}
                                // helperText={validations && validations.price && (validations.price[0] || validations.price)}
                                autoFocus
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">{state.data?.currency || 'BGN'}</InputAdornment>,
                                }}
                                style={{
                                    margin: 0,
                                    marginTop: '1px',
                                    height: '41px'
                                }}
                            />
                        </div>
                        <div
                            className='col'
                            style={{
                                width: '200px',
                                flex: 'initial'
                            }}>
                            <RedditSelect
                                variant="outlined"
                                name="payment_method_id"
                                value={state.data?.payment_method_id || ''}
                                onChange={handlePaymentMethodChange}
                                // label="Метод на плащане"
                                key={state.data?.payment_method_id}
                                style={{
                                    height: '41px',
                                }}
                            >
                                {paymentMethods.data.map(method =>
                                    <MenuItem
                                        key={'method-' + method.id}
                                        value={method.id}
                                    >
                                        <img src={PAYMENT_EXTRA_DATA[method.name].icon} />
                                        {method?.translation?.name}

                                        {isPaymentAvailable(method?.name || '') ?
                                            <img src={checkFilledIcon} />
                                            :
                                            <>
                                            </>
                                        }
                                    </MenuItem>
                                )}
                            </RedditSelect>
                        </div>
                        <div className='col'
                            style={{
                                flex: 1,
                            }}
                        >
                            <DatePicker
                                reddit
                                value={state.data?.date || moment().format('YYYY-MM-DD')}
                                onChange={e => handleDateChange(e.target.value)}
                                variant="outlined"
                                style={{
                                    height: '41px',
                                    width: '100%'
                                }}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <RedditTextField
                                fullWidth
                                label="Забележка"
                                name="description"
                                value={state.data?.description || ''}
                                multiline
                                rows="3"
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>
                    <input type="hidden" name="date" value={state.data?.date || ''} />
                </div>
                <div className="footer">
                    <Button
                        className="cancel"
                        onClick={handleHide}
                    >
                        Отказ
                    </Button>
                    <SaveButton
                        loading={state.loading}
                        className="save"
                    />
                </div>
            </form >
        </div >
    )
}

export default forwardRef(AddPayment)