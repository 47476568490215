//helpers
import { getSelectedEventType } from "helpers/Calendar"

//libraries
// import { Tooltip } from "@material-ui/core"
import moment from "moment"

//components
import MenuOptions from "components/misc/MenuOptions"
import CalendarAddOptions from "./CalendarAddOptions"
import CalendarEditOptions from "./CalendarEditOptions"

//images
import addIcon from "assets/img/customers/icons/add-note.png"

//stores
import { useAddToCalendarModal } from "stores/customer/useAddToCalendarModal"

//hooks
import useRemoveFromCalendar from "../hooks/useRemoveFromCalendar"

function DayOfWeekCalendarColumn({ date, events }) {
  const { showModal, setRefresh } = useAddToCalendarModal()
  const { removeFromCalendar } = useRemoveFromCalendar()

  function getBusyHeight(startHour, endHour) {
    const defaultHeight = 50

    if (!startHour || !endHour) {
      return defaultHeight
    }

    const startHourMoment = moment(startHour)
    const endHourMoment = moment(endHour)

    return (
      defaultHeight *
      (Math.floor(endHourMoment.diff(startHourMoment, "minutes") / 30) +
        1 +
        (endHourMoment.diff(startHourMoment, "minutes") % 30 !== 0 ? 1 : 0))
    )
  }

  // function setDummyDataToCalendar(hour) {
  //   setCurrentEvents((prev) => ({
  //     ...prev,
  //     [hour]: [
  //       ...prev[hour],
  //       {
  //         id: new Date().getTime(),
  //         title: "Събиране на екипа",
  //         start: "2022-07-01 01:00:00",
  //         end: "2022-07-01 02:30:00",
  //         type: "instagram",
  //       },
  //     ],
  //   }))
  // }

  function openAddEventModal(eventType, startHour) {
    showModal(true, {
      eventType,
      startDate: moment(date).format("YYYY-MM-DD"),
      endDate: moment(date).format("YYYY-MM-DD"),
      startHour,
      endHour: moment(startHour, "HH:mm").add(30, "minutes").format("HH:mm"),
    })
  }

  function isDayOf() {
    const dayOfWeek = moment(date).weekday()

    return dayOfWeek === 5 || dayOfWeek === 6
  }

  function handleClone(task) {
    delete task.id

    showModal(true, task)
  }

  function handleEdit(task) {
    showModal(true, task)
  }

  function handleRemove(id) {
    removeFromCalendar(id, setRefresh)
  }

  return (
    <li>
      <div className={`day-of-week ${isDayOf() ? "day-off" : ""}`}>
        <b>{moment(date).format("dddd")}</b>
        <p>{moment(date).format("DD.MM")}</p>
      </div>
      <ul className="cell-container">
        {Object.values(events).map((evs, i) => (
          <li key={Object.keys(events)[i]}>
            <MenuOptions
              hoverTooltipText={`${moment(date).format("DD.MM")} (${moment(
                date
              ).format("dddd")}) - ${Object.keys(events)[i]} ч.`}
              containsNestedLists
            >
              <CalendarAddOptions
                handleAdd={(eventType) =>
                  openAddEventModal(eventType, Object.keys(events)[i])
                }
              />
            </MenuOptions>
            {/* Ховър с "Нова бележка" */}
            {/* <div className="add-new">
              <MenuOptions
                hoverTooltipText={`${moment(date).format("DD.MM")} (${moment(
                  date
                ).format("dddd")}) - ${Object.keys(events)[i]} ч.`}
                containsNestedLists
              >
                <CalendarAddOptions
                  handleAdd={(eventType) =>
                    openAddEventModal(eventType, Object.keys(events)[i])
                  }
                />
              </MenuOptions>
              <img src={addIcon} alt="" />
              Нова бележка
            </div> */}
            {evs.map((event) => (
              // <Tooltip key={event.id} title={event.title} placement="top-end">

              <div
                key={event.id}
                className="event"
                style={{
                  width: `${100 / evs.length}%`,
                  left: `${(100 / evs.length) * evs.indexOf(event)}%`,
                  height: getBusyHeight(
                    `${event.startDate} ${event.startHour}`,
                    `${event.startDate} ${event.endHour}`
                  ),
                  background: getSelectedEventType(event.eventType).background,
                }}
              >
                <MenuOptions
                  hoverTooltipText={`Опции на ${event.title}`}
                  className="event-container"
                  placement="top"
                >
                  <CalendarEditOptions
                    handleDelete={() => handleRemove(event.id)}
                    handleEdit={() => handleEdit(event)}
                    handleClone={() => handleClone(event)}
                  />
                </MenuOptions>
                <b>{event.title}</b>
                <p>{`${event.startHour} - ${event.endHour}`}</p>
              </div>

              // </Tooltip>
            ))}
          </li>
        ))}
      </ul>
    </li>
  )
}

export default DayOfWeekCalendarColumn
