import { useEffect } from 'react'
import Layout from 'components/Layout'
import { useLocation, useParams } from 'react-router-dom'

//partals
import Documents from './Documents'
import Document from './Document'
import Tabs from './partials/Tabs'
import AddButton from './partials/AddButton'

//providers
import { useDocumentsTabsContext } from 'providers/DocumentsTabs'
import { useAuthDataContext } from 'providers/Auth'

//components
import Home from './Home'

function Index() {
    const auth = useAuthDataContext()
    const { getActiveSubtabId, handleAddNewSubtab, getActiveTabTypeId } = useDocumentsTabsContext()
    const params = useParams()
    const { state } = useLocation()
    const { data, mode } = state || {}

    function getMode() {
        return params.mode || mode
    }

    useEffect(() => {
        if (data || params.mode) handleAddNewSubtab(data, getMode)
    }, [])

    function hasWritePermission() {
        return auth.getUser().permission("documents:write")
    }

    function renderTabScreen() {
        if (getActiveTabTypeId() === 'home') {
            return <Home />
        }

        if (getActiveSubtabId()) {
            return <div className="active-doc">
                <Document
                    hasWritePermission={hasWritePermission()}
                    key={getActiveSubtabId()}
                />
            </div>
        }

        return <Documents />
    }

    return (
        <>
            <Layout>
                <div id="tabs-head" className="row space-between">
                    <Tabs />
                    <div
                        className="row right"
                        style={{
                            width: 'fit-content'
                        }}
                    >
                        {/* <AccountingButton /> */}
                        {hasWritePermission() ?
                            <AddButton />
                            :
                            <>
                            </>
                        }
                    </div>
                </div>
                {renderTabScreen()}
            </Layout>
        </>
    )
}

export default Index


