export function splitLongNumbers(number) {
    const parsedNumber = String(number)
    const leftPart = parsedNumber.split('.')[0]
    const rightPart = parsedNumber.split('.')?.[1] || null
    let newLeftPart = ''

    leftPart.split('').reverse().forEach((digit, index) => {
        if ((index % 3 === 0) && (index !== 0)) {
            newLeftPart += ` ${digit}`
        } else {
            newLeftPart += digit
        }
    })

    return `${newLeftPart.split("").reverse().join("")}${rightPart ? `.${rightPart}` : ''}`
}