import { useState, useImperativeHandle, forwardRef } from 'react'

//plugins
import { cloneDeep } from 'lodash'

//MUI components
import { Button, Menu, MenuItem, Tooltip } from '@material-ui/core'

//misc
import SaveButton from 'components/misc/Button'

//images
import closeIcon from 'assets/img/modals/icons/close.png'
import addIcon from 'assets/img/documents/icons/document-hover.png'
import nextIcon from 'assets/img/documents/icons/next.png'
import closeRedIcon from 'assets/img/settings/icons/close.svg'

//style
import 'assets/scss/send.scss'
import Autocomplete from 'components/misc/Autocomplete'

//helpers
import Api from 'helpers/Api'

//providers
import { useAppContext } from 'providers/App'
import RedditTextField from 'components/misc/RedditTextField'
import ClickAwayListener from 'react-click-away-listener'
import { useValidation } from 'helpers/Validation'
import RedditSelect from 'components/misc/RedditSelect'
import moment from 'moment'




const COLORS = ['2164B0', '0e61dd', 'F89938', 'FF4E77', '3BC870', 'FFC700', 'FA71CD', 'D62612', '435871', '00C9FF', 'B57652', '7979FF']
const VAT_RATES = [0, 9, 20]

const CATEGORY_EXAMPLE = {
    id: null,
    name: '',
    color: `#${COLORS[0]}`,
    editable: true,
    vat: VAT_RATES[2],
    is_public: 1,
}
const initialState = {
    parent: null,
    vatMenu: false,
    publicMenu: false,
    focusedRowIndex: 0,
    categories: [CATEGORY_EXAMPLE],
}
const formId = `add-product-form-${moment().unix()}`

function Send({ }, ref) {
    const app = useAppContext()

    const [state, setState] = useState({
        overlay: false,
        modal: false,
        ...initialState
    })

    const [validations, setValidations] = useValidation()

    useImperativeHandle(ref, () => ({
        open: (parent) => {
            show({ parent })
        },
        edit: (currCategory) => {
            show({ currCategory })
        },
        close: () => {
            hide()
        },
        isOpened: () => {
            if (state.modal || state.overlay) {
                return formId
            }

            return false
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }))
        },
        onError: fn => {
            setState(prev => ({
                ...prev,
                onError: fn
            }))
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }))
        }
    }))

    function getRandomColor() {
        const randomIndex = Math.floor(Math.random() * COLORS.length)

        return COLORS[randomIndex]
    }

    const show = ({ parent, currCategory }) => {
        setState(prev => ({
            ...prev,
            overlay: true,
            parent,
            categories: currCategory ? [currCategory] : [{ ...CATEGORY_EXAMPLE, color: `#${getRandomColor()}` }]
        }))

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                modal: true,
            }))
            setValidations({})
        }, 50)
    }

    const hide = () => {
        setState(prev => ({
            ...prev,
            modal: false,
        }))

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                overlay: false,
                ...initialState,
            }))
        }, 100)
    }

    const setLoading = loading => {
        setState(prev => ({
            ...prev,
            loading
        }))
    }

    const handleAddCategory = () => {
        setState(prev => ({
            ...prev,
            focusedRowIndex: (prev.focusedRowIndex || (prev.categories.length - 1)) + 1,
            categories: [
                ...prev.categories,
                cloneDeep({
                    ...CATEGORY_EXAMPLE,
                    color: `#${getRandomColor()}`
                })
            ]
        }))
    }

    /**
     * дали бутона за добавяне на категория да бъде достъпен.
     * изисква се първо да има нещо попълнено в полето
     * @returns {boolean}
     */
    const isAddCategoryEnabled = () => {
        if (state.categories[state.categories.length - 1].name.length) {
            return true
        }

        return false
    }

    /**
     * главна функция за обработка на данни от полетата и техните вложени категории (подкатегории)
     * @param {string} name 
     * @param {number} index 
     * @param {number || null} id 
     */
    const handleChange = (name, index) => {
        const categories = state.categories.map((c, i) => {
            if (i === index) {
                c.name = name
            }

            return c
        })

        setState(prev => ({
            ...prev,
            categories
        }))
    }

    /**
     * смяна на цвета на отделните полета
     * @param {string} color 
     * @param {number} index 
     */
    const handleChangeColor = (color, index) => {
        const categories = state.categories.map((c, i) => {
            if (i === index) {
                c.color = color
            }

            return c
        })

        setState(prev => ({
            ...prev,
            categories
        }))
    }

    /**
     * смяна на цвета на отделните полета
     * @param {string|number} vat 
     * @param {number} index 
     */
    const handleChangeVat = (vat, index) => {
        const categories = state.categories.map((c, i) => {
            if (i === index) {
                c.vat = vat
            }

            return c
        })

        setState(prev => ({
            ...prev,
            categories
        }))
    }

    /**
     * смяна на полето публична
     * @param {string|number} is_public 
     * @param {number} index 
     */
    const handleChangePublic = (is_public, index) => {
        setState(prev => ({
            ...prev,
            categories: prev.categories.map((c, i) => {
                if (i === index) {
                    c.is_public = is_public
                }

                return c
            })
        }))
    }

    function prepareCategoriesForRequest() {
        return state.categories.filter(c => c.name.length)
    }

    const handleSave = e => {
        e.preventDefault()

        const formData = new FormData(e.target)

        const data = state.parent ? {
            parent_id: state.parent.id,
            categories: prepareCategoriesForRequest(),
        } : {
            name: formData.get('name'),
            color: formData.get('color'),
            vat: state.categories[0].vat,
            is_public: state.categories[0].is_public,
            id: state.categories[0].id,
            parent_id: formData.get('parent_id') || null,
        }

        setLoading(true)

        const url = !state.parent && !state.categories[0].editable ? 'incomes/categories/edit' : state.parent ? 'incomes/categories/bulk-create' : 'incomes/categories/add'

        Api.post(url, data)
            .then(res => {
                if (typeof state.onSuccess === 'function') state.onSuccess(res.data)

                hide()
                app.handleSuccess('Категорията беше запазена успешно!')
            })
            .catch(error => {
                const _err = error.response
                if (_err && _err.status && _err.status === 422) setValidations(_err.data.errors)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const handleVatMenu = vatMenu => {
        setState(prev => ({
            ...prev,
            vatMenu
        }))
    }

    const handlePublicMenu = publicMenu => {
        setState(prev => ({
            ...prev,
            publicMenu
        }))
    }

    function handleRemoveCategory(index) {
        let updatedCategories = state.categories.filter((_, i) => i !== index)

        if (updatedCategories.length === 0) {
            updatedCategories = [{ ...CATEGORY_EXAMPLE, color: `#${getRandomColor()}` }]
            setFocuesdRowIndex(0)
        }

        setState(prev => ({
            ...prev,
            categories: updatedCategories,
        }))
    }

    function renderInputRow(c, i) {
        return <>
            {state.parent && state.focusedRowIndex !== i && c.name.length ?
                <div className="unfocused-field">
                    <span
                        className="color"
                        style={{
                            backgroundColor: c.color,
                        }}
                    >

                    </span>
                    <p>
                        {state.categories[i].name || ''}
                    </p>
                    <i>
                        ДДС {c.vat}%
                    </i>
                    <Tooltip title="Премахване на категорията">
                        <img src={closeRedIcon} onClick={() => {
                            handleRemoveCategory(i)
                        }} />
                    </Tooltip>
                </div>
                :
                <>
                    <span
                        className="color"
                        style={{
                            backgroundColor: c.color,
                        }}
                    >
                        <input
                            type="color"
                            name="color"
                            value={c.color}
                            onChange={e => {
                                handleChangeColor(e.target.value, i)
                            }}
                        />
                    </span>
                    <RedditTextField
                        size="small"
                        // margin="dense"
                        fullWidth
                        label="Наименование"
                        name="name"
                        value={state.categories[i].name || ''}
                        onChange={e => {
                            const { value } = e.target

                            handleChange(value, i)
                        }}
                        // onChange={handleInputChange}
                        error={Boolean(validations && validations.name)}
                        helperText={validations && validations.name && (validations.name[0] || validations.name)}
                        autoFocus
                        required
                    />
                    <Tooltip title="Промяна на процент ДДС">
                        <Button
                            className={`vat-index-${i}`}
                            onClick={() => {
                                handleVatMenu(i)
                            }}
                        >
                            ДДС {c.vat}%
                        </Button>
                    </Tooltip>
                    <Menu
                        anchorEl={document.querySelector(`.vat-index-${i}`)}
                        keepMounted
                        open={state.vatMenu === i}
                        onClose={() => {
                            handleVatMenu(false)
                        }}
                    >
                        {VAT_RATES.map((v, j) =>
                            <MenuItem
                                key={`vat-rate-${i}-${j}`}
                                onClick={() => {
                                    handleChangeVat(v, i)
                                    handleVatMenu(false)
                                }}
                            >
                                {v}%
                            </MenuItem>
                        )}
                    </Menu>
                    <Button
                        className={`public-index-${i}`}
                        onClick={() => {
                            handlePublicMenu(i)
                        }}
                    >
                        Публична: {c.is_public ? 'ДА' : 'НЕ'}
                    </Button>
                    <Menu
                        anchorEl={document.querySelector(`.public-index-${i}`)}
                        keepMounted
                        open={state.publicMenu === i}
                        onClose={() => {
                            handlePublicMenu(false)
                        }}
                    >
                        <MenuItem
                            onClick={() => {
                                handleChangePublic(1, i)
                                handlePublicMenu(false)
                            }}
                        >
                            ДА
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                handleChangePublic(0, i)
                                handlePublicMenu(false)
                            }}
                        >
                            НЕ
                        </MenuItem>
                    </Menu>
                </>
            }
        </>
    }

    function setFocuesdRowIndex(focusedRowIndex = null) {
        setState(prev => ({
            ...prev,
            focusedRowIndex
        }))
    }

    return (
        <div className={`${formId} ${state.overlay ? 'visible' : ''} overlay`}>
            {state.overlay ?
                <form
                    className={`${state.modal ? 'show' : ''} add-product-modal settings-category-modal popup-primary small`}
                    noValidate
                    id={formId}
                    onSubmit={handleSave}
                >
                    <div className="body">
                        <h3 className="head">
                            Добавяне на {state.parent ? 'подкатегория' : 'категория'}
                            <img
                                className="close-icon"
                                alt="close"
                                src={closeIcon}
                                onClick={hide}
                            />
                        </h3>
                        <p>
                            Добавете Вашият продукт към избрана категория или подкатегория или създайте нови такива.
                        </p>
                        {state.parent ?
                            <>
                                <b>
                                    {state.parent.name}
                                </b>
                                <ClickAwayListener onClickAway={() => {
                                    setFocuesdRowIndex()
                                }}>
                                    <ul className="categories">
                                        {state.categories.map((c, i) =>
                                            <li
                                                key={`category-${i}`}
                                                style={{
                                                    marginLeft: `${30}px`,
                                                    // pointerEvents: !c.editable ? 'none' : 'initial'
                                                }}
                                                className="with-vat"
                                                onClick={(e) => {
                                                    if (e.target.tagName === "IMG") {
                                                        return
                                                    }

                                                    setFocuesdRowIndex(i)
                                                }}
                                            >

                                                {renderInputRow(c, i)}

                                            </li>
                                        )}
                                        <Tooltip
                                            title={!isAddCategoryEnabled() ? 'Моля, първо въведете създаденото поле за категория.' : ''}
                                        >
                                            <li
                                                style={{
                                                    marginLeft: `${30}px`
                                                }}
                                                className={`add ${!state.categories.at(-1).name.length ? 'disabled' : ''}`}
                                            >
                                                <Button
                                                    onClick={handleAddCategory}
                                                >
                                                    <img src={addIcon} />
                                                    Подкатегория
                                                </Button>
                                            </li>
                                        </Tooltip>
                                    </ul>
                                </ClickAwayListener>
                            </>
                            :
                            <>
                                <ul className="categories">
                                    <li className="with-vat product">
                                        {renderInputRow(state.categories[0], 0)}
                                    </li>
                                </ul>
                                {!state.categories[0].editable ?
                                    <Autocomplete
                                        variant=""
                                        size="small"
                                        inputPlaceholder="Вмъкване в категория"
                                        inputName={`parent_name`}
                                        inputIdName={`parent_id`}
                                        url={`incomes/categories/simple-list?except=${state.categories[0]?.id}`}
                                        getResultData={data => data.items}
                                        getOptionLabel={option => option?.translation?.name || ''}
                                        renderOption={option => option?.translation?.name || ''}
                                        selectedId={state.categories[0]?.parent_id}
                                    />
                                    :
                                    <>
                                    </>
                                }
                            </>
                        }


                    </div>
                    <div className="footer">
                        <Button
                            onClick={hide}
                            className="cancel"
                        >
                            Отказ
                        </Button>
                        <Tooltip title={(!state.categories[0]?.name?.length || 0) ? 'Моля, изберете/въведете категория преди да запазите.' : ''}>
                            <div>
                                <SaveButton
                                    loading={state.loading}
                                    text="Запазване"
                                    className="save"
                                    form={formId}
                                    disabled={!state.parent ? false : (!state.categories[0]?.name?.length || 0) ? true : false}
                                />
                            </div>
                        </Tooltip>
                    </div>
                </form>
                :
                <>
                </>
            }
        </div >
    )
}

export default forwardRef(Send)