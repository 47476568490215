import { Card } from 'react-bootstrap'
import Payments from './partials/Payments'
import ClientPayments from './partials/ClientPayments'

function Index(props) {

    return (
        <div className="preview-bill-payments">
            <Payments
                id={props.id}
                showPayment={props.showPayment}
            />
        </div>
    )
}

export default Index