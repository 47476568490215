import { forwardRef, useImperativeHandle, useState } from 'react'

//images
import loader from 'assets/img/loader.svg'
import closeIcon from 'assets/img/modals/icons/close.png'

//MUI components
import { Button } from '@material-ui/core'

function Index(props, ref) {
    const [state, setState] = useState({
        overlay: false,
        modal: false,
        img: null
    })

    useImperativeHandle(ref, () => ({
        show: () => {
            handleShow()
        },
        hide: () => {
            handleHide()
        },
        setSrc: src => {
            setSrc(src)
        }
    }));

    const handleShow = () => {
        setState(prev => ({
            ...prev,
            overlay: true,
        }))

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                overlay: true,
                modal: true
            }))
        }, 50)
    }

    const handleHide = e => {
        e.preventDefault()

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                overlay: false,
                modal: false,
                img: null
            }))
        }, 50)
    }

    const setSrc = src => {
        setState(prev => ({
            ...prev,
            img: src
        }))
    }

    return (
        <div className={`${state.overlay ? 'visible' : ''} overlay`}>

            <div
                className={`${state.modal ? 'show' : ''} popup-primary small`}
                style={{ textAlign: 'center' }}
            >
                <h2 className="head">
                    Свързване чрез QR код
                    <img
                        className="close-icon"
                        alt="close"
                        src={closeIcon}
                        onClick={handleHide}
                    />
                </h2>

                <div className="body">
                    <img src={state.img || loader} alt="qrcode" width="200" height="200" />

                    <p>
                        Сканирайте QR кода, за да се продължите.
                    </p>
                </div>

                <div
                    className="footer"
                    style={{
                        justifyContent: 'center'
                    }}
                >
                    <Button
                        className="cancel"
                        onClick={handleHide}
                    >
                        Назад
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default forwardRef(Index);