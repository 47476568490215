//probiders
import { useAppContext } from "providers/App"
import { useDocumentsTabsContext } from "providers/DocumentsTabs"

//helpers
import Api from "helpers/Api"

function useConvertToInvoice() {
    const { handleInfo, handleError, handleHideInfo, handleSuccess } = useAppContext()
    const { handleAddNewSubtab } = useDocumentsTabsContext()

    function handleConvertToInvoice(id) {
        handleInfo('Издаване на фактура...')

        Api.post('documents/convert-to-invoice', {
            id
        })
            .then(res => {
                handleSuccess('Фактура е създадена!')
                handleAddNewSubtab(res.data, 'preview')
            })
            .catch(() => {
                handleError()
            })
            .finally(() => {
                handleHideInfo()
            })
    }

    return {
        handleConvertToInvoice
    }
}

export default useConvertToInvoice