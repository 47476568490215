import { useEffect, useRef, useState } from 'react'
import { useLocation, useHistory, Link } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'
import Layout from '../../Layout'

//helpers
import Api from 'helpers/Api'
import { useQuery } from 'helpers/Url'

//MUI components
import { Button } from '@material-ui/core'

//misc
import Pagination from '../../misc/Pagination'
import MenuOptions from '../../misc/MenuOptions'
import NoSelectedCompany from 'components/misc/NoSelectedCompany'
import NoRescordsFound from 'components/misc/NoRecordsFound'
import PaginationMini from 'components/misc/PaginationMini'

//modals
import Question from '../../modals/Question'
import AddOrEdit from './partials/AddOrEdit'

//providers
import { useAuthDataContext } from 'providers/Auth'
import Provider from './Provider'

//partials
import Filter from './partials/Filter'
import Options from './partials/Options'
import Refs from 'Refs'
import moment from 'moment'

let timeout
function Index() {
    const location = useLocation()
    const history = useHistory()
    const query = useQuery()
    const auth = useAuthDataContext()

    const addOrEditModalRef = useRef(null)
    const deleteModalRef = useRef(null)

    const [state, setState] = useState({
        loading: true,
        refresh: false,
        setFilter: false,
        data: [],
        pages: 0,
        total: 0,
        filter: {
            page: 1,
            company_id: query.get('company_id') || auth?.getUser()?.getCompany()?.id,
        },
    })

    useEffect(() => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: query.get('page') || 1,
                start: query.get('start') || '',
                end: query.get('end') || '',
                company_id: query.get('company_id') || prev.filter.company_id || '',
            },
            refresh: moment().unix()
        }))
    }, [location.search])

    useEffect(() => {
        if (state.setFilter) {
            Object.entries(state.filter).map(filter => {
                // if (filter[1]) {
                query.set(filter[0], filter[1])
                // }
            })

            history.push('?' + query.toString())
        }

    }, [state.setFilter])

    useEffect(() => {
        if (state.refresh) loadData()
    }, [state.refresh])

    const loadData = () => {
        if (!state.filter.company_id) return

        loading(true)

        let url = 'customers/all?resource=1'

        Object.entries(state.filter).map(filter => {
            url += (url.indexOf('?') > -1 ? '&' : '?') + filter[0] + '=' + filter[1]
        })

        Api.get(url)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    data: res.data.items,
                    total: res.data.total,
                    pages: res.data.pages
                }))
            })
            .finally(() => {
                loading(false)
            })
    }

    const loading = loading => {
        setState(prev => ({
            ...prev,
            loading: Boolean(loading)
        }))
    }

    const refresh = (reset = true) => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: reset ? 1 : prev.filter.page
            },
            refresh: moment().unix()
        }))
    }

    const handleShow = data => {
        let modal = Refs.getInstance().getRef('client')

        if (!modal) {
            return
        }

        modal.open(data.id)
    }

    const handleAdd = () => {
        let modal = addOrEditModalRef.current

        modal.add()

        modal.onSuccess(() => {
            refresh(true)
        })
    }

    // update
    const handleEdit = data => {
        let modal = addOrEditModalRef.current

        modal.edit(data.id)

        modal.onSuccess(() => {
            refresh(false)
        })
    }

    // delete
    const handleDelete = data => {
        const modal = deleteModalRef.current

        modal.open()
        modal.onSuccess(() => {
            handleDestroy(data.id)
        })
    }

    const handleDestroy = id => {
        Api.post('customers/delete', {
            id
        })
            .then(res => {
                if (res.data.success) refresh()
            })
    }

    // Search
    const handleSearch = (key, val, delay = 300) => {
        clearTimeout(timeout)

        if (typeof key === 'object') {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    ...key
                },
            }))
        } else {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    [key]: val,
                },
            }))
        }

        timeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    page: 1
                },
                setFilter: moment().unix()
            }))
        }, delay)
    }

    const handlePage = page => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: page.selected + 1,
            },
            setFilter: moment().unix()
        }))
    }

    return (
        <>
            <Provider>
                <AddOrEdit
                    ref={addOrEditModalRef}
                />
            </Provider>

            <Question
                ref={deleteModalRef}
                mainMessage="Сигурни ли сте?"
            />

            <Layout>
                <div className="store-moves">
                    <div className="head-with-heading">
                        <h3>Клиенти</h3>

                        <div className="buttons">
                            <Button
                                className="add"
                                onClick={handleAdd}
                            >
                                Нов клиент
                            </Button>
                        </div>
                    </div>
                    <div
                        className="panel"
                        style={{
                            marginTop: 0,
                        }}
                    >
                        <div className='filter-holder'>
                            <Filter
                                filter={state.filter}
                                handleSearch={handleSearch}
                            />
                            <PaginationMini
                                isLoading={state.loading}
                                page={state.filter.page}
                                pages={state.pages}
                                handlePage={handlePage}
                            />
                        </div>
                        {state.loading
                            ?
                            <Skeleton count={5} height={60} />
                            :
                            state.data.length === 0
                                ?
                                <NoRescordsFound />
                                :
                                <>

                                    <table className="type-outer">
                                        <thead>
                                            <tr>
                                                <th>Наименование</th>
                                                <th>ЕИК/ЕГН</th>
                                                <th>Е-поща</th>
                                                <th>Телефон</th>
                                                <th>Адрес</th>
                                                <th className="text-center">Опции</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {state.data.map(c =>
                                                <tr key={'c-' + c.id}>
                                                    <td
                                                        onClick={() => {
                                                            handleShow(c)
                                                        }}
                                                    >
                                                        {c?.info?.name}
                                                    </td>
                                                    <td
                                                        onClick={() => {
                                                            handleShow(c)
                                                        }}
                                                    >
                                                        {c?.info?.eikegn}
                                                    </td>
                                                    <td
                                                        onClick={() => {
                                                            handleShow(c)
                                                        }}
                                                    >
                                                        {c?.info?.email}
                                                    </td>
                                                    <td
                                                        onClick={() => {
                                                            handleShow(c)
                                                        }}
                                                    >
                                                        {c?.info?.phone}
                                                    </td>
                                                    <td
                                                        onClick={() => {
                                                            handleShow(c)
                                                        }}
                                                    >
                                                        {c.address?.full_address}
                                                    </td>
                                                    <td className="text-center">
                                                        <MenuOptions>
                                                            <Options
                                                                data={c}
                                                                handleShow={handleShow}
                                                                handleEdit={handleEdit}
                                                                handleDelete={handleDelete}
                                                            />
                                                        </MenuOptions>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>

                                    <Pagination
                                        page={state.filter.page}
                                        pages={state.pages}
                                        handlePage={handlePage}
                                    />
                                </>
                        }
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default Index