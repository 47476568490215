import { useEffect, useRef, useState } from 'react';
import Api from 'helpers/Api';
import { buildSearchParams, buildUrl, useQuery } from 'helpers/Url';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { Alert, Button, Table, OverlayTrigger, Tooltip, Form, Col } from 'react-bootstrap';
import { cloneDeep } from 'lodash';
import axios from 'axios';

import Options from './partials/Options';

import Filter from './partials/Filter';
import MassOperationsColumns from './partials/MassOperationsColumns';
import Layout from 'components/Layout';

//misc
import MenuOptions from 'components/misc/MenuOptions'
import Pagination from 'components/misc/Pagination'
import Loader from 'components/misc/Loader';
import NoDataFound from 'components/misc/NoDataFound';
import HelpInfo from 'components/misc/Info';
import Title from 'components/misc/Title';
import FilePreview from '../../partials/FilePreview';
import PriceValue from 'components/partials/PriceValue';
import TableHeader from 'components/misc/TableHeader';
import DynamicTableCol from 'components/partials/table/DynamicTableCol';
import DynamicTableBodyCol from 'components/partials/table/DynamicTableBodyCol';
import SaveButton from 'components/misc/Button';
import Autocomplete from 'components/misc/Dropdown';

import { useAuthDataContext } from 'providers/Auth';
import { ArrowLeftCircle, PencilFill, PencilSquare } from 'react-bootstrap-icons';
import { useValidation } from 'helpers/Validation';

// import { CKEditor } from '@ckeditor/ckeditor5-react';
import Categorisation from './partials/Categorisation';
import { useAppContext } from 'providers/App';

// const ClassicEditor = window.ClassicEditor;

import './partials/mass-update.scss';

import { isNumeric } from 'helpers/Price';
import { renderArticleDetailValueText } from 'constants/autocomplete';
import moment from 'moment'

let timeout;

function MassUpdate() {

    const app = useAppContext();

    const location = useLocation();
    const history = useHistory();
    const query = useQuery();
    const auth = useAuthDataContext();

    const tableRef = useRef(null);
    const massOperationsColumnsModalRef = useRef(null);
    const categoriesModalRef = useRef(null);

    const [state, setState] = useState({
        sorting: false,
        loading: true,
        refresh: false,
        setFilter: false,
        data: [],
        headings: {},
        types: {},
        pages: 0,
        total: 0,
        filter: {
            page: 1,
        },
        columns: {
            all: {},
            details: {},
            selected: [],
            sortable: [],
            sort: null,
            order: null,
        },
        tableKey: '',
        request: null,
    });

    const [amountTypes, setAmountTypes] = useState([]);
    const [taxGroups, setTaxGroups] = useState([]);

    const [newState, setNewState] = useState({
        loading: false,
        data: {}
    });

    const [selectedCategories, setSelectedCategories] = useState({});

    const [options, setOptions] = useState({
        enabled: false,
        field: '',
        operator: '=',
        value: '',
    });

    const [validations, setValidations] = useValidation();

    const inputMap = {
        amount_type: 'amount_type_id',
        tax: 'tax_id',
    }

    useEffect(() => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: query.get('page') || 1,
                search: query.get('search') || '',
                ref: query.get('ref') || '',
                category_id: query.getAll('category_id[]') || [],
                detail_value_id: JSON.parse(query.get('detail_value_id')) || {},
                prices: query.getAll('prices[]') || [],
                created_from_date: query.get('created_from_date') || '',
                created_to_date: query.get('created_to_date') || '',
                updated_from_date: query.get('updated_from_date') || '',
                updated_to_date: query.get('updated_to_date') || '',
                barcode: query.get('barcode') || '',
                file: query.get('file') || '',
                deleted: query.get('deleted') || '',
                current_price_less_than_avg_delivery_price: query.get('current_price_less_than_avg_delivery_price') || 0,
                columns: query.getAll('columns[]') || [],
                sort: query.get('sort') || '',
                order: query.get('order') || '',
            },
            refresh: moment().unix()
        }))
    }, [location.search]);

    useEffect(() => {
        if (state.setFilter) {
            history.push('?' + buildSearchParams(query, state.filter));
        }

    }, [state.setFilter]);

    useEffect(() => {
        if (state.refresh) {
            loadData();
        }
    }, [state.refresh]);

    useEffect(() => {
        refresh();
    }, [auth.getUser().getStoreId()]);

    useEffect(() => {
        Api.get('store/articles/amount-types')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setAmountTypes(res.data);
                }
            });

        Api.get('store/articles/tax-groups')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setTaxGroups(res.data);
                }
            });
    }, []);

    const loadData = () => {

        if (state.request) {
            state.request.cancel();
        }

        let request = axios.CancelToken.source();

        setState(prev => ({
            ...prev,
            request: request,
            loading: true,
        }));

        let url = 'store/articles/all';

        Api.get(url, {
            params: state.filter,
            cancelToken: request.token
        }).then(res => {
            setState(prev => ({
                ...prev,
                data: res.data.items,
                total: res.data.total,
                pages: res.data.pages,
                headings: res.data.headings,
                types: res.data.types,
                columns: res.data.columns,
                tableKey: res.data.tableKey,
                loading: false,
                sorting: false,
                filter: {
                    ...prev.filter,
                    ...res.data.filter,
                }
            }));

            if (res.data.pages > 0 && state.filter.page > res.data.pages) {
                return handlePage({ selected: res.data.pages - 1 });
            }
        });
    }

    const loading = (loading) => {
        setState(prev => ({
            ...prev,
            loading: Boolean(loading)
        }));
    }

    const refresh = (reset = true) => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: reset ? 1 : prev.filter.page
            },
            setFilter: moment().unix(),
            refresh: moment().unix(),
        }));
    }

    const refreshTable = () => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                sort: '',
                order: '',
                page: 1,
                limit: '',
            },
            setFilter: moment().unix(),
            refresh: moment().unix(),
        }));
    }

    // Search
    const handleSearch = (key, val, delay = 300) => {
        clearTimeout(timeout);

        if (typeof key === 'object') {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    ...key
                },
            }));
        } else {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    [key]: val,
                },
            }));
        }

        timeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    page: 1
                },
                setFilter: moment().unix()
            }));
        }, delay);
    }

    const handlePage = page => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: page.selected + 1,
            },
            setFilter: moment().unix(),
        }));
    }

    const sorting = (sorting) => {
        setState(prev => ({
            ...prev,
            sorting: Boolean(sorting)
        }));
    }

    const handleSort = (sort, order) => {
        setState(prev => ({
            ...prev,
            sorting: true,
            filter: {
                ...prev.filter,
                sort: sort,
                order: order,
                page: 1,
            },
            setFilter: moment().unix()
        }));
    }

    const showMassOperations = () => {
        let modal = massOperationsColumnsModalRef.current;

        if (!modal) {
            return;
        }

        modal.open();

        modal.onSuccess((columns) => {
            handleSearch('columns', columns);

            setNewState(prev => ({
                ...prev,
                data: {}
            }));
        });
    }

    const getInputName = column => {
        return inputMap[column] || column;
    }

    const handleInputClick = (e, id) => {
        if (!options.enabled) {
            return;
        }

        // Избраното поле
        if (options.field === '') {
            let name = e.target.name;

            let value = e.target.value;

            if (isModified(name, id)) {
                changeValue(name, undefined, id);

                return;
            }

            value = calcNewValue(name, value, id);

            changeValue(name, value, id);
        }
    }

    const handleInputChange = (e, id) => {
        let name = e.target.name;
        let value = e.target.value;

        changeValue(name, value, id);
    }

    const changeValue = (column, value, id) => {
        let data = cloneDeep(newState.data);

        if (!data[id]) {
            data[id] = {};
        }

        if (typeof column === 'object') {
            data[id] = Object.assign(data[id], column);
        } else if (typeof value !== 'undefined') {
            data[id][column] = value;
        } else {
            delete data[id][column];
        }

        // console.log(data[id]);

        // if (Object.entries(data[id]).length === 0) {
        //     delete data[id];
        // }

        // setNewState(prev => ({
        //     ...prev,
        //     data: {
        //         ...prev.data,
        //         [id]: {
        //             ...prev.data[id],
        //             [column]: value
        //         }
        //     }
        // }));

        setNewState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                ...data
            }
        }));
    }

    const changeValueForAll = (column, value) => {
        let data = cloneDeep(newState.data);

        let name = getInputName(column);

        state.data.map(row => {
            if (!data[row.article_id]) {
                data[row.article_id] = {};
            }

            let oldValue = getInputValue(name, row.article_id);
            let newValue = value;

            if (isModified(name, row.article_id)) {
                newValue = undefined;
            } else {
                newValue = calcNewValue(name, oldValue, row.article_id);
            }

            if (typeof column === 'object') {
                data[row.article_id] = Object.assign(data[row.article_id], column);
            } else if (typeof newValue !== 'undefined') {
                data[row.article_id][name] = newValue;
            } else {
                delete data[row.article_id][name];

                if (name === 'categories') {
                    delete data[row.article_id]['category_id'];
                    delete data[row.article_id]['master_cat_id'];
                }
            }
        });

        setNewState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                ...data
            }
        }));
    }

    const getInputValue = (column, id) => {
        column = getInputName(column);

        if (newState.data[id] && typeof newState.data[id][column] !== 'undefined') {
            if (newState.data[id][column] !== null) {
                return newState.data[id][column];
            }
        }

        let row = state.data.find(row => row.article_id === id);

        if (row) {
            return row[column];
        }

        return null;
    }

    const getInputRawValue = (column, id) => {
        column = getInputName(column);

        if (newState.data[id] && typeof newState.data[id][column] !== 'undefined') {
            if (newState.data[id][column] !== null) {
                return newState.data[id][column];
            }
        }

        return getInputOldRawValue(column, id);
    }

    const getInputOldRawValue = (column, id) => {
        column = getInputName(column);

        let row = state.data.find(row => row.article_id === id);

        if (row) {
            return row.article[column];
        }

        return null;
    }

    const isModified = (column, id) => {
        if (newState.data[id] && typeof newState.data[id][column] !== 'undefined') {
            if (newState.data[id][column] !== null) {
                return true;
            }
        }

        return false;
    }

    const hasChanges = () => {
        return Object.entries(newState.data).filter(e => Object.entries(e[1]).length).length;
    }

    const isText = (column) => {
        let columns = [
            'name',
            'public_name',
            'ref_num',
            'art_num',
            'barcode',
            'code',
            'intrastat',
            'slug',
        ];

        return columns.indexOf(column) > -1;
    }

    const isFloat = (column) => {
        let columns = [
            'actual_price',
            'amount_unit',
            'avg_delivery_price',
            'min_quantity',
            'max_quantity',
        ];

        return columns.indexOf(column) > -1;
    }

    const isAmountType = column => {
        return column === 'amount_type';
    }

    const isTax = column => {
        return column === 'tax';
    }

    const isDetail = column => {
        return String(column).startsWith('detail_');
    }

    const getDetailId = column => {
        return String(column).split('_')[1] || '';
    }

    const isCheckbox = column => {
        let columns = [
            'is_for_sale',
            'is_service',
            'is_public',
        ];

        return columns.indexOf(column) > -1;
    }

    const isDescription = column => {
        return ['description'].indexOf(column) > -1;
    }

    const isCategory = column => {
        return ['categories'].indexOf(column) > -1;
    }

    const showCategorisation = id => {
        let modal = categoriesModalRef.current;

        if (!modal) {
            return;
        }

        let rowIndex = state.data.findIndex(row => row.article_id === id);
        let row = state.data[rowIndex];

        // прилагат се текущо избраните...
        modal.open({
            categories: selectedCategories[id] || row.article.categories,
            master_cat_id: getInputRawValue('master_cat_id', id),
        });

        // ...обновяват се с новите
        modal.onSuccess(data => {
            let categories = data.categories.map(c => c.translation?.name || c.name).join(', ');
            let ids = data.categories.map(c => c.id);

            // console.log(categories);

            // changeValue('categories', categories, id);
            // changeValue('category_id', ids, id);
            // changeValue('master_cat_id', data.master_cat_id, id);

            changeValue({
                categories: categories,
                category_id: ids,
                master_cat_id: data.master_cat_id
            }, null, id);

            setSelectedCategories(prev => ({
                ...prev,
                [id]: data.categories
            }))

            // setState(prev => ({
            //     ...prev,
            //     data: Object.values({
            //         ...prev.data,
            //         [rowIndex]: {
            //             ...prev.data[rowIndex],
            //             article: {
            //                 ...prev.data[rowIndex].article,
            //                 categories: data.categories
            //             }
            //         }
            //     })
            // }));
        });
    }

    const showCategorisationForAll = () => {
        let modal = categoriesModalRef.current;

        if (!modal) {
            return;
        }

        modal.open({
            categories: [],
            master_cat_id: null
        });

        modal.onSuccess(data => {
            let categories = data.categories.map(c => c.translation?.name || c.name).join(', ');
            let ids = data.categories.map(c => c.id);

            changeValueForAll({
                categories: categories,
                category_id: ids,
                master_cat_id: data.master_cat_id
            });

            let newSelectedCategories = {};

            state.data.map(row => {
                newSelectedCategories[row.article_id] = data.categories;
            })

            setSelectedCategories(prev => ({
                ...prev,
                ...newSelectedCategories
            }));
        });
    }

    const handleSave = () => {
        setNewState(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let data = newState.data;

        // console.log(data);

        Api.post('store/articles/mass-update-columns', JSON.stringify({
            columns: data,
        }), {
            headers: {
                'content-type': 'application/json'
            }
        }).then(res => {
            let changed = res.data.changed;

            if (changed > 0) {
                app.showSuccess(
                    changed == 1 ? 'Беше променен 1 артикул' : `Бяха променени ${changed} артикула`
                );
            } else {
                app.showWarning('Не бяха направени никакви промени');
            }

            setNewState(prev => ({
                ...prev,
                data: {}
            }));

            let url = buildUrl('/articles', state.filter);

            history.push(url)
        }).catch(error => {
            const _err = error.response;

            if (_err && _err.status && _err.status === 422) {
                setValidations(_err.data.errors);
            }
        }).finally(() => {
            setNewState(prev => ({
                ...prev,
                loading: false,
            }));
        });
    }

    const getValidation = (column, id) => {
        return validations && validations.columns && validations.columns[id] && validations.columns[id][column] && (validations.columns[id][column][0] || validations.columns[id][column])
    }

    const toggleOptions = e => {
        e.preventDefault();

        setOptions(prev => ({
            ...prev,
            enabled: !prev.enabled,
        }));
    }

    const setOption = (name, value) => {
        setOptions(prev => ({
            ...prev,
            [name]: value,
        }));

        if (name === 'field') {
            setOption('value', '');
        }
    }

    const getOptionColumns = () => {
        let columns = {};

        Object.entries(state.headings).map(h => {
            if (state.filter.columns.indexOf(h[0]) > -1) {
                columns[h[0]] = h[1];
            }
        });

        // console.log(columns);

        return columns;
    }

    const getOptionOperators = () => {

        const operators = [
            {
                id: '=',
                name: 'приравни',
            },
            {
                id: '*',
                name: 'умножи'
            },
            {
                id: '/',
                name: 'раздели'
            }, {
                id: '+',
                name: 'прибави',
            },
            {
                id: '-',
                name: 'извади',
            },
            {
                id: 'rollback',
                name: 'върни стойността'
            }
        ];

        if (isText(options.field) || isDescription(options.field)) {
            return operators.filter(o => ['=', 'rollback'].indexOf(o.id) > -1);
        }

        if (isFloat(options.field)) {
            return operators;
        }

        if (isAmountType(options.field)) {
            return operators.filter(o => ['=', 'rollback'].indexOf(o.id) > -1);
        }

        if (isTax(options.field)) {
            return operators.filter(o => ['=', 'rollback'].indexOf(o.id) > -1);
        }

        if (isCheckbox(options.field)) {
            return operators.filter(o => ['=', 'rollback'].indexOf(o.id) > -1);
        }

        if (isDetail(options.field)) {
            return operators.filter(o => ['=', 'rollback'].indexOf(o.id) > -1);
        }

        if (isCategory(options.field)) {
            return operators.filter(o => ['=', 'rollback'].indexOf(o.id) > -1);
        }

        return operators;
    }

    const getOptionNewValueInput = () => {
        if (isText(options.field) || isDescription(options.field) || options.field === '') {
            return (
                <Form.Control
                    value={options.value || ''}
                    onChange={e => setOption('value', e.target.value)}
                    size="sm"
                />
            )
        }

        if (isFloat(options.field)) {
            return (
                <Form.Control
                    type="number"
                    step="0.01"
                    value={options.value || ''}
                    onChange={e => setOption('value', parseFloat(e.target.value) || '')}
                    size="sm"
                />
            )
        }

        if (isAmountType(options.field)) {
            return (
                <Form.Select
                    value={options.value || ''}
                    onChange={e => setOption('value', parseFloat(e.target.value) || '')}
                    size="sm"
                >
                    <option value="" disabled></option>
                    {amountTypes.map(o =>
                        <option key={o.id} value={o.id}>{o?.translation?.name || o.name}</option>
                    )}
                </Form.Select>
            )
        }

        if (isTax(options.field)) {
            return (
                <Form.Select
                    value={options.value || ''}
                    onChange={e => setOption('value', parseFloat(e.target.value) || '')}
                    size="sm"
                >
                    <option value="" disabled></option>
                    {taxGroups.map(o =>
                        <option key={o.id} value={o.id}>{o?.translation?.name || o.name}</option>
                    )}
                </Form.Select>
            )
        }

        if (isCheckbox(options.field)) {
            return (
                <Form.Check
                    type="switch"
                    value={1}
                    checked={Number(options.value || 0) === 1}
                    onChange={e => setOption('value', e.target.checked ? 1 : 0)}
                    size="sm"
                />
            )
        }

        if (isDetail(options.field)) {
            return (
                <Autocomplete
                    variant="basic"
                    size="sm"
                    url="store/autocomplete/article-details-values"
                    params={{
                        detail_id: getDetailId(options.field),
                    }}
                    requiredParams={['detail_id']}
                    inputValue={options.value || ''}
                    // inputIdValue={state.data.details[index].value_id || ''}
                    onChange={data => setOption('value', data.name)}
                    onInputChange={data => setOption('value', data)}
                    renderInputText={data => data?.translation?.name || data.name}
                    renderText={renderArticleDetailValueText}
                />
            )
        }

        if (isCategory(options.field)) {
            return (
                <Button
                    size="sm"
                    onClick={showCategorisationForAll}
                >
                    Избор
                </Button>
            )
        }
    }

    const hasNewValueInput = () => {
        if (options.operator === 'rollback') {
            return false;
        }

        return true;
    }

    const calcNewValue = (name, value, id) => {
        switch (options.operator) {
            case '=':
                return options.value;
                break;
            case '*':
                if (isNumeric(value) && isNumeric(options.value)) {
                    return parseFloat(value) * parseFloat(options.value);
                }
                break;
            case '/':
                if (isNumeric(value) && isNumeric(options.value)) {
                    return parseFloat(value) / parseFloat(options.value);
                }
                break;
            case '+':
                if (isNumeric(value) && isNumeric(options.value)) {
                    return parseFloat(value) + parseFloat(options.value);
                }
                break;
            case '-':
                if (isNumeric(value) && isNumeric(options.value)) {
                    return parseFloat(value) - parseFloat(options.value);
                }
                break;
            case 'rollback':
                return getInputOldRawValue(name, id);
                break;
        }

        return undefined;
    }

    // console.log(newState)

    // 'name' => 'Име на артикул',
    // 'public_name' => 'Публично име',
    // 'actual_price' => 'Цена продажба',
    // 'amount_unit' => 'Единица продажба',
    // 'amount_type' => 'Тип количество',
    // 'ref_num' => 'Референтен номер',
    // 'barcode' => 'Баркод',
    // 'avg_delivery_price' => 'Себестойност (без ДДС)',
    // 'tax_id' => 'ДДС група',
    // 'is_for_sale' => 'Продажба',
    // 'is_service' => 'Услуга',
    // 'is_public' => 'Публичен',
    // 'min_quantity' => 'Мин. складово к-во',
    // 'max_quantity' => 'Макс. складово к-во',
    // 'code' => 'Код',
    // 'intrastat' => 'Интрастат',
    // 'description' => 'Описание',
    // 'slug' => 'Публичен URL',
    // 'categories' => 'Категоризиране',

    return (
        <>


            <MassOperationsColumns
                ref={massOperationsColumnsModalRef}
                selected={state.filter.columns}
            />

            <Categorisation
                ref={categoriesModalRef}
            />

            <Layout>
                <div className="container-fluid">

                    <div className="page-head">
                        <h1 className="display-6">
                            <Link to="/articles" className="link-dark text-decoration-none">
                                <ArrowLeftCircle />
                            </Link> Масови корекции
                        </h1>

                        <div className="buttons">

                        </div>
                    </div>

                    <br />

                    <div className="panel">
                        <Filter
                            filter={state.filter}
                            handleSearch={handleSearch}
                        />

                        {state.loading && !state.sorting
                            ?
                            <Loader />
                            :
                            state.data.length === 0 ?
                                <NoDataFound />
                                :
                                <>
                                    <div className="pagination-with-options mb-3">
                                        <Pagination
                                            page={state.filter.page}
                                            pages={state.pages}
                                            handlePage={handlePage}
                                        />

                                        <div className="table-options">
                                            <Button
                                                variant="dark"
                                                // size="sm"
                                                onClick={showMassOperations}
                                            >
                                                <PencilSquare /> <span> Избор колони</span>
                                            </Button>

                                            {/* <SaveButton
                                                variant="dark"
                                                // size="sm"
                                                onClick={handleSave}
                                                style={{ marginLeft: '10px' }}
                                                loading={newState.loading}
                                                disabled={hasChanges() === 0}
                                            >
                                                <span>Запази променените</span>
                                            </SaveButton> */}
                                        </div>

                                    </div>

                                    <div className="mass-update-options mb-3">
                                        <div>
                                            <Button
                                                size="sm"
                                                variant="outline-primary"
                                                className={options.enabled ? 'active' : ''}
                                                onClick={toggleOptions}
                                            >
                                                Масови корекции
                                            </Button>
                                        </div>

                                        {options.enabled &&
                                            <>
                                                <div>
                                                    <Form.Select
                                                        value={options.field || ''}
                                                        onChange={e => setOption('field', e.target.value)}
                                                        size="sm"
                                                    >
                                                        <option value="">Избраното поле</option>
                                                        {Object.entries(getOptionColumns()).map(col =>
                                                            <option key={col[0]} value={col[0]}>{col[1]}</option>
                                                        )}
                                                    </Form.Select>
                                                </div>
                                                <div>
                                                    <Form.Select
                                                        value={options.operator || ''}
                                                        onChange={e => setOption('operator', e.target.value)}
                                                        size="sm"
                                                    >
                                                        {getOptionOperators().map(operator =>
                                                            <option key={operator.id} value={operator.id}>{operator.name}</option>
                                                        )}
                                                    </Form.Select>
                                                </div>

                                                {hasNewValueInput() &&
                                                    <div>
                                                        <span>с</span>

                                                        {getOptionNewValueInput()}
                                                    </div>
                                                }
                                                {options.field === ''
                                                    ?
                                                    <span>след клик с мишката</span>
                                                    :
                                                    <Button
                                                        variant="dark"
                                                        onClick={e => changeValueForAll(options.field, options.value)}
                                                        size="sm"
                                                    >
                                                        приложи
                                                    </Button>
                                                }
                                            </>
                                        }

                                        <Button
                                            variant="outline-danger"
                                            onClick={e => setNewState(prev => ({
                                                ...prev,
                                                data: {}
                                            }))}
                                            size="sm"
                                            style={{
                                                marginLeft: 'auto'
                                            }}
                                        >
                                            откажи всички промени
                                        </Button>
                                    </div>

                                    <Table className={Object.entries(state.headings).length > 14 ? 'xxl valign-top' : 'big valign-top'} responsive ref={tableRef}>
                                        <TableHeader
                                            tableRef={tableRef}
                                            activeSortKey={state.filter.sort}
                                            activeSortDir={state.filter.order}
                                            onSort={(col, dir) => handleSort(col, dir)}
                                        >
                                            {Object.entries(state.headings).map((heading, i) =>
                                                <DynamicTableCol
                                                    key={i}
                                                    type={state.types[heading[0]]}
                                                    name={heading[1]}
                                                    title={state.columns.description[heading[0]]}
                                                    sortKey={heading[0]}
                                                    sortable={state.columns.sortable.indexOf(heading[0]) > -1}
                                                />
                                            )}
                                        </TableHeader>
                                        <tbody>
                                            {state.data.map((c, index) =>
                                                <tr
                                                    key={'c-' + index}
                                                    className={c.deleted ? 'deleted' : ''}
                                                >
                                                    {Object.entries(state.headings).map((heading, i) =>
                                                        isText(heading[0])
                                                            ?
                                                            <td key={heading[0]} className={isModified(getInputName(heading[0]), c.article_id) ? 'modified-value' : ''}>
                                                                <Form.Control
                                                                    name={getInputName(heading[0])}
                                                                    // value={c[heading[0]] || ''}
                                                                    value={getInputValue(heading[0], c.article_id) || ''}
                                                                    onChange={e => handleInputChange(e, c.article_id)}
                                                                    size="sm"
                                                                    isInvalid={Boolean(getValidation(heading[0], c.article_id))}
                                                                    onClick={e => handleInputClick(e, c.article_id)}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {getValidation(getInputName(heading[0]), c.article_id)}
                                                                </Form.Control.Feedback>
                                                            </td>
                                                            :
                                                            isFloat(heading[0])
                                                                ?
                                                                <td key={heading[0]} className={isModified(heading[0], c.article_id) ? 'modified-value' : ''}>
                                                                    <Form.Control
                                                                        type="number"
                                                                        step="0.01"
                                                                        name={heading[0]}
                                                                        // value={parseFloat(c[heading[0]]) || ''}
                                                                        value={parseFloat(getInputValue(heading[0], c.article_id)) || ''}
                                                                        onChange={e => handleInputChange(e, c.article_id)}
                                                                        size="sm"
                                                                        isInvalid={Boolean(getValidation(heading[0], c.article_id))}
                                                                        onClick={e => handleInputClick(e, c.article_id)}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid">
                                                                        {getValidation(heading[0], c.article_id)}
                                                                    </Form.Control.Feedback>
                                                                </td>
                                                                :
                                                                isAmountType(heading[0])
                                                                    ?
                                                                    <td key={heading[0]} className={isModified(getInputName(heading[0]), c.article_id) ? 'modified-value' : ''}>
                                                                        <Form.Select
                                                                            name={getInputName(heading[0])}
                                                                            // value={c.amount_type_id || ''}
                                                                            value={getInputRawValue(heading[0], c.article_id) || ''}
                                                                            onChange={e => changeValue(getInputName(heading[0]), e.target.value, c.article_id)}
                                                                            isInvalid={Boolean(getValidation(getInputName(heading[0]), c.article_id))}
                                                                            size="sm"
                                                                        >
                                                                            <option value="" disabled></option>
                                                                            {amountTypes.map(o =>
                                                                                <option key={o.id} value={o.id}>{o?.translation?.name || o.name}</option>
                                                                            )}
                                                                        </Form.Select>
                                                                        <Form.Control.Feedback type="invalid">
                                                                            {getValidation(getInputName(heading[0]), c.article_id)}
                                                                        </Form.Control.Feedback>
                                                                    </td>
                                                                    :
                                                                    isTax(heading[0])
                                                                        ?
                                                                        <td key={heading[0]} className={isModified(getInputName(heading[0]), c.article_id) ? 'modified-value' : ''}>
                                                                            <Form.Select
                                                                                name={heading[0]}
                                                                                // value={c.tax_id || ''}
                                                                                value={getInputRawValue(heading[0], c.article_id) || ''}
                                                                                onChange={e => changeValue(getInputName(heading[0]), e.target.value, c.article_id)}
                                                                                isInvalid={Boolean(getValidation(getInputName(heading[0]), c.article_id))}
                                                                                size="sm"
                                                                            >
                                                                                <option value="" disabled></option>
                                                                                {taxGroups.map(o =>
                                                                                    <option key={o.id} value={o.id}>{o?.translation?.name || o.name}</option>
                                                                                )}
                                                                            </Form.Select>
                                                                            <Form.Control.Feedback type="invalid">
                                                                                {getValidation(getInputName(heading[0]), c.article_id)}
                                                                            </Form.Control.Feedback>
                                                                        </td>
                                                                        :
                                                                        isCheckbox(heading[0])
                                                                            ?
                                                                            <td key={heading[0]} className={isModified(heading[0], c.article_id) ? 'modified-value' : ''} style={{ textAlign: 'center' }}>
                                                                                <Form.Check
                                                                                    type="switch"
                                                                                    name={heading[0]}
                                                                                    value={1}
                                                                                    checked={Number(getInputRawValue(heading[0], c.article_id) || 0) === 1}
                                                                                    onChange={e => changeValue(heading[0], e.target.checked ? 1 : 0, c.article_id)}
                                                                                    isInvalid={Boolean(getValidation(heading[0], c.article_id))}
                                                                                    size="sm"
                                                                                />

                                                                                <Form.Control.Feedback type="invalid">
                                                                                    {getValidation(heading[0], c.article_id)}
                                                                                </Form.Control.Feedback>
                                                                            </td>
                                                                            :
                                                                            isDescription(heading[0])
                                                                                ?
                                                                                <td key={heading[0]} className={isModified(heading[0], c.article_id) ? 'modified-value' : ''} style={{ width: '10%' }}>
                                                                                    <Form.Control
                                                                                        type="text"
                                                                                        placeholder=""
                                                                                        name={heading[0]}
                                                                                        // value={parseFloat(c[heading[0]]) || ''}
                                                                                        value={getInputValue(heading[0], c.article_id) || ''}
                                                                                        onChange={e => handleInputChange(e, c.article_id)}
                                                                                        size="sm"
                                                                                        isInvalid={Boolean(getValidation(heading[0], c.article_id))}
                                                                                        as="textarea"
                                                                                    />
                                                                                    <Form.Control.Feedback type="invalid">
                                                                                        {getValidation(heading[0], c.article_id)}
                                                                                    </Form.Control.Feedback>
                                                                                </td>
                                                                                :
                                                                                isDetail(heading[0])
                                                                                    ?
                                                                                    <td key={heading[0]} className={isModified(heading[0], c.article_id) ? 'modified-value' : ''}>
                                                                                        <Autocomplete
                                                                                            variant="basic"
                                                                                            size="sm"
                                                                                            url="autocomplete/article-details-values"
                                                                                            params={{
                                                                                                detail_id: getDetailId(heading[0]),
                                                                                            }}
                                                                                            requiredParams={['detail_id']}
                                                                                            inputName={heading[0]}
                                                                                            inputValue={getInputValue(heading[0], c.article_id) || ''}
                                                                                            // inputIdValue={state.data.details[index].value_id || ''}
                                                                                            error={Boolean(getValidation(heading[0], c.article_id))}
                                                                                            helperText={getValidation(heading[0], c.article_id)}
                                                                                            onChange={data => changeValue(heading[0], data.name, c.article_id)}
                                                                                            onInputChange={data => changeValue(heading[0], data, c.article_id)}
                                                                                            onClick={e => handleInputClick(e, c.article_id)}
                                                                                            renderInputText={data => data?.translation?.name || data.name}
                                                                                            renderText={renderArticleDetailValueText}
                                                                                        />
                                                                                    </td>
                                                                                    :
                                                                                    isCategory(heading[0])
                                                                                        ?
                                                                                        <td key={heading[0]} className={isModified(heading[0], c.article_id) ? 'modified-value' : ''} style={{ cursor: 'pointer' }} onClick={e => showCategorisation(c.article_id)}>
                                                                                            <div >
                                                                                                {getInputValue(heading[0], c.article_id)}
                                                                                            </div>
                                                                                        </td>
                                                                                        :
                                                                                        <DynamicTableBodyCol
                                                                                            key={heading[0]}
                                                                                            type={state.types[heading[0]]}
                                                                                            name={c[heading[0]]}
                                                                                            data={c}
                                                                                            currency={state.currency}
                                                                                        />
                                                    )}


                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>

                                    <Pagination
                                        className="mt-3"
                                        page={state.filter.page}
                                        pages={state.pages}
                                        total={state.total}
                                        handlePage={handlePage}
                                    />
                                </>
                        }

                    </div>
                </div>

                {hasChanges() > 0 ?
                    <div className="baloon-selected">
                        Променени артикули: {hasChanges()} бр.
                        <div className="buttons">
                            <SaveButton
                                variant="dark"
                                // size="sm"
                                onClick={handleSave}
                                loading={newState.loading}
                                disabled={hasChanges() === 0}
                            >
                                <span>Запази</span>
                            </SaveButton>
                        </div>
                    </div>
                    : ''
                }
            </Layout>
        </>
    )
}

export default MassUpdate;
