import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

//MUI components
import { Tooltip, Popover, Radio, Button } from '@material-ui/core'

//providers
import { useAuthDataContext } from 'providers/Auth'
import Api from 'helpers/Api'
import moment from 'moment'

const timestamp = moment().unix()
const DelivererBankChange = props => {
    const auth = useAuthDataContext()
    const history = useHistory()
    const {
        handleBankModal,
        handleChangeBank,
        selected,
        companyId
    } = props
    const wasGuideShown = auth.getUser().bankHelpInfoSeen()
    const [state, setState] = useState({
        bankDetailsPopover: null,
        selected: selected?.id || null,
        guideIndicator: false
    })

    useEffect(() => {
        if (!state.selected) {
            setState(prev => ({
                ...prev,
                selected: selected?.id
            }))
        } else {
            if (!wasGuideShown) showGuide()
        }
    }, [selected])

    useEffect(() => {
        //Когато се добави нова сметка да се маркира като сметка по подразбиране
        // - най-новата сметка е винаги на 1ва позиция;
        // - единственото действие за промяна на props.banks е добавяне на нов елемент в списъка, тъй като няма редакция и изтриване;
        handleChange(props?.banks?.[0] || {})
    }, [props.banks])

    /**
     * 
     * @param {node|null} el референция към елемента, към който искаме да закачим popover | скриване на popover
     */
    const setPopoverElement = el => {
        setState(prev => ({
            ...prev,
            bankDetailsPopover: el,
        }))
    }

    /**
    * 
    * @param {number|string} id 
    * @returns Дали чекбокса е маркиран
    */
    const isChecked = id => {
        if (String(state.selected) === String(id)) {
            return true
        }

        return false
    }

    const handleChange = item => {
        setState(prev => ({
            ...prev,
            selected: item.id
        }))
        handleChangeBank(item)
        if (wasGuideShown) setPopoverElement(null)
    }

    const showBankConfigModal = onSuccess => {
        const modal = handleBankModal.current

        if (modal) {
            setPopoverElement(null)
            modal.show()

            modal.onSuccess(() => {
                if (onSuccess) onSuccess()
                // setPopoverElement(document.querySelector('.deliverer .clickable'))
            })
        }
    }

    const showGuide = () => {
        const el = document.querySelector(`.active-doc .bank-field-${timestamp}`)
        if (!el) return

        setPopoverElement(el)
        setGuideIndicator(true)

        setTimeout(() => {
            setPopoverElement(null)
            setGuideIndicator(false)

            Api.get('documents/settings/hide-bank-help')
                .then(res => {
                    auth.setData(res.data)
                })
        }, 2000)
    }

    const setGuideIndicator = guideIndicator => {
        setState(prev => ({
            ...prev,
            guideIndicator
        }))
    }

    return (
        selected || state.selected ? <>
            <Popover
                open={Boolean(state.bankDetailsPopover)}
                anchorEl={state.bankDetailsPopover}
                onClose={() => {
                    setPopoverElement(null)
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                className="popover-bank-details"
            >
                <div className="holder">
                    {(props.banks || []).length ? <ul>
                        {(props.banks || []).map(d =>
                            <li
                                key={`bank-details-${d.id}`}
                                onClick={() => {
                                    handleChange(d)
                                }}
                            >
                                <Radio
                                    color="primary"
                                    style={{
                                        padding: '0',
                                        marginRight: '5px',
                                    }}
                                    checked={isChecked(d.id)}
                                />
                                <div className="right">
                                    <div className="row">
                                        <div className="col">
                                            IBAN:
                                        </div>
                                        <div className="col">
                                            {d.iban}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            Банка:
                                        </div>
                                        <div className="col">
                                            {d.name}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            BIC:
                                        </div>
                                        <div className="col">
                                            {d.bic}
                                        </div>
                                    </div>
                                </div>
                            </li>
                        )}
                    </ul>
                        :
                        <>
                        </>
                    }
                    <div className="row buttons">
                        <Button
                            className="add"
                            onClick={showBankConfigModal}
                        >
                            Нова сметка
                        </Button>
                        <Tooltip title="Управление на банковите сметки. Към допълнителни настройки.">
                            <Button
                                className="config"
                                onClick={() => {
                                    history.push(`/settings?menu=4&company_id=${companyId}`)
                                }}
                            >
                            </Button>
                        </Tooltip>
                    </div>
                </div>
            </Popover>
            <Tooltip title="Промяна на банковата сметка">
                <div
                    className={`row clickable iban-field ${!state.guideIndicator ? 'green' : ''}`}
                    onClick={() => {
                        setPopoverElement(document.querySelector(`.active-doc .bank-field-${timestamp}`))
                    }}
                >
                    <div className="col">
                        IBAN:
                    </div>
                    <div className="col">
                        {selected?.iban || ''}
                    </div>
                </div>
            </Tooltip>
            <Tooltip
                placement="bottom"
                title={
                    <div className="popover-info-holder">
                        <div className="row">
                            <div className="col">
                                Банка:
                            </div>
                            <div className="col">
                                {selected?.name || ''}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                BIC:
                            </div>
                            <div className="col">
                                {selected?.bic || ''}
                            </div>
                        </div>
                    </div>
                }
            >
                <div
                    className={`row clickable bank-field-${timestamp} ${state.guideIndicator ? 'show-guide green' : ''}`}
                    onClick={e => {
                        setPopoverElement(e.target)
                    }}
                >

                    <div className="col">
                        Банка:
                    </div>

                    <div className="col">
                        {selected?.name || ''}
                    </div>
                </div>
            </Tooltip>
            <input type="hidden" value={selected?.id} name="company_bank_id" />
        </>
            :
            <>
            </>
    )
}

export default DelivererBankChange