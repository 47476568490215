import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

//plugins
import { Modal, Button, Row, Col, FloatingLabel, Form } from 'react-bootstrap'

//helpers
import Api from 'helpers/Api'
import { useValidation } from 'helpers/Validation'
import { useNestedState } from 'helpers/NestedState'

//providers
import { useCurrencyContext } from 'providers/Currency'
import { useAppContext } from 'providers/App'
import { useAuthDataContext } from 'providers/Auth'

//misc
import SaveButton from 'components/misc/Button'
import Info from 'components/modals/Info'
import RedditTextField from 'components/misc/RedditTextField'
import RedditSelect from 'components/misc/RedditSelect'
import { MenuItem } from '@material-ui/core'
import moment from 'moment'

function AddOrEdit(props, ref) {

    const app = useAppContext()
    const auth = useAuthDataContext()
    const currencies = useCurrencyContext()

    const defaultData = {
        langs: {}
    }

    const [state, setState] = useNestedState({
        show: false,
        id: null,
        edit: false,
        data: defaultData,
        loading: false,
        onSuccess: null,
        onEntering: null,
        onExiting: null,
        onClose: null,
    })

    const [validations, setValidations] = useValidation()

    const validationModalRef = useRef(null)
    const formRef = useRef(null)

    useImperativeHandle(ref, () => ({
        add: () => {
            handleShow()
        },
        edit: (id) => {
            edit(id)
        },
        hide: () => {
            hide()
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }))
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }))
        }
    }))

    useEffect(() => {
        if (!state.show) {
            return
        }

        if (auth.getUser().permission('suppliers_edit') === false) {
            app.showError('Нямате право да редактирате доставчик!')

            hide(state.onClose)
        }

    }, [state.show])

    useEffect(() => {
        loadData()
    }, [state.edit])

    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }))

        onEntering?.()
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }))

        onExiting?.()
    }

    const handleShow = () => {
        setState(prev => ({
            ...prev,
            data: defaultData,
            id: null,
        }))

        show()
    }

    const handleClose = e => {

        if (e) {
            e.preventDefault()
        }

        setState(prev => ({
            ...prev,
            data: defaultData,
            id: null,
        }))

        setValidations(null)

        if (typeof state.onClose === 'function') {
            hide(state.onClose)
        } else {
            hide()
        }

    }

    const edit = id => {
        setState(prev => ({
            ...prev,
            id: id,
            edit: moment().unix()
        }))

        show()
    }

    const loadData = () => {
        if (!state.id) {
            return
        }

        Api.get('store/suppliers/edit?id=' + state.id)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    data: res.data
                }))
            })
    }


    const handleSave = () => {
        setState(prev => ({
            ...prev,
            loading: true,
        }))

        setValidations(null)

        let url = state.id ? 'store/suppliers/update' : 'store/suppliers/add'

        let data = new FormData(formRef.current)

        if (state.id) {
            data.append('id', state.id)
        }

        Api.post(url, data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(res => {
            if (typeof state.onSuccess === 'function') {
                state.onSuccess(res.data)
            }

            hide()
        }).catch(error => {
            const _err = error.response
            if (_err && _err.status && _err.status === 422) {
                setValidations(_err.data.errors)
                // hide(() => {
                //     let modal = validationModalRef.current

                //     if (modal) {
                //         modal.open()

                //         modal.onClose(() => {
                //             setValidations(_err.data.errors)
                //             show()
                //         })
                //     }
                // })
            }
        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false,
            }))
        })
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(name, value, 'data')
    }

    const handleDateChange = e => {
        const name = e.target.name + '_ymd'
        const value = e.target.value

        setState(name, value, 'data')
    }

    return (
        <>
            <Info
                ref={validationModalRef}
                mainMessage="Моля, попълнете всички задължителни полета, означени с червен цвят!"
            />


            <div className={`${state.show ? "visible" : ""} overlay`}>
                <div
                    className={`popup-primary large ${state.show ? "show" : ""}`}
                >
                    <div className="body">
                        <form onSubmit={handleSave} ref={formRef}>
                            <h3>
                                Доставчик
                            </h3>
                            <br />
                            <div className="row">
                                <div className="col">
                                    <RedditTextField
                                        fullWidth
                                        label="Име*"
                                        name="name"
                                        value={state.data.name || ''}
                                        onChange={(e) => handleInputChange(e)}
                                        error={Boolean(validations?.name)}
                                        helperText={validations?.name}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <RedditTextField
                                        fullWidth
                                        label="ЕИК"
                                        name="bulstat"
                                        value={state.data.bulstat || ''}
                                        onChange={(e) => handleInputChange(e)}
                                    />
                                </div>
                                <div className="col">
                                    <RedditTextField
                                        fullWidth
                                        label="ДДС №"
                                        name="vat_num"
                                        value={state.data.vat_num || ''}
                                        onChange={(e) => handleInputChange(e)}
                                    />
                                </div>
                                <div className="col">
                                    <RedditTextField
                                        fullWidth
                                        label="МОЛ"
                                        name="mol"
                                        value={state.data.mol || ''}
                                        onChange={(e) => handleInputChange(e)}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <RedditTextField
                                        fullWidth
                                        label="Адрес"
                                        name="address"
                                        value={state.data.address || ''}
                                        onChange={(e) => handleInputChange(e)}
                                    />
                                </div>
                                <div className="col">
                                    <RedditTextField
                                        fullWidth
                                        label="Телефони"
                                        name="tel"
                                        value={state.data.tel || ''}
                                        onChange={(e) => handleInputChange(e)}
                                    />
                                </div>
                                <div className="col">
                                    <RedditTextField
                                        fullWidth
                                        label="Лица за контакти"
                                        name="contact_persons"
                                        value={state.data.contact_persons || ''}
                                        onChange={(e) => handleInputChange(e)}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <RedditTextField
                                        fullWidth
                                        label="Бележки"
                                        name="note"
                                        value={state.data.note || ''}
                                        onChange={(e) => handleInputChange(e)}
                                        multiline
                                        rows={3}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <RedditTextField
                                        fullWidth
                                        label="Е-поща"
                                        name="email"
                                        value={state.data.email || ''}
                                        onChange={(e) => handleInputChange(e)}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <RedditSelect
                                        name="has_tax"
                                        value={state.data.has_tax || 0}
                                        onChange={handleInputChange}
                                        label="Регистрация по ДДС"
                                    >
                                        <MenuItem value={0}>
                                            Да
                                        </MenuItem>
                                        <MenuItem value={1}>
                                            Не
                                        </MenuItem>
                                    </RedditSelect>
                                </div>
                                <div className="col">
                                    <RedditSelect
                                        name="default_price_mode"
                                        value={state.data.default_price_mode || 0}
                                        onChange={handleInputChange}
                                        label="Цени по подразбиране"
                                    >
                                        <MenuItem value={0}>
                                            без ДДС
                                        </MenuItem>
                                        <MenuItem value={1}>
                                            с ДДС
                                        </MenuItem>
                                    </RedditSelect>
                                </div>
                                <div className="col">
                                    <RedditSelect
                                        name="default_currency_id"
                                        value={state.data.default_currency_id || currencies.data[0].id || ''}
                                        onChange={handleInputChange}
                                        label="Валута по подразбиране"
                                    >
                                        {currencies.data.map(c =>
                                            <MenuItem key={c.id} value={c.id}>
                                                {c.name}
                                            </MenuItem>
                                        )}
                                    </RedditSelect>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="footer">
                        <Button
                            variant="contained"
                            className="cancel"
                            onClick={handleClose}
                            style={{
                                marginLeft: 'auto',
                                minWidth: '100px'
                            }}
                        >
                            Назад
                        </Button>
                        <SaveButton
                            loading={state?.loading}
                            className="save"
                            onClick={handleSave}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default forwardRef(AddOrEdit)
