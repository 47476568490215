//libraries
import MenuItem from "@material-ui/core/MenuItem"

//images
import editIcon from "assets/img/app/icons/edit.png"
import switchIcon from "assets/img/customers/icons/switch.png"
import deleteIcon from "assets/img/app/icons/delete.png"

function PipelinesItemOptions({
  handleEdit,
  handleRemove,
  statuses,
  onStatusChange,
  statusId,
}) {
  const OPTIONS = [
    {
      icon: editIcon,
      action: handleEdit,
      title: "Редакция",
      sublist: null,
    },
    {
      icon: switchIcon,
      action: null,
      title: "Смяна на статуса",
      sublist: getStatusesExeptCurrent(),
    },
    {
      icon: deleteIcon,
      action: handleRemove,
      title: "Изтриване",
      sublist: null,
    },
  ]

  function getStatusesExeptCurrent() {
    return statuses.filter((status) => status.id !== statusId)
  }

  return OPTIONS.map(({ action, title, icon, sublist }) => (
    <MenuItem
      onClick={action}
      disabled={sublist}
      disableRipple={sublist}
      className={sublist ? "parent" : ""}
    >
      <img src={icon} alt={title} />
      {title}

      {sublist ? (
        <ul>
          {sublist.map(({ id, title, color }) => (
            <MenuItem key={id} onClick={() => onStatusChange(id)}>
              <span
                style={{
                  width: "10px",
                  height: "10px",
                  display: "inline-block",
                  backgroundColor: color,
                  borderRadius: "50%",
                  marginRight: "8px",
                  flexShrink: 0,
                }}
              ></span>
              {title}
            </MenuItem>
          ))}
        </ul>
      ) : (
        <></>
      )}
    </MenuItem>
  ))
}

export default PipelinesItemOptions
