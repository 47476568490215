import { useEffect, useState } from "react"

//components
import SaveButton from "components/misc/Button"
import RedditSelect from "components/misc/RedditSelect"
import RedditTextField from "components/misc/RedditTextField"
import FindCompany from "components/pages/sandbox/findcompany/components/FindCompany"

//libraries
import {
  Button,
  Checkbox,
  MenuItem,
  TextField,
  // Tooltip,
} from "@material-ui/core"
import Autocomplete from "@mui/material/Autocomplete"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import moment from "moment"
import { useQueryClient } from "react-query"

//stores
import { useAddToCommunicationsModal } from "stores/customer/useAddToCommunicationsModal"

//images
import removeIcon from "assets/img/app/icons/delete.png"
import removeFilledIcon from "assets/img/customers/icons/remove-filled.png"
import uploadIcon from "assets/img/app/icons/upload.png"

//helpers
import {
  getAddOptionsTypes,
  getEndHours,
  getSelectedEventType,
  getStartHours,
} from "helpers/Calendar"

//hooks
import useSaveOnServer from "hooks/useSaveOnServer"

function AddOrEditCommunication({ allCommunications }, ref) {
  const queryClient = useQueryClient()

  const TAGS_FROM_SERVER = [
    { title: "Събитие", id: 1 },
    { title: "Проект", id: 2 },
    { title: "CRM", id: 3 },
    { title: "ERP", id: 4 },
    { title: "SaaS", id: 5 },
  ]
  const { getShown, getForm, hideModal } = useAddToCommunicationsModal()
  const [localForm, setLocalForm] = useState({})

  const { saveOnServer, loadingSaveOnServer } = useSaveOnServer()

  useEffect(() => {
    setLocalForm(getForm())
  }, [getForm()])

  function handleSetLocalForm(key, value) {
    setLocalForm((prev) => ({
      ...prev,
      [key]: value,
    }))
  }

  const handleClose = (e) => {
    if (e) {
      e.preventDefault()
    }

    setLocalForm(getForm())
    hideModal()
  }

  function handleChangeDate(value, key) {
    handleSetLocalForm(key, moment(value["$d"]).format("YYYY-MM-DD"))
  }

  function toggleIsAllDay(e) {
    handleSetLocalForm("isAllDay", !!(e.target.checked || !localForm.isAllDay))
  }

  function handleChangeStartHour(e) {
    handleSetLocalForm("startHour", e.target.value)
    handleSetLocalForm(
      "endHour",
      moment(e.target.value, "HH:mm").add(30, "minutes").format("HH:mm")
    )
  }

  function handleChangeTags(_, value) {
    const newValue = [...value].map((v) => {
      if (typeof v === "string") {
        v = { title: v, id: moment().unix() }
      }

      return v
    })

    handleSetLocalForm("tags", newValue)
  }

  function saveToLocalStorage() {
    let updatedCommunications = [...allCommunications]

    if (localForm.id) {
      updatedCommunications = allCommunications.map((communication) => {
        if (communication.id === localForm.id) {
          return {
            ...localForm,
            id: communication.id,
          }
        }

        return communication
      })
    } else {
      updatedCommunications.push({
        ...localForm,
        id: moment().unix(),
      })
    }

    saveOnServer("communications", updatedCommunications, () => {
      handleClose()
      queryClient.invalidateQueries("communications")
    })
  }

  return (
    <div className={`${getShown() ? "visible" : ""} overlay`}>
      <div
        className={`popup-primary small calendar-note ${getShown() ? "show" : ""
          }`}
      >
        <div className="body">
          <h3>{localForm.id ? "Редакция" : "Добавяне"} на комуникация</h3>
          <p>Въведете вида комуникация с Вашите клиенти или доставчици</p>
          <h6>Събитие</h6>
          <div className="field">
            <div className="row name-and-type-row">
              {/* <Tooltip
                title={
                  getSelectedEventType()?.title
                    ? `Тип на събитието: ${getSelectedEventType()?.title}`
                    : "Избор на тип"
                }
              > */}
              <Button className="simple-select margin-right">
                <img
                  src={getSelectedEventType(localForm.eventType)?.icon}
                  alt=""
                  className="event-type-icon"
                />
                <RedditSelect
                  name="type"
                  // value={state.data?.cashdesk_id || cashdesks[0]?.id || ""}
                  // onChange={handleCashdeskChange}
                  // error={Boolean(validations?.cashdesk_id)}
                  // helperText={
                  //   validations?.cashdesk_id &&
                  //   (validations.cashdesk_id[0] || validations.cashdesk_id)
                  // }
                  value={localForm.eventType}
                  onChange={(e) =>
                    handleSetLocalForm("eventType", e.target.value)
                  }
                >
                  {getAddOptionsTypes().map(({ title, type, sublist }) => (
                    <MenuItem value={type} disabled={sublist}>
                      {title}
                    </MenuItem>
                  ))}
                </RedditSelect>
              </Button>
              {/* </Tooltip> */}
              <TextField
                label="Име на събитие"
                value={localForm.title}
                onChange={(e) => handleSetLocalForm("title", e.target.value)}
                autoFocus
              />
            </div>
            <div className="row">
              <Button className="simple-select margin-right">
                {moment(localForm.startDate, "YYYY-MM-DD").format(
                  "ddd, DD MMM"
                )}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    onChange={(e) => handleChangeDate(e, "startDate")}
                  />
                </LocalizationProvider>
              </Button>
              <Button
                className="simple-select margin-right"
                disabled={localForm.isAllDay}
                style={{
                  opacity: localForm.isAllDay ? 0.5 : 1,
                }}
              >
                {localForm.startHour}
                <RedditSelect
                  name="start_hour"
                  // value={state.data?.cashdesk_id || cashdesks[0]?.id || ""}
                  // onChange={handleCashdeskChange}
                  // error={Boolean(validations?.cashdesk_id)}
                  // helperText={
                  //   validations?.cashdesk_id &&
                  //   (validations.cashdesk_id[0] || validations.cashdesk_id)
                  // }
                  value={localForm.startHour}
                  onChange={handleChangeStartHour}
                >
                  {getStartHours().map((hour) => (
                    <MenuItem key={hour} value={hour}>
                      {hour}
                    </MenuItem>
                  ))}
                </RedditSelect>
              </Button>
              {" - "}
              <Button className="simple-select margin-left">
                {moment(localForm.endDate, "YYYY-MM-DD").format("ddd, DD MMM")}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    onChange={(e) => handleChangeDate(e, "endDate")}
                  // minDate={moment(localForm.endDate, "YYYY-MM-DD")}
                  />
                </LocalizationProvider>
              </Button>
              <Button
                className="simple-select margin-left"
                disabled={localForm.isAllDay}
                style={{
                  opacity: localForm.isAllDay ? 0.5 : 1,
                }}
              >
                {localForm.endHour}
                <RedditSelect
                  name="end_hour"
                  // value={state.data?.cashdesk_id || cashdesks[0]?.id || ""}
                  // onChange={handleCashdeskChange}
                  // error={Boolean(validations?.cashdesk_id)}
                  // helperText={
                  //   validations?.cashdesk_id &&
                  //   (validations.cashdesk_id[0] || validations.cashdesk_id)
                  // }
                  value={localForm.endHour}
                  onChange={(e) =>
                    handleSetLocalForm("endHour", e.target.value)
                  }
                >
                  {getEndHours(localForm.startHour).map((hour) => (
                    <MenuItem key={hour} value={hour}>
                      {hour}
                    </MenuItem>
                  ))}
                </RedditSelect>
              </Button>
            </div>
            <div className="row checkbox-row">
              <Checkbox
                color="primary"
                onChange={toggleIsAllDay}
                value="all_day"
                key={localForm.isAllDay}
                checked={localForm.isAllDay}
              />
              <span className="label" onClick={toggleIsAllDay}>
                Направи събитието за цял ден
              </span>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col">
              <FindCompany
                // centeredLabel={true}
                label="Клиент/Доставчик"
                inputIdName="clientField"
                setSelectedCompanyCB={(value) => {
                  handleSetLocalForm("client", value)
                }}
                initialValue={localForm.clientField}
                setInputValueCB={(value) => {
                  console.log(value)
                  handleSetLocalForm("clientField", value)
                  // setState((prevState) => ({
                  //   ...prevState,
                  //   companyFieldValue: value,
                  // }))
                }}
              />
            </div>
          </div>
          <div className="row">
            <RedditSelect
              // name="cashdesk_id"
              // value={state.data?.cashdesk_id || cashdesks[0]?.id || ""}
              // onChange={handleCashdeskChange}
              label="Към Проект/Поръчка"
              // error={Boolean(validations?.cashdesk_id)}
              // helperText={
              //   validations?.cashdesk_id &&
              //   (validations.cashdesk_id[0] || validations.cashdesk_id)
              // }
              value="1"
            >
              <MenuItem key="1" value="1">
                Всички
              </MenuItem>
            </RedditSelect>
          </div>
          <div className="row">
            <Autocomplete
              multiple
              options={TAGS_FROM_SERVER}
              freeSolo
              getOptionLabel={(option) => option.title || option}
              value={localForm.tags}
              key={localForm.tags}
              onChange={handleChangeTags}
              renderInput={(params) => (
                <RedditTextField
                  {...params}
                  label="Тагове"
                  placeholder="Нов..."
                />
              )}
              renderTags={
                (value, getTagProps) =>
                  // <div className="chip-holder">
                  value.map((option, index) => (
                    <div className="chip">
                      {option.title}
                      <img
                        src={removeIcon}
                        className="remove"
                        onClick={() => {
                          const newTags = localForm.tags.filter(
                            (tag, i) => i !== index
                          )

                          handleSetLocalForm("tags", newTags)
                        }}
                      />
                    </div>
                  ))
                // </div>
              }
            />
          </div>
          <div
            className="upload disabled"
            style={{
              justifyContent: "center",
            }}
          >
            <img src={uploadIcon} alt="" />
            <h5>Изберете файл</h5>
            <input
              type="file"
              multiple
              name="file"
              //   ref={inputFileRef}
              //   onChange={handleAttach}
              accept=".pdf"
              title=""
            />
            <p>Допустими формати: .pdf</p>
          </div>
        </div>
        <div className="footer">
          <Button variant="contained" className="cancel" onClick={handleClose}>
            Отказ
          </Button>
          <SaveButton
            loading={loadingSaveOnServer}
            className="save"
            // onClick={(e) => formRef.current?.save(e)}
            onClick={saveToLocalStorage}
          />
        </div>
      </div>
    </div>
  )
}

export default AddOrEditCommunication
