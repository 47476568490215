import React, { useEffect, useRef, useState } from 'react'
import Api from 'helpers/Api'
import { buildSearchParams, useQuery } from 'helpers/Url'
import { useLocation, Link, useHistory } from 'react-router-dom'
import { useAuthDataContext } from 'providers/Auth'
import { Button } from '@material-ui/core'

import Options from './partials/Options'

import Question from 'components/modals/Question'
import QuestionRenew from 'components/modals/Question'
import Filter from './partials/Filter'
import AddOrEdit from './partials/AddOrEdit'

//misc
import MenuOptions from 'components/misc/MenuOptions'
import Pagination from 'components/misc/Pagination'
import Loader from 'components/misc/Loader'
import NoDataFound from 'components/misc/NoDataFound'
import PriceValue from 'components/partials/PriceValue'
import Refs from 'Refs'
import Activate from './partials/Activate'
import StoreOut from 'components/pages/storeouts/StoreOut'
import Layout from 'components/Layout'
import PaginationMini from 'components/misc/PaginationMini'
import Provider from './Provider'
import moment from 'moment'

let timeout

function Index() {
    const location = useLocation()
    const history = useHistory()
    const query = useQuery()
    const auth = useAuthDataContext()

    const addOrEditModalRef = useRef(null)
    const deleteModalRef = useRef(null)
    const activateModalRef = useRef(null)
    const storeOutModalRef = useRef(null)
    const questionRenewModal = useRef(null)

    const [state, setState] = useState({
        loading: true,
        refresh: false,
        setFilter: false,
        data: [],
        pages: 0,
        total: 0,
        filter: {
            page: 1,
        },
    })

    useEffect(() => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: query.get('page') || 1,
                search: query.get('search') || '',
                client_id: query.get('client_id') || '',
                type_id: query.get('type_id') || '',
                status: query.get('status') || ''
            },
            refresh: moment().unix()
        }))
    }, [location.search])

    useEffect(() => {
        if (state.setFilter) {
            history.push('?' + buildSearchParams(query, state.filter))
        }

    }, [state.setFilter])

    useEffect(() => {
        if (state.refresh) {
            loadData()
        }
    }, [state.refresh])

    useEffect(() => {
        refresh()
    }, [auth.getUser().getStoreId()])

    const loadData = () => {

        loading(true)

        let url = 'store/subscriptions/all'

        Api.get(url, {
            params: state.filter
        }).then(res => {
            setState(prev => ({
                ...prev,
                data: res.data.items,
                total: res.data.total,
                pages: res.data.pages
            }))

            if (res.data.pages > 0 && state.filter.page > res.data.pages) {
                return handlePage({ selected: res.data.pages - 1 })
            }

            loading(false)

        })
    }

    const loading = (loading) => {
        setState(prev => ({
            ...prev,
            loading: Boolean(loading)
        }))
    }

    const refresh = (reset = true) => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: reset ? 1 : prev.filter.page
            },
            setFilter: moment().unix(),
            refresh: moment().unix(),
        }))
    }

    const handleShow = data => {
        let modal = Refs.getInstance().getRef('subscription')

        if (!modal) {
            return
        }

        modal.open(data.id)
    }

    const handleAdd = () => {
        let modal = addOrEditModalRef.current

        modal.add()

        modal.onSuccess(() => {
            refresh(true)
        })
    }

    // update
    const handleEdit = id => {
        let modal = addOrEditModalRef.current

        modal.edit(id)

        modal.onSuccess(() => {
            refresh(false)
        })
    }

    // delete
    const handleDelete = id => {
        let modal = deleteModalRef.current

        modal.open()
        modal.onSuccess(() => {
            handleDestroy(id)
        })
    }

    const handleDestroy = id => {
        Api.post('store/subscriptions/delete', {
            id: id
        }).then(res => {
            if (res.data.success) {
                refresh(false)
            }
        })
    }


    // Search
    const handleSearch = (key, val, delay = 300) => {
        clearTimeout(timeout)

        if (typeof key === 'object') {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    ...key
                },
            }))
        } else {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    [key]: val,
                },
            }))
        }

        timeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    page: 1
                },
                setFilter: moment().unix()
            }))
        }, delay)
    }

    const handlePage = page => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: page.selected + 1,
            },
            setFilter: moment().unix()
        }))
    }

    const handleActivate = id => {
        let modal = activateModalRef.current

        modal.open(id)

        modal.onSuccess(() => {
            refresh(false)
        })

    }

    const handleRenew = id => {
        let modal = questionRenewModal.current

        modal.open()
        modal.onSuccess(() => {
            submitRenew(id)
        })
    }

    const submitRenew = id => {
        Api.post('store/subscriptions/renew', {
            id: id
        }).then(res => {
            let modal = Refs.getInstance().getRef('success')

            modal.open('Действието е успешно')

            modal.onClose(() => {
                refresh(false)
            })
        })
    }

    const handleAddStoreOut = id => {
        // history.push('/storage/outs/new?from_subscription_id=' + id)
        storeOutModalRef.current.add(id)
    }

    return (
        <>
            <StoreOut
                ref={storeOutModalRef}
            />

            <Provider>
                <AddOrEdit
                    ref={addOrEditModalRef}
                    refresh={refresh}
                />
            </Provider>

            <Activate
                ref={activateModalRef}
            />

            <Question
                ref={deleteModalRef}
                mainMessage="Сигурни ли сте?"
                agreeBtnText="Изтриване"
                agreeBtnClass="remove"
            />
            <QuestionRenew
                ref={questionRenewModal}
                mainMessage="Потвърждавате ли подновяването на абонамента?"
                agreeBtnText="Потвърждавам"
            />
            <Layout>
                <div className="store-moves">
                    <div className="head-with-heading">
                        <h3>Абонаменти</h3>

                        <div className="buttons">
                            <Button
                                className="add"
                                onClick={handleAdd}
                            >
                                Нов абонамент
                            </Button>
                        </div>
                    </div>
                    <div
                        className="panel"
                        style={{
                            marginTop: 0,
                        }}
                    >

                        <div className='filter-holder'>
                            <Filter
                                filter={state.filter}
                                handleSearch={handleSearch}
                            />
                            <PaginationMini
                                isLoading={state.loading}
                                page={state.filter.page}
                                pages={state.pages}
                                handlePage={handlePage}
                            />
                        </div>

                        {state.loading
                            ?
                            <Loader />
                            :
                            state.data.length === 0 ?
                                <NoDataFound />
                                :
                                <>
                                    <table className="type-outer">
                                        <thead>
                                            <tr>
                                                <th className="id">ID</th>
                                                <th>Клиент</th>
                                                <th>Тип</th>
                                                <th className="text-right">Цена</th>
                                                <th>Активирано</th>
                                                <th>Валидност</th>
                                                <th className="options">
                                                    Опции
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {state.data.map(c =>
                                                <React.Fragment key={'c-' + c.id}>
                                                    <tr

                                                    >
                                                        <td className="id" onClick={e => handleShow(c)}>
                                                            {c.id}
                                                        </td>

                                                        <td onClick={e => handleShow(c)}>
                                                            {c.customer?.name}
                                                        </td>

                                                        <td onClick={e => handleShow(c)}>
                                                            {c.type?.name}
                                                        </td>

                                                        <td className="text-right" onClick={e => handleShow(c)}>
                                                            <PriceValue>{c.price}</PriceValue>
                                                        </td>

                                                        <td onClick={e => handleShow(c)}>
                                                            {c.activated_at_dmy}
                                                        </td>

                                                        <td onClick={e => handleShow(c)}>
                                                            {c.expires_at_dmy}
                                                        </td>

                                                        <td className="options" style={{ width: '65px' }}>
                                                            <MenuOptions>
                                                                <Options
                                                                    data={c}
                                                                    handleActivate={handleActivate}
                                                                    handleRenew={handleRenew}
                                                                    handleEdit={handleEdit}
                                                                    handleDelete={handleDelete}
                                                                    handleAddStoreOut={handleAddStoreOut}
                                                                />
                                                            </MenuOptions>
                                                        </td>
                                                    </tr>

                                                </React.Fragment>
                                            )}
                                        </tbody>
                                    </table>

                                    <Pagination
                                        className="mt-3"
                                        page={state.filter.page}
                                        pages={state.pages}
                                        total={state.total}
                                        handlePage={handlePage}
                                    />
                                </>
                        }

                    </div>
                </div>
            </Layout>
        </>
    )
}

export default Index