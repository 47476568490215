export const licenses = [
    {
        bulstat: "201122991",
        company: "„ТЕКА ПЛАСТ“ ЕООД",
        no: "No DOC-11034-CRM",
        date: "30.06.2027г.",
        contract: "BG-RRP-3.005-0462-C01"
    },
    {
        bulstat: "115147345",
        company: "„ФЕРНЕТ БЪЛГАРИЯ“ ЕООД",
        no: "No DOC-11037-CRM",
        date: "30.06.2027г.",
        contract: "BG-RRP-3.005-0268-C01"
    },
]