//providers
import { useAuthDataContext } from "providers/Auth"

function useCheckPaymentAvailability() {
    const { getUser } = useAuthDataContext()

    function isPaymentAvailable(paymentName) {
        const company = getUser()?.getCompany()

        if ((paymentName === 'epay' || paymentName === 'epay_borica') && company.hasEpay()) {
            return true
        }

        if ((paymentName === 'paypal' || paymentName === 'paypal_card') && company.hasPayPal()) {
            return true
        }

        if ((paymentName === 'stripe') && company.hasStripe()) {
            return true
        }

        return false
    }

    return {
        isPaymentAvailable
    }
}

export default useCheckPaymentAvailability