import { useState, useEffect } from 'react'

//helpers
import Api from 'helpers/Api'

//components
import Loader from 'components/misc/Loader'
import FilePreview from 'components/partials/FilePreview'
import PriceValue from 'components/partials/PriceValue'

//libraries
import moment from 'moment'

//providers
import { useAuthDataContext } from 'providers/Auth'
import { useAppContext } from 'providers/App'

//refs
import Refs from 'Refs'

function Index({ show, hide, id }) {
    const { getUser } = useAuthDataContext()
    const { showError } = useAppContext()

    const defaultData = {
        article: null,
    }

    const [state, setState] = useState({
        data: defaultData,
        loading: false,
        onEntering: null,
        onExiting: null
    })

    useEffect(() => {
        fetchData()
    }, [id])

    function fetchData() {
        if (id) {
            setLoading(!state.data.article)

            Api.get('store/articles/view', {
                params: {
                    id
                }
            })
                .then(res => {
                    const data = res.data

                    if (data.article.details) {
                        data.article.details = res.data.article.details.map(detail => {
                            return {
                                name: detail?.detail?.translation?.name || detail?.detail?.name,
                                value: detail?.value?.translation?.name || detail?.value?.name,
                                detail_id: detail.detail_id,
                                value_id: detail.value_id,
                            }
                        })
                    } else {
                        data.details = []
                    }

                    setState(prev => ({
                        ...prev,
                        data,
                    }))
                })
                .then(() => {
                    setLoading(false)
                })
        }
    }

    function setLoading(loading) {
        setState(prev => ({
            ...prev,
            loading
        }))
    }

    function calculatePrice(e) {
        e.preventDefault()

        let ids = [id]

        Api.post('store/articles/calculate-price', {
            article_id: ids,
        }).then(res => {
            let modal = Refs.getInstance().getRef('info')

            hide(() => {
                modal.open('Ще получите имейл, когато синхронизацията завърши!')
            })

            modal.onClose(() => {
                show()
            })
        }).catch(err => {
            showError()
        })
    }

    function createMarkup(html) {
        return { __html: html }
    }

    return (

        state.loading
            ?
            <div style={{ padding: '150px 0' }}>
                <Loader />
            </div>
            :
            <div className="right-form-holder">
                <div className="preview-article">
                    <div className="row">
                        <div className="col">
                            <h6>
                                Информация за продукта
                            </h6>
                            <div className="field">
                                <div className="top row">
                                    <div
                                        className="images"
                                        style={{
                                            width: "235px"
                                        }}
                                    >
                                        <FilePreview
                                            file={state.data.article?.files ? state.data.article?.files[0] : null}
                                            height="235px"
                                            width="100%"
                                        />
                                        <ul className="gallery">
                                            {state.data.article?.files.map((file, i) => (
                                                i > 0 ?
                                                    <li>
                                                        <FilePreview
                                                            file={file}
                                                        />
                                                    </li>
                                                    :
                                                    <>
                                                    </>
                                            ))}
                                        </ul>
                                    </div>
                                    <div className="data">
                                        <h3>
                                            {state.data.article?.translation?.name}
                                        </h3>
                                        <h4>
                                            {state.data.article?.article_name}
                                        </h4>
                                        <div className="row">
                                            <div className="col">
                                                <table>
                                                    <tr>
                                                        <td>
                                                            Референтен номер:
                                                        </td>
                                                        <td>
                                                            {state.data.article?.ref_num}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Цена продажба:
                                                        </td>
                                                        <td>
                                                            {state.data.article?.currency?.prefix} {state.data.article?.actual_price !== null ? <PriceValue>{parseFloat(state.data.article?.actual_price || 0)}</PriceValue> : ''}  {state.data.article?.currency?.sufix}
                                                        </td>
                                                    </tr>
                                                    {getUser().permission('show_delivery_price') ?
                                                        <tr>
                                                            <td>
                                                                Себестойност (без ДДС):
                                                            </td>
                                                            <td>
                                                                {state.data.article?.currency?.prefix} {state.data.article?.avg_delivery_price !== null ? <PriceValue>{parseFloat(state.data.article?.avg_delivery_price || 0)}</PriceValue> : ''}  {state.data.article?.currency?.sufix}
                                                            </td>
                                                        </tr>
                                                        :
                                                        <>
                                                        </>
                                                    }
                                                    <tr>
                                                        <td>
                                                            Единица продажба:
                                                        </td>
                                                        <td>
                                                            {state.data.article?.amount_unit} {state.data.article?.amounttype?.translation?.name_short}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Създаден на:
                                                        </td>
                                                        <td>
                                                            {state.data.article?.created_at !== null ? moment(state.data.article?.created_at).format('DD.MM.YYYY HH:mm') : ''}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Актуализиран на:
                                                        </td>
                                                        <td>
                                                            {state.data.article?.updated_at !== null ? moment(state.data.article?.updated_at).format('DD.MM.YYYY HH:mm') : ''}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Мин. складово к-во:
                                                        </td>
                                                        <td>
                                                            {state.data.article?.min_quantity || '-'} {state.data.article?.amounttype?.translation?.name_short}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Публичен:
                                                        </td>
                                                        <td>
                                                            {state.data.article?.is_public
                                                                ?
                                                                <span className="green-value">Да</span>
                                                                :
                                                                <span className="red-value">Не</span>
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Интрастат:
                                                        </td>
                                                        <td>
                                                            {state.data.article?.intrastat}
                                                        </td>
                                                    </tr>
                                                    {getUser().permission('show_delivery_price') &&
                                                        <tr>
                                                            <td className="th">
                                                                Марж:
                                                            </td>
                                                            <td>
                                                                <PriceValue>{state.data.article?.margin_perc}</PriceValue>%
                                                            </td>
                                                        </tr>
                                                    }
                                                </table>
                                            </div>
                                            <div className="col">
                                                <table>
                                                    <tr>
                                                        <td>
                                                            Баркод:
                                                        </td>
                                                        <td>
                                                            {state.data.article?.barcode}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Последно зареждане:
                                                        </td>
                                                        <td>
                                                            {state.data.article?.currency?.prefix} {state.data.article?.current_delivery_price !== null ? <PriceValue>{parseFloat(state.data.article?.current_delivery_price || 0)}</PriceValue> : ''}  {state.data.article?.currency?.sufix}
                                                        </td>
                                                    </tr>
                                                    {getUser().permission('show_delivery_price') ?
                                                        <tr>
                                                            <td>
                                                                Себестойност (+ ДДС):
                                                            </td>
                                                            <td>
                                                                {state.data.article?.currency?.prefix} {state.data.article?.avg_delivery_price_with_vat !== null ? <PriceValue>{parseFloat(state.data.article?.avg_delivery_price_with_vat || 0)}</PriceValue> : ''}  {state.data.article?.currency?.sufix}
                                                            </td>
                                                        </tr>
                                                        :
                                                        <>
                                                        </>
                                                    }
                                                    <tr>
                                                        <td>
                                                            Код:
                                                        </td>
                                                        <td>
                                                            {state.data.article?.code}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Създаден от:
                                                        </td>
                                                        <td>
                                                            {state.data.article?.user?.username || ''}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Промяна наличност:
                                                        </td>
                                                        <td>
                                                            {state.data.article?.amount_last_update !== null ? moment(state.data.article?.amount_last_update).format('DD.MM.YYYY HH:mm') : ''}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Макс. складово к-во:
                                                        </td>
                                                        <td>
                                                            {state.data.article?.max_quantity || '-'} {state.data.article?.amounttype?.translation?.name_short}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Продажба:
                                                        </td>
                                                        <td>
                                                            {state.data.article?.is_for_sale
                                                                ?
                                                                <span className="green-value">Да</span>
                                                                :
                                                                <span className="red-value">Не</span>
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            ДДС група:
                                                        </td>
                                                        <td>
                                                            {state.data.article?.taxgroup?.name}
                                                        </td>
                                                    </tr>
                                                    {getUser().permission('show_delivery_price') &&
                                                        <tr>
                                                            <td className="th">
                                                                Надценка:
                                                            </td>
                                                            <td>
                                                                <PriceValue>{state.data.article?.profit_perc}</PriceValue>%
                                                            </td>
                                                        </tr>
                                                    }
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {state.data.article?.translation?.description && state.data.article?.translation?.description.length > 0 &&
                                    <p dangerouslySetInnerHTML={createMarkup(state.data.article?.translation?.description)} />
                                }
                            </div>
                        </div>
                    </div>
                    {state.data.article?.details.length ?
                        <div className="row">
                            <div className="col">
                                <h6>
                                    Характеристики
                                </h6>
                                <div className="field">
                                    <table>
                                        <tbody>
                                            {state.data.article?.details.map((d, i) =>
                                                <tr key={i}>
                                                    <td>
                                                        {d?.name}
                                                    </td>
                                                    <td>
                                                        {d?.value}
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        :
                        <>
                        </>
                    }
                </div>
            </div>
    )
}

export default Index