import { useState } from "react"

//components
import Layout from "components/Layout"
import FindCompany from "./findcompany/components/FindCompany"

function Sandbox() {
  const [state, setState] = useState({
    companyFieldValue: "",
  })

  console.log(state)

  return (
    <Layout>
      <section>
        <h1>Място за тестване и разработка на компоненти</h1>
        <hr
          style={{
            backgroundColor: "#2c2c2c",
          }}
        />
        <FindCompany
          // centeredLabel={true}
          inputIdName="customer_id"
          setInputValueCB={(value) => {
            setState((prevState) => ({
              ...prevState,
              companyFieldValue: value,
            }))
          }}
        />
      </section>
    </Layout>
  )
}

export default Sandbox
