//components
import DocumentsTable from "./DocumentsTable"
import TransactionsTable from "./TransactionsTable"
import IncomesAndExpenses from "components/pages/reports/partials/IncomesAndExpenses"
import CRMLicense from "./CRMLicense"

//providers
import { useDashboardDataContext } from "../providers/dashboard"
import { useAuthDataContext } from "providers/Auth"
import { licenses } from "constants/licenses"

function WithDocuments() {
  const auth = useAuthDataContext();
  const dashboardData = useDashboardDataContext()

  const { getTransactionsCount, getRefreshed } = dashboardData

  const license = licenses.find(license => license.bulstat === auth.getUser().getCompany().getNumber());

  function renderChart() {
    if (getTransactionsCount()) {
      return (
        <div className="statistics-holder">
          <IncomesAndExpenses
            chartOnly={true}
            hasAnimation={false}
            key={getRefreshed("TRANSACTIONS")}
          />
        </div>
      )
    }

    return <></>
  }

  return (
    <section
      id="with-documents"
      className={getTransactionsCount() ? "with-chart" : ""}
    >
      <div className="top">
        <div className="documents-holder">
          <DocumentsTable />
        </div>
        <div style={{ flex: '0.4 1' }}>
          {license &&
            <CRMLicense license={license} />
          }
          <div className="transactions-holder">
            <TransactionsTable />
          </div>
        </div>
      </div>
      {renderChart()}
    </section>
  )
}

export default WithDocuments
