import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

//plugins
import moment from 'moment'
import { Modal, Button, Tabs, Tab, FloatingLabel, Form, Row, Col, Nav, Alert, Table, InputGroup } from 'react-bootstrap';

//helpers
import Api from 'helpers/Api'
import { useValidation } from 'helpers/Validation'
import { useNestedState } from 'helpers/NestedState'

//misc
import SaveButton from 'components/misc/Button'
import Info from 'components/modals/Info'
import Autocomplete from 'components/misc/Dropdown';
import { TrashFill } from 'react-bootstrap-icons';
import PriceValue from 'components/partials/PriceValue';

function Expenses(props, ref) {

    const defaultData = {
        expenses: [],
        documents: [],
    }

    const [state, setState] = useNestedState({
        show: false,
        id: null,
        edit: false,
        tabId: 'expenses',
        data: defaultData,
        loading: false,
        onSuccess: null,
        onEntering: null,
        onExiting: null,
    });

    const [validations, setValidations] = useValidation();

    const validationModalRef = useRef(null);

    useImperativeHandle(ref, () => ({
        add: (loadId) => {
            add(loadId);
        },
        edit: (loadId) => {
            edit(loadId);
        },
        hide: () => {
            hide();
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        }
    }));

    useEffect(() => {

    }, []);

    useEffect(() => {
        loadData();
    }, [state.edit]);

    useEffect(() => {
        if (state.data.expenses.length === 0) {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    expenses: [{}]
                }
            }));
        }
    }, [state.data.expenses]);

    // useEffect(() => {
    //     if (state.tabId !== 'documents') {
    //         return;
    //     }

    //     if (state.data.documents.length === 0) {
    //         setState(prev => ({
    //             ...prev,
    //             data: {
    //                 ...prev.data,
    //                 documents: [{}]
    //             }
    //         }));
    //     }
    // }, [state.tabId, state.data.documents]);

    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }));
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }));
    }

    const handleClose = e => {

        if (e) {
            e.preventDefault();
        }

        setState(prev => ({
            ...prev,
            data: defaultData,
            id: null,
            intrastatId: null,
        }));

        setValidations(null);

        hide();
    }

    const add = (loadId) => {
        setState(prev => ({
            ...prev,
            tabId: 'expenses',
            data: defaultData,
            loadId: loadId,
            edit: moment().unix()
        }));

        show();
    }

    const edit = loadId => {
        setState(prev => ({
            ...prev,
            tabId: 'expenses',
            data: defaultData,
            loadId: loadId,
            edit: moment().unix()
        }));

        show();
    }

    const loadData = () => {
        if (!state.loadId) {
            return;
        }

        Api.get('store/storeloads/expenses/show?id=' + state.loadId)
            .then(res => {
                let data = res.data;

                setState(prev => ({
                    ...prev,
                    data: data
                }));
            });
    }

    const handleSave = e => {
        e.preventDefault();

        setState(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url = 'store/storeloads/expenses/edit';

        let data = new FormData(e.target);

        if (state.loadId) {
            data.append('id', state.loadId)
        }

        Api.post(url, data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(res => {
            if (typeof state.onSuccess === 'function') {
                state.onSuccess(res.data)
            }

            hide()
        }).catch(error => {
            const _err = error.response;

            if (_err && _err.status && _err.status === 422) {
                setValidations(_err.data.errors)
            }

        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false,
            }));
        });
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(name, value, 'data')
    }

    const handleCheckboxChange = e => {
        const name = e.target.name;

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: prev.data[name] ? false : true
            }
        }));
    }

    const handleChangeArticle = data => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                article: data,
                article_id: data.id
            }
        }))
    }

    const setTab = id => {
        setState(prev => ({
            ...prev,
            tabId: id,
        }));
    }

    const handleAddExpense = e => {
        e.preventDefault()

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                expenses: prev.data.expenses.concat({})
            }
        }))
    }

    const handleRemoveExpense = (e, index) => {
        e.preventDefault()

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                expenses: prev.data.expenses.filter((row, i) => i !== index)
            }
        }))
    }

    const handleAddDocument = e => {
        e.preventDefault()

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                documents: prev.data.documents.concat({})
            }
        }))
    }

    const handleRemoveDocument = (e, index) => {
        e.preventDefault()

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                documents: prev.data.documents.filter((row, i) => i !== index)
            }
        }))
    }

    const handleChangeDocument = (data, index) => {
        if (data.id) {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    documents: Object.values({
                        ...prev.data.documents,
                        [index]: {
                            ...prev.data.documents[index],
                            document_id: data.id,
                            document: data,
                        }
                    })
                }
            }));
        } else {

        }
    }

    return (
        <>
            <Info
                ref={validationModalRef}
                mainMessage="Моля, попълнете всички задължителни полета, означени с червен цвят!"
            />

            <Modal size="lg" centered show={state.show} onHide={handleClose} onEntering={state.onEntering} onExiting={state.onExiting}>
                <form onSubmit={handleSave}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Редактиране на допълнителни разходи
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Tabs
                            activeKey={state.tabId}
                            onSelect={(k) => setTab(k)}
                            className="mb-3"
                        >
                            <Tab eventKey="expenses" title="Допълнителни разходи">
                                <Row className="mb-3">
                                    <Col>
                                        <Form.Group as={Row}>
                                            <Form.Label column="sm" xs="auto" className="text-left">
                                                Разходите са за сметка на:
                                            </Form.Label>
                                            <Col xs="auto">
                                                <Form.Select
                                                    name="expenses_mode_id"
                                                    value={state.data?.expenses_mode_id || 1}
                                                    onChange={handleInputChange}
                                                    isInvalid={Boolean(validations?.expenses_mode_id)}
                                                    size="sm"
                                                >
                                                    <option value={1}>Получателя</option>
                                                    <option value={2}>Доставчика</option>
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">
                                                    {validations?.expenses_mode_id && (validations.expenses_mode_id[0] || validations.expenses_mode_id)}
                                                </Form.Control.Feedback>
                                            </Col>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Table className="valign-top big no-shadow">
                                            <thead>
                                                <tr>
                                                    <th style={{ width: '5%' }}>
                                                        #
                                                    </th>
                                                    <th>
                                                        Име
                                                    </th>
                                                    <th className="text-right" style={{ width: '95px' }}>
                                                        Сума
                                                    </th>
                                                    <th>
                                                        Описание
                                                    </th>
                                                    <th className="options">

                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {state.data.expenses.map((row, index) =>
                                                    <tr key={'r-' + index}>
                                                        <td title={row.id || '-'} style={{ width: '5%' }}>
                                                            {index + 1}

                                                            <input type="hidden" name={`expenses[${index}][id]`} value={row.id || ''} />
                                                        </td>
                                                        <td>
                                                            <Autocomplete
                                                                variant="basic"
                                                                url="store/autocomplete/storeload-expenses"
                                                                inputName={`expenses[${index}][name]`}
                                                                inputValue={row.name || ''}
                                                                error={Boolean(validations && validations.expenses && validations.expenses[index] && validations.expenses[index].name && (validations.expenses[index].name[0] || validations.rows[index].name))}
                                                                helperText={validations && validations.expenses && validations.expenses[index] && validations.expenses[index].name && (validations.expenses[index].name[0] || validations.rows[index].name)}
                                                                size="sm"
                                                                autoFocus
                                                            />
                                                        </td>
                                                        <td>
                                                            <InputGroup size="sm" style={{ minWidth: '95px' }}>
                                                                <Form.Control
                                                                    type="number"
                                                                    step="0.01"
                                                                    name={`expenses[${index}][sum]`}
                                                                    value={row.sum || ''}
                                                                    onChange={e => handleInputChange(e)}
                                                                    isInvalid={Boolean(validations && validations.expenses && validations.expenses[index] && validations.expenses[index].sum && (validations.expenses[index].sum[0] || validations.expenses[index].sum))}
                                                                    size="sm"
                                                                    className="price-input"
                                                                />

                                                                <InputGroup.Text>
                                                                    {state.data.currency?.prefix || state.data.currency?.sufix || '-'}
                                                                </InputGroup.Text>

                                                                {/* <Form.Control.Feedback type="invalid">
                                                                    {validations && validations.expenses && validations.expenses[index] && validations.expenses[index].sum && (validations.expenses[index].sum[0] || validations.expenses[index].sum)}
                                                                </Form.Control.Feedback> */}
                                                            </InputGroup>
                                                        </td>
                                                        <td>
                                                            <Form.Control
                                                                type="text"
                                                                name={`expenses[${index}][description]`}
                                                                value={row.description || ''}
                                                                onChange={handleInputChange}
                                                                isInvalid={Boolean(validations && validations.expenses && validations.expenses[index] && validations.expenses[index].description && (validations.expenses[index].description[0] || validations.expenses[index].description))}
                                                                size="sm"
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {validations && validations.expenses && validations.expenses[index] && validations.expenses[index].description && (validations.expenses[index].description[0] || validations.expenses[index].description)}
                                                            </Form.Control.Feedback>
                                                        </td>
                                                        <td className="options">
                                                            <Button variant="danger" size="sm" onClick={e => handleRemoveExpense(e, index)}>
                                                                <TrashFill />
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </Table>

                                        <Button variant="outline-dark" className="mt-3" size="sm" onClick={handleAddExpense}>
                                            Нов ред
                                        </Button>
                                    </Col>
                                </Row>
                            </Tab>
                            <Tab eventKey="documents" title="Документи за разходи">
                                <Table className="valign-top big no-shadow">
                                    <thead>
                                        <tr>
                                            <th style={{ width: '5%' }}>
                                                ID
                                            </th>
                                            <th className="text-center">
                                                Тип документ
                                            </th>
                                            <th>
                                                Номер
                                            </th>
                                            <th>
                                                Описание
                                            </th>
                                            <th>
                                                Доставчик
                                            </th>
                                            <th className="text-right">
                                                Сума
                                            </th>
                                            <th className="options">

                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {state.data.documents.map((row, index) =>
                                            <tr key={'r-' + index}>
                                                <td title={row.id || '-'} style={{ width: '5%' }}>
                                                    {row.document_id}

                                                    <input type="hidden" name={`documents[${index}][id]`} value={row.id || ''} />
                                                </td>
                                                <td className="text-center">
                                                    {row.document?.documenttype?.name}
                                                </td>
                                                <td>
                                                    <Autocomplete
                                                        variant="basic"
                                                        url="store/autocomplete/storeload-expenses-documents"
                                                        inputName={`documents[${index}][name]`}
                                                        inputIdName={`documents[${index}][document_id]`}
                                                        // inputValue={row.name || ''}
                                                        inputIdValue={row.document_id || ''}
                                                        onChange={data => handleChangeDocument(data, index)}
                                                        onInputChange={data => handleChangeDocument(data, index)}
                                                        error={Boolean(validations && validations.documents && validations.documents[index] && validations.documents[index].document_id && (validations.documents[index].document_id[0] || validations.rows[index].document_id))}
                                                        helperText={validations && validations.documents && validations.documents[index] && validations.documents[index].document_id && (validations.documents[index].document_id[0] || validations.rows[index].document_id)}
                                                        size="sm"
                                                        renderText={data => {
                                                            return (
                                                                <div style={{ display: 'flex', width: '100%' }}>
                                                                    <div style={{ minWidth: '40%', marginRight: '10px' }}>
                                                                        {data.document_no}
                                                                    </div>
                                                                    <div style={{ minWidth: '30%', marginRight: '10px' }}>
                                                                        {data.id}
                                                                    </div>
                                                                    <div style={{ minWidth: 'calc(30% - 20px)' }}>
                                                                        {data.supplier?.name}
                                                                    </div>
                                                                </div>
                                                            )
                                                        }}
                                                        renderInputText={data => data.document_no}
                                                        listStyle={{
                                                            minWidth: 'max-content'
                                                        }}
                                                        autoFocus
                                                    />
                                                </td>
                                                <td>
                                                    {row.document?.description}
                                                </td>
                                                <td>
                                                    {row.document?.supplier?.name}
                                                </td>
                                                <td className="text-right">
                                                    <PriceValue prefix={row.document?.currency?.prefix} sufix={row.document?.currency?.sufix}>{row.document?.delivery_gross}</PriceValue>
                                                </td>
                                                <td className="options">
                                                    <Button variant="danger" size="sm" onClick={e => handleRemoveDocument(e, index)}>
                                                        <TrashFill />
                                                    </Button>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>

                                <Button variant="outline-dark" className="mt-3" size="sm" onClick={handleAddDocument}>
                                    Нов ред
                                </Button>
                            </Tab>
                        </Tabs>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Отказ
                        </Button>
                        <SaveButton
                            loading={state.loading}
                            className="save"
                        />
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default forwardRef(Expenses);
