import { baseUrl } from "helpers/mode";

export const showPdf = (id, token) => {
    let params = new URLSearchParams();

    params.set('id', id);
    params.set('token', token);

    const url = baseUrl + 'store/storeouts/stream?' + params.toString();

    window.open(url);
}