//components
import PreviewBillDocumentButtons from "./PreviewBillDocumentButtons"

export default function PreviewBillStock() {
  return (
    <div className="content">
      <div
        className="frame-wrapper"
        style={{
          backgroundColor: "#fff",
          marginBottom: "20px",
        }}
      >
        <div className="options-holder">
          <PreviewBillDocumentButtons />
        </div>
        <embed
          type="text/html"
          src="http://192.168.1.19/blackbox-backend/public/api/documents/html?id=1068&updated_at=2024-09-10T06:06:24.000000Z&token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC8xOTIuMTY4LjEuMTlcL2JsYWNrYm94LWJhY2tlbmRcL3B1YmxpY1wvYXBpXC9hdXRoXC9sb2dpbiIsImlhdCI6MTcyNzE3Nzg3OCwibmJmIjoxNzI3MTc3ODc4LCJqdGkiOiJmeFdIbXlOdFlXSGNNMGNIIiwic3ViIjoxLCJwcnYiOiIyM2JkNWM4OTQ5ZjYwMGFkYjM5ZTcwMWM0MDA4NzJkYjdhNTk3NmY3In0.uVpCJQ6o_wc6If_HzDO8UHXpUV20yJn8vzkYd82n1X4"
          className="doc-iframe"
        />
      </div>
    </div>
  )
}
