import { useRef, useState } from "react"

//libraries
import { Button, Menu, MenuItem } from "@material-ui/core"

//hooks
import useFetchUnits from "../hooks/useFetchUnits"

//images
import checkIcon from "assets/img/app/icons/check-gray.png"

function SelectUnit({ initialUnit, onChange }) {
  const { isLoadingUnits, getUnits, addUnit } = useFetchUnits()

  const [anchorEl, setAnchorEl] = useState(null)

  const inputRef = useRef()

  function getSelectedUnit() {
    if (initialUnit) {
      return initialUnit
    }

    if (getUnits().length) {
      return getUnits()[0]
    }

    return {}
  }

  const closeMenu = () => {
    setAnchorEl(null)
  }

  const openMenu = (e) => {
    setAnchorEl(e.target)
  }

  function handleSaveCustonValue() {
    addUnit(inputRef.current.value, handleSaveUnitSuccess)
    closeMenu()
    inputRef.current.value = ""
  }

  function handleSaveUnitSuccess(unit) {
    onChange(unit)
  }

  function isActive(unit) {
    if (unit.name === getSelectedUnit()?.name) {
      return true
    }

    if (unit.id === getSelectedUnit()?.id) {
      return true
    }

    return false
  }

  return (
    <>
      <Button
        className={`select-unit ${isLoadingUnits ? "disabled" : ""}`}
        onClick={openMenu}
      >
        {getSelectedUnit()?.name || "-"}
      </Button>
      <Menu
        anchorEl={anchorEl}
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        onClick={(e) => {
          const dataIndex = e.target.getAttribute("data-index")

          if (dataIndex === null) return

          onChange(getUnits()[dataIndex])

          if (dataIndex) closeMenu()
        }}
        className="additinal-pagination-menu"
      >
        {getUnits().map((unit, i) => (
          <MenuItem data-index={i} key={unit.id || unit.name}>
            {unit.name}
            {isActive(unit) ? <img src={checkIcon} alt="" /> : <></>}
          </MenuItem>
        ))}
        <div className="bottom">
          <input placeholder="Друго" ref={inputRef} />
          <Button onClick={handleSaveCustonValue}>Запази</Button>
        </div>
      </Menu>
    </>
  )
}

export default SelectUnit
