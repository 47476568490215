export default function PreviewBillDocumentButtons() {
  return (
    <div className="buttons">
      <button className="normal send" onClick={() => {}}>
        Изпращане
      </button>
      <button className="normal download" onClick={() => {}}>
        Изтегляне
      </button>
      <button className="normal print" onClick={() => {}}>
        Принтиране
      </button>
    </div>
  )
}
