//librarires
import moment from "moment"

function Footer() {
  function getYear() {
    return moment().format("YYYY")
  }

  return (
    <footer
      style={{
        backgroundColor: "#162B51",
        height: "22px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        color: "#fff",
        fontSize: "12px",
      }}
    >
      Docuela © {getYear()}. All rights reserved.
    </footer>
  )
}

export default Footer
