import { useState, useEffect } from 'react'
import { useHistory } from "react-router-dom"
import moment from "moment"

//providers
import { useAuthDataContext } from "providers/Auth"

//helpers
import { useQuery } from 'helpers/Url'
import Api from 'helpers/Api'
import Loader from 'components/misc/Loader'
import Autocomplete from 'components/misc/Dropdown'
import Category from 'components/partials/filter/Category'
import DatePicker from 'components/partials/filter/DatePicker'
import StoreAndCashdesk from 'components/partials/filter/StoreAndCashdesk'
import RedditTextField from 'components/misc/RedditTextField'
import RedditSelect from 'components/misc/RedditSelect'
import { MenuItem } from '@material-ui/core'

function Filter(props) {
    const history = useHistory()
    const query = useQuery()

    const auth = useAuthDataContext()

    const [statuses, setStatuses] = useState([])

    const [filters, setFilters] = useState({
        show: false,
    })

    useEffect(() => {
        Api.get('store/accounts/statuses')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setStatuses(res.data)
                }
            })
    }, [])

    const handleDateChange = (date, name) => {
        props.handleSearch(name, moment(date).format('YYYY-MM-DD'))
    }

    const handleSearch = e => {
        const name = e.target.name
        const value = e.target.value

        props.handleSearch(name, value)
    }

    const handleFromDateChange = e => {
        const name = e.target.name
        const value = e.target.value

        let date = value ? moment(value).format('YYYY-MM-DD') : ''

        props.handleSearch({
            created_from_date: date,
            created_to_date: date,
        })
    }

    const handleToDateChange = e => {
        const value = e.target.value

        let date = value ? moment(value).format('YYYY-MM-DD') : ''

        if (date && props.filter.created_from_date > date) {
            return
        }

        props.handleSearch({
            created_to_date: date,
        })
    }

    const handleCashdeskChange = selected => {
        props.handleSearch('cashdesk_id', selected)
    }

    const handleChangeArticle = data => {
        props.handleSearch({
            article_id: data?.id || '',
        })
    }

    const handleCategoryChange = selected => {
        props.handleSearch('category_id', selected)
    }

    const handleChangeUser = data => {
        let checked = data.map(d => d.id)

        props.handleSearch({
            creator_id: checked,
        })
    }

    const handleChangeClient = data => {
        props.handleSearch({
            client_id: data?.id || '',
        })
    }

    const handleChangeSpeditor = data => {
        let checked = data.map(d => d.id)

        props.handleSearch({
            speditor_id: checked
        })
    }

    const handleChangePaymentMethod = data => {
        let checked = data.map(d => d.id)

        props.handleSearch({
            paymethod_id: checked
        })
    }

    const toggleFilters = () => {
        setFilters(prev => ({
            ...prev,
            show: !prev.show,
        }))
    }

    const getMoreFiltersValue = () => {
        let data = []

        if (props.filter.created_from_date) {
            data.push(1)
        }

        if (props.filter.created_to_date) {
            data.push(1)
        }

        if ((props.filter.creator_id || []).length > 0) {
            data.push(1)
        }

        if ((props.filter.speditor_id || []).length > 0) {
            data.push(1)
        }

        if (props.filter.tracking_number) {
            data.push(1)
        }

        if (props.filter.article_id) {
            data.push(1)
        }

        if ((props.filter.category_id || []).length > 0) {
            data.push(1)
        }

        if ((props.filter.paymethod_id || []).length > 0) {
            data.push(1)
        }

        if (props.filter.paid_status) {
            data.push(1)
        }

        if (props.filter.deleted) {
            data.push(1)
        }

        if (data.length === 0) {
            return 'Няма избрани'
        }

        return data.length + ' избрани'
    }

    return (
        <div className="row filter">
            <div
                className="col"
            >
                <RedditTextField
                    fullWidth
                    label="Търсене..."
                    name="search"
                    value={props.filter.search || ''}
                    onChange={handleSearch}
                />
            </div>
            <div
                className="col"
            >
                <RedditTextField
                    fullWidth
                    label="Номер на сметка"
                    name="account_num"
                    value={props.filter.account_num || ''}
                    onChange={handleSearch}

                />
            </div>
            <div
                className="col"
            >
                <RedditSelect
                    name="status_id"
                    value={props.filter.status_id || 0}
                    onChange={handleSearch}
                    label="Статус"
                >
                    <MenuItem value="0">
                        Всички
                    </MenuItem>
                    {statuses.map(o =>
                        <MenuItem key={o.id} value={o.id}>
                            {o?.translation?.name || o.name}
                        </MenuItem>
                    )}
                </RedditSelect>
            </div>

            <div
                className="col"
            >
                <Autocomplete
                    margin="none"
                    size="small"
                    variant="filled"
                    inputPlaceholder="Клиент"
                    url="store/autocomplete/clients"
                    inputIdName="client_id"
                    // inputValue={state.data?.supplier?.name}
                    inputIdValue={props.filter.client_id || ''}
                    onChange={handleChangeClient}
                    onInputChange={handleChangeClient}
                    renderText={data => data.info?.name}
                />
            </div>
        </div >
    )
}

export default Filter