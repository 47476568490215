import { useState } from "react"
import { createPortal } from "react-dom"

//components
import PaginationMini from "components/misc/PaginationMini"
import RedditTextField from "components/misc/RedditTextField"
import MenuOptions from "components/misc/MenuOptions"
import CommunicationOptions from "./CommunicationOptions"
import RedditSelect from "components/misc/RedditSelect"
import AddOrEditComplaint from "./AddOrEditComplaint"

//librarires
import { Pagination } from "react-bootstrap"
import moment from "moment"
import { MenuItem } from "@material-ui/core"
import { useQueryClient } from "react-query"
import { Skeleton } from "@mui/material"

//stores
import { useAddToComplaintsModal } from "stores/customer/useAddToComplaintsModal"
import { COMPLAINT_STATUSES } from "constants/Complaints"

//hooks
import useFetchFromServer from "hooks/useFetchFromServer"
import useSaveOnServer from "hooks/useSaveOnServer"

function ComplaintsList() {
  const queryClient = useQueryClient()

  const { getData, loadingFromServer } = useFetchFromServer("complaints", [])
  const { saveOnServer } = useSaveOnServer()

  const [filters, setFilters] = useState({
    search: "",
    statusId: "all",
  })

  const { getShown, showModal } = useAddToComplaintsModal()

  function getFilteredComplaintsList() {
    return getData().filter((complaint) => {
      if (filters.statusId !== "all") {
        if (complaint?.form?.statusId !== filters.statusId) {
          return false
        }
      }

      if (filters.search) {
        if (
          !complaint?.form?.client?.info?.name
            .toLowerCase()
            .includes(filters.search.toLowerCase())
        ) {
          return false
        }
      }

      return true
    })
  }

  function handleSearch(e) {
    const { name, value } = e.target

    setFilters((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  function handleEdit(complaint) {
    showModal(true, complaint)
  }

  function handleRevemo(i) {
    const newComplaintsList = [...getData()]
    newComplaintsList.splice(i, 1)

    saveOnServer("complaints", newComplaintsList, () => {
      queryClient.invalidateQueries("complaints")
    })
  }

  function getCurrentStatus(status) {
    return COMPLAINT_STATUSES.find(({ value }) => value === status)
  }

  return (
    <>
      <div className="filter-holder">
        <div className="row filter">
          <div
            className="col"
            style={{
              maxWidth: "300px",
            }}
          >
            <RedditTextField
              size="small"
              margin="dense"
              fullWidth
              label="Търсене..."
              name="search"
              value={filters.search}
              onChange={handleSearch}
            />
          </div>
          <div className="col">
            <RedditSelect
              value={filters.statusId}
              name="statusId"
              onChange={handleSearch}
              label="Статус"
            >
              <MenuItem value="all">Всички</MenuItem>
              {COMPLAINT_STATUSES.map(({ value, title }) => (
                <MenuItem value={value} key={value}>
                  {title}
                </MenuItem>
              ))}
            </RedditSelect>
          </div>
        </div>
        <PaginationMini
          isLoading={false}
          page={1}
          pages={5}
          handlePage={() => {}}
        />
      </div>
      {loadingFromServer ? (
        <>
          <Skeleton variant="rectangular" width="100%" height={50} />
          <br />
          <Skeleton variant="rectangular" width="100%" height={50} />
          <br />
          <Skeleton variant="rectangular" width="100%" height={50} />
          <br />
          <Skeleton variant="rectangular" width="100%" height={50} />
          <br />
          <Skeleton variant="rectangular" width="100%" height={50} />
        </>
      ) : (
        <table className="type-outer">
          <thead>
            <tr>
              <th>Статус</th>
              <th>Дата</th>
              <th>Поръчка</th>
              <th>Услуга</th>
              <th>Клиент</th>
              <th>Адрес</th>
              <th className="text-center">Опции</th>
            </tr>
          </thead>
          <tbody>
            {getFilteredComplaintsList().map((complaint, i) => (
              <tr key={complaint.id}>
                <td
                  onClick={() => {
                    handleEdit(complaint)
                  }}
                >
                  <div className="row">
                    <span
                      style={{
                        backgroundColor:
                          getCurrentStatus(complaint?.form?.statusId)?.color ||
                          "#2c2c2c",
                      }}
                      className="status"
                    ></span>
                    {getCurrentStatus(complaint?.form?.statusId)?.title || "-"}
                  </div>
                </td>
                <td
                  onClick={() => {
                    handleEdit(complaint)
                  }}
                >
                  {`${moment(complaint?.data?.date_ymd).format(
                    "DD.MM.YYYY"
                  )} г.`}
                </td>
                <td
                  onClick={() => {
                    handleEdit(complaint)
                  }}
                >
                  {complaint?.data?.order_number || "-"}
                </td>
                <td
                  onClick={() => {
                    handleEdit(complaint)
                  }}
                >
                  {complaint?.data?.description &&
                  complaint?.data?.description?.length >= 30
                    ? `${complaint?.data?.description?.substring(30, 0)}...`
                    : complaint?.data?.description || "-"}
                </td>
                <td
                  onClick={() => {
                    handleEdit(complaint)
                  }}
                >
                  {complaint?.form?.client?.info?.name || "-"}
                </td>
                <td
                  onClick={() => {
                    handleEdit(complaint)
                  }}
                >
                  {complaint?.form?.client?.info?.address?.full_address || "-"}
                </td>
                <td
                  className="text-center"
                  style={{
                    width: "64px",
                  }}
                >
                  <MenuOptions>
                    <CommunicationOptions
                      handleEdit={() => handleEdit(complaint)}
                      handleRemove={() => handleRevemo(i)}
                    />
                  </MenuOptions>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <Pagination page={1} pages={5} handlePage={() => {}} />
      {createPortal(
        <AddOrEditComplaint allComplaints={getData()} />,
        document.body
      )}
    </>
  )
}

export default ComplaintsList
