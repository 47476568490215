import { useState, useEffect, useRef } from 'react'
import { Button, Dropdown, FloatingLabel, Form, Table } from 'react-bootstrap';
import Api from 'helpers/Api';
import Loader from 'components/misc/Loader';
import moment from 'moment';
import NoDataFound from 'components/misc/NoDataFound';
import Pagination from 'components/misc/Pagination';
import TableHeader from 'components/misc/TableHeader';
import TableCol from 'components/misc/TableCol';
import PriceValue from 'components/partials/PriceValue';
import MenuOptions from 'components/misc/MenuOptions';
import Autocomplete from 'components/misc/Autocomplete';
import Refs from 'Refs';
import DatePicker from 'components/partials/filter/DatePicker';

let timeout;

function Index(props) {

    const defaultData = [];

    const tableRef = useRef(null);

    const [state, setState] = useState({
        data: defaultData,
        pages: 0,
        total: 0,
        totals: {},
        client: null,
        loading: false,
        filter: {
            page: 1,
            created_from_date: null,
            created_to_date: null,
            creator_id: null,
            paymethod_id: []
        },
        refresh: false,
    });

    useEffect(() => {

        loading(true);

        Api.get('clients/payments', {
            params: {
                id: props.id,
                ...state.filter,
            }
        }).then(res => {
            setState(prev => ({
                ...prev,
                data: res.data.items,
                total: res.data.total,
                totals: res.data.totals,
                client: res.data.client,
                pages: res.data.pages,
                filter: {
                    ...prev.filter,
                    ...res.data.filter,
                },
            }));

            if (res.data.pages > 0 && state.filter.page > res.data.pages) {
                return handlePage({ selected: res.data.pages - 1 });
            }

            loading(false);
            sorting(false);
        });
    }, [props.id, state.refresh]);

    const loading = (loading) => {
        setState(prev => ({
            ...prev,
            loading: Boolean(loading)
        }));
    }

    const sorting = (sorting) => {
        setState(prev => ({
            ...prev,
            sorting: Boolean(sorting)
        }));
    }

    const handleSearch = (key, val, delay = 300) => {
        clearTimeout(timeout);

        if (typeof key === 'object') {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    ...key
                },
            }));
        } else {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    [key]: val,
                },
            }));
        }

        timeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    page: 1
                },
                refresh: moment().unix()
            }));
        }, delay);
    }

    const handlePage = page => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: page.selected + 1,
            },
            refresh: moment().unix()
        }));
    }

    const handleSort = (sort, order) => {
        setState(prev => ({
            ...prev,
            sorting: true,
            filter: {
                ...prev.filter,
                sort: sort,
                order: order,
                page: 1,
            },
            refresh: moment().unix()
        }));
    }

    const handleFromDateChange = e => {
        const name = e.target.name
        const value = e.target.value

        let date = value ? moment(value).format('YYYY-MM-DD') : '';

        handleSearch({
            created_from_date: date,
            created_to_date: date,
        });
    }

    const handleToDateChange = e => {
        const value = e.target.value

        let date = value ? moment(value).format('YYYY-MM-DD') : '';

        if (date && state.filter.created_from_date > date) {
            return;
        }

        handleSearch({
            created_to_date: date,
        });
    }

    const handleChangeUser = data => {
        handleSearch({
            creator_id: data?.id || '',
        });
    }

    const handleChangePaymentMethod = data => {
        let checked = data.map(d => d.id);

        handleSearch({
            paymethod_id: checked
        });
    }

    const handleShow = id => {
        props.showPayment(id);
    }

    const showAccount = id => {
        props.showAccount(id);
    }

    const handleAdd = () => {
        let modal = Refs.getInstance().getRef('newPayment');

        if (!modal) {
            return;
        }

        props.hide(() => {
            modal.forClient(state.client);
        });

        modal.onClose(() => {
            props.show();
        });
    }

    return (

        <>

            <div className="row">
                <div
                    className="col col-auto col-md-3 col-12 mb-3"
                >
                    <DatePicker
                        placeholder="Създаден от"
                        name="created_from_date"
                        value={state.filter.created_from_date || ''}
                        onChange={handleFromDateChange}
                    />
                </div>

                <div
                    className="col col-auto col-md-3 col-12 mb-3"
                >
                    <DatePicker
                        placeholder="Създаден до"
                        name="created_to_date"
                        value={state.filter.created_to_date || ''}
                        onChange={handleToDateChange}
                    />
                </div>
                <div
                    className="col col-auto col-md-3 col-12 mb-3"
                >
                    <Autocomplete
                        inputPlaceholder="Създадено от"
                        url="autocomplete/admins"
                        inputIdName="creator_id"
                        // inputValue={state.data?.supplier?.name}
                        inputIdValue={state.filter.creator_id || ''}
                        onChange={handleChangeUser}
                        onInputChange={handleChangeUser}
                    />
                </div>
                <div
                    className="col col-auto col-md-3 col-12 mb-3"
                >
                    <Autocomplete
                        inputPlaceholder="Начин на плащане"
                        url="autocomplete/payment-methods"
                        inputIdName="paymethod_id[]"
                        selectedIds={state.filter.paymethod_id || []}
                        onChange={handleChangePaymentMethod}
                        onInputChange={handleChangePaymentMethod}
                        multiple
                        filterable={false}
                    />
                </div>
            </div>

            <Button variant="outline-dark" className="mb-3" onClick={e => handleAdd()}>
                Ново плащане
            </Button>

            {state.loading && !state.sorting
                ?
                <Loader />
                :
                state.data.length === 0 ?
                    <NoDataFound />
                    :
                    <>
                        <Table className="big" bordered striped ref={tableRef}>
                            <TableHeader
                                tableRef={tableRef}
                                activeSortKey={state.filter.sort}
                                activeSortDir={state.filter.order}
                                onSort={(col, dir) => handleSort(col, dir)}
                            >
                                <TableCol sortable sortKey="id" style={{ width: '5%' }}>ID</TableCol>
                                <TableCol sortable sortKey="payment_id" style={{ width: '5%' }}>Плащане</TableCol>
                                <TableCol sortable sortKey="date">Дата</TableCol>
                                <TableCol className="text-center" sortable sortKey="operation">Операция</TableCol>
                                <TableCol sortable sortKey="user">Потребител</TableCol>
                                <TableCol className="text-right" sortable sortKey="total_sum">Сума</TableCol>
                                <TableCol>Сметка(и)</TableCol>
                                <TableCol className="text-right" sortable sortKey="money_left">Остатък</TableCol>
                                <TableCol className="text-left" sortable sortKey="paymethod">Начин на плащане</TableCol>
                                <TableCol className="text-left" sortable sortKey="ref">Референция</TableCol>
                                <TableCol className="text-left" sortable sortKey="description">Бележки</TableCol>
                            </TableHeader>
                            <tbody>
                                {state.data.map((r, i) =>
                                    <tr key={i}>
                                        <td>
                                            {r.id}
                                        </td>
                                        <td>
                                            {r.payment_id}
                                        </td>
                                        <td>
                                            {r.date ? moment(r.date).format('DD.MM.YYYY HH:mm') : ''}
                                        </td>
                                        <td className={`text-center ${r.operation?.code === 'DBT' ? 'negative-value' : r.operation?.code === 'CRD' ? 'green-value' : ''}`}>
                                            {r.operation?.name}
                                        </td>
                                        <td>
                                            {r.user?.username}
                                        </td>
                                        <td className={`text-right ${r.total_sum < 0 && 'negative-value'}`}>
                                            <PriceValue prefix={r.currency?.prefix} sufix={r.currency?.sufix}>{r.total_sum}</PriceValue>
                                        </td>
                                        <td>
                                            {r?.accounts && r.accounts.map(account =>
                                                <span
                                                    key={account.id}
                                                    className="link"
                                                    onClick={e => showAccount(account.id)}
                                                    style={{ display: 'block' }}
                                                >
                                                    {account.id}: <span className={account.paid_sum < 0 && 'negative-value'}>
                                                        <PriceValue prefix={r.currency?.prefix} sufix={r.currency?.sufix}>{account.paid_sum}</PriceValue>
                                                    </span>
                                                </span>
                                            )}
                                        </td>
                                        <td className={`text-right ${r.payment?.money_left < 0 && 'negative-value'}`}>
                                            {r.payment &&
                                                <PriceValue prefix={r.payment?.currency?.prefix} sufix={r.payment?.currency?.sufix}>{r.payment?.money_left}</PriceValue>
                                            }
                                        </td>
                                        <td>
                                            {r.paymentmethod?.name}
                                        </td>
                                        <td className={`text-left`}>
                                            {r.ref}
                                        </td>
                                        <td className={`text-left`}>
                                            {r.description}
                                        </td>
                                    </tr>
                                )}
                                <tr className="total">
                                    <td colSpan={5}>
                                        Общо
                                    </td>
                                    <td className={`text-right ${state.totals.total_sum < 0 && 'negative-value'}`}>
                                        <b> <PriceValue prefix={state.totals?.currency?.prefix} sufix={state.totals?.currency?.sufix}>{state.totals.total_sum}</PriceValue></b>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>

                        <Pagination
                            className="mt-3"
                            page={state.filter.page}
                            pages={state.pages}
                            handlePage={handlePage}
                        />
                    </>
            }
        </>
    )
}

export default Index;