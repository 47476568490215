import { useState, useImperativeHandle, forwardRef } from 'react'
import { Modal, Button } from 'react-bootstrap';

function Info(props, ref) {
    const [state, setState] = useState({
        show: false,
        params: null,
        msg: null,
        onSuccess: null,
        onClose: null,
        onCancel: null
    });

    useImperativeHandle(ref, () => ({
        open: (msg = null) => {
            handleShow(msg);
        },
        close: () => {
            handleClose();
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }));
        },
        onCancel: fn => {
            setState(prev => ({
                ...prev,
                onCancel: fn
            }));
        }
    }));

    const show = () => {
        setState(prev => ({
            ...prev,
            show: true,
        }));
    }

    const handleShow = (msg) => {
        setState(prev => ({
            ...prev,
            overlay: true,
            msg
        }))

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                modal: true,
            }))
        }, 50)
    }

    const handleClose = () => {
        setState(prev => ({
            ...prev,
            modal: false,
        }))

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                overlay: false,
            }))
        }, 100)

        if (typeof state.onCancel === 'function') {
            state.onCancel();
        }

        setState(prev => ({
            ...prev,
            onCancel: null,
        }))
    }


    const handleAccept = () => {
        setState(prev => ({
            ...prev,
            modal: false,
        }))

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                overlay: false,
            }))
        }, 100)

        if (state.onSuccess && typeof state.onSuccess === 'function') {
            state.onSuccess();
        }

        setState(prev => ({
            ...prev,
            onSuccess: null,
        }))
    }

    const style = {
        button: {
            margin: '0 10px',
            minWidth: '120px'
        }
    }

    return (
        <div className={`${state.overlay ? 'visible' : ''} overlay`}>
            <div className={`popup-primary small question ${state.modal ? 'show' : ''}`}>

                <div className="body">
                    <h3>
                        {state.msg || props.mainMessage}
                    </h3>
                    <p>
                        {props.secondaryMessage}
                    </p>
                </div>
                <div className="footer">
                    {props.showCancel &&
                        <Button
                            onClick={handleClose}
                            className="cancel"
                        >
                            {props.cancelBtnText || 'Отказ'}
                        </Button>
                    }
                    <Button
                        variant="contained"
                        color="primary"
                        className={props.agreeBtnClass || 'save'}
                        onClick={handleAccept}
                    >
                        {props.agreeBtnText || 'ОК'}
                    </Button>

                </div>
            </div>
        </div>
    )

    return (
        <Modal centered show={state.show} onHide={handleClose}>
            <Modal.Body>
                {/* <div style={{ textAlign: 'center', marginTop: '10px', marginBottom: '20px' }}> */}
                {/* <ExclamationCircle fontWeight={400} size={80} style={{ color: '#444' }} /> */}
                {/* <img src={pic} alt="" style={{ width: '90px' }} /> */}
                {/* </div> */}

                <p style={{ fontSize: '1.25rem', textAlign: 'center', fontWeight: '300' }}>
                    {state.msg || props.mainMessage}
                </p>

                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                    {props.showCancel &&
                        <Button variant="outline-secondary" onClick={handleClose} style={style.button}>
                            {props.cancelBtnText || 'Отказ'}
                        </Button>
                    }
                    <Button variant="outline-dark" onClick={handleAccept} style={style.button}>
                        {props.agreeBtnText || 'Добре'}
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default forwardRef(Info);