import { useState, useImperativeHandle, forwardRef } from 'react'

//miscs
import RedditTextField from 'components/misc/RedditTextField'
import SaveButton from 'components/misc/Button'

//images
import closeIcon from 'assets/img/modals/icons/close.png'

//helpers
import { useValidation } from 'helpers/Validation'

//providers
import { useAppContext } from 'providers/App'
import Api from 'helpers/Api'

const initialState = {
    loading: false,
    data: {
        password: '',
        new_password: '',
        repeat_new_passwrod: ''
    }
}
function Success(props, ref) {
    const app = useAppContext()

    const [state, setState] = useState({
        overlay: false,
        modal: false,
        params: null,
        onSuccess: null,
        onClose: null,
        ...initialState,
    })
    const [validations, setValidations] = useValidation()

    useImperativeHandle(ref, () => ({
        open: () => {
            setState(prev => ({
                ...prev,
                overlay: true,
            }))

            setTimeout(() => {
                setState(prev => ({
                    ...prev,
                    modal: true,
                }))
            }, 50)
        },
        close: () => {
            handleClose()
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }))
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }))
        }
    }))

    const handleAccept = () => {
        handleClose()

        if (state.onSuccess && typeof state.onSuccess === 'function') {
            return state.onSuccess()
        }
    }

    const handleClose = () => {
        setState(prev => ({
            ...prev,
            modal: false,
        }))

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                overlay: false,
                ...initialState,
            }))
        }, 100)

        if (typeof state.onClose === 'function') state.onClose()
    }

    const handleChange = e => {
        const { name, value } = e.target

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: value
            }
        }))
    }

    const handleSubmit = e => {
        e.preventDefault()
        setLoading(true)
        const data = new FormData(e.target)

        Api.post('auth/change-password', data)
            .then(() => {
                app.handleSuccess('Паролата беше сменена успешно!')
                handleClose()
            })
            .catch(error => {
                const _err = error.response
                console.log(_err.data.errors)
                if (_err && _err.status && _err.status === 422) setValidations(_err.data.errors)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const setLoading = loading => {
        setState(prev => ({
            ...prev,
            loading
        }))
    }

    return (
        <div className={`${state.overlay ? 'visible' : ''} overlay`}>
            <form className={`popup-primary small change-password ${state.modal ? 'show' : ''}`} onSubmit={handleSubmit}>
                <div className="body">
                    <img className="close" alt="close" src={closeIcon} onClick={handleClose} />
                    <h3>
                        Смяна на паролата
                    </h3>
                    <p>
                        Попълнете старата парола и напишете новата парола за достъп
                    </p>
                    <div className="row">
                        <RedditTextField
                            size="small"
                            variant="filled"
                            margin="dense"
                            label="Парола"
                            name="old_password"
                            type="password"
                            value={state.data?.old_password || ''}
                            onChange={handleChange}
                            error={Boolean(validations && validations.old_password)}
                            helperText={validations && validations.old_password && (validations.old_password[0] || validations.old_password)}
                            required
                        />
                    </div>
                    <div className="row">
                        <RedditTextField
                            size="small"
                            variant="filled"
                            margin="dense"
                            label="Нова парола"
                            name="new_password"
                            type="password"
                            value={state.data?.new_password || ''}
                            onChange={handleChange}
                            error={Boolean(validations && validations.new_password)}
                            helperText={validations && validations.new_password && (validations.new_password[0] || validations.new_password)}
                            required
                        />
                    </div>
                    <div className="row">
                        <RedditTextField
                            size="small"
                            variant="filled"
                            margin="dense"
                            label="Повторете новата парола"
                            name="new_password_confirmation"
                            type="password"
                            value={state.data?.new_password_confirmation || ''}
                            onChange={handleChange}
                            error={Boolean(validations && validations.new_password_confirmation)}
                            helperText={validations && validations.new_password_confirmation && (validations.new_password_confirmation[0] || validations.new_password_confirmation)}
                            required
                        />
                    </div>
                </div>
                <div className="footer">
                    <SaveButton
                        type="submit"
                        fullWidth
                        variant="contained"
                        // className={classes.submit}
                        loading={state.loading}
                        loadingColor="inherit"
                    >
                        Смяна на паролата
                    </SaveButton>
                </div>
            </form>
        </div>
    )
}

export default forwardRef(Success)