import axios from "axios"
import Loader from "components/misc/Loader"
import NoDataFound from "components/misc/NoDataFound"
import Pagination from "components/misc/Pagination"
import RedditTextField from "components/misc/RedditTextField"
import TableHeader from "components/misc/TableHeader"
import TableOptions from "components/misc/TableOptions"
import DynamicTableBodyCol from "components/partials/table/DynamicTableBodyCol"
import DynamicTableCol from "components/partials/table/DynamicTableCol"
import Api from "helpers/Api"
import moment from "moment"
import { useEffect, useRef, useState } from "react"
import { Form, InputGroup, Table } from "react-bootstrap"

let timeout

function Rows(props) {

    const { show, hide } = props

    const tableRef = useRef(null)

    const [state, setState] = useState({
        sorting: false,
        loading: true,
        refresh: false,
        data: {},
        totals: {},
        headings: {},
        types: {},
        pages: 0,
        total: 0,
        filter: {
            page: 1,
        },
        columns: {
            all: {},
            details: {},
            selected: [],
            sortable: [],
            sort: null,
            order: null,
        },
        tableKey: '',
        request: null,
    })

    useEffect(() => {
        loadData()
    }, [state.refresh])

    const getUrl = () => {
        return 'store/storemoves/rows?move_id=' + props.id
    }

    const loadData = () => {

        if (!props.id) {
            return
        }

        if (state.request) {
            state.request.cancel()
        }

        let request = axios.CancelToken.source()

        setState(prev => ({
            ...prev,
            request: request,
            loading: true,
        }))

        let url = getUrl()

        Api.get(url, {
            params: state.filter,
            cancelToken: request.token
        }).then(res => {
            setState(prev => ({
                ...prev,
                currency: res.data.currency,
                data: res.data.items,
                total: res.data.total,
                pages: res.data.pages,
                totals: res.data.totals,
                headings: res.data.headings,
                types: res.data.types,
                columns: res.data.columns,
                tableKey: res.data.tableKey,
                loading: false,
                sorting: false,
                filter: {
                    ...prev.filter,
                    ...res.data.filter,
                }
            }))

            if (res.data.pages > 0 && state.filter.page > res.data.pages) {
                return handlePage({ selected: res.data.pages - 1 })
            }
        })
    }

    const refresh = (reset = true) => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: reset ? 1 : prev.filter.page
            },
            refresh: moment().unix(),
        }))
    }

    const refreshTable = () => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                sort: '',
                order: '',
                page: 1
            },
            refresh: moment().unix(),
        }))
    }

    // Search
    const handleSearch = (key, val, delay = 300) => {
        clearTimeout(timeout)

        if (typeof key === 'object') {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    ...key
                },
            }))
        } else {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    [key]: val,
                },
            }))
        }

        timeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    page: 1
                },
                refresh: moment().unix()
            }))
        }, delay)
    }

    const handlePage = page => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: page.selected + 1,
            },
            refresh: moment().unix()
        }))
    }

    const handleSort = (sort, order) => {
        setState(prev => ({
            ...prev,
            sorting: true,
            filter: {
                ...prev.filter,
                sort: sort,
                order: order,
                page: 1,
            },
            refresh: moment().unix()
        }))
    }

    return (
        <div className="mt-3">
            <div className="filter row">
                <RedditTextField
                    size="small"
                    variant="filled"
                    margin="dense"
                    type="text"
                    label="Търсене..."
                    name="search"
                    value={state.filter.search || ''}
                    onChange={e => handleSearch(e.target.name, e.target.value)}
                />
            </div>
            {state.loading && !state.sorting
                ?
                <Loader />
                :
                state.data.length === 0 ?
                    <NoDataFound />
                    :
                    <>
                        <table className="type-doc" ref={tableRef}>
                            <TableHeader
                                tableRef={tableRef}
                                activeSortKey={state.filter.sort}
                                activeSortDir={state.filter.order}
                                onSort={(col, dir) => handleSort(col, dir)}
                            >
                                {Object.entries(state.headings).map((heading, i) =>
                                    <DynamicTableCol
                                        key={i}
                                        type={state.types[heading[0]]}
                                        name={heading[1]}
                                        title={state.columns.description[heading[0]]}
                                        sortKey={heading[0]}
                                        sortable={state.columns.sortable.indexOf(heading[0]) > -1}
                                    />
                                )}
                            </TableHeader>
                            <tbody>
                                {state.data.map((c, index) =>
                                    <tr
                                        key={'c-' + c.id + 'index-' + index}
                                        className={c.deleted ? 'deleted' : ''}
                                    >
                                        {Object.entries(state.headings).map((heading, i) =>

                                            <DynamicTableBodyCol
                                                key={heading[0]}
                                                type={state.types[heading[0]]}
                                                name={c[heading[0]]}
                                                data={c}
                                                currency={state.currency}
                                            />
                                        )}
                                    </tr>
                                )}
                                {state.totals.map((c, i) =>
                                    <tr key={i} className="additional-options">
                                        {Object.entries(c.items).map((heading, i) =>
                                            <DynamicTableBodyCol
                                                key={heading[0]}
                                                type={state.types[heading[0]]}
                                                name={heading[1]}
                                                data={c.items}
                                                currency={state.currency}
                                                colspan={c.colspan[heading[0]]}
                                            />
                                        )}
                                    </tr>
                                )}
                            </tbody>
                        </table>

                        <Pagination
                            className="mt-3"
                            page={state.filter.page}
                            pages={state.pages}
                            total={state.total}
                            handlePage={handlePage}
                        />
                    </>
            }
        </div>
    )
}

export default Rows