import Autocomplete from "components/misc/Autocomplete";
import DatePicker from "components/misc/DatePicker";
import RedditTextField from "components/misc/RedditTextField";
import moment from "moment";
import { useAuthDataContext } from "providers/Auth";
import { useRef } from "react";

function Index(props) {

    const auth = useAuthDataContext();

    const companyId = auth?.getUser()?.getCompany()?.getId();

    const setState = props.setState;

    const data = props.state.data;
    const validations = props.validations;

    const payeeBankInputRef = useRef(null);

    const handleInputChange = e => {
        const { name } = e.target
        const { value } = e.target

        setState(name, value, 'data');
    }

    const handleDateChange = (date, name = 'date') => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name + '_ymd']: moment(date).format('YYYY-MM-DD')
            }
        }));
    }

    // payee
    const handlePayeeChange = data => {
        if (data && typeof data === 'object') {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    payee: data,
                }
            }));
        } else {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    payee: null,
                }
            }));
        }

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                payeebank: null,
            }
        }));

        if (payeeBankInputRef.current) {
            payeeBankInputRef.current.resetData();
        }
    }

    const handlePayeeBankChange = data => {
        if (data && typeof data === 'object') {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    payeebank: data,
                }
            }));
        }
    }


    // payer
    const handlePayerChange = data => {
        if (data && typeof data === 'object') {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    payer: data,
                }
            }));
        } else {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    payer: null,
                }
            }));
        }
    }

    return (
        <div>
            <div className="row">
                <div className="col">
                    <RedditTextField
                        margin="dense"
                        size="small"
                        fullWidth
                        label="До банка"
                        name="bank_name"
                        value={data?.bank_name || ''}
                        onChange={handleInputChange}
                        error={Boolean(validations && validations.bank_name)}
                        helperText={validations && validations.bank_name && (validations.bank_name[0] || validations.bank_name)}
                    />
                </div>
                <div className="col">
                    <DatePicker
                        reddit
                        fullWidth
                        label="Дата на подаване"
                        name="date"
                        value={data?.date_ymd || ''}
                        onChange={e => handleDateChange(e.target.value)}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <RedditTextField
                        margin="dense"
                        size="small"
                        fullWidth
                        label="Клон"
                        name="bank_branch"
                        value={data?.bank_branch || ''}
                        onChange={handleInputChange}
                        error={Boolean(validations && validations.bank_branch)}
                        helperText={validations && validations.bank_branch && (validations.bank_branch[0] || validations.bank_branch)}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <RedditTextField
                        margin="dense"
                        size="small"
                        fullWidth
                        label="Адрес"
                        name="bank_address"
                        value={data?.bank_address || ''}
                        onChange={handleInputChange}
                        error={Boolean(validations && validations.bank_address)}
                        helperText={validations && validations.bank_address && (validations.bank_address[0] || validations.bank_address)}
                    />
                </div>
            </div>

            <div className="row">
                <Autocomplete
                    size="small"
                    inputPlaceholder="В полза на - име"
                    inputName="payee[name]"
                    inputIdName=""
                    url="documents/payorders/customers"
                    params={{
                        company_id: companyId
                    }}
                    requiredParams={['company_id']}
                    selected={data?.payee || null}
                    freeSolo={true}
                    getOptionLabel={option => option?.info?.name || option?.name || ''}
                    renderOption={option => option?.info?.name || option?.name || ''}
                    error={Boolean(validations && validations.payee?.name)}
                    helperText={validations && validations.payee && validations.payee.name && (validations.payee.name[0] || validations.payee.name)}
                    onChange={handlePayeeChange}
                    onInputChange={handlePayeeChange}
                />
                <input type="hidden" name="payee[id]" value={data?.payee?.id || ''} />
            </div>

            <div className="row">
                <Autocomplete
                    ref={payeeBankInputRef}
                    size="small"
                    inputPlaceholder="IBAN на получателя"
                    inputName="payeebank[iban]"
                    inputIdName="payeebank[id]"
                    url="documents/payorders/customer-banks"
                    params={{
                        customer_id: data?.payee?.id
                    }}
                    requiredParams={['customer_id']}
                    selected={data?.payeebank || null}
                    freeSolo={true}
                    getOptionLabel={option => option?.iban || ''}
                    renderOption={option => option?.iban || ''}
                    error={Boolean(validations && validations.payeebank?.iban)}
                    helperText={validations && validations.payeebank && validations.payeebank.iban && (validations.payeebank.iban[0] || validations.payeebank.iban)}
                    onChange={handlePayeeBankChange}
                    onInputChange={handlePayeeBankChange}
                />
            </div>

            <div className="row">
                <RedditTextField
                    margin="dense"
                    size="small"
                    fullWidth
                    label="Бри банка - банка, клон"
                    name="payeebank[name]"
                    value={data?.payeebank?.name || ''}
                    onChange={handleInputChange}
                    error={Boolean(validations && validations.payeebank?.name)}
                    helperText={validations && validations.payeebank && validations.payeebank.name && (validations.payeebank.name[0] || validations.payeebank.name)}
                />
            </div>

            <div className="row">
                <div className="col">
                    <Autocomplete
                        size="small"
                        inputPlaceholder="Вид валута"
                        inputName="currency"
                        inputIdName=""
                        url="currencies"
                        getResultData={data => data}
                        selected={{ id: null, name: data?.currency || '' }}
                        freeSolo={true}
                        error={Boolean(validations && validations.currency)}
                        helperText={validations && validations.currency && (validations.currency[0] || validations.currency)}
                    // onChange={handlePayeeChange}
                    // onInputChange={handlePayeeChange}
                    />
                </div>
                <div className="col">
                    <RedditTextField
                        margin="dense"
                        size="small"
                        fullWidth
                        label="Сума"
                        name="price"
                        value={data?.price || ''}
                        onChange={handleInputChange}
                        error={Boolean(validations && validations.price)}
                        helperText={validations && validations.price && (validations.price[0] || validations.price)}
                    />
                </div>
            </div>

            <div className="row">
                <Autocomplete
                    size="small"
                    inputPlaceholder="Вносител - име"
                    inputName="payer[name]"
                    inputIdName=""
                    url="documents/payorders/customers"
                    params={{
                        company_id: companyId
                    }}
                    requiredParams={['company_id']}
                    selected={data?.payer || null}
                    freeSolo={true}
                    getOptionLabel={option => option?.info?.name || option?.name || ''}
                    renderOption={option => option?.info?.name || option?.name || ''}
                    error={Boolean(validations && validations.payer?.name)}
                    helperText={validations && validations.payer && validations.payer.name && (validations.payer.name[0] || validations.payer.name)}
                    onChange={handlePayerChange}
                    onInputChange={handlePayerChange}
                />
                <input type="hidden" name="payer[id]" value={data?.payer?.id || ''} />
            </div>

            <div className="row">
                <RedditTextField
                    margin="dense"
                    size="small"
                    fullWidth
                    label="Основание за внасяне"
                    name="description"
                    value={data?.description || ''}
                    onChange={handleInputChange}
                    error={Boolean(validations && validations.description)}
                    helperText={validations && validations.description && (validations.description[0] || validations.description)}
                />
            </div>
        </div>
    )
}

export default Index;