import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

//helpers
import Api from 'helpers/Api'
import { useQuery } from 'helpers/Url'

//misc
import PageLoader from 'components/misc/PageLoader'

function Index() {
    const history = useHistory()
    const query = useQuery()

    useEffect(() => {
        const appId = query.get('app_id')

        if (!appId) console.warn('Missing app_id query param')

        Api.get('e-services/messenger/auth/url', {
            params: {
                app_id: appId
            }
        })
        .then(res => {
            window.location = res.data
        })
        .catch(() => {
            history.push('/integrations/fail')
        })
    }, [])

    return (
        <PageLoader show />
    )
}

export default Index