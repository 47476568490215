//component
import CampaignFormTemplateQuestion from "./CampaignFormTemplateQuestion"

//libraries
import { Button, Tooltip } from "@material-ui/core"

//stores
import { useCampaignFormTemplate } from "stores/customer/useCampaignFormTemplate"

//images
import removeIcon from "assets/img/app/icons/delete-white.png"

function CampaignFormTemplateGroup({ group }) {
  const { id, titleValue, descriptionValue, questions } = group

  const { addQuestion, removeGroup } = useCampaignFormTemplate()

  return (
    <div className="campaign-form-template-group">
      <div className="row">
        <input
          placeholder="Въведете заглавие..."
          className="heading heading-1"
          value={titleValue}
        />
      </div>
      <div className="row">
        <textarea
          placeholder="Въведете описание..."
          className="heading paragraph"
          rows="1"
          value={descriptionValue}
        ></textarea>
      </div>
      {questions.map(({ id, placeholder, type, answers }, i) => (
        <div className="row" key={id}>
          <CampaignFormTemplateQuestion
            question={{
              id,
              placeholder,
              type,
              answers,
              parentGroupId: group.id,
            }}
          />
        </div>
      ))}
      <hr />
      <Button className="add" onClick={() => addQuestion(id)}>
        Нова въпрос
      </Button>
      <Tooltip title="Премахване на група">
        <Button onClick={() => removeGroup(id)} className="remove-group">
          <img src={removeIcon} alt="" />
        </Button>
      </Tooltip>
    </div>
  )
}

export default CampaignFormTemplateGroup
