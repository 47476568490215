import { Box } from '@material-ui/core';
import React from 'react';
import successIcon from 'assets/img/success.png';
import { Link } from 'react-router-dom';
// import Link from '@material-ui/core/Link';

function Index() {
    return (
        <section>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                m="auto"
                height="75vh"
            >
                <div style={{ textAlign: 'center' }}>
                    <img src={successIcon} height={200} />
                    <h3 style={{ marginTop: '40px' }}>
                        Готово!
                    </h3>
                    <p style={{ marginTop: '20px', fontSize: '13px' }}>
                        Вашите акаунти са вече свързани и можете да затворите този прозорец.
                    </p>
                    <Link to="/settings/integrations" style={{ display: 'block', marginTop: '20px' }}>
                        Към интеграции
                    </Link>
                </div>
            </Box>
        </section>
    )
}

export default Index;