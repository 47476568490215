//MUI components
import { fade, makeStyles } from '@material-ui/core/styles'
import { TextField } from '@material-ui/core'
import { Tooltip } from '@material-ui/core'

export const useStylesReddit = makeStyles(theme => ({
    root: {
        border: '1px solid #e2e2e1',
        overflow: 'hidden',
        borderRadius: 4,
        backgroundColor: '#fff',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:hover': {
            backgroundColor: '#fff',
        },
        '&$focused': {
            backgroundColor: '#fff',
            boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
            borderColor: theme.palette.primary.main,
        },
    },
    focused: {},
}))

function RedditTextField(props) {
    const classes = useStylesReddit()
    const size = props.size || 'medium'
    const variant = props.variant && ['outlined', 'filled', 'standard'].indexOf(props.variant) > -1 ? props.variant : 'outlined'
    const margin = props.margin || 'dense'


    return <TextField
        size={size}
        margin={margin}
        InputProps={{
            classes,
            disableUnderline: true,
            endAdornment: (
                props.helperText &&
                <Tooltip title={props.helperText} placement="top" arrow>
                    <span className="error-info">

                    </span>
                </Tooltip>
            ),
            form: {
                autocomplete: "off",
            },
            disabled: Boolean(props.disabled),
            // autoComplete: "new-password",
            style: props.style || {},
            readOnly: props.readOnly || false,
        }}
        {...props}
        style={{}}
        helperText={null}
        variant={props?.variant || 'filled'}
        InputLabelProps={(props.type === 'datetime-local') || (props.type === 'date') ? {
            shrink: true,
        } : {}}
        autoComplete="off"
    />
}

export default RedditTextField