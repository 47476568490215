import { forwardRef, useImperativeHandle, useState } from "react"

//images
import closeIcon from "assets/img/app/icons/close.png"
import brandCRMImage from "assets/img/app/brand-crm.png"

//libraries
import moment from "moment"
import { useAuthDataContext } from "providers/Auth"
import { licenses } from "constants/licenses"

function ViewLicense(props, ref) {
  const [state, setState] = useState({
    show: false,
  })

  const auth = useAuthDataContext();

  const license = licenses.find(license => license.bulstat === auth.getUser().getCompany().getNumber());

  useImperativeHandle(ref, () => ({
    show: () => {
      show()
    },
  }))

  const show = () => {
    setState((prev) => ({
      ...prev,
      show: true,
    }))
  }

  const hide = () => {
    setState((prev) => ({
      ...prev,
      show: false,
    }))
  }


  return (
    <div className={`${state.show ? "visible" : ""} overlay`}>
      <div className={`popup-primary view-license ${state.show ? "show" : ""}`}>
        <img src={closeIcon} className="close" alt="" onClick={() => hide()} />
        <div className="body">
          <img src={brandCRMImage} alt="" />
          <h6>Версия 1.2.0</h6>
          <p>{`© ${moment().format(
            "YYYY"
          )} Docuela CRM. Всички права запазени.`}</p>
          <hr />
          <div className="row">
            <div className="col">
              <span className="label">Лицензирано за:</span>
              <b>{license?.company}</b>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <span className="label">ЛИЦЕНЗ №: </span>
              <b>{license?.no}</b>
            </div>
            <div className="col">
              <span className="label">Изтичане на лиценза: </span>
              <b>{license?.date}</b>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default forwardRef(ViewLicense)
