export const COMPLAINT_STATUSES = [
    {
        title: "За поправяне",
        value: "repair",
        color: "#FCCB90",
    },
    {
        title: "За изпращане",
        value: "send",
        color: "#FCCB90",
    },
    {
        title: "Изпратена",
        value: "sent",
        color: "#FF4E77",
    },
    {
        title: "Получена",
        value: "received",
        color: "#FF4E77",
    },
    {
        title: "Завършена",
        value: "completed",
        color: "#4DCCBD",
    },
    {
        title: "Отказана",
        value: "canceled",
        color: "#FF4E77",
    },
]