import { forwardRef, useImperativeHandle, useState } from 'react'

//MUI components
import { Button } from '@material-ui/core'

//misc
import RedditTextField from 'components/misc/RedditTextField'
import SaveButton from 'components/misc/Button'

//providers
import { usePublicPreviewContext } from './PublicPreviewProvider'

//components
import CompanySettlementInputField from './CompanySettlementInputField'

function AddOrEdit(props, ref) {
    const { handleSaveData } = props
    const publicPreview = usePublicPreviewContext()
    const [state, setState] = useState({
        overlay: false,
        modal: false,
        handleSaveData: null,
        fromNextBtn: false,
        data: {
            eik: publicPreview.getEik(),
            name: publicPreview.getCustomerName(),
            address: publicPreview.getCustomerAddress(),
            mol: publicPreview.getMol(),
            settlement: publicPreview.getSettlement(),
        }
    })

    useImperativeHandle(ref, () => ({
        edit: (data, fromNextBtn) => {
            edit(data, fromNextBtn)
        },
        hide: () => {
            hide()
        }
    }))

    const show = () => {
        setState(prev => ({
            ...prev,
            overlay: true,
            data: {
                eik: publicPreview.getEik(),
                name: publicPreview.getCustomerName(),
                address: publicPreview.getCustomerAddress(),
                mol: publicPreview.getMol(),
                settlement: publicPreview.getSettlement(),
            }
        }))

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                overlay: true,
                modal: true
            }))
        }, 50)
    }

    const hide = () => {
        setState(prev => ({
            ...prev,
            modal: false,
        }))

        setTimeout(() => {
            setState({
                overlay: false,
                modal: false,
                data: {},
                handleSaveData: null,
                fromNextBtn: false
            })
        }, 150)
    }

    const handleHide = e => {
        e.preventDefault()

        hide()
    }

    const edit = (data, fromNextBtn) => {
        setState(prev => ({
            ...prev,
            data,
            fromNextBtn
        }))

        show()
    }

    const handleSave = e => {
        e.preventDefault()

        publicPreview.setDocument(prev => ({
            ...prev,
            ...state.data
        }))
        hide()
        
        if (state.fromNextBtn) handleSaveData()
    }

    const handleChange = e => {
        const { name, value } = e.target

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: value
            }
        }))
    }

    const getAnyValidations = () => {
        let validations = []
        const { address, name, eik, mol, settlement } = state.data

        if (name?.length === 0) {
            validations.push('name')
        }
        if (eik?.length === 0) {
            validations.push('eik')
        }
        if (mol?.length === 0) {
            validations.push('mol')
        }
        if (address?.length === 0) {
            validations.push('address')
        }
        if (settlement?.length === 0) {
            validations.push('settlement')
        }

        return validations
    }

    const handleValidate = fieldName => {
        if (getAnyValidations().includes(fieldName)) {
            return true
        }

        return false
    }

    return (
        <div key={state.id} className={`${state.overlay ? 'visible' : ''} overlay`}>
            <form className={`popup-primary medium change-password ${state.modal ? 'show' : ''}`} noValidate onSubmit={handleSave}>
                <div className="body">
                    <h3>
                        Редакция на фирма
                    </h3>
                    <p>
                        Може да редактирате данните на своята фирма, които ще използвате в системата.
                    </p>
                    <div className="row">
                        <div className="col">
                            <RedditTextField
                                label="Име на фирма"
                                onChange={handleChange}
                                value={state.data.name}
                                name="name"
                                error={handleValidate('name')}
                                helperText={handleValidate('name') && "Моля, въведете име на фирма"}
                                required
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <RedditTextField
                                label="ЕИК"
                                onChange={handleChange}
                                value={state.data.eik}
                                name="eik"
                                error={handleValidate('eik')}
                                helperText={handleValidate('eik') && "Моля, въведете ЕИК"}
                                required
                            />
                        </div>
                        <div className="col">
                            <RedditTextField
                                label="МОЛ"
                                onChange={handleChange}
                                value={state.data.mol}
                                name="mol"
                                error={handleValidate('mol')}
                                helperText={handleValidate('mol') && "Моля, въведете МОЛ"}
                                required
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <CompanySettlementInputField />
                        </div>
                        <div className="col">
                            <RedditTextField
                                label="Адрес"
                                onChange={handleChange}
                                value={state.data.address}
                                name="address"
                                error={handleValidate('address')}
                                helperText={handleValidate('address') && "Моля, въведете адрес"}
                                required
                            />
                        </div>
                    </div>
                </div>
                <div className="footer">
                    <Button
                        className="cancel"
                        onClick={handleHide}
                    >
                        Отказ
                    </Button>
                    <SaveButton
                        loading={state.loading}
                        className="save"
                        disabled={getAnyValidations().length > 0}
                    />
                </div>
            </form>
        </div>
    )
}

export default forwardRef(AddOrEdit);