import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import loader from 'assets/img/loader.svg';
import closeIcon from 'assets/img/modals/icons/close.png';
import TextField from '@material-ui/core/TextField';
import { Box, Button } from '@material-ui/core';
import SaveButton from 'components/misc/Button';
import Api from 'helpers/Api';
import { useValidation } from 'helpers/Validation';
import Autocomplete from 'components/misc/Autocomplete';
import Grid from '@material-ui/core/Grid';
import { useNestedState } from 'helpers/NestedState';
import { useCurrencyContext } from 'providers/Currency';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useAuthDataContext } from 'providers/Auth';
import { useLanguageContext } from 'providers/Language';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import DeleteIcon from '@material-ui/icons/Delete';
import moment from 'moment';

function AddOrEdit(props, ref) {

    const langs = useLanguageContext();
    const auth = useAuthDataContext();
    const currencies = useCurrencyContext();

    const defaultData = {
        stages: []
    }

    const [state, setState] = useNestedState({
        overlay: false,
        modal: false,
        id: null,
        edit: false,
        data: defaultData,
        tab: langs?.data[0]?.id,
        onSuccess: null
    });

    const [validations, setValidations] = useValidation();

    const stagesModalRef = useRef(null);

    // useEffect(() => {
    //     console.log(validations);
    // }, [validations]);

    useImperativeHandle(ref, () => ({
        add: () => {
            show();
        },
        edit: (id) => {
            edit(id);
        },
        hide: () => {
            hide();
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        }
    }));

    useEffect(() => {
        loadData();
    }, [state.edit]);

    const show = () => {
        setState(prev => ({
            ...prev,
            overlay: true,
        }));

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                overlay: true,
                modal: true
            }));
        }, 50);

    }

    const hide = () => {
        setState(prev => ({
            ...prev,
            modal: false,
        }));

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                overlay: false,
                modal: false
            }));
        }, 150);

        setValidations(null);
    }

    const handleHide = e => {
        e.preventDefault();

        setState(prev => ({
            ...prev,
            id: null,
            data: defaultData
        }));

        hide();
    }

    const edit = id => {
        setState(prev => ({
            ...prev,
            id: id,
            edit: moment().unix()
        }));

        show();
    }

    const loadData = () => {
        if (!state.id) {
            return
        }

        Api.get('projects/show?id=' + state.id)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    data: res.data
                }))
            });
    }

    const handleSave = e => {
        e.preventDefault();

        setState(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url = state.id ? 'projects/edit' : 'projects/add';

        let data = new FormData(e.target);

        if (state.id) {
            data.append('id', state.id);
        }

        if (props.companyId) {
            data.append('company_id', props.companyId);
        }

        Api.post(url, data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        })
            .then(res => {

                if (typeof state.onSuccess === 'function') {
                    state.onSuccess(res.data);
                }

                hide();

            })
            .catch(error => {

                let _err = error.response;

                if (_err && _err.status && _err.status === 422) {
                    // console.log(_err.data.errors);
                    setValidations(_err.data.errors);
                }

            })
            .finally(() => {
                setState(prev => ({
                    ...prev,
                    loading: false,
                }));
            });
    }

    const handleInputChange = e => {
        let name = e.target.name;
        let value = e.target.value;

        setState(name, value, 'data');
    }

    const handleDateChange = e => {
        let name = e.target.name + '_ymd';
        let value = e.target.value;

        setState(name, value, 'data');
    }

    const handleTabChange = (e, value) => {
        setState(prev => ({
            ...prev,
            tab: value
        }));
    }

    const handleAddStageRow = () => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                stages: (prev.data.stages || []).concat({})
            }
        }));
    }

    const handleRemoveStageRow = index => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                stages: prev.data.stages.filter((row, i) => i !== index)
            }
        }));
    }

    return (
        <>
            <div key={state.id} className={`${state.overlay ? 'visible' : ''} overlay`}>
                <form className={`${state.modal ? 'show' : ''} popup-primary large`} noValidate onSubmit={handleSave}>
                    <h2 className="head">
                        Проект
                        <img className="close-icon" alt="close" src={closeIcon} onClick={handleHide} />
                    </h2>


                    <Box className="body">

                        <Tabs
                            value={state.tab}
                            onChange={handleTabChange}
                            indicatorColor="primary"
                            scrollButtons="auto"
                            textColor="primary"
                            variant="scrollable"
                            aria-label="tabs"
                        >
                            {langs.data.map(lang =>
                                <Tab key={'lt-' + lang.id} value={lang.id} label={lang.name} />
                            )}
                        </Tabs>

                        <Autocomplete
                            freeSolo={false}
                            inputIdName="type_id"
                            inputPlaceholder="Тип"
                            url="projects/types/all"
                            params={{
                                company_id: props.companyId
                            }}
                            requiredParams={['company_id']}
                            selected={state.data?.type || null}
                            getOptionLabel={option => option?.translation?.name}
                            renderOption={option => option?.translation?.name}
                        // onChange={handleCategoryChange}
                        />

                        {langs.data.map(lang =>
                            <div
                                key={'l-' + lang.id}
                                hidden={state.tab !== lang.id}
                            >
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    label="Наименование"
                                    name={`langs[${lang.id}][name]`}
                                    value={state.data?.langs && state.data?.langs[lang.id]?.name || ''}
                                    onChange={handleInputChange}
                                    error={Boolean(validations && validations.langs && validations.langs[lang.id]?.name)}
                                    helperText={validations && validations.langs && validations.langs[lang.id] && validations.langs[lang.id].name && (validations.langs[lang.id].name[0] || validations.langs[lang.id].name)}
                                />

                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    label="Описание"
                                    name={`langs[${lang.id}][description]`}
                                    value={state.data?.langs && state.data?.langs[lang.id]?.description || ''}
                                    onChange={handleInputChange}
                                    error={Boolean(validations && validations.langs && validations.langs[lang.id]?.description)}
                                    helperText={validations && validations.langs && validations.langs[lang.id] && validations.langs[lang.id]?.description && (validations.langs[lang.id].description[0] || validations.langs[lang.id].name)}
                                    multiline
                                    rows={4}
                                />
                            </div>
                        )}

                        <Grid spacing={2} container>
                            <Grid item xs={6}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    label="Начало"
                                    name="from_date"
                                    type="date"
                                    value={state.data?.from_date_ymd || ''}
                                    onChange={handleDateChange}
                                    error={Boolean(validations && validations.from_date)}
                                    helperText={validations && validations.from_date && (validations.from_date[0] || validations.from_date)}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    label="Край"
                                    name="to_date"
                                    type="date"
                                    value={state.data?.to_date_ymd || ''}
                                    onChange={handleDateChange}
                                    error={Boolean(validations && validations.to_date)}
                                    helperText={validations && validations.to_date && (validations.to_date[0] || validations.to_date)}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            label="Цена"
                            name="price"
                            type="number"
                            min="0"
                            step="0.01"
                            value={state.data?.price || ''}
                            onChange={handleInputChange}
                            error={Boolean(validations && validations.price)}
                            helperText={validations && validations.price && (validations.price[0] || validations.price)}
                        />


                        {Array.isArray(state.data.stages)
                            ?
                            state.data.stages.map((row, index) => (
                                <Grid key={'stage-' + index} spacing={2} container>

                                    <input type="hidden" name={`stages[${index}][id]`} value={row.id || null} />

                                    <Grid item xs={5}>
                                        {langs.data.map(lang =>
                                            <div
                                                key={'sl-' + lang.id}
                                                hidden={state.tab !== lang.id}
                                                style={{ width: '100%' }}
                                            >
                                                <TextField
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullWidth
                                                    label="Етап"
                                                    name={`stages[${index}][langs][${lang.id}][name]`}
                                                    value={row.langs ? row?.langs[lang.id]?.name : ''}
                                                    onChange={handleInputChange}
                                                    error={Boolean(validations && validations.stages && validations.stages[index] && validations.stages[index].langs && validations.stages[index].langs[lang.id] && validations.stages[index].langs[lang.id].name && (validations.stages[index].langs[lang.id].name[0] || validations.stages[index].langs[lang.id].name))}
                                                    helperText={validations && validations.stages && validations.stages[index] && validations.stages[index].langs && validations.stages[index].langs[lang.id] && validations.stages[index].langs[lang.id].name && (validations.stages[index].langs[lang.id].name[0] || validations.stages[index].langs[lang.id].name)}
                                                />
                                            </div>
                                        )}
                                    </Grid>

                                    <Grid item xs={3}>
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            label="Начало"
                                            name={`stages[${index}][from]`}
                                            type="date"
                                            value={row.from ? moment(row.from).utc().format('YYYY-MM-DD') : ''}
                                            onChange={handleInputChange}
                                            error={Boolean(validations && validations.stages && validations.stages[index] && validations.stages[index].from && (validations.stages[index].from[0] || validations.stages[index].from))}
                                            helperText={validations && validations.stages && validations.stages[index] && validations.stages[index].from && (validations.stages[index].from[0] || validations.stages[index].from)}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={3}>
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            label="Край"
                                            name={`stages[${index}][to]`}
                                            type="date"
                                            value={row.to ? moment(row.to).utc().format('YYYY-MM-DD') : ''}
                                            onChange={handleInputChange}
                                            error={Boolean(validations && validations.stages && validations.stages[index] && validations.stages[index].to && (validations.stages[index].to[0] || validations.stages[index].to))}
                                            helperText={validations && validations.stages && validations.stages[index] && validations.stages[index].to && (validations.stages[index].to[0] || validations.stages[index].to)}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={1} style={{ textAlign: 'center' }}>
                                        <DeleteIcon
                                            style={{ marginTop: '30px', cursor: 'pointer' }}
                                            onClick={e => handleRemoveStageRow(index)}
                                        />
                                    </Grid>
                                </Grid>
                            ))
                            :
                            ''
                        }

                        <br />

                        <Button
                            variant="outlined"
                            onClick={e => handleAddStageRow()}
                        >
                            Добави етап
                        </Button>

                        <br />
                    </Box>

                    <div className="footer">
                        <Button
                            className="cancel"
                            onClick={handleHide}
                        >
                            Отказ
                        </Button>
                        <SaveButton
                            loading={state.loading}
                            className="save"
                        />
                    </div>
                </form>
            </div>
        </>
    )
}

export default forwardRef(AddOrEdit);