//stores
import { usePreviewBillTabs } from "stores/accounts/usePreviewBillTabs"

const TABS = [
  {
    id: "preview",
    name: "Преглед",
  },
  {
    id: "invoice",
    name: "Фактура",
  },
  {
    id: "stock",
    name: "Сток. разписка",
  },
]

export default function PreviewBillTabs() {
  const { getActiveTabIndex, setActiveTabIndex } = usePreviewBillTabs()

  return (
    <div id="tabs-head" className="row space-between">
      <ul className="tabs">
        {TABS.map((tab, index) => (
          <li
            key={tab.id}
            className={
              TABS[getActiveTabIndex()] &&
              TABS[getActiveTabIndex()].id === tab.id
                ? "active"
                : ""
            }
            onClick={() => setActiveTabIndex(index)}
          >
            <ul className="subtabs">
              <li
                key={"tab-" + tab.id}
                className={
                  TABS[getActiveTabIndex()] &&
                  TABS[getActiveTabIndex()].id === tab.id
                    ? "active-subtab"
                    : ""
                }
              >
                <span>{tab.name}</span>
              </li>
            </ul>
          </li>
        ))}
      </ul>
    </div>
  )
}
