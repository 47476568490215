import { useState } from "react"

const usePasswordStrengthIndicatorHook = () => {
    const [state, setState] = useState({
        focusIn: false
    })
    /**
     * (?=.*[a-z])      - поне един малък символ от азбиката
     * (?=.*[A-Z])      - поне един голям символ от азбуката
     * (?=.*[0-9])      - поне една цифра
     * (?=.*[!@#$%^&*]) - поне един символ (с изключение на специалните символи в regex)
     * (?=.{8,})        - най-малко 8 символ
     */
    const mediumStrength = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})")
    const highStrength = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})")

    /**
     * 1 - слаба
     * 2 - средна
     * 3 - силна
     * @returns колко е силна паролата от 1 до 3
     */
    const testPassword = password => {
        if (highStrength.test(password)) {
            return 3
        }
        if (mediumStrength.test(password)) {
            return 2
        }

        return 1
    }

    /**
     * 
     * @returns съобщение с препоръка
     */
    const getRecommendationMessage = password => {
        if (testPassword(password) === 1) {
            return 'Препоръчваме паролата да съдържа поне 6 символа, включващи буква и цифра.'
        } else if (testPassword(password) === 2) {
            return 'Препоръчваме паролата да съдържа главна буква и символ.'
        }

        return 'Паролата отговаря на изискванията за сигурност на Docuela.'
    }

    const setFocusType = focusIn => {
        setState(prev => ({
            ...prev,
            focusIn
        }))
    }

    /**
     * 
     * @returns дали е фокусирано полето за парола
     */
    const getFocusType = () => state.focusIn

    return {
        getRecommendationMessage,
        testPassword,
        setFocusType,
        getFocusType
    }
}

export default usePasswordStrengthIndicatorHook