//images
import mailIcon from 'assets/img/app/icons/send-colored.png'

//MUI components
import { Button } from '@material-ui/core'

function MultipleActions(props) {
    const selectedCount = props.selectedCount

    return (
        <div
            className="multiple-actions"
        >
            Избрани елементи: {selectedCount}
            <ul>
                <li>
                    <Button
                        onClick={() => {
                            props.handleSendSelected()
                        }}
                    >
                        <img src={mailIcon} />
                        Изпращане
                    </Button>
                </li>
            </ul>
        </div>
    )
}

export default MultipleActions