import { useState, useEffect, useRef } from 'react'
import { Dropdown, Table } from 'react-bootstrap';
import Api from 'helpers/Api';
import Loader from 'components/misc/Loader';
import moment from 'moment';
import NoDataFound from 'components/misc/NoDataFound';
import Pagination from 'components/misc/Pagination';
import TableHeader from 'components/misc/TableHeader';
import TableCol from 'components/misc/TableCol';
import PriceValue from 'components/partials/PriceValue';
import MenuOptions from 'components/misc/MenuOptions';
import Alert from 'components/misc/Alert';

function Index(props) {

    const defaultData = [];

    const tableRef = useRef(null);

    const [state, setState] = useState({
        data: defaultData,
        pages: 0,
        total: 0,
        loading: false,
        filter: {
            page: 1,
        },
        refresh: false,
    });

    useEffect(() => {

        loading(true);

        Api.get('store/orders/payments', {
            params: {
                id: props.id,
                ...state.filter,
            }
        }).then(res => {
            setState(prev => ({
                ...prev,
                data: res.data.items,
                total: res.data.total,
                pages: res.data.pages,
                filter: {
                    ...prev.filter,
                    ...res.data.filter,
                },
            }));

            if (res.data.pages > 0 && state.filter.page > res.data.pages) {
                return handlePage({ selected: res.data.pages - 1 });
            }

            loading(false);
            sorting(false);
        });
    }, [props.id, state.refresh]);

    const loading = (loading) => {
        setState(prev => ({
            ...prev,
            loading: Boolean(loading)
        }));
    }

    const sorting = (sorting) => {
        setState(prev => ({
            ...prev,
            sorting: Boolean(sorting)
        }));
    }

    const handlePage = page => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: page.selected + 1,
            },
            refresh: moment().unix()
        }));
    }

    const handleSort = (sort, order) => {
        setState(prev => ({
            ...prev,
            sorting: true,
            filter: {
                ...prev.filter,
                sort: sort,
                order: order,
                page: 1,
            },
            refresh: moment().unix()
        }));
    }

    const handleShow = id => {
        props.showPayment(id);
    }

    return (

        state.loading && !state.sorting
            ?
            <Loader />
            :
            state.data.length === 0 ?
                // <NoDataFound />
                <Alert style={{ margin: 0 }}>Няма намерена информация</Alert>
                :
                <>

                    <Table className='type-outer' size="sm" striped ref={tableRef}>
                        <TableHeader
                            tableRef={tableRef}
                            activeSortKey={state.filter.sort}
                            activeSortDir={state.filter.order}
                            onSort={(col, dir) => handleSort(col, dir)}
                        >
                            <TableCol sortable sortKey="id" style={{ width: '5%' }}>ID</TableCol>
                            <TableCol sortable sortKey="date">Дата</TableCol>
                            <TableCol sortable sortKey="user">Потребител</TableCol>
                            <TableCol className="text-right" sortable sortKey="amount">Обща сума</TableCol>
                            <TableCol sortable sortKey="payment_method">Начин на плащане</TableCol>
                            <TableCol className="text-left" sortable sortKey="store">Търговски обект</TableCol>
                            <TableCol className="text-left" sortable sortKey="cashdesk">Каса</TableCol>
                            <TableCol className="text-left" sortable sortKey="description">Бележки</TableCol>
                            <TableCol className="options">Опции</TableCol>
                        </TableHeader>
                        <tbody>
                            {state.data.map((r, i) =>
                                <tr key={i}>
                                    <td>
                                        {r.id}
                                    </td>
                                    <td>
                                        {r.date ? moment(r.date).format('DD.MM.YYYY HH:mm') : ''}
                                    </td>
                                    <td>
                                        {r.user?.username}
                                    </td>
                                    <td className="text-right">
                                        <PriceValue prefix={r.currency?.prefix} sufix={r.currency?.sufix}>{r.amount}</PriceValue>
                                    </td>
                                    <td>
                                        {r.paymentmethod?.name}
                                    </td>
                                    <td className="text-left">
                                        {r.store?.name}
                                    </td>
                                    <td className="text-left">
                                        {r.cashdesk?.name}
                                    </td>
                                    <td className={`text-left`}>
                                        {r.description}
                                    </td>
                                    <td className="options">
                                        <MenuOptions>
                                            <Dropdown.Item onClick={e => handleShow(r.id)}>
                                                Преглед
                                            </Dropdown.Item>
                                        </MenuOptions>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>

                    <Pagination
                        className="mt-3"
                        page={state.filter.page}
                        pages={state.pages}
                        handlePage={handlePage}
                    />
                </>
    )
}

export default Index;