//hooks
import useCompanyDataHook from './useCompanyDataHook'
import useAuthDataHook from './useAuthDataHook'

//providers
import { useRegisterContext } from './RegisterProvider'

const TOTAL_SCREENS = 2
const useScreensHook = () => {
    const register = useRegisterContext()

    const {
        isCompanyDataFilled
    } = useCompanyDataHook()

    const {
        isAuthDataFilled
    } = useAuthDataHook()

    // const [register.screens, setState] = useState({
    //     currentScreen: 1
    // })

    const handleNextScreen = () => {
        if ((register.screens.currentScreen >= TOTAL_SCREENS) || !isCompanyDataFilled()) return

        setScreens(register.screens.currentScreen + 1)
    }

    const setScreens = currentScreen => {
        if(!isCompanyDataFilled()) return
        
        register.setScreensState(prev => ({
            ...prev,
            currentScreen
        }))
    }

    /**
     * 
     * @returns колко % е прогреса
     */
     const getProgressValue = () => {
        if (isCompanyDataFilled() ^ isAuthDataFilled()) {
            return 50
        } else if (isCompanyDataFilled() && isAuthDataFilled()) {
            return 100
        }

        return 0
    }

    return {
        setScreens,
        handleNextScreen,
        getProgressValue
    }
}

export default useScreensHook