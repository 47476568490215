import { useEffect, useState } from "react"

//providers
import { useRegisterContext } from "./RegisterProvider"
import { useAuthDataContext } from "providers/Auth"

//helpers
import Api from "helpers/Api"

const useAuthDataHook = () => {
    const register = useRegisterContext()
    const auth = useAuthDataContext()
    const [authDataState, setAuthDataState] = useState({
        viewPw: false
    })

    /**
     * 
     * @returns дали всички данни за вход са попълнени.
     */
    const isAuthDataFilled = () => {
        let filled = true

        Object.values(register.authData.data).map(d => {
            if (!d) {
                filled = false
            }
        })

        return filled
    }

    const handleSubmit = e => {
        e.preventDefault()
        setLoading(true)
        register.setValidation()

        const data = new FormData(e.target)
        Api.post('auth/register', data)
            .then(res => {
                if (res.status === 200 && res.data.user) {
                    auth.login(res.data)
                }
            })
            .catch(err => {
                const _err = err.response

                if (_err && _err.status && _err.status === 422) {
                    for (let [input, err] of Object.entries(_err.data.errors)) {
                        register.setValidation(prev => ({
                            ...prev,
                            [input]: err
                        }))
                    }
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const setLoading = loading => {
        register.setAuthDataState(prev => ({
            ...prev,
            loading
        }))
    }

    const handleChange = e => {
        let { name, value } = e.target

        if (name === 'terms') {
            value = !register.authData.data.terms
        }

        register.setAuthDataState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: value
            }
        }))
    }

    useEffect(() => {
        appendPressHold()
    }, [])

    const appendPressHold = () => {
        let item = document.querySelector(".view-pw")

        if (!item) return

        let timerID
        let counter = 0

        let pressHoldEvent = new CustomEvent("pressHold")

        let pressHoldDuration = 50

        item.addEventListener("mousedown", pressingDown, false)
        item.addEventListener("mouseup", notPressingDown, false)
        item.addEventListener("mouseleave", notPressingDown, false)

        item.addEventListener("touchstart", pressingDown, false)
        item.addEventListener("touchend", notPressingDown, false)

        item.addEventListener("pressHold", doSomething, false)

        function pressingDown(e) {
            requestAnimationFrame(timer)

            e.preventDefault()
            viewPw(true)
        }

        function notPressingDown(e) {
            cancelAnimationFrame(timerID)
            counter = 0
            viewPw(false)
        }


        function timer() {
            if (counter < pressHoldDuration) {
                timerID = requestAnimationFrame(timer);
                counter++;
            } else {
                console.log("Press threshold reached!");
                item.dispatchEvent(pressHoldEvent);
            }
        }

        function doSomething(e) {

        }
    }

    const viewPw = viewPw => {
        setAuthDataState(prev => ({
            ...prev,
            viewPw
        }))
    }

    return {
        isAuthDataFilled,
        handleChange,
        handleSubmit,
        viewPw,
        authDataState
    }
}

export default useAuthDataHook