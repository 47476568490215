import { useHistory } from 'react-router-dom'

//MUI componenets
import { Button } from '@material-ui/core'

//images
import nextIcon from 'assets/img/documents/icons/forward.png'
import backIcon from 'assets/img/app/icons/back-arrow.png'

//hooks
import useCompanyDataHook from '../useCompanyDataHook'
import useScreensHook from '../useScreensHook'

//providers
import { useRegisterContext } from '../RegisterProvider'

//miscs
import SaveButton from 'components/misc/Button'

const Footer = () => {
    const history = useHistory()
    const register = useRegisterContext()
    const { currentScreen } = register.screens

    const {
        handleNextScreen,
        getProgressValue,
        setScreens
    } = useScreensHook()

    const {
        isCompanyDataFilled
    } = useCompanyDataHook()

    return (
        <div className="footer">
            <Button
                className="back"
                onClick={() => {
                    if (currentScreen === 1) history.push('/')
                    else setScreens(1)
                }}
            >
                <img src={backIcon} />
                {(currentScreen === 1) ? 'Към вход' : 'Назад'}
            </Button>
            {currentScreen === 1 ?
                <Button
                    className={`save ${!isCompanyDataFilled() ? 'disabled' : ''}`}
                    onClick={handleNextScreen}
                >
                    Следваща стъпка
                    <img src={nextIcon} />
                </Button>
                :
                <SaveButton
                    className="save"
                    onClick={handleNextScreen}
                    style={{
                        padding: '10px 110px'
                    }}
                    fullWidth
                    loading={register.authData.loading}
                    disabled={register.authData.loading}
                    form="register-form"
                >
                    Започнете работа
                </SaveButton>
            }
            <div className="progress">
                <div
                    className="done"
                    style={{
                        width: `${getProgressValue()}%`
                    }}
                >
                    <span>
                        {getProgressValue()}%
                    </span>
                </div>
            </div>
        </div>
    )
}

export default Footer