import { forwardRef, useImperativeHandle, useMemo } from 'react'
//plugins
import { CKEditor } from '@ckeditor/ckeditor5-react'

//helpers
import { useValidation } from 'helpers/Validation'
import { useNestedState } from 'helpers/NestedState'
import Api from 'helpers/Api'

//MUI components
import { Button, Tooltip } from '@material-ui/core'

//images
import closeIcon from 'assets/img/modals/icons/close.png'

//misc
import SaveButton from 'components/misc/Button'

//providers
import { useAuthDataContext } from 'providers/Auth'

function AddOrEdit(props, ref) {
    const auth = useAuthDataContext()
    const getSuggestedTemplate = useMemo(() => {
        return `<p data-placeholder="Въведете подпис...">Поздрави,</p><p>${auth.getUser().data.fullname}!<br><br>Е-поща:&nbsp;<a href="mailto:${auth.getUser().getEmail()}">${auth.getUser().getEmail()}</a></p>`
    }, [auth.getUser().data])

    const [state, setState] = useNestedState({
        overlay: false,
        modal: false,
        data: {
            text: getSuggestedTemplate
        },
        type: null,
        onSuccess: null,
        signChanged: false
    })

    const [validations, setValidations] = useValidation()

    useImperativeHandle(ref, () => ({
        add: type => {
            show(type)
        },
        edit: data => {
            edit(data)
        },
        hide: () => {
            hide()
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }))
        }
    }))

    const show = type => {
        setState(prev => ({
            ...prev,
            overlay: true,
            type
        }))

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                modal: true
            }))
        }, 50)
    }

    const hide = () => {
        setState(prev => ({
            ...prev,
            modal: false,
        }))

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                overlay: false,
                data: {
                    text: getSuggestedTemplate
                },
            }))
        }, 150)

        setValidations(null)
    }

    const handleHide = e => {
        e.preventDefault()

        hide()
    }

    const edit = data => {
        setState(prev => ({
            ...prev,
            data,
        }))

        show()
    }

    const handleSave = e => {
        e.preventDefault()

        setLoading(true)
        setValidations(null)

        const url = state.data?.id ? 'documents/mail-signatures/edit' : `documents/mail-signatures/${state.type}`
        const data = new FormData(e.target)

        data.append('text', state.data.text)
        if (state.data?.id) data.append('id', state.data?.id)

        Api.post(url, data)
            .then(res => {
                if (typeof state.onSuccess === 'function') state.onSuccess(res.data)

                hide()
            })
            .catch(error => {
                const _err = error.response

                if (_err && _err.status && _err.status === 422) setValidations(_err.data.errors)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const setLoading = loading => {
        setState(prev => ({
            ...prev,
            loading
        }))
    }

    const handleInputChange = e => {
        const { name, value } = e.target

        setState(name, value, 'data')
    }


    const handleChangePicture = () => {
        document.querySelector('input[type="file"]').click()
    }

    const handleUploadPicture = e => {
        const reader = new FileReader()
        reader.addEventListener('load', ev => {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    image: ev.target.result
                }
            }))
        })
        reader.readAsDataURL(e.target.files[0])
    }

    const handleChange = editor => {
        if (!state.signChanged) {
            setState(prev => ({
                ...prev,
                signChanged: true
            }))
        }
        const value = editor.getData() || ''

        setState('text', value, 'data')
    }
    return (
        <div key={state.data?.id} className={`${state.overlay ? 'visible' : ''} overlay`}>
            <form className={`${state.modal ? 'show' : ''} popup-primary large sign`} noValidate onSubmit={handleSave}>
                <h2 className="head">
                    Подпис
                    <img className="close-icon" alt="close" src={closeIcon} onClick={handleHide} />
                </h2>
                <input
                    type="file"
                    hidden
                    name="picture"
                    onChange={handleUploadPicture}
                    accept="image/*"
                />
                <div className="body custom">
                    <div className={`row  ${Boolean(validations && validations.text) ? 'validate' : ''}`}>
                        <div className="col">
                            <CKEditor
                                editor={window.ClassicEditor}
                                data={state.data?.text}
                                config={{
                                    placeholder: 'Въведете подпис...',
                                }}
                                onChange={(_, editor) => {
                                    handleChange(editor)
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div className="footer">
                    <Button
                        className="cancel"
                        onClick={handleHide}
                    >
                        Отказ
                    </Button>
                    <SaveButton
                        loading={state.loading}
                        className="save"
                    />
                </div>
            </form >
        </div >
    )
}

export default forwardRef(AddOrEdit)