//images
import brand from 'assets/img/app/brand-full.png'
import nextIcon from 'assets/img/documents/icons/forward.png'
import alertIcon from 'assets/img/verify/icons/alert-with-decor.png'

//MUI components
import { Button } from '@material-ui/core'

//components
import Footer from '../partials/Footer'

function Index() {

    const handleNavigateToHome = () => {
        window.location.href = '/'
    }

    return (
        <section id="verify-fail">
            <div className="wrapper">
                <img src={brand} className="brand" />
                <img src={alertIcon} className="main-image" />
                <h1>
                    Нещо се обърка
                </h1>
                <p className="description">
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                </p>
                <Button
                    className="save"
                    onClick={handleNavigateToHome}
                >
                    Към начало
                    <img src={nextIcon} />
                </Button>
                <Footer />
            </div>
        </section>
    )
}

export default Index;