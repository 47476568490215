import { useRef } from "react"

//hooks
import useFetchStores from "../hooks/useFetchStores"
import useRemoveStore from "../hooks/useRemoveStore"

//libraries
import Skeleton from "react-loading-skeleton"
import { Button } from "@material-ui/core"

//components
import Pagination from "components/misc/Pagination"
import NoDataFound from "components/misc/NoDataFound"
import Question from "components/modals/Question"
import MenuOptions from "components/misc/MenuOptions"
import AddOrEditStore from "./AddOrEditStore"
import StoreOptions from "./StoreOptions"

function StoresList({ clientId }) {
  const {
    getStores,
    loadingStores,
    getCurrentPage,
    getTotalPages,
    getTotalItems,
    handlePage,
    refetchData,
  } = useFetchStores(clientId)
  const { removeStore } = useRemoveStore()

  const addOrEidtStoreRef = useRef(null)
  const deleteModalRef = useRef(null)

  function handleAddStore() {
    const modal = addOrEidtStoreRef.current

    modal.add()
    modal.onSuccess(refetchData)
  }

  function handleEdit(data) {
    const modal = addOrEidtStoreRef.current

    modal.edit(data.id)
    modal.onSuccess(refetchData)
  }

  function handleDelete(data) {
    const modal = deleteModalRef.current

    modal.open()
    modal.onSuccess(() => {
      removeStore(data.id, refetchData)
    })
  }

  if (loadingStores) {
    return (
      <div
        style={{
          width: "100%",
        }}
      >
        <Skeleton count={1} height={35} />
        <Skeleton count={1} height={35} />
        <Skeleton count={1} height={35} />
        <Skeleton count={1} height={35} />
        <Skeleton count={1} height={35} />
      </div>
    )
  }

  if (!loadingStores && !getStores().length) {
    return (
      <>
        <NoDataFound>
          <h2>Все още няма добавени адрси</h2>
          <p>Може да добавите първият си адрес, чрез бутона по-долу.</p>
          <Button className="add" onClick={handleAddStore}>
            Добавяне
          </Button>
        </NoDataFound>
        <AddOrEditStore ref={addOrEidtStoreRef} clientId={clientId} />
      </>
    )
  }

  return (
    <>
      <table className="type-doc">
        <thead>
          <tr>
            <th>№</th>
            <th>Име</th>
            <th>Населеном място</th>
            <th>Адрес</th>
            <th className="options text-center">Опции</th>
          </tr>
        </thead>
        <tbody>
          {getStores().map((store) => (
            <tr key={store.id}>
              <td>{store.id}</td>
              <td>{store.name || "-"}</td>
              <td>{store.settlement?.name || "-"}</td>
              <td>{store.address || "-"}</td>
              <td className="text-center">
                <MenuOptions>
                  <StoreOptions
                    data={store}
                    handleEdit={handleEdit}
                    handleDelete={handleDelete}
                  />
                </MenuOptions>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <hr />
      <Button className="add" onClick={handleAddStore}>
        Нов
      </Button>
      <Pagination
        page={getCurrentPage()}
        pages={getTotalPages()}
        total={getTotalItems()}
        handlePage={handlePage}
      />

      <AddOrEditStore ref={addOrEidtStoreRef} clientId={clientId} />
      <Question
        ref={deleteModalRef}
        mainMessage="Искате ли да изтриете този обект?"
        agreeBtnText="Изтриване"
        agreeBtnClass="remove"
      />
    </>
  )
}

export default StoresList
