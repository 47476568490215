//helpers
import { useQuery as uQ } from 'react-query'

//helpers
import Api from "helpers/Api"

export default function useFetchFromServer(queryKey, defaultData = null) {

    const { data, isFetching, refetch } = uQ([queryKey], _fetchData, {
        staleTime: 100,
    })

    async function _fetchData() {
        const res = await Api.get(`localstorage/get?key=${queryKey}`)

        return res.data
    }

    function getData() {
        return data || defaultData
    }

    return {
        getData,
        loadingFromServer: isFetching,
    }
}