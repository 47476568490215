//misc
import RedditTextField from "components/misc/RedditTextField"

function Filter(props) {
    const handleSearch = e => {
        const { name } = e.target
        const { value } = e.target

        props.handleSearch(name, value)
    }

    return (
        <div className="row filter">

            <div
                className="col"
                style={{
                    maxWidth: '300px'
                }}
            >
                <RedditTextField
                    size="small"
                    variant="filled"
                    margin="dense"
                    fullWidth
                    label="Търсене..."
                    name="search"
                    value={props.filter.search || ''}
                    onChange={handleSearch}
                />
            </div>
        </div>
    )
}

export default Filter;