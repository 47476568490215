import React, { useEffect, useState } from 'react';
import { useQuery } from 'helpers/Url';
import Layout from 'components/Layout';
import { useHistory } from 'react-router-dom';
import IncomesAndExpenses from './partials/IncomesAndExpenses';
import IncomesByCategory from './partials/IncomesByCategory';
import IncomesByCustomer from './partials/IncomesByCustomer';
import ExpensesByCategory from './partials/ExpensesByCategory';
import ExpensesByContragent from './partials/ExpensesByContragent';

import './style.scss'

function Index() {
    const history = useHistory();
    const query = useQuery();

    const tabs = [
        {
            id: 'incomes-expenses',
            name: 'Приходи и разходи',
            component: IncomesAndExpenses
        },
        {
            id: 'incomes-category',
            name: 'Приходи по категории',
            component: IncomesByCategory
        },
        {
            id: 'incomes-customer',
            name: 'Приходи по клиенти',
            component: IncomesByCustomer
        },
        {
            id: 'expenses-category',
            name: 'Разходи по категории',
            component: ExpensesByCategory
        },
        {
            id: 'expenses-customer',
            name: 'Разходи по контрагенти',
            component: ExpensesByContragent
        },
    ];

    const tab = query.get('tab') ? query.get('tab') : tabs[0].id;

    const [state, setState] = useState({
        tab: tabs[0].id,
        index: 0,
    });

    useEffect(() => {
        setState(prev => ({
            ...prev,
            loading: true,
            tab: tab,
            index: findTabIndex(tab)
        }));
    }, []);

    const handleTab = (e, id) => {

        let index = findTabIndex(id);

        setState(prev => ({
            ...prev,
            tab: id,
            index: index
        }));

        history.push('?tab=' + id);
    }

    const findTabIndex = id => {
        let index = tabs.findIndex(t => {
            return t.id === id;
        });

        return index;
    }

    return (
        <>
            <Layout>

                <div id="head">
                    <ul className="tabs">
                        {tabs.map(tab =>
                            <li
                                key={tab.id}
                                className={tabs[state.index] && tabs[state.index].id === tab.id ? 'active' : ''}
                                onClick={e => handleTab(e, tab.id)}
                            >
                                <ul className="subtabs">
                                    <li key={'tab-' + tab.id} className={tabs[state.index] && tabs[state.index].id === tab.id ? 'active-subtab' : ''}>
                                        <span>
                                            {tab.name}
                                        </span>
                                    </li>
                                </ul>
                            </li>
                        )}
                    </ul>
                </div>

                <section id="accounts">
                    <div className="panel">
                        {tabs[state.index] &&
                            React.createElement(tabs[state.index].component, {
                                // ref: componentRef
                            })
                        }
                    </div>
                </section>
            </Layout>
        </>
    )
}

export default Index;