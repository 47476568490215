import { useState, useImperativeHandle, forwardRef, useEffect, useRef } from 'react'
import { Button, MenuItem } from '@material-ui/core'
import { useValidation } from 'helpers/Validation'
import { INVOICE, TYPES as INVOICES_TYPES } from 'constants/invoices'
import { TYPES as DOCUMENTS_TYPES } from 'constants/documents'
import { useHistory } from 'react-router-dom'
import useInvoiceFromAccount from 'hooks/useInvoiceFromAccount'
import RedditSelect from 'components/misc/RedditSelect'
import SaveButton from 'components/misc/Button'

function NewDocument(props, ref) {

    const navigate = useHistory()

    const defaultData = {}

    const [state, setState] = useState({
        show: false,
        params: null,
        id: null,
        data: defaultData,
        loading: false,
        onSuccess: null,
        onClose: null,
        selectedDocTypeId: null
    })

    const [validations, setValidations] = useValidation()

    const { createInvoiceFromAccount } = useInvoiceFromAccount()

    const errorModalRef = useRef(null)

    useImperativeHandle(ref, () => ({
        open: (id) => {
            handleShow(id)
        },
        close: () => {
            handleClose()
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }))
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }))
        }
    }))

    const handleShow = (id) => {
        setState(prev => ({
            ...prev,
            show: true,
            id: id,
        }))
    }

    const handleClose = () => {
        setState(prev => ({
            ...prev,
            show: false,
            data: defaultData
        }))

        setValidations(null)

        if (typeof state.onClose === 'function') {
            state.onClose()
        }
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: value
            }
        }))
    }

    const handleCreateInvoice = () => {
        if (!state.selectedDocTypeId) return

        setState(prev => ({
            ...prev,
            loading: true
        }))
        createInvoiceFromAccount(state.id, state.selectedDocTypeId)
            .then(res => {
                if (res) {
                    navigate.push("/invoices", {
                        data: res,
                        mode: "new",
                    })
                }
            })
            .finally(() => {
                setState(prev => ({
                    ...prev,
                    loading: false
                }))
            })
    }

    const handleCreateDocument = (type) => {
        navigate(type.new + '?from_account_id=' + state.id)
    }

    const style = {
        width: '100%',
        margin: '2.5px 0',
        textAlign: 'left',
    }

    const label = {

    }

    function setSelectedDocTypeId(typeId) {
        setState(prev => ({
            ...prev,
            selectedDocTypeId: typeId
        }))
    }

    return (
        <>
            <div className={`${state.show ? "visible" : ""} overlay`}>
                <div
                    className={`popup-primary small ${state.show ? "show" : ""}`}
                >
                    {state.show ?
                        <div className="body">
                            <h3 style={{
                                marginBottom: '15px'
                            }}>
                                Изберете документ
                            </h3>
                            <RedditSelect

                                name="cashdesk_id"
                                value={state.selectedDocTypeId}
                                onChange={e => {
                                    setSelectedDocTypeId(e.target.value)
                                }}
                                label="Тип документ"
                            >
                                {INVOICES_TYPES.map(type =>
                                    <MenuItem key={type.id} value={type.id}>
                                        {type.name}
                                    </MenuItem>
                                )}
                            </RedditSelect>
                            {/* 
                            {INVOICES_TYPES.map(type =>
                                <Button key={type.id} className='add' onClick={e => handleCreateInvoice(type)}>
                                    {type.name}
                                </Button>
                            )} */}

                            {/* <label>
                                Други
                            </label>

                            {DOCUMENTS_TYPES.map(type =>
                                <Button key={type.id} className='add' onClick={e => handleCreateDocument(type)}>
                                    {type.name}
                                </Button>
                            )} */}
                        </div>
                        :
                        <>
                        </>
                    }
                    <div className="footer">
                        <SaveButton
                            loading={state.loading}
                            disabled={!state.selectedDocTypeId || state.loading}
                            className="save"
                            text="Създаване"
                            onClick={handleCreateInvoice}
                        />
                        <Button
                            className="cancel"
                            onClick={handleClose}
                        >
                            Отказ
                        </Button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default forwardRef(NewDocument)