import Middleware from './Middleware';

class Access extends Middleware {
    run(props, next) {
        const permission = props.permission;
        const module = props.module;

        let hasAccess = true;

        if (module) {
            if (this.user) {
                if (!this.user.module(module)) {
                    hasAccess = false;
                }
            } else {
                hasAccess = false;
            }
        }

        if (hasAccess) {
            if (permission) {
                if (this.user) {
                    if (!this.user.permission(permission)) {
                        hasAccess = false;
                    }
                } else {
                    hasAccess = false;
                }
            }
        }

        if (!hasAccess) {
            return this.redirect('/unauthorized');
        }

        return next(this.app);
    }
}

export default Access;