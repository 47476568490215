import { useState, useEffect, useRef } from 'react'
import Api from 'helpers/Api'
import Loader from 'components/misc/Loader'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import PriceValue from 'components/partials/PriceValue'
import Refs from 'Refs'

import { Button } from '@material-ui/core'
import useInvoiceFromClient from 'hooks/useInvoiceFromClient'

//images
import invoiceIcon from 'assets/img/accounts/icons/invoice.png'
import billIcon from 'assets/img/accounts/icons/bill.png'

//providers
import { useAuthDataContext } from 'providers/Auth'

function Index(props) {
    const { getUser } = useAuthDataContext()

    const navigate = useHistory()

    const defaultData = {
        subscription: {}
    }

    const [state, setState] = useState({
        data: defaultData,
        loading: false,
        onEntering: null,
        onExiting: null
    })

    useEffect(() => {
        if (props.id) {
            setState(prev => ({
                ...prev,
                loading: true
            }))

            Api.get('store/subscriptions/view', {
                params: {
                    id: props.id
                }
            }).then(res => {
                setState(prev => ({
                    ...prev,
                    data: res.data,
                    loading: false,
                }))
            })
        }
    }, [props.id])

    return (

        state.loading
            ?
            <Loader />
            :
            <>
                <div className="preview-article-home">

                    <div className="row">
                        <div className="col">
                            <h6>
                                Основни данни
                            </h6>
                            <div className="field">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td className="th">
                                                Клиент
                                            </td>
                                            <td>
                                                {state.data.subscription.customer?.name || '-'}
                                            </td>
                                            <td className="th">
                                                ЕИК/ЕГН
                                            </td>
                                            <td>
                                                {state.data.subscription.customer?.number || '-'}
                                            </td>

                                        </tr>
                                        <tr>
                                            <td className="th">
                                                Период
                                            </td>
                                            <td>
                                                {state.data.subscription.type?.period_text || '-'}
                                            </td>
                                            <td className="th">
                                                Период на плащане
                                            </td>
                                            <td>
                                                {state.data.subscription.type?.payment_period_text || '-'}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="th">
                                                Тип
                                            </td>
                                            <td>
                                                {state.data.subscription.type?.name || '-'}
                                            </td>
                                            <td className="th">
                                                Статус
                                            </td>
                                            <td>
                                                {state.data.subscription.is_active
                                                    ?
                                                    <span>АКТИВЕН</span>
                                                    :
                                                    <span>НЕАКТИВЕН</span>
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="th">
                                                Активиран на
                                            </td>
                                            <td>
                                                {state.data.subscription?.activated_at_dmy}
                                            </td>
                                            <td className="th">
                                                Валиден до
                                            </td>
                                            <td>
                                                {state.data.subscription?.expires_at_dmy}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>
            </>
    )
}

export default Index