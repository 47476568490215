import { plural, trans } from "providers/Translation";

export const convertDateToText = (date1, date2) => {
    const diff = (date1 - date2) / 1000;

    const days = Math.floor(diff / (60 * 60 * 24))
    const hours = Math.floor(diff / 3600);
    const minutes = Math.floor((diff / 60) % 60);
    const seconds = diff % 60;

    // console.log(hours, minutes, seconds)

    if (days > 0) {
        return plural('dates.published.days', days, {
            count: days
        });
    }

    if (hours > 0) {
        return plural('dates.published.hours', hours, {
            count: hours
        });
    }

    if (minutes > 0) {
        return plural('dates.published.minutes', minutes, {
            count: minutes
        });
    }

    if (seconds > 0) {
        return plural('dates.published.seconds', seconds, {
            count: seconds
        });
    }

    return trans('dates.published.now');
}