//components
import MenuOptions from "components/misc/MenuOptions"
import CalendarAddOptions from "./CalendarAddOptions"

//libraries
import moment from "moment"
import { Tooltip } from "@material-ui/core"

//stores
import { useAddToCalendarModal } from "stores/customer/useAddToCalendarModal"

//images
import addIcon from "assets/img/customers/icons/add-note.png"

//helpers
import {
  getSelectedEventType,
  roundCurrentHourToNearestHalfHour,
} from "helpers/Calendar"
import CalendarEditOptions from "./CalendarEditOptions"

//hooks
import useRemoveFromCalendar from "../hooks/useRemoveFromCalendar"

function WeekCalendarColumn({ tasks, date, maxTasksCount }) {
  const { showModal, setRefresh } = useAddToCalendarModal()
  const { removeFromCalendar } = useRemoveFromCalendar()

  // function setDummyDataToTasks() {
  //   const newTask = {
  //     id: new Date().getTime(),
  //     title: "Събиране на екипа",
  //     start: "2022-07-01 01:00:00",
  //     end: "2022-07-01 02:30:00",
  //     type: "instagram",
  //   }

  //   setCurrentDayTasks((prev) => [...prev, newTask])
  // }

  function openAddEventModal(eventType, startHour) {
    showModal(true, {
      eventType,
      startDate: moment(date).format("YYYY-MM-DD"),
      endDate: moment(date).format("YYYY-MM-DD"),
      startHour,
      endHour: moment(startHour, "HH:mm").add(30, "minutes").format("HH:mm"),
    })
  }

  function handleClone(task) {
    delete task.id

    showModal(true, task)
  }

  function handleEdit(task) {
    showModal(true, task)
  }

  function handleRemove(id) {
    removeFromCalendar(id, setRefresh)
  }

  return (
    <li
      style={{
        minHeight: `${maxTasksCount * 30}px`,
      }}
    >
      <MenuOptions
        hoverTooltipText={`${moment(date).format("DD.MM")} (${moment(
          date
        ).format("dddd")})`}
        containsNestedLists
      >
        <CalendarAddOptions
          handleAdd={(eventType) =>
            openAddEventModal(eventType, roundCurrentHourToNearestHalfHour())
          }
        />
      </MenuOptions>
      {/* Ховър с "Нова бележка" */}
      {/* <div className="add-new">
        <MenuOptions
          hoverTooltipText={`${moment(date).format("DD.MM")} (${moment(
            date
          ).format("dddd")})`}
          containsNestedLists
        >
          <CalendarAddOptions
            handleAdd={(eventType) =>
              openAddEventModal(
                eventType,
                roundCurrentHourToNearestHalfHour()
              )
            }
          />
        </MenuOptions>
        <img src={addIcon} alt="" />
        Нова бележка
      </div> */}
      <ul>
        {tasks.map((task) => (
          <Tooltip
            key={task.id}
            title={`${task.title} от ${task.startHour} ч.`}
            placement="top-end"
          >
            <li
              style={{
                background: getSelectedEventType(task.eventType)?.background,
              }}
            >
              <b>{task.startHour}</b> {task.title}
              <MenuOptions
                hoverTooltipText={`Опции на ${task.title}`}
                className="event-container"
                placement="top"
              >
                <CalendarEditOptions
                  handleDelete={() => handleRemove(task.id)}
                  handleEdit={() => handleEdit(task)}
                  handleClone={() => handleClone(task)}
                />
              </MenuOptions>
            </li>
          </Tooltip>
        ))}
      </ul>
    </li>
  )
}

export default WeekCalendarColumn
