import { useEffect, useState } from "react"

//helpers
import Api from "helpers/Api"

function useFetchUnits() {
    const [units, setUnits] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        _fetchUnits()
    }, [])

    function _fetchUnits() {
        Api.get('/documents/units/all')
            .then(res => {
                setUnits(res.data.items)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    async function addUnit(unitName, successCB) {
        const newUnit = await _saveUnit(unitName)
        successCB(newUnit)
    }

    async function _saveUnit(unitName) {
        return await Api.post('units/add', { name: unitName })
            .then(res => {
                _fetchUnits()
                return res.data
            })
    }

    function getUnits() {
        return units
    }

    return {
        getUnits,
        addUnit,
        isLoadingUnits: isLoading
    }
}

export default useFetchUnits