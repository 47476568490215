import { useEffect, useState } from "react"

//helpers
import Api from "helpers/Api"

//providers
import { useDashboardDataContext } from "../providers/dashboard"

function useFetchTransactions() {
    const dashboardData = useDashboardDataContext()

    const [isLoading, setIsLoading] = useState(true)
    const [transactions, setTransactions] = useState([])

    useEffect(() => {
        _fetchData()
    }, [dashboardData.getRefreshed('TRANSACTIONS')])

    function _fetchData() {
        if(!isLoading) setIsLoading(true)
        Api.get('dashboard/latest-incomes-expenses')
            .then(res => {
                const { items } = res.data
                setTransactions(items)

                dashboardData.setTransactionsCount(items.length)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    function getTransactions() {
        return transactions
    }

    return {
        getTransactions,
        isLoading
    }
}

export default useFetchTransactions