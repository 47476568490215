import { useEffect, useRef, useState } from 'react'
import Api from 'helpers/Api'
import { buildSearchParams, useQuery } from 'helpers/Url'
import { useLocation, Link, useHistory } from 'react-router-dom'
import { Alert, Table } from 'react-bootstrap'
import { Button } from '@material-ui/core'

import Options from './partials/Options'

import Question from 'components/modals/Question'
import Filter from './partials/Filter'
import AddOrEdit from './partials/AddOrEdit'

//misc
import MenuOptions from 'components/misc/MenuOptions'
import Pagination from 'components/misc/Pagination'
import Loader from 'components/misc/Loader'
import TableHeader from 'components/misc/TableHeader'
import TableCol from 'components/misc/TableCol'
import { useAuthDataContext } from 'providers/Auth'
import moment from 'moment'
import NoDataFound from 'components/misc/NoDataFound'
import DynamicTableBodyCol from 'components/partials/table/DynamicTableBodyCol'
import DynamicTableCol from 'components/partials/table/DynamicTableCol'
import TableOptions from 'components/misc/TableOptions'
import axios from 'axios'

let timeout

function Index() {

    const location = useLocation()
    const navigate = useHistory()
    const query = useQuery()
    const auth = useAuthDataContext()

    const tableRef = useRef(null)
    const addOrEditModalRef = useRef(null)
    const deleteModalRef = useRef(null)

    const [state, setState] = useState({
        sorting: false,
        loading: true,
        refresh: false,
        setFilter: false,
        data: {},
        totals: {},
        headings: {},
        types: {},
        pages: 0,
        total: 0,
        filter: {
            page: 1,
        },
        columns: {
            all: {},
            details: {},
            selected: [],
            sortable: [],
            sort: null,
            order: null,
        },
        tableKey: '',
        request: null,
    });

    useEffect(() => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                //Допълнителни филтри
                credit_from: query.get('credit_from') || '',
                credit_to: query.get('credit_to') || '',
                uses_from: query.get('uses_from') || '',
                uses_to: query.get('uses_to') || '',
                valid_from: query.get('valid_from') || '',
                valid_to: query.get('valid_to') || '',
                //Базови филтри
                search: query.get('search') || '',
                card_id: query.getAll('card_id[]') || [],
                client_id: query.get('client_id') || '',
                page: query.get('page') || 1,
                num: query.get('num') || '',
                sort: query.get('sort') || '',
                order: query.get('order') || '',
            },
            refresh: moment().unix()
        }))
    }, [location.search])

    useEffect(() => {
        if (state.setFilter) {
            navigate.push('?' + buildSearchParams(query, state.filter))
        }

    }, [state.setFilter])

    useEffect(() => {
        if (state.refresh) {
            loadData()
        }
    }, [state.refresh])

    const loadData = () => {

        if (state.request) {
            state.request.cancel();
        }

        let request = axios.CancelToken.source();

        setState(prev => ({
            ...prev,
            request: request,
            loading: true,
        }));

        let url = 'store/cards/get';

        Api.get(url, {
            params: state.filter,
            cancelToken: request.token
        }).then(res => {
            setState(prev => ({
                ...prev,
                data: res.data.items,
                total: res.data.total,
                pages: res.data.pages,
                totals: res.data.totals,
                headings: res.data.headings,
                types: res.data.types,
                columns: res.data.columns,
                tableKey: res.data.tableKey,
                loading: false,
                sorting: false,
                filter: {
                    ...prev.filter,
                    ...res.data.filter,
                }
            }));

            if (res.data.pages > 0 && state.filter.page > res.data.pages) {
                return handlePage({ selected: res.data.pages - 1 });
            }
        });
    }

    const loading = (loading) => {
        setState(prev => ({
            ...prev,
            loading: Boolean(loading)
        }))
    }

    const refresh = (reset = true) => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: reset ? 1 : prev.filter.page
            },
            setFilter: moment().unix(),
            refresh: moment().unix(),
        }))
    }

    const refreshTable = () => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                sort: '',
                order: '',
                page: 1
            },
            setFilter: new Date().getTime(),
            refresh: new Date().getTime(),
        }));
    }

    const handleAdd = () => {
        let modal = addOrEditModalRef.current

        modal.add()

        modal.onSuccess(() => {
            refresh(true)
        })
    }

    // update
    const handleEdit = data => {
        if (data.deleted) {
            return;
        }

        let modal = addOrEditModalRef.current;

        modal.edit(data.card_id);

        modal.onSuccess(() => {
            refresh(false);
        });
    }

    // delete
    const handleDelete = data => {
        if (data.deleted) {
            return;
        }

        let modal = deleteModalRef.current;

        modal.open();
        modal.onSuccess(() => {
            handleDestroy(data.card_id);
        })
    }

    const handleDestroy = id => {
        Api.post('/store/cards/delete', {
            id: id
        }).then(res => {
            if (res.data.success) {
                refresh(false)
            }
        })
    }

    // Search
    const handleSearch = (key, val, delay = 600) => {
        clearTimeout(timeout);

        if (typeof key === 'object') {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    ...key
                },
            }));
        } else {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    [key]: val,
                },
            }));
        }

        timeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    page: 1
                },
                setFilter: new Date().getTime()
            }));
        }, delay);
    }

    const handlePage = page => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: page.selected + 1,
            },
            setFilter: moment().unix()
        }))
    }

    const handleRestore = data => {
        Api.post('cards/restore', {
            id: data.card_id
        }).then(res => {
            if (res.data.success) {
                refresh();
            }
        });
    }

    const sorting = (sorting) => {
        setState(prev => ({
            ...prev,
            sorting: Boolean(sorting)
        }))
    }

    const handleSort = (sort, order) => {
        setState(prev => ({
            ...prev,
            sorting: true,
            filter: {
                ...prev.filter,
                sort: sort,
                order: order,
                page: 1,
            },
            setFilter: moment().unix()
        }))
    }

    return (
        <>

            <AddOrEdit
                ref={addOrEditModalRef}
            />

            <Question
                ref={deleteModalRef}
                mainMessage="Сигурни ли сте?"
                agreeBtnText="Изтриване"
                agreeBtnClass="remove"
            />

            <section id="companies">
                <div id="head">
                    <div className="title">
                        Пакетни карти
                    </div>
                    <Button className="add" onClick={handleAdd} disabled={auth.getUser().permission('cards_instances_new') === false}>
                        Добави
                    </Button>
                </div>
                <hr />

                <div className="panel">
                    <Filter
                        filter={state.filter}
                        handleSearch={handleSearch}
                    />

                    {state.loading && !state.sorting
                        ?
                        <Loader />
                        :
                        state.data.length === 0 ?
                            <NoDataFound />
                            :
                            <>
                                {/* <div className="pagination-with-options mb-3">
                                    <Pagination
                                        page={state.filter.page}
                                        pages={state.pages}
                                        handlePage={handlePage}
                                    />

                                    <TableOptions
                                        url="store/cards/get"
                                        filter={state.filter}
                                        columns={state.columns}
                                        tableKey={state.tableKey}
                                        refresh={refreshTable}
                                    />
                                </div> */}

                                <Table className={Object.entries(state.headings).length > 14 ? 'type-outer xxl' : 'type-outer big'} responsive striped hover ref={tableRef}>
                                    <TableHeader
                                        tableRef={tableRef}
                                        activeSortKey={state.filter.sort}
                                        activeSortDir={state.filter.order}
                                        onSort={(col, dir) => handleSort(col, dir)}
                                    >
                                        {Object.entries(state.headings).map((heading, i) =>
                                            <DynamicTableCol
                                                key={i}
                                                type={state.types[heading[0]]}
                                                name={heading[1]}
                                                title={state.columns.description[heading[0]]}
                                                sortKey={heading[0]}
                                                sortable={state.columns.sortable.indexOf(heading[0]) > -1}
                                            />
                                        )}
                                        <TableCol className="options">Опции</TableCol>
                                    </TableHeader>
                                    <tbody>
                                        {state.data.map(c =>
                                            <tr
                                                key={'c-' + c.id}
                                                className={c.deleted ? 'deleted' : ''}
                                            >
                                                {Object.entries(state.headings).map((heading, i) =>
                                                    <DynamicTableBodyCol
                                                        key={heading[0]}
                                                        type={state.types[heading[0]]}
                                                        name={c[heading[0]]}
                                                        data={c}
                                                        currency={state.currency}
                                                    />
                                                )}
                                                <td className="options">
                                                    <MenuOptions>
                                                        <Options
                                                            data={c}
                                                            handleEdit={e => handleEdit(c)}
                                                            handleDelete={e => handleDelete(c)}
                                                            handleRestore={handleRestore}
                                                        />
                                                    </MenuOptions>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>

                                <Pagination
                                    className="mt-3"
                                    page={state.filter.page}
                                    pages={state.pages}
                                    total={state.total}
                                    handlePage={handlePage}
                                />
                            </>
                    }


                </div>
            </section>
        </>
    )
}

export default Index
