import { forwardRef, useEffect, useImperativeHandle } from 'react'

//helpers
import { useValidation } from 'helpers/Validation'
import { useNestedState } from 'helpers/NestedState'
import Api from 'helpers/Api'
import { useAuthDataContext } from 'providers/Auth'

//MUI components
import { Button } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

//images
import closeIcon from 'assets/img/modals/icons/close.png'

//mics
import RedditTextField from 'components/misc/RedditTextField'

//misc
import SaveButton from 'components/misc/Button'

//constants
import { PAYMENT_EXTRA_DATA } from 'constants/PaymentMethod'

function Epay(props, ref) {
    const { message } = props
    const auth = useAuthDataContext()

    const [state, setState] = useNestedState({
        overlay: false,
        modal: false,
        data: null,
        options: {},
        onSuccess: null,
        onError: null,
        editable: false
    })

    const [validations, setValidations] = useValidation()

    useImperativeHandle(ref, () => ({
        show: () => {
            show()
        },
        hide: () => {
            hide()
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }))
        },
        onError: fn => {
            setState(prev => ({
                ...prev,
                onError: fn
            }))
        }
    }))

    useEffect(() => {
        setState(prev => ({
            ...prev,
            data: auth.getUser().getCompany().getSettings()
        }))
    }, [auth])

    const show = () => {
        setState(prev => ({
            ...prev,
            overlay: true,
        }))

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                overlay: true,
                modal: true
            }))
        }, 50)
    }

    const hide = () => {
        setState(prev => ({
            ...prev,
            modal: false,
        }))

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                overlay: false,
                modal: false,
                editable: false,
                data: null,
            }))
        }, 150)

        setValidations(null)
    }

    /**
     * 
     * @param {object} e референция към елемента 
     */
    const handleHide = e => {
        e.preventDefault()

        hide()
    }

    /**
     * 
     * @param {object} e референция към формата 
     */
    const handleSave = e => {
        e.preventDefault()

        setLoading(true)
        setValidations(null)

        const url = 'settings/epay/set'
        const data = new FormData(e.target)

        Api.post(url, data)
            .then(res => {
                if (typeof state.onSuccess === 'function') state.onSuccess(res.data)

                hide()
            })
            .catch(error => {
                const _err = error.response

                if (_err && _err.status && _err.status === 422) setValidations(_err.data.errors)
                else {
                    if (typeof state.onError === 'function') state.onError(error)
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }

    /**
     * 
     * @param {boolean} loading дали да се визуализира зареждане 
     */
    const setLoading = loading => {
        setState(prev => ({
            ...prev,
            loading
        }))
    }

    /**
     * 
     * @param {object} e референция към елемента 
     */
    const handleInputChange = e => {
        const { name, value } = e.target
        setEditable(true)

        setState(name, value, '')
    }

    /**
     * 
     * @param {boolean} editable дали да се позволи запзаване 
     */
    const setEditable = editable => {
        setState(prev => ({
            ...prev,
            editable
        }))
    }

    return (
        <div key={state.id} className={`${state.overlay ? 'visible' : ''} overlay`}>
            <form className={`${state.modal ? 'show' : ''} popup-primary small`} noValidate onSubmit={handleSave}>
                <h2 className="head">
                    <img src={PAYMENT_EXTRA_DATA['epay'].icon} className="logo" />
                    Настройки за ePay плащания
                    <img className="close-icon" alt="close" src={closeIcon} onClick={handleHide} />
                </h2>

                <div className="body">
                    <div className="row">
                        <div className="col">
                            <RedditTextField
                                size="small"
                                variant="filled"
                                margin="dense"
                                fullWidth
                                label="Клиентски номер"
                                name="data[epay_client_id]"
                                value={state.data?.epay_client_id || ''}
                                onChange={handleInputChange}
                                error={Boolean(validations && validations.epay_client_id)}
                                helperText={validations && validations.epay_client_id && (validations.epay_client_id[0] || validations.epay_client_id)}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <RedditTextField
                                size="small"
                                variant="filled"
                                margin="dense"
                                fullWidth
                                label="API ключ"
                                name="data[epay_client_secret]"
                                value={state.data?.epay_client_secret || ''}
                                onChange={handleInputChange}
                                error={Boolean(validations && validations.epay_client_secret)}
                                helperText={validations && validations.epay_client_secret && (validations.epay_client_secret[0] || validations.epay_client_secret)}
                            />
                        </div>
                    </div>
                </div>

                <div className="footer">
                    <Button
                        className="cancel"
                        onClick={handleHide}
                    >
                        Отказ
                    </Button>
                    <SaveButton
                        loading={state.loading}
                        className={`save ${!state.editable ? 'disabled' : ''}`}
                    />
                </div>
            </form>
        </div>
    )
}

export default forwardRef(Epay)