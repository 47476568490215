import { forwardRef, useState, useEffect, useRef, useImperativeHandle } from 'react'
import { Col, Row } from 'react-bootstrap'
import { TrashFill } from 'react-bootstrap-icons'
import Api from 'helpers/Api'
import Loader from 'components/misc/Loader'
import SaveButton from 'components/misc/Button'
import Alert from 'components/misc/Alert'
import { useAppContext } from 'providers/App'
import { useValidation } from 'helpers/Validation'
import Autocomplete from 'components/misc/Dropdown'
import { renderArticleDetailValueText } from 'constants/autocomplete'
// import Autocomplete from "components/misc/Autocomplete"
import { Button } from "@material-ui/core"
import { Skeleton } from '@material-ui/lab'
import moment from 'moment'

let timeout

function Index({ handleStopLoadingSave, loadingSave, onSuccess, id }, ref) {

    const app = useAppContext()

    const formRef = useRef(null)

    const [state, setState] = useState({
        data: null,
        pages: 0,
        total: 0,
        totals: {},
        loading: true,
        filter: {
            page: 1,
        },
        refresh: false,
    })

    const [validations, setValidations] = useValidation()

    useEffect(() => {

        if (!state.data) {
            loading(true)
        }

        Api.get('store/articles/details', {
            params: {
                id,
                ...state.filter,
            }
        }).then(res => {

            let data = res.data

            if (data.details) {
                data.details = res.data.details.map(detail => {
                    return {
                        name: detail?.detail?.translation?.name || detail?.detail?.name,
                        value: detail?.value?.translation?.name || detail?.value?.name,
                        detail_id: detail.detail_id,
                        value_id: detail.value_id,
                    }
                })
            } else {
                data.details = []
            }

            setState(prev => ({
                ...prev,
                data: data,
                filter: {
                    ...prev.filter,
                    ...res.data.filter,
                },
            }))

            if (res.data.pages > 0 && state.filter.page > res.data.pages) {
                return handlePage({ selected: res.data.pages - 1 })
            }

            loading(false)
            sorting(false)
        })
    }, [id, state.refresh])

    useImperativeHandle(ref, () => ({
        save: () => {
            handleSave()
        }
    }))

    const loading = (loading) => {
        setState(prev => ({
            ...prev,
            loading: Boolean(loading)
        }))
    }

    const sorting = (sorting) => {
        setState(prev => ({
            ...prev,
            sorting: Boolean(sorting)
        }))
    }

    const handleSearch = (key, val, delay = 300) => {
        clearTimeout(timeout)

        if (typeof key === 'object') {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    ...key
                },
            }))
        } else {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    [key]: val,
                },
            }))
        }

        timeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    page: 1
                },
                refresh: moment().unix()
            }))
        }, delay)
    }

    const handlePage = page => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: page.selected + 1,
            },
            refresh: moment().unix()
        }))
    }

    const handleSort = (sort, order) => {
        setState(prev => ({
            ...prev,
            sorting: true,
            filter: {
                ...prev.filter,
                sort: sort,
                order: order,
                page: 1,
            },
            refresh: moment().unix()
        }))
    }

    const handleSave = () => {
        if (loadingSave) return

        setValidations(null)

        let data = new FormData(formRef.current)

        data.append('id', id)

        Api.post('store/articles/details', data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(res => {

            if (typeof onSuccess === 'function') {
                onSuccess(res.data)
            }

            app.showSuccess()
        }).catch(error => {
            const _err = error.response

            if (_err && _err.status && _err.status === 422) {
                setValidations(_err.data.errors)
            } else {
                app.showError()
            }

        }).finally(() => {
            handleStopLoadingSave()
        })
    }

    const addDetail = () => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                details: prev.data.details.concat({})
            }
        }))
    }

    const removeDetail = (index) => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                details: prev.data.details.filter((d, i) => Number(i) !== Number(index))
            }
        }))
    }

    const handleDetailChange = (data, index) => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                details: Object.values({
                    ...prev.data.details,
                    [index]: {
                        ...prev.data.details[index],
                        detail_id: data.id,
                        name: data.name,
                        value: '',
                        value_id: ''
                    }
                })
            }
        }))
    }

    return (
        <div className="right-form-holder">
            {state.loading
                ?
                <>
                    <Skeleton count={1} height={35} />
                    <Skeleton count={1} height={35} />
                    <Skeleton count={1} height={35} />
                    <Skeleton count={1} height={35} />
                    <Skeleton count={1} height={35} />
                </>
                :
                <>
                    {state.data.details.length === 0 ?
                        <Alert>
                            Няма добавени характеристики
                        </Alert>
                        :
                        <>
                        </>
                    }

                    <form ref={formRef}>
                        <table className="type-doc">
                            <thead>
                                <tr>
                                    <th>
                                        Име
                                    </th>
                                    <th>
                                        Стойност
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {state.data.details.map((detail, index) =>
                                    <tr key={'d-' + index}>
                                        <td>
                                            <Autocomplete
                                                url="store/autocomplete/article-details"
                                                // inputPlaceholder="Име"
                                                inputName={`details[${index}][name]`}
                                                inputIdName={`details[${index}][id]`}
                                                inputValue={state.data.details[index].name || ''}
                                                inputIdValue={state.data.details[index].detail_id || ''}
                                                error={Boolean(validations?.details && validations.details[index] && validations.details[index].name)}
                                                helperText={validations?.details && validations.details[index] && validations.details[index].name && (validations.details[index].name[0] || validations.details[index].name)}
                                                onChange={data => handleDetailChange(data, index)}
                                                renderText={data => data?.translation?.name || data.name}
                                                fullWidth

                                            />
                                        </td>
                                        <td>
                                            <Autocomplete
                                                url="store/autocomplete/article-details-values"
                                                params={{
                                                    detail_id: state.data.details[index].detail_id,
                                                }}
                                                requiredParams={['detail_id']}
                                                // inputPlaceholder="Стойност"
                                                inputName={`details[${index}][value]`}
                                                inputIdName={`details[${index}][value_id]`}
                                                // inputIdValue={`details[${index}][value_id]`}
                                                inputValue={state.data.details[index].value || ''}
                                                inputIdValue={state.data.details[index].value_id || ''}
                                                error={Boolean(validations?.details && validations.details[index] && validations.details[index].value)}
                                                helperText={validations?.details && validations.details[index] && validations.details[index].value && (validations.details[index].value[0] || validations.details[index].value)}
                                                // onChange={data => handleDetailChange(data,index)}
                                                renderInputText={data => data?.translation?.name || data.name}
                                                renderText={renderArticleDetailValueText}
                                                fullWidth
                                            />
                                        </td>
                                        <td
                                            style={{
                                                maxWidth: '40px',
                                            }}
                                        >
                                            <div>
                                                <Button className="remove small" onClick={e => removeDetail(index)}>
                                                </Button>
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>

                        {/* {state.data.details.map((detail, index) =>
                            <div className="row" key={'d-' + index}>
                                <div className="col">
                                    <Autocomplete
                                        url="store/autocomplete/article-details"
                                        inputPlaceholder="Име"
                                        inputName={`details[${index}][name]`}
                                        inputIdName={`details[${index}][id]`}
                                        inputValue={state.data.details[index].name || ''}
                                        inputIdValue={state.data.details[index].detail_id || ''}
                                        error={Boolean(validations?.details && validations.details[index] && validations.details[index].name)}
                                        helperText={validations?.details && validations.details[index] && validations.details[index].name && (validations.details[index].name[0] || validations.details[index].name)}
                                        onChange={data => handleDetailChange(data, index)}
                                        renderText={data => data?.translation?.name || data.name}
                                        fullWidth

                                    />
                                </div>
                                <div className="col">
                                    <Autocomplete
                                        url="store/autocomplete/article-details-values"
                                        params={{
                                            detail_id: state.data.details[index].detail_id,
                                        }}
                                        requiredParams={['detail_id']}
                                        inputPlaceholder="Стойност"
                                        inputName={`details[${index}][value]`}
                                        inputIdName={`details[${index}][value_id]`}
                                        // inputIdValue={`details[${index}][value_id]`}
                                        inputValue={state.data.details[index].value || ''}
                                        inputIdValue={state.data.details[index].value_id || ''}
                                        error={Boolean(validations?.details && validations.details[index] && validations.details[index].value)}
                                        helperText={validations?.details && validations.details[index] && validations.details[index].value && (validations.details[index].value[0] || validations.details[index].value)}
                                        // onChange={data => handleDetailChange(data,index)}
                                        renderInputText={data => data?.translation?.name || data.name}
                                        renderText={renderArticleDetailValueText}
                                        fullWidth
                                    />
                                </div>
                                <div className="col" style={{
                                    maxWidth: '40px',
                                    alignSelf: 'center',
                                }}>
                                    <Button className="remove small" onClick={e => removeDetail(index)}>
                                    </Button>
                                </div>
                            </div>
                        )} */}
                    </form>
                    <br />
                    <Button className="add" onClick={addDetail}>
                        Нова
                    </Button>
                </>
            }
        </div>
    )
}

export default forwardRef(Index)