import React, { useState, useImperativeHandle, forwardRef, useEffect, useRef } from 'react'
import Loader from 'components/misc/Loader';
import Home from './Home';
import History from './History';
import Notes from './Notes';
import RowsBySuppliers from './RowsBySuppliers';
import PackageCards from './PackageCards';
import AddPackageCard from './partials/AddPackageCard';
import Payments from './Payments';
import Accounts from './Accounts';
import NewAccount from './partials/NewAccount';
import Fairnet from './Fairnet.jsx';

// import SendMail from 'components/pages/orders/partials/SendMail';
// import SmartUcfStatus from 'components/pages/orders/partials/SmartUcfStatus';
// import PaymentRequestInfo from 'components/modals/payment_request/PaymentRequestInfo';
// import SendReceipt from '../partials/SendReceipt';

import Question from 'components/modals/Question';
import Info from 'components/modals/Info';

import Api from 'helpers/Api';

import { MODELS } from 'constants/models';
import Refs from 'Refs';
import { useAppContext } from 'providers/App';
import { useAuthDataContext } from 'providers/Auth';
import { Button } from '@material-ui/core';
import LeftSideMenu from 'components/misc/LeftSideMenu';
import moment from 'moment'

function View(props, ref) {

    const app = useAppContext();
    const auth = useAuthDataContext();

    const defaultData = {
        rows: []
    };

    const [state, setState] = useState({
        show: false,
        params: null,
        id: null,
        tabId: null,
        key: 1,
        data: defaultData,
        loading: false,
        onEntering: null,
        onExiting: null,
        onClose: null,
        activeTabIndex: 0
    });

    const [tabs, setTabs] = useState([
        {
            id: 'fairnet',
            name: 'Конфигуратор',
            component: Fairnet,
        },
        {
            id: 'home',
            name: 'Преглед',
            component: Home
        },
        {
            id: 'history',
            name: 'История',
            component: History
        },
        // {
        //     id: 'by_suppliers',
        //     name: 'По доставчици',
        //     component: RowsBySuppliers
        // },
        {
            id: 'accounts',
            name: 'Сметки',
            component: Accounts,
            count: state.data?.accounts_count,
        },
        {
            id: 'payments',
            name: 'Плащания',
            component: Payments,
            count: state.data?.payments_count + state.data?.clientpayments_count,
        },
        {
            id: 'cards',
            name: 'Пакетни карти',
            component: PackageCards,
            count: state.data?.packagecards_count,
        },
    ]);

    const sendMailModalRef = useRef(null);
    const sendReceiptModalRef = useRef(null);
    const addPackageCardModalRef = useRef(null);
    const questionModalRef = useRef(null);
    const infoModalRef = useRef(null);
    const smartUcfStatusModalRef = useRef(null);
    const paymentRequestInfoModalRef = useRef(null);
    const newAccountModalRef = useRef(null);
    const leftArrowRef = useRef(null);
    const rightArrowRef = useRef(null);

    useImperativeHandle(ref, () => ({
        open: (id, resetTab = true) => {
            handleShow(id, resetTab);
        },
        close: () => {
            hide();
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }));
        }
    }));

    useEffect(() => {
        // фернет
        if (auth.getUser().getCompany().getNumber() !== '115147345') {
            setTabs(prev => prev.filter(tab => tab.id !== 'fairnet'));
        }
    }, [])

    useEffect(() => {
        if (!state.id || !state.show) {
            return;
        }

        setState(prev => ({
            ...prev,
            loading: state.data.id ? false : true
        }));

        Api.get('store/orders/show', {
            params: {
                id: state.id
            }
        }).then(res => {
            setState(prev => ({
                ...prev,
                data: res.data,
                loading: false,
            }));
        });
    }, [state.id, state.show]);

    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }));

        onEntering?.();
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }));

        onExiting?.();
    }

    const handleShow = (id, resetTab = true) => {

        if (auth.getUser().permission('clientorders_view') === false) {
            app.showError('Нямате право да преглеждате клиентска заявка!');

            return;
        }

        setState(prev => ({
            ...prev,
            id: id,
            tabId: resetTab || !prev.tabId ? getDefaultActiveTab()?.id : prev.tabId,
        }));

        show();
    }

    const handleClose = () => {
        setState(prev => ({
            ...prev,
            show: false,
            tabId: tabs[0].id
        }));

        if (typeof state.onClose === 'function') {
            hide(state.onClose);
        } else {
            hide();
        }

        setState(prev => ({
            ...prev,
            onClose: null,
        }));
    }

    const setKey = id => {
        setState(prev => ({
            ...prev,
            tabId: id,
        }));
    }

    const refresh = () => {
        setState(prev => ({
            ...prev,
            key: moment().unix(),
        }));
    }

    const hasTabPermission = key => {
        let map = {
            notes: 'clientorders_notes',
        };

        let permission = map[key];

        if (permission && auth.getUser().permission(permission) === false) {
            return false;
        }

        return true;
    }

    const getDefaultActiveTab = () => {
        return tabs.find(tab => {
            return hasTabPermission(tab.id);
        });
    }

    const showArticle = id => {
        if (!id) {
            return;
        }

        let modal = Refs.getInstance().getRef('article');

        if (!modal) {
            return;
        }

        hide(() => {
            modal.open(id);
        });

        modal.onClose(() => {
            show();
        });
    }

    const showClient = id => {
        if (!id) {
            return;
        }

        let modal = Refs.getInstance().getRef('client');

        if (!modal) {
            return;
        }

        hide(() => {
            modal.open(id);
        });

        modal.onClose(() => {
            show();
        });
    }

    const sendMail = order => {
        if (!order) {
            return;
        }

        let modal = sendMailModalRef.current;

        if (!modal) {
            return;
        }

        hide(() => {
            modal.show(order.id, order.client_email);
        });

        modal.onClose(() => {
            show();
        });
    }

    const sendReceipt = order => {
        if (!order) {
            return;
        }

        let modal = sendReceiptModalRef.current;

        if (!modal) {
            return;
        }

        hide(() => {
            modal.show(order.id, order.client_email);
        });

        modal.onClose(() => {
            show();
        });
    }

    const addPackageCard = () => {

        let modal = addPackageCardModalRef.current;

        if (!modal) {
            return;
        }

        hide(() => {
            modal.add(state.id);
        });

        modal.onClose(() => {
            show();
        });
    }

    const deletePackageCard = (cardId) => {

        let modal = infoModalRef.current;

        if (!modal) {
            return;
        }

        hide(() => {
            modal.open('Това действие ще възстанови сумата и използванията на пакетната карта, но няма да прекалкулира цените на артикулите в клиентската заявка');
        });

        modal.onCancel(() => {
            show();
        });

        modal.onSuccess(() => {
            destroyPackageCard(cardId, () => {
                show();
            });
        });
    }

    const destroyPackageCard = (cardId, cb) => {
        Api.post('store/orders/package-cards/remove', {
            id: state.id,
            card_id: cardId
        }).then(res => {
            cb();
        });
    }

    const addAccount = () => {
        let modal = newAccountModalRef.current;

        hide(() => {
            modal.open(state.data.id);
        });

        modal.onClose(() => {
            show();
        });
    }

    const showAccount = (id) => {
        let modal = Refs.getInstance().getRef('account');

        if (!modal) {
            return;
        }

        hide(() => {
            modal.open(id);
        });

        modal.onClose(() => {
            show();
        });

    }

    const deleteAccount = (id) => {

        let modal = questionModalRef.current;

        if (!modal) {
            return;
        }

        hide(() => {
            modal.open('Сигурни ли сте, че искате да изтриете тази сметка?');
        });

        modal.onCancel(() => {
            show();
        });

        modal.onSuccess(() => {
            destroyAccount(id, () => {
                show();
            });
        });
    }

    const destroyAccount = (id, cb) => {
        Api.post('store/accounts/delete', {
            id: id
        }).then(res => {
            cb();
        });
    }

    const showPayment = (id) => {
        let modal = Refs.getInstance().getRef('payment');

        if (!modal) {
            return;
        }

        hide(() => {
            modal.open(id);
        });

        modal.onClose(() => {
            show();
        });

    }

    const showSmartUcfStatus = id => {
        let modal = smartUcfStatusModalRef.current;

        if (!modal) {
            return;
        }

        hide(() => {
            modal.show(state.id);
        });

        modal.onClose(() => {
            show();
        });
    }

    const showPaymentRequestInfo = id => {
        let modal = paymentRequestInfoModalRef.current;

        if (!modal) {
            return;
        }

        hide(() => {
            modal.show(id);
        });

        modal.onClose(() => {
            show();
        });
    }

    const newReturn = () => {
        let modal = Refs.getInstance().getRef('newReturn');

        if (!modal) {
            return;
        }

        hide(() => {
            modal.open(state.id);
        });

        modal.onClose(() => {
            show();
        });
    }

    const showReturn = (id) => {
        let modal = Refs.getInstance().getRef('return');

        if (!modal) {
            return;
        }

        hide(() => {
            modal.open(id);
        });

        modal.onClose(() => {
            show();
        });

    }

    const deleteReturn = (id) => {
        let modal = questionModalRef.current;

        if (!modal) {
            return;
        }

        hide(() => {
            modal.open('Сигурни ли сте, че искате да изтриете това връщане?');
        });

        modal.onCancel(() => {
            show();
        });

        modal.onSuccess(() => {
            destroyReturn(id, () => {
                show();
            });
        });
    }

    const destroyReturn = (id, cb) => {
        Api.post('store/returns/delete', {
            id: id
        }).then(res => {
            cb();
        });
    }

    useEffect(() => {
        if (!state.show) {
            return;
        }

        const onKeyPress = (e) => {
            if (e.key === 'ArrowRight') {
                rightArrowRef.current?.click();
            }

            if (e.key === 'ArrowLeft') {
                leftArrowRef.current?.click();
            }
        }

        window.addEventListener('keyup', onKeyPress);

        return () => {
            window.removeEventListener('keyup', onKeyPress);
        }
    }, [state.show]);

    const handleShowNextItem = e => {
        e.preventDefault();

        if (props.showNextItem) {
            props.showNextItem(state.id);
        }
    }

    const handleShowPrevItem = e => {
        e.preventDefault();

        if (props.showPrevItem) {
            props.showPrevItem(state.id);
        }
    }

    const handleRefresh = e => {
        e.preventDefault();

        refresh();
    }

    const handleTabChange = tabIndex => {
        setState(prev => ({
            ...prev,
            activeTabIndex: Number(tabIndex)
        }))
    }

    return (
        <>
            {/* <SendMail
                ref={sendMailModalRef}
            /> */}

            {/* <SendReceipt
                ref={sendReceiptModalRef}
            /> */}

            <AddPackageCard
                ref={addPackageCardModalRef}
            />

            {/* <SmartUcfStatus
                ref={smartUcfStatusModalRef}
            /> */}

            {/* <PaymentRequestInfo
                ref={paymentRequestInfoModalRef}
            /> */}

            <NewAccount
                ref={newAccountModalRef}
            />

            <Question
                ref={questionModalRef}
            />

            <Info
                ref={infoModalRef}
                showCancel
            />

            <div className={`${state.show ? "visible" : ""} overlay`}>
                <div
                    className={`popup-primary extra-large complex-modal bill-modal ${state.show ? "show" : ""}`}
                >
                    <div className="header sky-blue">
                        <b>
                            Преглед на заявка
                        </b>
                        {/* <p>
                            Lorem Ipsum е елементарен примерен<br /> текст, използван в печатарската
                        </p> */}


                        {/* {props.showPrevItem &&
                            <span ref={leftArrowRef} className="arrow left" onClick={handleShowPrevItem}>
                                <ChevronLeft />
                            </span>
                        }
                        {props.showNextItem &&
                            <span ref={rightArrowRef} className="arrow right" onClick={handleShowNextItem}>
                                <ChevronRight />
                            </span>
                        } */}

                        <div className="buttons">
                            <button className="btn-reload" onClick={handleRefresh}>

                            </button>
                            <button className="btn-close" onClick={handleClose}>

                            </button>
                        </div>
                    </div>
                    {state.show &&
                        <div className="body">
                            <LeftSideMenu
                                tabsArr={tabs}
                                activeTabIndex={state.activeTabIndex}
                                setActiveTabIndex={handleTabChange}
                            />

                            <div className="right-form-holder">
                                {React.createElement(tabs[state.activeTabIndex].component, {
                                    id: state.id,
                                    state: state,
                                    setState: setState,
                                    show: show,
                                    hide: hide,
                                    showArticle: showArticle,
                                    showClient: showClient,
                                    sendMail: sendMail,
                                    sendReceipt: sendReceipt,
                                    addPackageCard: addPackageCard,
                                    deletePackageCard: deletePackageCard,
                                    addAccount: addAccount,
                                    showAccount: showAccount,
                                    deleteAccount: deleteAccount,
                                    showPayment: showPayment,
                                    showPaymentRequestInfo: showPaymentRequestInfo,
                                    showSmartUcfStatus: showSmartUcfStatus,
                                    newReturn: newReturn,
                                    showReturn: showReturn,
                                    deleteReturn: deleteReturn,
                                })}
                            </div>
                        </div>
                    }
                    <div className="footer">
                        <Button
                            variant="contained"
                            className="cancel"
                            onClick={handleClose}
                        >
                            Затвори
                        </Button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default forwardRef(View);