import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

//constatns
import { PAYORDER_TYPE } from 'constants/Document'

//MUI components
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { Button } from '@material-ui/core'

function AddButton() {
    const [anchorEl, setAnchorEl] = useState(null)

    const history = useHistory()

    const handleClick = e => {
        setAnchorEl(e.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleAddCashOrderIncome = () => {
        history.push('/cashorders/add?type_id=' + PAYORDER_TYPE.CASH_ORDER_INCOME)
    }

    const handleAddCashOrderDebit = () => {
        history.push('/cashorders/add?type_id=' + PAYORDER_TYPE.CASH_ORDER_DEBIT)
    }

    return (
        <>
            <Button
                onClick={handleClick}
                className="add"
            >
                Нов документ
            </Button>
            <Menu
                anchorEl={anchorEl}
                elevation={0}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                onClick={handleClose}
            >
                <MenuItem onClick={handleAddCashOrderIncome}>
                    Приходен касов ордер
                </MenuItem>
                <MenuItem onClick={handleAddCashOrderDebit}>
                    Разходен касов ордер
                </MenuItem>
            </Menu>
        </>
    )
}

export default AddButton