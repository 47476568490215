import { useState } from 'react'
import { Button, Tooltip } from '@material-ui/core'

const defaultMessage    = 'Копиране на връзката'
const copiedMessage     = 'Връзката беше копирана!'

const Copy = props => {
    const { string, buttonClassName = '', inputClassName = '' } = props
    const [state, setState] = useState({
        message   : defaultMessage,
    })

    const handleCopy = () => {
        navigator.clipboard.writeText(string)
        
        setMessage(copiedMessage)
        setTimeout(() => {
            setMessage(defaultMessage)
        }, 3000)
    }

    /**
     * 
     * @param {string} message визуализация на съобщение при посочване 
     */
    const setMessage = message => {
        setState(prev => ({
            ...prev,
            message
        }))
    }

    return (
        <div className="row copy">
            <input
                readOnly
                value={string}
                className={inputClassName}
            />
            <Tooltip
                title={state.message}
            >
                <Button
                    onClick={handleCopy}
                    className={buttonClassName}
                >
                </Button>
            </Tooltip>
        </div>
    )
}

export default Copy