import { forwardRef, useImperativeHandle, useRef } from 'react'

//MUI components
import { Button } from '@material-ui/core'

//providers
import { useAuthDataContext } from 'providers/Auth'
import { useAppContext } from 'providers/App'

//helpers
import Api from 'helpers/Api'
import { useValidation } from 'helpers/Validation'
import { useNestedState } from 'helpers/NestedState'

//images
import closeIcon from 'assets/img/app/icons/close.png'

//misc
import RedditTextField from 'components/misc/RedditTextField'
import SaveButton from 'components/misc/Button'

//components
import CompanyNumberInputField from 'components/pages/auth/register/partials/CompanyNumberInputField'
import CompanyVatInputField from "components/pages/auth/register/partials/CompanyVatInputField"
import CompanySettlementInputField from "components/pages/auth/register/partials/CompanySettlementInputField"

//hooks
import useCompanyDataHook from 'components/pages/auth/register/useCompanyDataHook'

//providers
import { useRegisterContext } from 'components/pages/auth/register/RegisterProvider'

//images
import loadingImage from 'assets/img/loader-2.svg'
import FindCompany from 'components/pages/sandbox/findcompany/components/FindCompany'
import useCustomInputFieldHook from 'components/pages/auth/register/useCompanyNumberInputFieldHook'

function AddOrEdit({ refresh }, ref) {
    const register = useRegisterContext()
    const { companyData } = register

    const {
        handleCompanyDataChange,
        setCompanyDataState,
        handleSearchChange,
        isEditMode,
        areFieldsShown,
        setEditMode,
    } = useCompanyDataHook()
    const {
        handleInputChange
    } = useCustomInputFieldHook()

    const auth = useAuthDataContext()
    const app = useAppContext()

    const [state, setState] = useNestedState({
        overlay: false,
        modal: false,
        data: null,
        onSuccess: null
    })

    const [validations, setValidations] = useValidation()

    useImperativeHandle(ref, () => ({
        add: () => {
            show()
        },
        hide: () => {
            hide()
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }))
        }
    }))

    const show = () => {
        setState(prev => ({
            ...prev,
            overlay: true,
        }))

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                overlay: true,
                modal: true
            }))
        }, 50)
    }

    const hide = () => {
        setState(prev => ({
            ...prev,
            modal: false,
        }))

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                data: null,
                overlay: false,
                modal: false
            }))
            register.resetCompanyData()
        }, 150)

        setValidations(null)
    }

    const handleHide = e => {
        e.preventDefault()

        hide()
    }

    const handleSave = e => {
        e.preventDefault()

        setLoading(true)

        setValidations(null)

        const url = 'companies/add'
        const data = new FormData(e.target)

        Api.post(url, data)
            .then(res => {
                app.handleSuccess('Фирмата беше добавена успешно!')
                auth.getUser().addCompany(res.data)
                register.resetCompanyData()

                hide()
                refresh()
            })
            .catch(error => {
                const _err = error.response

                if (_err && _err.status && _err.status === 422) setValidations(_err.data.errors)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const setLoading = loading => {
        setState(prev => ({
            ...prev,
            loading
        }))
    }

    return (
        <>
            <div key={state.id} className={`${state.overlay ? 'visible' : ''} overlay`}>
                <form className={`${state.modal ? 'show' : ''} popup-primary add-edit-company small`} noValidate onSubmit={handleSave}>
                    <div className="head">
                        <h2>
                            Добавяне на фирма
                        </h2>
                        <p>
                            Lorem Ipsum е елементарен примерен текст, използван в печатарската
                        </p>
                        <img className="close-icon" alt="close" src={closeIcon} onClick={handleHide} />
                    </div>

                    <div className={`body ${areFieldsShown() ? 'filled' : ''}`}>
                        <h3>
                            Въведете ЕИК/Булстат<br /> на Вашата фирма
                        </h3>
                        <p>
                            След като въведете ЕИК / Булстат, ще попълним всички данни на фирмата автоматично за Ваше улеснение, ако тя съществува в търговския регистър
                        </p>
                        {/* <CompanyNumberInputField
                            companyId={1}
                            label="ЕИК / Булстат"
                            name="customerinfo[name]"
                            value={register.search || null}
                            type="number"
                            selectCustomer={setCompanyDataState}
                            onInputChange={handleSearchChange}
                            autoFocus
                            className="main-input"
                            noCompany={true}
                        /> */}
                        <FindCompany
                            label="ЕИК / Булстат"
                            name="customerinfo[name]"
                            // initialValue={register.search || ''}
                            centeredLabel={true}
                            autoFocus={true}
                            setInputValueCB={value => {
                                handleInputChange({ target: { name: "eik", value } })
                            }}
                            setSelectedCompanyCB={setCompanyDataState}
                            inputIdName="customer_id"
                            companyInputTypeId={2}
                            disableDropdown={true}
                            disableFetchFromLocalDB={true}
                            autoSelectWhenOneResult={true}
                            companyEikOnly={true}
                        />
                        {areFieldsShown() ?
                            <>
                                {!isEditMode() ?
                                    <div className={`holder ${register.companyData.loading ? 'disabled' : ''}`}>
                                        <ul className="data-list">
                                            <li>
                                                <span>Име на фирма:</span> <i>{companyData.data?.name || ''}</i>
                                            </li>
                                            <li>
                                                <span>МОЛ:</span> <i>{companyData.data?.mol || ''}</i>
                                            </li>
                                            <li>
                                                <span>ИН по ЗДДС:</span> <i>{register.getVatNumberLoading() ? <img src={loadingImage} style={{ width: '15px', height: '15px' }} /> : companyData.data?.vatNumber || ''}</i>
                                            </li>
                                            <li>
                                                <span>Населено място:</span> <i>{companyData.data?.settlement || ''}</i>
                                            </li>
                                            <li>
                                                <span>Адрес:</span> <i>{companyData.data?.address || ''}</i>
                                            </li>
                                        </ul>
                                        <Button
                                            onClick={() => {
                                                setEditMode(true)
                                            }}
                                            className="edit-mode"
                                        >
                                            Редактиране на данните
                                        </Button>
                                        {companyData.data ?
                                            <>
                                                <input type="hidden" name="name" value={companyData.data.name} />
                                                <input type="hidden" name="eik" value={companyData.eik} />
                                                <input type="hidden" name="mol" value={companyData.data.mol} />
                                                <input type="hidden" name="vat" value={companyData.data.vatNumber} />
                                                <input type="hidden" name="address" value={companyData.data.address} />
                                                <input type="hidden" name={`settlement[name]`} value={companyData.data.settlement} />
                                                <input type="hidden" name={`settlement[id]`} value={companyData.data.settlementId} />
                                                <input type="hidden" name="settlement[municipality_id]" value={companyData.data?.municipalityId} />
                                            </>
                                            :
                                            <>
                                            </>
                                        }
                                    </div>
                                    :
                                    <div className={`holder ${register.companyData.loading ? 'disabled' : ''}`}>
                                        <div className="row">
                                            <div className="col">
                                                <RedditTextField
                                                    size="small"
                                                    margin="dense"
                                                    fullWidth
                                                    name="name"
                                                    value={companyData.data?.name || ''}
                                                    label="Име на фирма"
                                                    required
                                                    onChange={handleCompanyDataChange}
                                                    error={Boolean(validations?.name)}
                                                    helperText={validations?.name}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <RedditTextField
                                                    size="small"
                                                    margin="dense"
                                                    fullWidth
                                                    name="mol"
                                                    value={companyData.data?.mol || ''}
                                                    label="МОЛ"
                                                    required
                                                    onChange={handleCompanyDataChange}
                                                    error={Boolean(validations?.mol)}
                                                    helperText={validations?.mol}
                                                />
                                            </div>
                                            <div className="col">
                                                <CompanyVatInputField />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <CompanySettlementInputField validation={validations?.settlement} />
                                            </div>
                                            <div className="col">
                                                <RedditTextField
                                                    size="small"
                                                    margin="dense"
                                                    fullWidth
                                                    name="address"
                                                    value={companyData.data?.address || ''}
                                                    label="Адрес"
                                                    required
                                                    onChange={handleCompanyDataChange}
                                                    error={Boolean(validations?.address)}
                                                    helperText={validations?.address}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                }
                            </>
                            :
                            <>
                            </>
                        }

                    </div>

                    <div className="footer">
                        <Button
                            className="cancel"
                            onClick={handleHide}
                        >
                            Отказ
                        </Button>
                        <SaveButton
                            className="save"
                            loading={state.loading}
                            disabled={!areFieldsShown()}
                        />
                    </div>
                </form >
            </div >
        </>
    )
}

export default forwardRef(AddOrEdit)