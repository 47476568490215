import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from "react-router-dom"
import App from './App'
import * as serviceWorker from './serviceWorker'
import AppProvider from './providers/App'
import AuthDataProvider from './providers/Auth'
import LanguageProvider from './providers/Language'
import CurrencyProvider from './providers/Currency'
import TranslationProvider from 'providers/Translation'
import DocumentProvider from 'components/pages/documents/Provider'
import PayOrderProvider from 'components/pages/payorders/Provider'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import DashboardDataProvider from 'components/pages/dashboard/providers/dashboard'
import RegisterProvider from 'components/pages/auth/register/RegisterProvider'
import { QueryClient, QueryClientProvider } from 'react-query'
import DocumentsTabsProvider from 'providers/DocumentsTabs'

const history = require("history").createLocation

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#388ad0'
        }
    }
})

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            // retry: false
        }
    }
})

ReactDOM.render(
    <Router history={history}>
        <AuthDataProvider>
            <LanguageProvider>
                <CurrencyProvider>
                    <TranslationProvider>
                        <QueryClientProvider client={queryClient}>
                            <DocumentProvider>
                                <DocumentsTabsProvider>
                                    <DashboardDataProvider>
                                        <PayOrderProvider>
                                            <AppProvider>
                                                <RegisterProvider>
                                                    <MuiThemeProvider theme={theme}>
                                                        <App />
                                                    </MuiThemeProvider>
                                                </RegisterProvider>
                                            </AppProvider>
                                        </PayOrderProvider>
                                    </DashboardDataProvider>
                                </DocumentsTabsProvider>
                            </DocumentProvider>
                        </QueryClientProvider>
                    </TranslationProvider>
                </CurrencyProvider>
            </LanguageProvider>
        </AuthDataProvider>
    </Router>,
    document.getElementById('root')

)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
