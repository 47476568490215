//components
import Layout from "components/Layout"
import Pipelines from "components/pages/customers/view/Pipelines"

//libraries
import { Button } from "@material-ui/core"

//stores
import { useAddToPipelinesModal } from "stores/customer/useAddToPipelinesModal"

export default function MarketingDeals() {
  const { showModal } = useAddToPipelinesModal()
  function openAddToPipelineModal() {
    showModal(true)
  }

  return (
    <Layout>
      <div className="container-fluid">
        <div className="head-with-heading">
          <h3>Управление на сделките/продажбите</h3>
          <div className="buttons">
            <Button className="add" onClick={openAddToPipelineModal}>
              Нова сделка
            </Button>
          </div>
        </div>
        <div
          className="panel"
          style={{
            marginTop: 0,
          }}
        >
          <div className="calendar-holder">
            <Pipelines />
          </div>
        </div>
      </div>
    </Layout>
  )
}
