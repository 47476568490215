import MenuItem from "@material-ui/core/MenuItem"

//images
import editIcon from "assets/img/app/icons/edit.png"
import deleteIcon from "assets/img/app/icons/delete.png"

function StoreOptions({ data, handleEdit, handleDelete }) {
  return (
    <>
      <MenuItem onClick={(e) => handleEdit(data)}>
        <img src={editIcon} />
        Редакция
      </MenuItem>
      <MenuItem onClick={(e) => handleDelete(data)}>
        <img src={deleteIcon} />
        Изтриване
      </MenuItem>
    </>
  )
}

export default StoreOptions
