import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { Checkbox, ListItemText, MenuItem } from '@material-ui/core'
import RedditSelect from 'components/misc/RedditSelect'
import Api from 'helpers/Api'
import axios from 'axios'
import { cloneDeep } from 'lodash'

function Index(props, ref) {

    const [categories, setCategories] = useState({
        data: [],
        original: [],
        selected: [],
        request: null,
    })

    const [customers, setCustomers] = useState({
        data: [],
        selected: [],
        request: null,
    })

    // useImperativeHandle(ref, () => ({
    //     show: (e) => {
    //         handleShow(e)
    //     },
    //     close: () => {
    //         handleClose();
    //     },
    // }));

    useEffect(() => {
        if (Array.isArray(props.selectedCategories)) {
            setCategories(prev => ({
                ...prev,
                selected: props.selectedCategories
            }))
        }
    }, [props.selectedCategories])

    useEffect(() => {
        if (Array.isArray(props.selectedCustomers)) {
            setCustomers(prev => ({
                ...prev,
                selected: props.selectedCustomers
            }))
        }
    }, [props.selectedCustomers])

    useEffect(() => {
        loadCategories()
    }, [customers.selected, props.filter])

    useEffect(() => {
        loadCustomers()
    }, [categories.selected, props.filter])

    useEffect(() => {
        let path = props.path

        if (typeof path === 'undefined') {
            return
        }

        let fullPath = []

        let data = cloneDeep(categories.original)

        props.path.map(id => {
            let category = data.find(c => Number(c.id) === Number(id))

            if (category) {
                data = Object.values(category.children)

                fullPath.push(category)
            }
        })

        setCategories(prev => ({
            ...prev,
            data: data,
            fullPath: fullPath,
        }))
    }, [props.path, categories.original])

    const loadCategories = () => {
        if (categories.request) {
            categories.request.cancel()
        }

        let request = axios.CancelToken.source()

        setCategories(prev => ({
            ...prev,
            request: request
        }))

        Api.post('accounts/expenses-filter-categories', {
            ...props.filter,
            contragent_id: customers.selected,
        }, {
            cancelToken: request.token
        }).then(res => {
            setCategories(prev => ({
                ...prev,
                data: res.data.items,
                original: res.data.items,
            }))
        })
    }

    const findCategory = id => {
        return categories.data.find(c => Number(c.id) === Number(id))
    }

    const isSelectedCategory = id => {
        return categories.selected.indexOf(id) > -1
    }

    const handleChangeCategory = e => {
        let values = e.target.value

        // if (props.primaryFilter === 'category') {
        //     if (values.length === 0) {
        //         return;
        //     }
        // }

        setCategories(prev => ({
            ...prev,
            selected: values
        }))

        if (typeof props.onCategoryChange === 'function') {
            props.onCategoryChange(values)
        }
    }

    const loadCustomers = () => {

        if (customers.request) {
            customers.request.cancel()
        }

        let request = axios.CancelToken.source()

        setCustomers(prev => ({
            ...prev,
            request: request
        }))

        Api.post('accounts/expenses-filter-contragents', {
            ...props.filter,
            category_id: props.disableFilterCustomers ? [] : categories.selected,
        }, {
            cancelToken: request.token
        }).then(res => {
            setCustomers(prev => ({
                ...prev,
                data: res.data.items
            }))
        })
    }

    const findCustomer = id => {
        return customers.data.find(c => Number(c.id) === Number(id))
    }

    const isSelectedCustomer = id => {
        return customers.selected.indexOf(id) > -1
    }

    const handleChangeCustomer = e => {
        let values = e.target.value

        // if (props.primaryFilter === 'contragent') {
        //     if (values.length === 0) {
        //         return;
        //     }
        // }

        setCustomers(prev => ({
            ...prev,
            selected: values
        }))

        if (typeof props.onCustomerChange === 'function') {
            props.onCustomerChange(values)
        }
    }


    const getFilters = () => {

        let filters = [
            {
                name: 'category',
                jsx: <div className="col category" style={{ maxWidth: '300px' }}>
                    <RedditSelect
                        label="Категории"
                        value={categories.selected}
                        renderValue={(selected) => selected.map(id => findCategory(id)).filter(c => c).map(category => category.name).join(', ')}
                        onChange={handleChangeCategory}
                        multiple
                    >
                        {(categories.data || []).map(category =>
                            <MenuItem key={'c' + category.id} value={category.id}>
                                <Checkbox color="primary" checked={isSelectedCategory(category.id)} />
                                <ListItemText primary={category.name} />
                            </MenuItem>
                        )}
                    </RedditSelect>
                </div>
            },
            {
                name: 'contragent',
                jsx: <div className="col category" style={{ maxWidth: '300px' }}>
                    <RedditSelect
                        label="Контрагенти"
                        value={customers.selected}
                        renderValue={(selected) => selected.map(id => findCustomer(id)).filter(c => c).map(customer => customer.info.name).join(', ')}
                        onChange={handleChangeCustomer}
                        multiple
                    >
                        {(customers.data || []).map(customer =>
                            <MenuItem key={'c' + customer.id} value={customer.id}>
                                <Checkbox color="primary" checked={isSelectedCustomer(customer.id)} />
                                <ListItemText primary={customer.info.name} />
                            </MenuItem>
                        )}
                    </RedditSelect>
                </div>
            }
        ]

        if (props.primaryFilter) {
            let primaryFilterIndex = filters.findIndex(f => f.name === props.primaryFilter)

            if (primaryFilterIndex > -1) {
                let first = filters[0]

                filters[0] = filters[primaryFilterIndex]
                filters[primaryFilterIndex] = first
            }
        }

        return filters
    }

    return (
        <div className="row filter">
            {getFilters().map(filter => filter.jsx)}
        </div>
    )
}

export default forwardRef(Index)