import { useState } from "react"

//helpers
import Api from "helpers/Api"

export default function useSaveOnServer() {
    const [loading, setLoading] = useState(false)

    function saveOnServer(key, value, successCb = () => { }, errorCb = () => { }) {
        setLoading(true)

        Api.post('localstorage/set', {
            key,
            value: JSON.stringify(value)
        })
            .then(() => {
                successCb()
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return {
        saveOnServer,
        loadingSaveOnServer: loading
    }
}