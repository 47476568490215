import { useEffect, useState } from "react"

//constants
import { DOC_FILTER_TYPES, PAID_FILTER_TYPES, PERIOD_FILTER_TYPES } from "constants/Document"

//libraries
import moment from "moment"

//providers
import { useAppContext } from "providers/App"
import { useDocumentContext } from "../Provider"

function useFindFilters() {
    const app = useAppContext()

    const context = useDocumentContext()
    const { getSearchParam } = context

    const [paymentMethods, setPaytmentMethods] = useState([])

    useEffect(() => {
        fetchPaymentMethods()
    }, [])

    async function fetchPaymentMethods() {
        const paymentMethods = await app.getPaymentMethods()

        setPaytmentMethods(paymentMethods)
    }

    /**
     * 
     * @returns {string} стойността от полето за търсене от филтъра
     */
    const getSearch = () => {
        let search = getSearchParam('search')
        if (search.length > 10) {
            search = `${search.substring(0, 10)}...`
        }

        return `"${search}"`
    }

    /**
     * 
     * @returns {string} избраните типове документи от филтъра
     */
    const getDocTypes = typeId => {
        if (!typeId) return 'Неизвестен тип'

        const ids = Array.isArray(typeId) ? typeId : getSearchParam('type_id')?.split(',') || []

        let types = ''
        if (ids[0]?.length) {
            ids.map((id, i) => {
                types += `${i !== 0 ? ',' : ''} ${DOC_FILTER_TYPES[id]?.shortName}`
            })
        } else {
            types = DOC_FILTER_TYPES[typeId]?.shortName
        }
        return types
    }

    /**
     * 
     * @returns {string} периода от флтъра
     */
    const getPeriod = () => {
        const period = getSearchParam('period')
        if (PERIOD_FILTER_TYPES[period]) {
            return PERIOD_FILTER_TYPES[period]
        }
        const startDate = getSearchParam('start_date')
        const endDate = getSearchParam('end_date')

        return `${moment(startDate).format('D.MM.YY')}г. - ${moment(endDate).format('D.MM.YY')}г.`
    }

    /**
     * 
     * @returns {string} платените от филтъра
     */
    const getSent = () => {
        const sent = Number(getSearchParam('sent'))

        return sent === 0 ? 'Всички' : sent === 1 ? 'Да' : 'Не'
    }

    /**
     * 
     * @returns {string} статуса на плащане от филтъра
     */
    const getPaymentStatus = () => {
        const status = getSearchParam('status')
        
        return PAID_FILTER_TYPES[status]
    }   

    /**
     * 
     * @returns метода на плащане от филтъра
     */
    const getPaymentMethod = () => {
        const method = paymentMethods.find(m => Number(m.id) === Number(getSearchParam('payment_method_id')))

        return method?.translation?.name || ''
    }

    return {
        getSearch,
        getDocTypes,
        getPeriod,
        getSent,
        getPaymentStatus,
        getPaymentMethod
    }
}

export default useFindFilters