import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import loader from 'assets/img/loader.svg';
import closeIcon from 'assets/img/modals/icons/close.png';
import TextField from '@material-ui/core/TextField';
import { Box, Button } from '@material-ui/core';
import SaveButton from 'components/misc/Button';
import Api from 'helpers/Api';
import { useValidation } from 'helpers/Validation';
import Autocomplete from 'components/misc/Autocomplete';
import { buildNestedState } from 'helpers/State';
import Grid from '@material-ui/core/Grid';
import { useNestedState } from 'helpers/NestedState';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useLanguageContext } from 'providers/Language';
import moment from 'moment'

function AddOrEdit(props, ref) {

    const langs = useLanguageContext();

    const [state, setState] = useNestedState({
        overlay: false,
        modal: false,
        id: null,
        edit: false,
        data: null,
        tab: langs?.data[0]?.id,
        onSuccess: null
    });

    const [validations, setValidations] = useValidation();

    // useEffect(() => {
    //     console.log(validations);
    // }, [validations]);

    useImperativeHandle(ref, () => ({
        add: () => {
            show();
        },
        edit: (id) => {
            edit(id);
        },
        hide: () => {
            hide();
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        }
    }));

    useEffect(() => {
        loadData();
    }, [state.edit]);

    const show = () => {
        setState(prev => ({
            ...prev,
            overlay: true,
        }));

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                overlay: true,
                modal: true
            }));
        }, 50);

    }

    const hide = () => {
        setState(prev => ({
            ...prev,
            modal: false,
            data: null,
            id: null,
        }));

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                overlay: false,
                modal: false
            }));
        }, 150);

        setValidations(null);
    }

    const handleHide = e => {
        e.preventDefault();

        hide();
    }

    const edit = id => {
        setState(prev => ({
            ...prev,
            id: id,
            edit: moment().unix()
        }));

        show();
    }

    const loadData = () => {
        if (!state.id) {
            return
        }

        Api.get('projects/teams/show?id=' + state.id)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    data: res.data
                }))
            });
    }

    const handleSave = e => {
        e.preventDefault();

        setState(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url = state.id ? 'projects/teams/edit' : 'projects/teams/add';

        let data = new FormData(e.target);

        if (state.id) {
            data.append('id', state.id);
        }

        if (props.companyId) {
            data.append('company_id', props.companyId);
        }

        Api.post(url, data)
            .then(res => {

                if (typeof state.onSuccess === 'function') {
                    state.onSuccess(res.data);
                }

                hide();

            })
            .catch(error => {

                let _err = error.response;

                if (_err && _err.status && _err.status === 422) {
                    // console.log(_err.data.errors);
                    setValidations(_err.data.errors);
                }

            })
            .finally(() => {
                setState(prev => ({
                    ...prev,
                    loading: false,
                }));
            });
    }

    const handleInputChange = e => {
        let name = e.target.name;
        let value = e.target.value;

        setState(name, value, 'data');
    }

    const handleTabChange = (e, value) => {
        setState(prev => ({
            ...prev,
            tab: value
        }));
    }

    return (
        <div key={state.id} className={`${state.overlay ? 'visible' : ''} overlay`}>
            <form className={`${state.modal ? 'show' : ''} popup-primary small`} noValidate onSubmit={handleSave}>
                <h2 className="head">
                    Екип и отговорници
                    <img className="close-icon" alt="close" src={closeIcon} onClick={handleHide} />
                </h2>

                <Box className="body">

                    <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        label="Наименование"
                        name="name"
                        value={state.data?.name || ''}
                        onChange={handleInputChange}
                        error={Boolean(validations && validations.name && (validations.name[0] || validations.name))}
                        helperText={validations && validations.name && (validations.name[0] || validations.name)}
                    />

                    <Autocomplete
                        variant="outlined"
                        freeSolo={false}
                        multiple
                        inputPlaceholder="Отговорници"
                        inputIdName="members[]"
                        url="users/all"
                        params={{
                            resource: 1,
                            company_id: props.companyId
                        }}
                        // selectedId={params.company_id || null}
                        value={state.data?.members || []}
                        // getOptionLabel={option => `${option.name}`}
                        // renderOption={option => `${option.name}`}
                        // onChange={handleCompanyChange}
                        // onInputChange={handleCompanyChange}
                        error={Boolean(validations && validations.members)}
                        helperText={validations && validations.members && (validations.members[0] || validations.members)}
                    />
                </Box>

                <div className="footer">
                    <Button
                        className="cancel"
                        onClick={handleHide}
                    >
                        Отказ
                    </Button>
                    <SaveButton
                        loading={state.loading}
                        className="save"
                    />
                </div>
            </form>
        </div>
    )
}

export default forwardRef(AddOrEdit);