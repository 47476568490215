import { useState, useImperativeHandle, forwardRef, useRef, useEffect, useLayoutEffect } from 'react'
import { Modal, Button, Row, Col, FloatingLabel, Form, Tabs, Tab, ProgressBar, Table, Card } from 'react-bootstrap';
import { Check, Gear, Save, X } from 'react-bootstrap-icons';
import { createPortal } from 'react-dom';

import SaveButton from 'components/misc/Button'
import { useAuthDataContext } from 'providers/Auth';
import { useAppContext } from 'providers/App';
import { cloneDeep } from 'lodash';

import DragDropFile from 'components/misc/DragDropFile';
import Alert from 'components/misc/Alert';
import Api from 'helpers/Api';
import { useValidation } from 'helpers/Validation';
import Columns from './import/Columns';
import axios from 'axios';

import 'assets/scss/import.scss';

import Refs from 'Refs';
import Result from './import/Result';
import TableHeader from 'components/misc/TableHeader';
import Previous from './import/Previous';
import Details from './import/Details';
import Configs from './import/Configs';

import { OPERATION_TYPE_IDS, TYPES } from 'constants/imports';
import TextUpload from './import/TextUpload';

function Import(props, ref) {

    const auth = useAuthDataContext();
    const app = useAppContext();

    const TYPE = props.type;
    const OPERATION_TYPE_ID = OPERATION_TYPE_IDS[TYPE];

    const hasCreateAndUpdate = props.createText !== false && props.updateText !== false;

    const defaultData = {
        has_file: false,
        import_id: null,
        limit: null,
        rows: [],
        type: 'create',
        show_settings: false,
        csv_delimiter: ',',
        has_heading: true,
        config: {},
        import_result: null,
    }

    const [state, setState] = useState({
        show: false,
        params: null,
        data: defaultData,
        tabId: 'file',
        fileProgress: null,
        fileLoading: null,
        fileUploadRequest: null,
        table: {
            scrollLeft: 0,
        },
        onSuccess: null,
        onClose: null,
        onEntering: null,
        onExiting: null,
        onChunk: null,
    });

    // console.log(state.params)

    const [validations, setValidations] = useValidation();

    const tableRef = useRef(null);
    const columnsModalRef = useRef(null);
    const resultModalRef = useRef(null);
    const detailsModalRef = useRef(null);
    const configsModalRef = useRef(null);
    const textUploadModalRef = useRef(null);

    useImperativeHandle(ref, () => ({
        open: (options = null) => {
            handleShow(options);
        },
        close: () => {
            handleClose();
        },
        setParams: params => {
            setState(prev => ({
                ...prev,
                params: params
            }));
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }));
        },
        onCancel: fn => {
            setState(prev => ({
                ...prev,
                onCancel: fn
            }));
        },
        onChunk: fn => {
            setState(prev => ({
                ...prev,
                onChunk: fn
            }));
        }
    }));

    // console.log(state.params);

    // useEffect(() => {
    //     read(5)
    // }, [])

    // при промяна на разделител да се обнови и таблицата
    useEffect(() => {
        if (!state.data.import_id) {
            return;
        }

        read(state.data.import_id);
    }, [state.data.csv_delimiter, state.data.encoding]);

    useLayoutEffect(() => {
        let table = tableRef.current;

        if (!table) {
            return;
        }

        let container = table.closest('div');

        container.scrollLeft = state.table.scrollLeft;
    });

    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }));
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }));
    }

    const handleShow = (options = null) => {
        let data = defaultData;

        if (typeof options === 'object' && options) {
            data = { ...defaultData, ...options };
        }

        // console.log(data);

        setState(prev => ({
            ...prev,
            data: data,
        }));

        show();
    }


    const handleClose = () => {
        setState(prev => ({
            ...prev,
            data: defaultData,
        }));

        hide(state.onClose);
    }

    const handleSave = e => {
        e.preventDefault();

        if (getColumnsCount() > getConfiguredColumnsCount()) {
            let modal = Refs.getInstance().getRef('question');

            hide(() => {
                modal.open(`Има ${getColumnsCount() - getConfiguredColumnsCount()} неконфигурирани колони! Искате ли да продължите импорта?`);
            });

            modal.onCancel(() => {
                show();
            });

            modal.onClose(() => {
                show();
            });

            modal.onSuccess(() => {
                save();
            });
        } else {
            save();
        }
    }

    const save = () => {
        setState(prev => ({
            ...prev,
            loading: true,
        }));

        const data = {
            id: state.data.import_id,
            type: state.data.type,
            csv_delimiter: state.data.csv_delimiter,
            encoding: state.data.encoding,
            skip_rows: state.data.skip_rows,
            has_heading: state.data.has_heading,
            is_test: state.data.test,
            config: state.data.config
        };

        Api.post('store/imports/update', data)
            .then(res => {
                if (res.data.success) {
                    importData();
                } else {
                    app.showError();
                }
            })
            .catch(err => {
                app.showError();
            })
            .finally(() => {
                setState(prev => ({
                    ...prev,
                    loading: false,
                }));
            });
    }

    const importData = () => {
        let urls = {
            [TYPES.ARTICLE]: 'store/articles/import',
            [TYPES.STORELOAD]: 'store/storeloads/import',
            [TYPES.STORELOADCHECKUP]: 'store/storeloads/checkup/import',
            [TYPES.STOREMOVE]: 'store/storemoves/import',
            [TYPES.STOREMOVECHECKUP]: 'store/storemoves/checkup/import',
            [TYPES.REVISION]: 'store/revisions/import',
            [TYPES.ACCOUNT]: 'store/accounts/import',
            [TYPES.PRICERULE]: 'store/price_rules/articles/import',
            [TYPES.ARTICLEGROUP]: 'store/groups/articles/articles/import'
        };

        let url = urls[TYPE];

        if (!url) {
            app.showError('Няма зададен URL за импорт на текущата операция!');
            return;
        }

        // 
        Api.post(url, {
            ...state.params,
            import_id: state.data.import_id
        }).then(res => {
            // console.log(res.data);

            setState(prev => ({
                ...prev,
                import_result: res.data
            }));
        }).catch(err => {
            // console.log(err);

            const _err = err.response;

            if (_err && _err.status && _err.status === 422) {
                if (_err.data.errors.error) {
                    showError(_err.data.errors.error[0]);
                } else if (_err.data.errors) {
                    for (let [input, error] of Object.entries(_err.data.errors)) {
                        showError(error[0]);
                        break;
                    }
                }
            } else if (axios.isCancel(err)) {
                // 
            } else {
                app.showError();
            }
        }).finally(() => {
            // 
        });

        showResult();

    }

    const showResult = () => {
        let modal = resultModalRef.current;

        hide(() => {
            modal.open(state.data.import_id);
        });

        modal.onClose(() => {
            show();
        });

        // modal.onChunk(state.onChunk);

        // modal.onSuccess(() => {
        //     hide();
        //     hideResult();

        //     if (typeof state.onSuccess === 'function') {
        //         state.onSuccess();
        //     }
        // });
    }

    const hideResult = (cb) => {
        let modal = resultModalRef.current;

        modal.close(cb);
    }

    const onResultChunk = state.onChunk;

    const onResultSuccess = () => {
        setTimeout(() => {
            hide(state.onClose);
        }, 700);

        // hideResult();

        if (typeof state.onSuccess === 'function') {
            state.onSuccess(state.import_result);
        }
    }

    const showError = error => {
        let modal = Refs.getInstance().getRef('info');

        hideResult();

        hide();

        modal.open(error)

        modal.onClose(() => {
            show();
        });
    }

    const handleTabChange = id => {
        setState(prev => ({
            ...prev,
            tabId: id
        }));
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: value
            }
        }))
    }

    const handleCheckboxChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: !prev.data[name]
            }
        }))
    }

    const handleFileChange = file => {
        // console.log(file);

        if (state.fileUploadRequest) {
            state.fileUploadRequest.cancel();
        }

        let request = axios.CancelToken.source();

        setState(prev => ({
            ...prev,
            fileUploadRequest: request,
            fileLoading: true,
        }));

        setValidations(null);

        const data = new FormData();

        data.append('file', file);
        data.append('operation_type_id', OPERATION_TYPE_ID);

        Api.post('store/imports/upload', data, {
            headers: {
                'content-type': 'multipart/form-data'
            },
            onUploadProgress: progress => {
                // console.log(progress)

                let percent = ((progress.loaded * 100) / progress.total).toFixed();

                setState(prev => ({
                    ...prev,
                    fileProgress: percent
                }));
            },
            cancelToken: request.token
        }).then(res => {
            if (res.data.id) {
                read(res.data.id);
            }
        }).catch(err => {
            const _err = err.response;

            if (_err && _err.status && _err.status === 422) {
                setValidations(_err.data.errors)
            } else if (axios.isCancel(err)) {
                app.showWarning('Операцията беше отказана...');
            } else {
                app.showError();
            }
        }).finally(() => {
            setState(prev => ({
                ...prev,
                fileProgress: null,
                fileLoading: false,
            }));
        });
    }

    const handleTextChange = () => {
        let modal = textUploadModalRef.current;

        if (!modal) {
            return;
        }

        hide(() => {
            modal.open();
        });

        modal.onClose(() => {
            show();
        });

        modal.onSuccess((text) => {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    has_heading: false,
                }
            }));

            handleFileChange(text);
        });
    }

    const read = id => {
        Api.get('store/imports/read', {
            params: {
                id: id,
                csv_delimiter: state.data.csv_delimiter,
                encoding: state.data.encoding,
                skip_rows: state.data.skip_rows,
                has_heading: state.data.has_heading,
            }
        }).then(res => {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    has_file: true,
                    import_id: id,
                    limit: res.data.limit,
                    rows: res.data.rows,
                }
            }));
        }).catch(err => {
            const _err = err.response;

            if (_err && _err.status && _err.status === 422) {
                setValidations(_err.data.errors)
            } else if (axios.isCancel(err)) {
                // 
            } else {
                app.showError();
            }
        })
    }

    const getValues = colIndex => {
        return Api.get('store/imports/column-unique-values', {
            params: {
                id: state.data.import_id,
                index: colIndex,
                has_heading: Number(state.data.has_heading),
            }
        }).then(res => {
            return res;
        }).catch(err => {
            const _err = err.response;

            if (_err && _err.status && _err.status === 422) {
                setValidations(_err.data.errors)
            } else if (axios.isCancel(err)) {
                // 
            } else {
                app.showError('Неуспешно изтегляне на стойности за колона с ID ' + colIndex);
            }
        })
    }

    const setType = type => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                type: type,
            }
        }));
    }

    const toggleSettings = () => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                show_settings: !prev.data.show_settings,
            }
        }));
    }

    const configure = colIndex => {
        let modal = columnsModalRef.current;

        if (!modal) {
            return;
        }

        let config = cloneDeep(state.data.config[colIndex]) || {};

        // config.type = 'article_name';

        hide(() => {
            modal.open(config, colIndex);
        });

        modal.onClose(() => {
            show();
        });

        modal.onSuccess(config => {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    config: {
                        ...prev.data.config,
                        [colIndex]: config
                    }
                }
            }))
        });

    }

    const getColumnsCount = () => {
        return state.data.rows[0] ? state.data.rows[0].length : 0;
    }

    const getConfiguredColumnsCount = () => {
        return Object.keys(state.data.config).length;
    }

    const isRowSkipped = rowIndex => {
        if (state.data.skip_rows > 0) {
            if (state.data.skip_rows >= rowIndex + 1) {
                return true;
            }
        }

        return false;
    }

    const getHeadingRowIndex = () => {
        for (let i = 0; i < state.data.rows.length; i++) {
            if (!isRowSkipped(i)) {
                return i;
            }
        }

        return -1;
    }

    const handleShowDetails = (id) => {
        let modal = detailsModalRef.current;

        if (!modal) {
            return;
        }

        hide(() => {
            modal.open(id);
        });

        modal.onClose(() => {
            show();
        });
    }

    const handleShowConfigs = () => {
        let modal = configsModalRef.current;

        if (!modal) {
            return;
        }

        hide(() => {
            modal.open();
        });

        modal.onClose(() => {
            show();
        });

        modal.onSuccess((config) => {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    config: config,
                }
            }));
        })
    }

    const onScroll = (e) => {
        let left = e.target.scrollLeft;

        setState(prev => ({
            ...prev,
            table: {
                ...prev.table,
                scrollLeft: left,
            }
        }));
    }

    // console.log(state.data.config);

    return (
        <>
            <Columns
                ref={columnsModalRef}
                type={TYPE}
                createOrUpdate={state.data.type}
                getValues={getValues}
            />

            <Result
                ref={resultModalRef}
                onChunk={onResultChunk}
                onSuccess={onResultSuccess}

            />

            <Details
                ref={detailsModalRef}
            />

            <Configs
                ref={configsModalRef}
                type={TYPE}
            />

            <TextUpload
                ref={textUploadModalRef}
            />

            {createPortal(
                <Modal enforceFocus={false} size="xl" centered backdrop="static" show={state.show} onHide={handleClose} onEntering={state.onEntering} onExiting={state.onExiting}>
                    <form onSubmit={handleSave}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                Импорт
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="import">
                            <Tabs
                                activeKey={state.tabId}
                                onSelect={handleTabChange}
                                className="mb-3"
                            >
                                <Tab eventKey="file" title="Файл">
                                    {state.data.has_file === false &&
                                        <>
                                            {validations?.file &&
                                                <Alert variant="danger">
                                                    {validations.file[0] || validations.file}
                                                </Alert>
                                            }

                                            <DragDropFile
                                                accept=".xlsx,.xls,xlsm,.ods,.csv"
                                                onChange={handleFileChange}
                                            />

                                            {state.fileProgress !== null &&
                                                <ProgressBar className="mt-2" now={state.fileProgress} label={`${state.fileProgress}%`} />
                                            }
                                        </>
                                    }

                                    {state.data.has_file === true &&
                                        <>
                                            <div className="import-options mb-3">
                                                {hasCreateAndUpdate &&
                                                    <div className="import-type">
                                                        <span>
                                                            Тип импорт:
                                                        </span>
                                                        <label className="create">
                                                            <input
                                                                type="radio"
                                                                checked={state.data.type === 'create'}
                                                                onChange={e => setType('create')}
                                                            />
                                                            <span>{props.createText || 'Въвеждане'}</span>
                                                        </label >
                                                        <label className="update">
                                                            <input
                                                                type="radio"
                                                                checked={state.data.type === 'update'}
                                                                onChange={e => setType('update')}
                                                            />
                                                            <span>{props.updateText || 'Актуализация'}</span>

                                                        </label>
                                                    </div>
                                                }
                                                <Button
                                                    size="sm"
                                                    onClick={handleShowConfigs}
                                                >
                                                    <Save /> <span>
                                                        Вземи настройки от предишни
                                                    </span>
                                                </Button>
                                                <Button
                                                    size="sm"
                                                    onClick={toggleSettings}
                                                >
                                                    <Gear /> <span>Още</span>
                                                </Button>
                                                <Form.Check
                                                    type="switch"
                                                    name="test"
                                                    label="Тестов импорт"
                                                    value={1}
                                                    checked={Number(state.data.test) === 1}
                                                    onChange={e => handleCheckboxChange(e)}
                                                    size="sm"
                                                    title="Ще симулира импорта и ще покаже краен резултат, без реално да го прилага"
                                                    style={{
                                                        marginLeft: 'auto'
                                                    }}
                                                />
                                            </div >
                                            {state.data.show_settings &&
                                                <div className="import-settings mb-3">
                                                    <div className="group">
                                                        <label>
                                                            CSV разделител
                                                        </label>
                                                        <Form.Control
                                                            name="csv_delimiter"
                                                            value={state.data.csv_delimiter || ''}
                                                            onChange={e => handleInputChange(e)}
                                                            size="sm"
                                                            style={{ width: '70px' }}
                                                        />
                                                    </div>

                                                    <div className="group">
                                                        <label>
                                                            Енкодинг
                                                        </label>
                                                        <Form.Select
                                                            name="encoding"
                                                            value={state.data.encoding || ''}
                                                            onChange={e => handleInputChange(e)}
                                                            size="sm"
                                                        >
                                                            <option value="">автоматично</option>
                                                            <option value="utf-8">UTF-8</option>
                                                            <option value="ascii">ASCII</option>
                                                            <option value="windows-1251">Windows-1251</option>
                                                        </Form.Select>
                                                    </div>

                                                    <div className="group">
                                                        <label>
                                                            Пропусни Х реда
                                                        </label>
                                                        <Form.Control
                                                            type="number"
                                                            step="1"
                                                            min="0"
                                                            name="skip_rows"
                                                            value={state.data.skip_rows || ''}
                                                            onChange={e => handleInputChange(e)}
                                                            size="sm"
                                                            style={{ width: '70px' }}
                                                        />
                                                    </div>

                                                    <div className="group">
                                                        <label>
                                                            Има заглавен ред
                                                        </label>
                                                        <Form.Check
                                                            name="has_heading"
                                                            value={1}
                                                            checked={Number(state.data.has_heading) === 1}
                                                            onChange={e => handleCheckboxChange(e)}
                                                            size="sm"
                                                        />
                                                    </div>
                                                </div>
                                            }
                                            <Card>
                                                <Card.Header>
                                                    Преглед на файл (първите {state.data.limit} реда)
                                                </Card.Header>
                                                <Card.Body>
                                                    <div className="table-responsive" onScroll={onScroll} style={{ overflowY: 'hidden' }}>
                                                        <Table className="big" striped bordered ref={tableRef}>
                                                            <TableHeader
                                                                tableRef={tableRef}
                                                            >
                                                                <th>

                                                                </th>
                                                                {state.data.rows[0].map((col, colIndex) =>
                                                                    <th key={colIndex}>
                                                                        <Button
                                                                            size="sm"
                                                                            variant={state.data.config[colIndex] ? 'success' : 'primary'}
                                                                            onClick={e => configure(colIndex)}
                                                                            style={{ whiteSpace: 'nowrap' }}
                                                                        >
                                                                            {state.data.config[colIndex]
                                                                                ?
                                                                                <Check />
                                                                                :
                                                                                <X />
                                                                            }
                                                                            {state.data.config[colIndex]?.name || 'Конфигурирай'}
                                                                        </Button>
                                                                    </th>
                                                                )}
                                                            </TableHeader>
                                                            <tbody>
                                                                {state.data.rows.map((row, rowIndex) =>
                                                                    <tr key={rowIndex} className={isRowSkipped(rowIndex) ? 'deleted' : ''}>
                                                                        <td>{rowIndex + 1}</td>
                                                                        {row.map((col, colIndex) =>
                                                                            <td key={colIndex} style={{ whiteSpace: 'nowrap', fontWeight: rowIndex === getHeadingRowIndex() && state.data.has_heading ? 'bold' : 'normal' }}>
                                                                                {col}
                                                                            </td>
                                                                        )}
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </Card.Body>
                                            </Card >
                                        </>
                                    }
                                </Tab >
                                <Tab eventKey="old" title="Предишни импорти" mountOnEnter>
                                    <Previous
                                        handleShowDetails={handleShowDetails}
                                        type={TYPE}
                                    />
                                </Tab>
                            </Tabs >
                        </Modal.Body >
                        <Modal.Footer style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Button variant="outline-dark" onClick={handleTextChange}>
                                Свободно въвеждане на данни
                            </Button>

                            <div>
                                <Button variant="secondary" onClick={handleClose}>
                                    Отказ
                                </Button>
                                <SaveButton
                                    loading={state.loading}
                                    disabled={!state.data.import_id}
                                    className="save"
                                    style={{ marginLeft: '5px' }}
                                >
                                    Импорт
                                </SaveButton>
                            </div>
                        </Modal.Footer>
                    </form>
                </Modal>,
                document.body
            )}
        </>
    )
}

export default forwardRef(Import);