export const PROFORMA = 2;
export const INVOICE = 3;
export const CREDIT_NOTE = 4;
export const DEBIT_NOTE = 5;
export const PROTOCOL = 6;
export const STOCK = 7;

export const TYPES = [
    {
        id: INVOICE,
        type: 'invoice',
        name: 'Фактура'
    },
    {
        id: CREDIT_NOTE,
        type: 'creditnote',
        name: 'Кредитно известие'
    },
    {
        id: DEBIT_NOTE,
        type: 'debitnote',
        name: 'Дебитно известие'
    },
    {
        id: PROFORMA,
        type: 'proforma',
        name: 'Проформа'
    },
    {
        id: STOCK,
        type: 'stock',
        name: 'Стокова разписка'
    },
];

