//MUI components
import { Tooltip } from "@material-ui/core"

//miscs
import RedditTextField from "components/misc/RedditTextField"

//images
import checkIcon from 'assets/img/documents/icons/check.png'
import warningIcon from 'assets/img/documents/icons/warning.png'
import loadingImage from 'assets/img/loader-2.svg'

//hooks
import useCompanyVatFieldHook from "../useCompanyVatFieldHook"

//providers
import { useRegisterContext } from "../RegisterProvider"

const CompanyVatField = ({ name }) => {
    const register = useRegisterContext()

    const {
        companyData,
        vatFieldState,
        isVatNumberValid,
        handleInputChange,
        handleCheckCustomerVatNumber
    } = useCompanyVatFieldHook()

    return (
        <div className="customer-input">
            <Tooltip
                title={register.getVatNumberLoading() ? 'Извършва се проверка...' : (companyData.data?.vatNumber?.length || 0) ? `Въведения ДДС номер е ${isVatNumberValid().isValid ? 'валиден' : 'невалиден'}!` : 'Въвеждане на ДДС номер за проверка'}
            >
                <RedditTextField
                    margin="dense"
                    size="small"
                    fullWidth
                    label="ИН по ЗДДС"
                    name={name || 'vatNumber'}
                    value={companyData.data?.vatNumber || ''}
                    onChange={handleInputChange}
                // error={Boolean(validations && validations.customerinfo?.vatNumber)}
                // helperText={validations && validations.customerinfo && validations.customerinfo.vatNumber && (validations.customerinfo.vatNumber[0] || validations.customerinfo.vatNumber)}
                // InputLabelProps={{ shrink: true }}
                />
            </Tooltip>
            {(companyData.data?.vatNumber?.length || 0) ?
                isVatNumberValid().hasVat && !register.getVatNumberLoading() && !vatFieldState.renewCheckVat ?
                    isVatNumberValid().isValid ?
                        <Tooltip
                            title="ДДС номера е валиден!"
                        >
                            <img src={checkIcon} />
                        </Tooltip>
                        :
                        <Tooltip
                            title="ДДС номера е невалиден!"
                        >
                            <img src={warningIcon} />
                        </Tooltip>
                    :
                    register.getVatNumberLoading() ?
                        <img src={loadingImage} />
                        :
                        <Tooltip
                            title="Проверка на ДДС номер"
                        >
                            <div
                                className="eu"
                                onClick={() => {
                                    handleCheckCustomerVatNumber()
                                }}
                            >
                            </div>
                        </Tooltip>
                :
                <>
                </>
            }
        </div>
    )
}

export default CompanyVatField