import { useState, useEffect, useRef } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Layout from 'components/Layout'

//partals
import Documents from './Documents'
import Document from './Document'
import Tabs from './partials/Tabs'
import AddButton from './partials/AddButton'
import SaveButton from 'components/misc/Button';

//constatns
import { DOCUMENT_TYPE } from 'constants/Document'

//MUI components
import { Tooltip, Button, Checkbox, CircularProgress } from '@material-ui/core'

//helpers
import { useQuery } from 'helpers/Url'
import Api from 'helpers/Api'

//providers
import { useDocumentContext } from './Provider'

//plugins
import moment from 'moment'
import axios from 'axios'

//providers
import { useAuthDataContext } from 'providers/Auth'
import { usePrivateSocketChannel, useSocketNotification } from 'helpers/Socket'
import MenuOptions from 'components/misc/MenuOptions'

// images
import checkIcon from 'assets/img/app/icons/check.png'
import crossIcon from 'assets/img/app/icons/close.png'
import { cloneDeep } from 'lodash'

import Success from 'components/modals/Success'
import Error from 'components/modals/Error'

// modals

const Index = () => {
    const auth = useAuthDataContext()
    const params = useParams()
    const history = useHistory()
    const query = useQuery()
    const context = useDocumentContext()

    const importId = params.id;

    const successModalRef = useRef(null);
    const errorModalRef = useRef(null);

    const [state, setState] = useState({
        loading: false,
        dataLoading: true,
        import: null,
        documents: [],
        duplicated: [],
        documentsLoading: true,
        selected: {},
    });

    useEffect(() => {
        loadData();
    }, [params.id]);

    usePrivateSocketChannel({
        channel: 'users',
        channelId: auth.getUser()?.getId(),
        event: '.blackbox\\documents\\events\\InvoiceImportRead',
        onEvent: (payload) => {
            if (Number(payload.import.id) === Number(importId)) {
                setState(prev => ({
                    ...prev,
                    import: payload.import,
                    documents: payload.documents,
                    duplicated: payload.duplicated,
                    documentsLoading: false,
                }));
            }
        }
    }, [auth]);

    usePrivateSocketChannel({
        channel: 'users',
        channelId: auth.getUser()?.getId(),
        event: '.blackbox\\documents\\events\\InvoiceImportReady',
        onEvent: (payload) => {
            if (Number(payload.import.id) === Number(importId)) {
                setState(prev => ({
                    ...prev,
                    import: payload.import,
                    documents: payload.documents.length ? payload.documents : prev.documents,
                    documentsLoading: false,
                    loading: false,
                }));
            }
        }
    }, [auth]);

    const loadData = () => {
        Api.get('documents/import/show', {
            params: {
                id: params.id
            }
        }).then(res => {
            setState(prev => ({
                ...prev,
                import: res.data.import,
                dataLoading: false,
            }));
        })
    }

    const isDuplicated = (index) => {
        return state.duplicated.indexOf(index) > -1;
    }

    const wasImported = (index) => {
        let imported = state.import?.imported;

        if (!Array.isArray(imported)) {
            imported = [];
        }

        return imported.indexOf(index) > -1;
    }

    const handleSelect = index => {
        const selected = cloneDeep(state.selected)

        if (selected[index]) {
            delete selected[index]
        } else {
            selected[index] = 1
        }

        setState(prev => ({
            ...prev,
            selected
        }))
    }

    const handleSelectAll = () => {
        const selected = {}

        if (!areAllSelected()) {
            state.documents.map((d, i) => {
                if (!wasImported(i)) {
                    selected[i] = 1
                }
            })
        }

        setState(prev => ({
            ...prev,
            selected
        }))
    }

    const getSelectedCount = () => {
        const selectedCount = Object.values(state.selected).length

        return selectedCount
    }

    const areAllSelected = () => getSelectedCount() === state.documents.length;

    const handleImport = () => {
        setState(prev => ({
            ...prev,
            loading: true,
        }));

        Api.post('documents/import/store', {
            id: params.id,
            indexes: Object.keys(state.selected)
        }).then(res => {

            setState(prev => ({
                ...prev,
                selected: {}
            }));

            let modal = successModalRef.current;

            if (modal) {
                modal.open();
            }
        }).catch(err => {
            let modal = errorModalRef.current;

            if (modal) {
                modal.open();
            }
        });
    }

    return (
        <>
            <Success
                mainMessage="След като документите бъдат импортирани ще бъдете уведомени!"
                ref={successModalRef}
            />

            <Error
                ref={errorModalRef}
                mainMessage="Нещо се обърка!"
            />

            <Layout>
                <section id="documents">
                    <div id="head" className="row space-between">
                        <div className="title">
                            Импорт
                        </div>
                    </div>
                    <div className="panel">
                        <table className="type-outer">
                            <tbody>
                                <tr>
                                    <td>
                                        Софтуер
                                    </td>
                                    <td>
                                        Ажур
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Дата на създаване
                                    </td>
                                    <td>
                                        {state.import?.created_at ? moment(state.import?.created_at).format('DD.MM.YYYY HH:mm:ss') : ''}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Начало
                                    </td>
                                    <td>
                                        {state.import?.start_at ? moment(state.import?.start_at).format('DD.MM.YYYY HH:mm:ss') : ''}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Край
                                    </td>
                                    <td>
                                        {state.import?.end_at ? moment(state.import?.end_at).format('DD.MM.YYYY HH:mm:ss') : ''}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Файл
                                    </td>
                                    <td>
                                        {state.import?.original_file}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Статус
                                    </td>
                                    <td>
                                        {state.import?.ready ? 'Приключен' : state.import?.read ? 'Прочетен' : 'Обработва се'}
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <br />

                        {state.documentsLoading
                            ?
                            <div className="flex col center align-center" style={{ padding: '100px 0' }}>
                                <CircularProgress color="inherit" />
                                <h3 style={{ marginTop: '20px', fontWeight: '500' }}>
                                    Моля, изчакайте. Файлът се сканира...
                                </h3>
                            </div>
                            :
                            <>
                                <table className="type-outer">
                                    <thead>
                                        <tr>
                                            <th>
                                                <Tooltip
                                                    title="Маркиране на всички документи от тази страница"
                                                >
                                                    <Checkbox
                                                        checked={areAllSelected()}
                                                        onChange={handleSelectAll}
                                                    />
                                                </Tooltip>
                                            </th>
                                            <th>
                                                Тип
                                            </th>
                                            <th>
                                                Получател
                                            </th>
                                            <th>
                                                Номер
                                            </th>
                                            <th>
                                                Дата
                                            </th>
                                            <th className="text-right">
                                                Сума
                                            </th>
                                            <th className="text-right">
                                                ДДС
                                            </th>
                                            <th className="text-right">
                                                Общо
                                            </th>
                                            <th className="text-right">
                                                За плащане
                                            </th>
                                            <th className="text-center">
                                                Платено
                                            </th>
                                            <th className="text-center">
                                                Забележка
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {state.documents.map((d, i) =>
                                            <tr
                                                key={'doc-' + d.id}
                                            // onClick={}

                                            >
                                                <td>
                                                    <Tooltip
                                                        title={`Маркиране на ${d.type?.translation?.name.toLowerCase()}`}
                                                    >
                                                        <Checkbox
                                                            id={`document-cb-${i}`}
                                                            checked={state.selected[i] ? true : false}
                                                            color="primary"
                                                            onClick={() => {
                                                                handleSelect(i)
                                                            }}
                                                            disabled={wasImported(i)}
                                                        />
                                                    </Tooltip>
                                                </td>
                                                <td>
                                                    <div style={{ display: 'flex' }}>
                                                        <span>{d.type?.translation?.name}</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    {d?.customerinfo?.name || ''}
                                                </td>
                                                <td>
                                                    {d.no}
                                                </td>
                                                <td>
                                                    {d.date_dmy}
                                                </td>
                                                <td className="text-right">
                                                    {d.net_price_formatted} {d?.currency?.name}
                                                </td>
                                                <td className="text-right">
                                                    {d.vat_price_formatted} {d?.currency?.name}
                                                </td>
                                                <td className="text-right">
                                                    {d.gross_price_formatted} {d?.currency?.name}
                                                </td>
                                                <td className="text-right">
                                                    {d.unpaid_price_formatted} {d?.currency?.name}
                                                </td>
                                                <td className="text-center">
                                                    {d.is_paid
                                                        ?
                                                        <img src={checkIcon} alt="" height={12} />
                                                        :
                                                        <img src={crossIcon} alt="" height={12} />
                                                    }
                                                </td>
                                                <td className="text-center">
                                                    {wasImported(i) ? 'Импортиран' : isDuplicated(i) ? 'Дублира се' : ''}
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>

                                <br />

                                <SaveButton
                                    loading={state.loading}
                                    className="save"
                                    text="Импортирай избраните"
                                    disabled={getSelectedCount() === 0}
                                    onClick={handleImport}
                                />

                            </>
                        }
                    </div>
                </section>
            </Layout>
        </>
    )
}

export default Index


