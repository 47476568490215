import { useRef } from "react"

//libraries
import Skeleton from "react-loading-skeleton"
import moment from "moment"
import { Button } from "@material-ui/core"

//components
import NoDataFound from "components/misc/NoDataFound"
import StoreOut from "components/pages/storeouts/StoreOut"
import View from "components/pages/storeouts/view/View"

function OutsActivitiesDashboardTable({
  loadingActivities,
  getActivities,
  refetchData,
  clientId,
}) {
  const addOrEditOutModalRef = useRef(null)
  const viewModalRef = useRef(null)

  function handleClickAddOutButton() {
    const modal = addOrEditOutModalRef.current

    modal.add(null, clientId)
    modal.onSuccess(() => {
      modal.hide()
      refetchData()
    })
  }

  function handleView(data) {
    let modal = viewModalRef.current

    modal.open(data.id)
  }

  if (loadingActivities) {
    return (
      <div
        style={{
          width: "100%",
        }}
      >
        <Skeleton count={1} height={35} />
        <Skeleton count={1} height={35} />
        <Skeleton count={1} height={35} />
        <Skeleton count={1} height={35} />
        <Skeleton count={1} height={35} />
      </div>
    )
  }

  if (!loadingActivities && !getActivities().length) {
    return (
      <>
        <NoDataFound
          style={{
            marginBottom: "-10px",
          }}
        >
          <h2>Няма налични записи</h2>
          <p>Може да добавите ново изписване от бутона по-долу.</p>
          <Button
            className="add new-btn-primary"
            onClick={handleClickAddOutButton}
          >
            Изписване
          </Button>
        </NoDataFound>
        <StoreOut ref={addOrEditOutModalRef} />
      </>
    )
  }

  return (
    <>
      <View ref={viewModalRef} />
      <table className="type-outer">
        <thead>
          <tr>
            <th>№</th>
            <th>Статус</th>
            <th>От склад</th>
            <th>Количество</th>
            <th>Създадено на</th>
          </tr>
        </thead>
        <tbody>
          {getActivities().map((activity) => (
            <tr
              key={activity.id}
              onClick={() => {
                handleView(activity)
              }}
            >
              <td>{activity.id}</td>
              <td>{activity.status}</td>
              <td>{activity.depot}</td>
              <td>{activity.total_quantity}</td>
              <td>
                {activity.created_at
                  ? `${moment(activity.created_at, "YYYY-MM-DDTHH:mm").format(
                      "DD.MM.YYYY"
                    )} г.`
                  : "-"}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <hr />
      <Button className="add new-btn-primary" onClick={handleClickAddOutButton}>
        Изписване
      </Button>
      <StoreOut ref={addOrEditOutModalRef} />
    </>
  )
}

export default OutsActivitiesDashboardTable
