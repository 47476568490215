/**
 * 
 * @param {string} string произволен string 
 * @param {number} length къде да бъде отрязан string-a
 * @returns отрязания стринг + многоточие || оригиналния string
 */
export const substring = (string, length = null) => {
    if (!length || !string) {
        return string
    }
    if (string.length >= length) {
        string = `${string.substring(1, length)}...`
    }

    return string
}