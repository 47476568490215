import { useState } from "react"

//constants
import { PERIOD_FILTER_TYPES } from "constants/Document"

//librarires
import moment from "moment-timezone"
import "moment/locale/bg"
import ClickAwayListener from "react-click-away-listener"

//images
import prevSixMonthsIcon from "assets/img/documents/filter/icons/last-6-months.png"
import prevThreeMonthsIcon from "assets/img/documents/filter/icons/last-3-months.png"
import prevYearIcon from "assets/img/documents/filter/icons/prev-year.png"
import currentYearIcon from "assets/img/documents/filter/icons/current-year.png"
import startDateIcon from "assets/img/documents/filter/icons/start-date.png"
import endDateIcon from "assets/img/documents/filter/icons/end-date.png"

moment.locale("bg")
function PeriodNew({ period, dates, handleSearch }) {
  const { start_date, end_date } = dates

  const [isVisible, setIsVisible] = useState(false)

  function getSelectedStartDate() {
    return start_date
  }

  function getSelectedEndDate() {
    return end_date
  }

  function getSelectedStartDateFormatted() {
    if (getSelectedStartDate()) {
      return moment(getSelectedStartDate(), "YYYY-MM-DD").format("DD.MM.YYYY")
    }

    return null
  }

  function getSelectedEndDateFormatted() {
    if (getSelectedEndDate()) {
      return moment(getSelectedEndDate(), "YYYY-MM-DD").format("DD.MM.YYYY")
    }

    return null
  }

  function getSelectedPeriod() {
    if (getSelectedStartDate() || getSelectedEndDate()) {
      return `${getSelectedStartDateFormatted() || "Няма"} - ${
        getSelectedEndDateFormatted() || "Няма"
      }`
    }

    return null
  }

  function handleChangePeriod(value) {
    handleSearch("period", isActive(value) ? "" : value)
    handleSearch("start_date", "")
    handleSearch("end_date", "")
  }

  function handleChangeDate(e) {
    const { value, name } = e.target

    handleSearch(name, value)
  }

  function isActive(param) {
    if (period === param) {
      return true
    }

    return false
  }

  function getCurrentMonthAsWord() {
    return moment().format("MMMM")
  }

  function getCurrentYear() {
    return moment().format("YYYY")
  }

  function getCurrentMonthAsShortenedWord() {
    return moment().format("MMM")
  }

  function getPrevMonthYear() {
    return moment().subtract(1, "month").format("YYYY")
  }

  function getPrevMonthAsWord() {
    return moment().subtract(1, "month").format("MMMM")
  }

  function getPrevMonthAsShortenedWord() {
    return moment().subtract(1, "month").format("MMM")
  }

  function getLastDigitsOfCurrentYear() {
    return moment().format("YY")
  }

  function getLastYear() {
    return moment().subtract(1, "year").format("YYYY")
  }

  function getLastDigitsOfPrevYear() {
    return moment().subtract(1, "year").format("YY")
  }

  return (
    <div className="col">
      <div
        className="custom-filter-select period-new"
        onClick={() => {
          setIsVisible(true)
        }}
      >
        <div className="option">
          {PERIOD_FILTER_TYPES[period] ||
            getSelectedPeriod() ||
            "Изберете период"}
          {PERIOD_FILTER_TYPES[period] || getSelectedPeriod() ? (
            <span className="applied"></span>
          ) : (
            <></>
          )}
        </div>
        {isVisible ? (
          <ClickAwayListener
            onClickAway={() => {
              setIsVisible(false)
            }}
          >
            <div className="period-options">
              <div className="header">Бърз избор</div>
              <ul>
                <li
                  onClick={() => {
                    handleChangePeriod("this-month")
                  }}
                  className={`hover-new ${
                    isActive("this-month") ? "active-new" : ""
                  }`}
                >
                  <span className="icon current-month">
                    {getCurrentMonthAsShortenedWord()}
                  </span>
                  {`${getCurrentMonthAsWord()} ${getCurrentYear()}`}
                </li>
                <li
                  onClick={() => {
                    handleChangePeriod("prev-month")
                  }}
                  className={`hover-new ${
                    isActive("prev-month") ? "active-new" : ""
                  }`}
                >
                  <span className="icon prev-month"></span>
                  {`${getPrevMonthAsWord()} ${getPrevMonthYear()}`}
                </li>
              </ul>
              <hr />
              <ul>
                <li
                  onClick={() => {
                    handleChangePeriod("last-3-months")
                  }}
                  className={`hover-new ${
                    isActive("last-3-months") ? "active-new" : ""
                  }`}
                >
                  <img src={prevThreeMonthsIcon} alt="" />
                  {PERIOD_FILTER_TYPES["last-3-months"]}
                </li>
                <li
                  onClick={() => {
                    handleChangePeriod("last-6-months")
                  }}
                  className={`hover-new ${
                    isActive("last-6-months") ? "active-new" : ""
                  }`}
                >
                  <img src={prevSixMonthsIcon} alt="" />
                  {PERIOD_FILTER_TYPES["last-6-months"]}
                </li>
                <li
                  onClick={() => {
                    handleChangePeriod("last-12-months")
                  }}
                  className={`hover-new ${
                    isActive("last-12-months") ? "active-new" : ""
                  }`}
                >
                  <img src={prevSixMonthsIcon} alt="" />
                  {PERIOD_FILTER_TYPES["last-12-months"]}
                </li>
              </ul>
              <hr />
              <div className="row">
                <div
                  onClick={() => {
                    handleChangePeriod("prev-year")
                  }}
                  className={`year-related-new hover-new ${
                    isActive("prev-year") ? "active-new" : ""
                  }`}
                >
                  <img src={prevYearIcon} alt="" />
                  <div className="right">
                    <i>{getLastYear()}</i>
                    <br />
                    година
                  </div>
                </div>

                <div
                  onClick={() => {
                    handleChangePeriod("last-year")
                  }}
                  className={`year-related-new hover-new ${
                    isActive("last-year") ? "active-new" : ""
                  }`}
                >
                  <img src={currentYearIcon} alt="" />
                  <div className="right">
                    <i>{getCurrentYear()}</i>
                    <br />
                    година
                  </div>
                </div>
              </div>
              <div className="header">Избор на период</div>
              <div className="row">
                <div className="calendar-holder">
                  <i>От</i>
                  <div className="date-holder-new start-date">
                    <img src={startDateIcon} alt="" />
                    {getSelectedStartDateFormatted() || "Избор"}
                    <input
                      type="date"
                      onChange={handleChangeDate}
                      name="start_date"
                    />
                  </div>
                </div>
                <div className="calendar-holder">
                  <i>До</i>
                  <div className="date-holder-new end-date">
                    <img src={endDateIcon} alt="" />
                    {getSelectedEndDateFormatted() || "Избор"}
                    <input
                      type="date"
                      onChange={handleChangeDate}
                      name="end_date"
                    />
                  </div>
                </div>
              </div>
            </div>
          </ClickAwayListener>
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}

export default PeriodNew
