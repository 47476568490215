import { createPortal } from "react-dom"
import { useState } from "react"

//libraries
import { Button, Tooltip } from "@material-ui/core"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import moment from "moment"
import { Skeleton } from "@mui/material"

//images
import prevMonthIcon from "assets/img/customers/icons/month-prev.png"
import nextMonthIcon from "assets/img/customers/icons/month-next.png"
import calendarIcon from "assets/img/customers/icons/calendar-2.png"

//components
import CalendarHourly from "../partials/CalendarHourly"
import CalendarDaily from "../partials/CalendarDaily"
import AddOrEditCalendarNote from "../partials/AddOrEditCalendarNote"

//constants
import { MONTHS } from "constants/calendar"

//hooks
import useFetchCalendar from "../hooks/useFetchCalendar"

function Calendar({ filters }) {
  const [selectedMonthAndYear, setSelectedMonthAndYear] = useState(
    moment().format("MM.YYYY")
  )
  const [selectedWeekIndex, setSelectedWeekIndex] = useState(
    getInitialSelectedWeekIndexByCurrentDate() - 1
  )
  const [calendarType, setCalendarType] = useState(1)

  const { getCalendar, loadingCalendar } = useFetchCalendar(
    getFormattedPeriodForReq()
  )

  function getInitialSelectedWeekIndexByCurrentDate() {
    const firstDayOfMonth = moment().startOf('month');
    const firstDayOfWeek = firstDayOfMonth.clone().startOf('week');
  
    const offset = firstDayOfMonth.diff(firstDayOfWeek, 'days');
  
    return Math.ceil((moment().date() + offset) / 7);
  }

  function getWeeksGroupedByMonths() {
    const numberOfWeeksInYear = moment(
      selectedMonthAndYear,
      "MM.YYYY"
    ).weeksInYear()
    const calendarObj = {}

    for (let i = 1; i <= numberOfWeeksInYear; i++) {
      const week = moment(selectedMonthAndYear, "MM.YYYY").week(i)
      const monthNumber = Number(week.format("M"))

      calendarObj[monthNumber] = [
        ...(calendarObj[monthNumber] || []),
        {
          month: MONTHS[monthNumber],
          weekNumber: i,
          monday: week.day(1).format("DD.MM"),
          sunday: week.day(7).format("DD.MM"),
        },
      ]
    }

    return calendarObj
  }

  function nextMonth() {
    const [month, year] = selectedMonthAndYear.split(".")
    const nextMonth = Number(month) + 1

    if (nextMonth > 12) {
      setSelectedMonthAndYear(`${1}.${Number(year) + 1}`)
    } else {
      setSelectedMonthAndYear(`${nextMonth}.${year}`)
    }

    setSelectedWeekIndex(0)
  }

  function getSelectedDateAndYearFormatted() {
    const [month, year] = selectedMonthAndYear.split(".")

    return `${MONTHS[Number(month)]} ${year}`
  }

  function prevMonth() {
    const [month, year] = selectedMonthAndYear.split(".")
    const prevMonth = Number(month) - 1

    if (prevMonth < 1) {
      setSelectedMonthAndYear(`${12}.${Number(year) - 1}`)
    } else {
      setSelectedMonthAndYear(`${prevMonth}.${year}`)
    }

    setSelectedWeekIndex(0)
  }

  function getFormattedPeriodForReq() {
    if (calendarType === 1) {
      const selectedWeek =
        getWeeksGroupedByMonths()[
          moment(selectedMonthAndYear, "MM.YYYY").month() + 1
        ][selectedWeekIndex]

      const weekStartDate = moment(
        `${selectedWeek.monday}.${moment(
          selectedMonthAndYear,
          "MM.YYYY"
        ).format("YYYY")}`,
        "DD.MM.YYYY"
      ).format("YYYY-MM-DD")

      return {
        startDate: weekStartDate,
        endDate: moment(weekStartDate, "YYYY-MM-DD")
          .add(6, "days")
          .format("YYYY-MM-DD"),
      }
    }

    return {
      startDate: moment(selectedMonthAndYear, "MM.YYYY"),
    }
  }

  function handleChangeMonthAndYear(value) {
    const selectedDate = moment(value["$d"]).format("MM.YYYY")

    setSelectedMonthAndYear(selectedDate)
    setSelectedWeekIndex(0)
  }

  function toggleCalendarType() {
    setCalendarType(calendarType === 1 ? 2 : 1)
  }

  return (
    <>
      <div className="event-calendar">
        <div className="calendar-head">
          <div className="month-and-year">
            <Tooltip title="Предишен месец">
              <Button onClick={prevMonth}>
                <img src={prevMonthIcon} alt="" />
              </Button>
            </Tooltip>
            <Tooltip title="Промяна на датата">
              <Button className="select-date">
                <b>{getSelectedDateAndYearFormatted()}</b>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    views={["month", "year"]}
                    onChange={handleChangeMonthAndYear}
                  />
                </LocalizationProvider>
              </Button>
            </Tooltip>
            <Tooltip title="Следващ месец">
              <Button onClick={nextMonth}>
                <img src={nextMonthIcon} alt="" />
              </Button>
            </Tooltip>
          </div>
          {calendarType === 1 ? (
            <ul className="periods">
              {getWeeksGroupedByMonths()[
                moment(selectedMonthAndYear, "MM.YYYY").format("M")
              ].map((week, index) => (
                <li>
                  <Button
                    className={selectedWeekIndex === index ? "active" : ""}
                    onClick={() => setSelectedWeekIndex(index)}
                  >
                    <b>{`${week.monday} - ${week.sunday}`}</b>
                  </Button>
                </li>
              ))}
            </ul>
          ) : (
            <></>
          )}
          <Tooltip title="Промяна на изгледа">
            <div className="calendar-type" onClick={toggleCalendarType}>
              <img src={calendarIcon} alt="" />
              <b>{calendarType === 1 ? "Седмица" : "Ден"}</b>
            </div>
          </Tooltip>
        </div>
        {loadingCalendar ? (
          <div
            style={{
              width: "100%",
              padding: 15,
            }}
          >
            <Skeleton count={1} height={100} />
            <Skeleton count={1} height={100} />
            <Skeleton count={1} height={100} />
            <Skeleton count={1} height={100} />
            <Skeleton count={1} height={100} />
          </div>
        ) : calendarType === 1 ? (
          <CalendarHourly calendar={getCalendar()} />
        ) : (
          <CalendarDaily calendar={getCalendar()} />
        )}
      </div>
      {createPortal(<AddOrEditCalendarNote />, document.body)}
    </>
  )
}

export default Calendar
