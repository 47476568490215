import { useRef, useState } from 'react'

//misc
import SaveButton from 'components/misc/Button'

//helpers
import Api from 'helpers/Api'

//images
import brand from 'assets/img/app/brand-full.png'
import alertIcon from 'assets/img/verify/icons/alert-with-decor.png'

//components
import Footer from '../partials/Footer'

//providers
import { useAppContext } from 'providers/App'
import { useAuthDataContext } from 'providers/Auth'

function Index() {
    const app = useAppContext()
    const auth = useAuthDataContext()
    const [loading, setLoading] = useState(false)

    const handleResend = () => {
        setLoading(true)

        Api.post('auth/email/verify/send')
            .then(() => {
                app.handleSuccess('Писмо с връзка за потвърждение беше изпратен на Вашата е-поща!')
            })
            .catch(() => {
                app.handleError('Нещо се обърка! Моля, опитайте отново.')
            })
            .finally(() => {
                setLoading(false)
            })
    }

    function handleLogout(){
        auth.logout()
    }

    return (
        <section id="verify-fail">
            <div className="wrapper">
                <img src={brand} className="brand" />
                <img src={alertIcon} className="main-image" />
                <h1>
                    Потвърдете Вашият профил<br /> на електронната си поща
                </h1>
                <p className="description">
                    Посетете Вашият имейл, който посочихте при регистрация и потвърдете Вашият профил. След това директно ще може да влезете във Вашия профил и да стартирате издаването на документи.
                </p>
                <div className="buttons row">
                    <SaveButton
                        className="save"
                        loading={loading}
                        onClick={handleResend}
                    >
                        Повторно изпращане на писмо за потвърждение
                    </SaveButton>
                    <SaveButton
                        className="secondary"
                        loading={loading}
                        onClick={handleLogout}
                    >
                        Към начало
                    </SaveButton>
                </div>
                <Footer />
            </div>
        </section>
    )
}

export default Index