//components
import PreviewBillOptions from "./PreviewBillOptions"
import PreviewBillDocumentOptions from "./PreviewBillDocumentOptions"

//images
import checkIcon from "assets/img/accounts/icons/check.png"
import warningIcon from "assets/img/accounts/icons/warning.png"

//libraries
import moment from "moment"

export default function PreviewBill() {
  const isBillClosed = true

  return (
    <div className="content">
      <div className="row">
        <div className="col">
          <h6>Клиент</h6>
          <b>Кауфланд България ООД</b>
          <ul className="client-holder">
            <li>
              <span>ЕИК:</span>
              1234567890
            </li>
            <li>
              <span>МОЛ:</span>
              Константин Константинов
            </li>
            <li>
              <span>Град:</span>
              София
            </li>
            <li>
              <span>Адрес:</span>
              бул. Цариградско шосе 7
            </li>
          </ul>
        </div>
        <div className="col">
          <h6>Информация за продажбата</h6>
          <p>
            {`Продажба №1110056489 / ${moment().format(
              "DD.MM.YYYY, HH:mm ч."
            )}`}
          </p>
          <div className="bill-status">
            {isBillClosed ? (
              <>
                <img src={checkIcon} alt="" />
                Продажбата е приключена
              </>
            ) : (
              <>
                <img src={warningIcon} alt="" />
                Продажбата не е приключена
              </>
            )}
          </div>
          <PreviewBillOptions isBillClosed={isBillClosed} />
        </div>
        <div className="col">
          <h6>Издадени документи</h6>
          <PreviewBillDocumentOptions />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <h6>Артикули</h6>
          <table className="type-doc">
            <thead>
              <tr>
                <th className="number">№</th>
                <th className="product">Артикул</th>
                <th>От склад</th>
                <th className="quantity">К-во</th>
                <th className="single-price">Ед. цена</th>
                <th className="discount">Отстъпка</th>
                <th className="price text-right">Тотал</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="number">1</td>
                <td className="product">Чехли Puma с еко кожa - 45 номер</td>
                <td>123</td>
                <td className="quantity">123</td>
                <td className="single-price">568.00 лв.</td>
                <td className="discount">12%</td>
                <td className="price text-right">2 568.00 лв.</td>
              </tr>
              <tr>
                <td className="number">1</td>
                <td className="product">Чехли Puma с еко кожa - 45 номер</td>
                <td>123</td>
                <td className="quantity">123</td>
                <td className="single-price">568.00 лв.</td>
                <td className="discount">12%</td>
                <td className="price text-right">2 568.00 лв.</td>
              </tr>
            </tbody>
          </table>
          <hr />
          <table
            style={{
              width: "300px",
              marginLeft: "auto",
              marginTop: "auto",
            }}
          >
            <tbody>
              <tr>
                <td className="text-right">Сума</td>
                <td
                  className="bold text-right"
                  style={{
                    maxWidth: "150px",
                  }}
                >
                  2 568.00 лв.
                </td>
              </tr>
              <tr>
                <td className="text-right">ДДС</td>
                <td
                  className="bold text-right"
                  style={{
                    maxWidth: "150px",
                  }}
                >
                  24.00 лв.
                </td>
              </tr>
              <tr>
                <td className="text-right">Отстъпка</td>
                <td
                  className="bold text-right"
                  style={{
                    maxWidth: "150px",
                  }}
                >
                  12%
                </td>
              </tr>
              <tr>
                <td className="text-right">Сума за плащане</td>
                <td
                  className="bold text-right"
                  style={{
                    maxWidth: "150px",
                  }}
                >
                  2 568.00 лв.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
