import Middleware from './Middleware';

class Guest extends Middleware {
    run(props, next) {
        if (this.auth.isLogged()) {
            // this.history.goBack();
            return this.redirect('/');
        }

        return next(this.app);
    }
}

export default Guest;