import { forwardRef, useEffect, useImperativeHandle, useRef, useState, Fragment } from 'react'

//plugins
import { Button, Checkbox, FormControlLabel, MenuItem } from '@material-ui/core'

//helpers
import Api from 'helpers/Api'
import { useValidation } from 'helpers/Validation'
import { useNestedState } from 'helpers/NestedState'
import { getPaymentPeriods as pp } from 'helpers/Subscriptions'

//providers
import { useLanguageContext } from 'providers/Language'

//misc
import SaveButton from 'components/misc/Button'
import RedditTextField from 'components/misc/RedditTextField'
import RedditSelect from 'components/misc/RedditSelect'
import { plural, trans } from 'providers/Translation'
import moment from 'moment'


function AddOrEdit(props, ref) {

    const langs = useLanguageContext()

    const defaultData = {
        period_type: null,
        calendar_type: 1
    }

    const [state, setState] = useNestedState({
        show: false,
        id: null,
        edit: false,
        data: defaultData,
        tabId: langs.data[0]?.id,
        loading: false,
        onSuccess: null,
        onEntering: null,
        onExiting: null
    })

    const [validations, setValidations] = useValidation()

    const validationModalRef = useRef(null)
    const formRef = useRef(null)

    useImperativeHandle(ref, () => ({
        add: () => {
            handleShow()
        },
        edit: (id) => {
            edit(id)
        },
        hide: () => {
            hide()
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }))
        }
    }))

    useEffect(() => {
        loadData()
    }, [state.edit])

    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }))

        onEntering?.()
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }))

        onExiting?.()
    }

    const handleShow = () => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
            },
            id: null,
        }))

        show()
    }

    const handleClose = e => {

        if (e) {
            e.preventDefault()
        }

        setState(prev => ({
            ...prev,
            data: defaultData,
            id: null,
        }))

        setValidations(null)

        hide()
    }

    const edit = id => {
        setState(prev => ({
            ...prev,
            id: id,
            edit: moment().unix()
        }))

        show()
    }

    const loadData = () => {
        if (!state.id) {
            return
        }

        Api.get('store/subscriptions/types/show?id=' + state.id)
            .then(res => {

                let data = res.data

                setState(prev => ({
                    ...prev,
                    data: data
                }))
            })
    }


    const handleSave = () => {
        if (state.loading) {
            return
        }

        setState(prev => ({
            ...prev,
            loading: true,
        }))

        setValidations(null)

        let url = state.id ? 'store/subscriptions/types/edit' : 'store/subscriptions/types/add'

        let data = new FormData(formRef.current)

        if (state.id) {
            data.append('id', state.id)
        }

        Api.post(url, data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(res => {
            if (typeof state.onSuccess === 'function') {
                state.onSuccess(res.data)
            }

            handleClose()
        }).catch(error => {
            const _err = error.response

            if (_err && _err.status && _err.status === 422) {
                setValidations(_err.data.errors)
            }

        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false,
            }))
        })
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(name, value, 'data')
    }

    const handlePriceChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                price: value,
            }
        }));

        updatePriceInputs(value);
    }

    const updatePriceInputs = (price) => {
        let periods = getPaymentPeriods();

        let avg = periods.length > 0
            ? Math.round(price / periods.length)
            : 0;

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                periods: (prev.data.periods || []).map((p, i) => {
                    p.price = avg;

                    return p;
                })
            }
        }))
    }

    const handlePriceInputChange = (index, value) => {
        // state.data.periods?.[i]?.price

        let sum = 0;

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                periods: (prev.data.periods || []).map((p, i) => {
                    if (i === index) {
                        p.price = value;
                    }

                    sum += Number(p.price);

                    return p;
                }),
                price: sum,
            }
        }))
    }

    const handleCheckboxChange = e => {
        const name = e.target.name

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: prev.data[name] ? false : true
            }
        }))
    }

    const handleDateChange = e => {
        const name = e.target.name + '_ymd'
        const value = e.target.value

        setState(name, value, 'data')
    }

    const handleTabChange = id => {
        setState(prev => ({
            ...prev,
            tabId: Number(id)
        }))
    }

    const getPaymentPeriodTypes = () => {
        let periods = [
            {
                id: 1,
                name: 'ден'
            },
            {
                id: 2,
                name: 'седмица',
            },
            {
                id: 3,
                name: 'месец'
            },
            {
                id: 4,
                name: 'година'
            },
        ];

        // ден
        if (state.data.period_type === 1) {
            periods = periods.filter(p => p.id === 1);
        }

        // седмица
        if (state.data.period_type === 2) {
            periods = periods.filter(p => p.id <= 2);
        }

        // месец
        if (state.data.period_type === 3) {
            periods = periods.filter(p => p.id === 2 || p.id === 3);
        }

        // година
        if (state.data.period_type === 4) {
            periods = periods.filter(p => p.id === 2 || p.id === 3 || p.id === 4);
        }

        periods.push({
            id: 5,
            name: 'еднократно'
        })

        return periods;
    }

    const getPaymentPeriods = () => {
        return pp(state.data.calendar_type, state.data.payment_period_type, state.data.period, state.data.period_type);
    }

    // console.log(state.data)

    return (
        <>

            <div className={`${state.show ? "visible" : ""} overlay`}>
                <div
                    className={`popup-primary medium ${state.show ? "show" : ""}`}
                >
                    <div className="body">
                        <h3>
                            Тип абонамент {state.data?.id && '(ID: ' + state.data.id + ')'}
                        </h3>
                        <br />
                        <form onSubmit={handleSave} ref={formRef}>
                            <div className="row">
                                <div className="col">
                                    <RedditTextField
                                        size="small"
                                        variant="filled"
                                        margin="dense"
                                        label="Име"
                                        name="name"
                                        value={state.data.name || ''}
                                        onChange={handleInputChange}
                                        error={Boolean(validations && validations.name)}
                                        helperText={validations && validations.name && (validations.name[0] || validations.name)}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <RedditTextField
                                        size="small"
                                        variant="filled"
                                        margin="dense"
                                        type="number"
                                        min="0"
                                        step="0.01"
                                        label="Цена"
                                        name="price"
                                        value={state.data.price || ''}
                                        onChange={handlePriceChange}
                                        error={Boolean(validations && validations.price)}
                                        helperText={validations && validations.price && (validations.price[0] || validations.price)}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <RedditTextField
                                        size="small"
                                        variant="filled"
                                        margin="dense"
                                        type="number"
                                        min="0"
                                        step="1"
                                        label="Продължителност"
                                        name="period"
                                        value={state.data.period || ''}
                                        onChange={handleInputChange}
                                        error={Boolean(validations && validations.period)}
                                        helperText={validations && validations.period && (validations.period[0] || validations.period)}
                                    />
                                </div>
                                <div className="col">
                                    <RedditSelect
                                        size="small"
                                        variant="filled"
                                        margin="dense"
                                        label="Период"
                                        name="period_type"
                                        value={state.data.period_type || ''}
                                        onChange={handleInputChange}
                                        error={Boolean(validations && validations.period_type)}
                                        helperText={validations && validations.period_type && (validations.period_type[0] || validations.period_type)}

                                    >

                                        <MenuItem value={1}>
                                            {plural('dates.plural_periods.day', state.data?.period || 1)}
                                        </MenuItem>
                                        <MenuItem value={2}>
                                            {plural('dates.plural_periods.week', state.data?.period || 1)}
                                        </MenuItem>
                                        <MenuItem value={3}>
                                            {plural('dates.plural_periods.month', state.data?.period || 1)}
                                        </MenuItem>
                                        <MenuItem value={4}>
                                            {plural('dates.plural_periods.year', state.data?.period || 1)}
                                        </MenuItem>
                                    </RedditSelect>
                                </div>

                            </div>
                            <div className="row">
                                <div className="col">
                                    <RedditSelect
                                        size="small"
                                        variant="filled"
                                        margin="dense"
                                        label="Валидност"
                                        name="calendar_type"
                                        value={state.data.calendar_type || ''}
                                        onChange={handleInputChange}
                                        error={Boolean(validations && validations.calendar_type)}
                                        helperText={validations && validations.calendar_type && (validations.calendar_type[0] || validations.calendar_type)}

                                    >
                                        <MenuItem value={1}>
                                            от момента на активация
                                        </MenuItem>
                                        <MenuItem value={2}>
                                            календарен период
                                        </MenuItem>
                                    </RedditSelect>
                                </div>
                                <div className="col">
                                    <RedditSelect
                                        size="small"
                                        variant="filled"
                                        margin="dense"
                                        label="Период за плащане"
                                        name="payment_period_type"
                                        value={state.data.payment_period_type || ''}
                                        onChange={handleInputChange}
                                        error={Boolean(validations && validations.payment_period_type)}
                                        helperText={validations && validations.payment_period_type && (validations.payment_period_type[0] || validations.payment_period_type)}

                                    >
                                        {getPaymentPeriodTypes().map(p =>
                                            <MenuItem key={p.id} value={p.id}>
                                                {p.name}
                                            </MenuItem>
                                        )}
                                    </RedditSelect>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <table className="type-outer">
                                        <thead>
                                            <tr>
                                                <th style={{ width: '100px', textAlign: 'right' }}>
                                                    Период
                                                </th>
                                                <th>
                                                    Цена
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {getPaymentPeriods().map((p, i) =>
                                                <tr key={p.id}>
                                                    <td style={{ textAlign: 'right' }}>
                                                        {p.name}
                                                    </td>
                                                    <td>
                                                        <RedditTextField
                                                            type="number"
                                                            step="0.01"
                                                            size="small"
                                                            variant="standard"
                                                            margin="dense"
                                                            name={`periods[${i}][price]`}
                                                            value={state.data.periods?.[i]?.price || ''}
                                                            onChange={e => handlePriceInputChange(i, e.target.value)}
                                                            error={Boolean(validations && validations.periods && validations.periods[i] && validations.periods[i].price)}
                                                            helperText={validations && validations.periods && validations.periods[i] && validations.periods[i].price && (validations.periods[i].price[0] || validations.periods[i].price)}
                                                        />
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="footer">
                        <Button className="cancel" onClick={handleClose}>
                            Отказ
                        </Button>
                        <SaveButton
                            loading={state.loading}
                            onClick={handleSave}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default forwardRef(AddOrEdit)
