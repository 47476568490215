export const DOCUMENT_COLUMNS = [
    {
        name: 'Тип',
        className: null
    }, {
        name: '№',
        className: null
    }, {
        name: 'Дата',
        className: null
    }, {
        name: 'Получател',
        className: null
    }, {
        name: 'Общо',
        className: 'text-right'
    }, {
        name: 'Платено',
        className: 'text-center'
    },
    // {
    //     name: 'Опции',
    //     className: 'text-center'
    // }
]