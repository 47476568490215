import { forwardRef, useImperativeHandle, useState } from 'react'
import closeIcon from 'assets/img/modals/icons/close.png'
import TextField from '@material-ui/core/TextField'
import { Box, Button } from '@material-ui/core'
import SaveButton from 'components/misc/Button'
import Api from 'helpers/Api'

// този компонент не се използва
function Index(props, ref) {
    const [state, setState] = useState({
        overlay: false,
        modal: false,
        providerId: null,
        onSuccess: null
    })

    const [validations, setValidations] = useState(null)

    useImperativeHandle(ref, () => ({
        show: providerId => {
            handleShow(providerId)
        },
        hide: () => {
            handleHide()
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }))
        }
    }))

    const handleShow = providerId => {
        setState(prev => ({
            ...prev,
            overlay: true,
            providerId
        }));

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                overlay: true,
                modal: true
            }));
        }, 50);

        setValidations(null)
    }

    const handleHide = e => {
        e.preventDefault();
        setLoading(false)
        setState(prev => ({
            ...prev,
            modal: false,
        }))

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                overlay: false,
                modal: false
            }))
        }, 50)
    }

    const handleSave = e => {
        e.preventDefault();

        setLoading(true)
        setValidations(null)

        const url = 'e-services/user/set-credentials'

        const data = new FormData(e.target)

        data.append('provider_id', state.providerId)

        Api.post(url, data)
            .then(res => {
                if (typeof props.onSuccess === 'function') props.onSuccess(res.data)

                handleHide(e)
            })
            .catch(error => {

                const _err = error.response

                if (_err && _err.status && _err.status === 422) {
                    for (let [input, err] of Object.entries(_err.data.errors)) {
                        setValidations(prevState => ({
                            ...prevState,
                            [input]: true
                        }))
                    }
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const setLoading = loading => {
        setState(prev => ({
            ...prev,
            loading
        }))
    }

    return (
        <div className={`${state.overlay ? 'visible' : ''} overlay`}>
            <form className={`${state.modal ? 'show' : ''} popup-primary medium`} noValidate onSubmit={handleSave}>
                <h2 className="head">
                    Моля, въведете вашите данни
                    <img className="close-icon" alt="close" src={closeIcon} onClick={handleHide} />
                </h2>

                <div className="body">
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="client_id"
                        label="Client ID"
                        name="client_id"
                        autoComplete="client_id"
                        error={validations && validations.client_id}
                        autoFocus
                    />

                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="client_secret"
                        label="Client Secret"
                        name="client_secret"
                        autoComplete="client_secret"
                        error={validations && validations.client_secret}
                        autoFocus
                    />
                </div>

                <div className="footer">
                    <Button
                        className="cancel"
                        onClick={handleHide}
                    >
                        Отказ
                    </Button>
                    <SaveButton
                        loading={state.loading}
                        className="save"
                    />
                </div>
            </form>
        </div>
    )
}

export default forwardRef(Index);