import { useRef } from "react"

//libraries
import { useQuery as uQ } from 'react-query'
import axios from "axios"

//helpers
import Api from "helpers/Api"

function useFetchCompaniesFromTradeRegistry({ fetchUrl = null, inputValue = "", disableFetch = false }) {
    const reqRef = useRef(null)
    const params = {
        name: inputValue,
        page: 1,
    }

    const { data, isLoading, refetch } = uQ(['companiesFromTradeRegistry', params], loadData, {
        staleTime: 100,
    })

    async function loadData() {
        if (disableFetch) return

        if (!fetchUrl) {
            console.error("fetchUrl is null")

            return
        }

        if (reqRef.current) reqRef.current.cancel()
        reqRef.current = axios.CancelToken.source()

        const res = await Api.get(fetchUrl, {
            params,
            cancelToken: reqRef.current?.token
        })
        
        reqRef.current = null

        return res.data
    }

    function getCompaniesFromTradeRegistry() {
        return data
    }

    return {
        loadingCompaniesFromTradeRegistry: isLoading,
        getCompaniesFromTradeRegistry,
    }
}

export default useFetchCompaniesFromTradeRegistry