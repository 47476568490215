import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

import { MenuItem } from '@material-ui/core'

//helpers
import Api from 'helpers/Api'
import { getPaymentPeriods as pp } from 'helpers/Subscriptions'

//providers
import { useSubscriptionContext } from '../Provider'
import { useAppContext } from 'providers/App'
import { plural } from 'providers/Translation'

//misc
import Autocomplete from 'components/misc/Autocomplete'
import Refs from 'Refs'
import RedditTextField from 'components/misc/RedditTextField'
import RedditSelect from 'components/misc/RedditSelect'

function Index(props, ref) {

    const app = useAppContext()
    const context = useSubscriptionContext()

    const { state, setState } = context
    const { validations, setValidations } = context

    const [types, setTypes] = useState([])

    useImperativeHandle(ref, () => ({
        save: (e) => {
            handleSave(e)
        },
    }))

    const formRef = useRef(null)

    useEffect(() => {
        loadData()
    }, [props.id])

    useEffect(() => {
        Api.get('store/subscriptions/types')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setTypes(res.data)
                }
            })
    }, [])


    const loadData = () => {
        if (!props.id) {
            return
        }

        if (state.data.id === props.id) {
            return
        }

        Api.get('store/subscriptions/show?id=' + props.id)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    data: {
                        ...prev.data,
                        ...res.data,
                    },
                    initialData: res.data,
                }))
            })
    }

    const handleSave = e => {
        if (e) e.preventDefault()

        setState(prev => ({
            ...prev,
            loading: true,
        }))

        setValidations(null)

        let url = state.data.id ? 'store/subscriptions/edit' : 'store/subscriptions/add'

        let data = new FormData(formRef.current)

        if (state.data.id) {
            data.append('id', state.data.id)
        }

        Api.post(url, data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(res => {
            if (typeof props.onSuccess === 'function') {
                props.onSuccess(res.data)
            }

            app.showSuccess()
        }).catch(error => {
            const _err = error.response

            if (_err && _err.status && _err.status === 422) {

                setValidations(_err.data.errors)

                // props.hide(() => {
                //     let modal = Refs.getInstance().getRef('info')

                //     if (modal) {
                //         modal.open('Моля попълнете всички полета означени с червено')

                //         modal.onClose(() => {
                //             props.show()
                //         })
                //     }
                // })
            } else {
                app.showError()
            }
        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false,
            }))
        })
    }

    const setMainTab = key => {
        setState(prev => ({
            ...prev,
            mainTabId: key
        }))
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(name, value, 'data')
    }

    const handlePriceChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                price: value,
            }
        }));

        updatePriceInputs(value);
    }

    const handlePriceInputChange = (index, value) => {
        // state.data.periods?.[i]?.price

        let sum = 0;

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                periods: (prev.data.periods || []).map((p, i) => {
                    if (i === index) {
                        p.price = value;
                    }

                    sum += Number(p.price);

                    return p;
                }),
                price: sum,
            }
        }))
    }

    const handleCheckboxChange = e => {
        const name = e.target.name

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: prev.data[name] ? false : true
            }
        }))
    }

    const handleDateChange = e => {
        const name = e.target.name + '_ymd'
        const value = e.target.value

        setState(name, value, 'data')
    }

    const handleTypeChange = (e) => {
        let id = e.target.value

        let type = types.find(type => type.id === id)

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                type_id: type?.id || null,
                price: type ? type.price : prev.data.price,
                type: type,
                periods: type.periods.map(p => {
                    return {
                        price: p.price,
                    }
                })
            }
        }))
    }

    const getPaymentPeriods = () => {
        return pp(state.data.type?.calendar_type, state.data.type?.payment_period_type, state.data.type?.period, state.data.type?.period_type);
    }

    const updatePriceInputs = (price) => {
        let periods = getPaymentPeriods();

        let avg = periods.length > 0
            ? Math.round(price / periods.length)
            : 0;

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                periods: prev.data.periods.map((p, i) => {
                    p.price = avg;

                    return p;
                })
            }
        }))
    }


    return (
        <form ref={formRef} onSubmit={handleSave} style={{ width: '100%' }}>
            <div className="row">
                <div className="col">
                    <h6>
                        Данни
                    </h6>
                    <div className="field">
                        <div className="row">
                            <div className="col">
                                <RedditSelect
                                    size="small"
                                    variant="filled"
                                    margin="dense"
                                    label="Тип"
                                    name="type_id"
                                    value={state.data.type_id || ''}
                                    onChange={handleTypeChange}
                                    error={Boolean(validations && validations.type_id)}
                                    helperText={validations && validations.type_id && (validations.type_id[0] || validations.type_id)}

                                >
                                    {types.map(type =>
                                        <MenuItem value={type.id}>
                                            {type.name} ({type.period_text})
                                        </MenuItem>
                                    )}
                                </RedditSelect>
                            </div>
                            <div
                                className="col"
                                style={{
                                    maxWidth: '100px'
                                }}
                            >
                                <RedditTextField
                                    size="small"
                                    variant="filled"
                                    margin="dense"
                                    type="number"
                                    min="0"
                                    step="0.01"
                                    label="Цена"
                                    name="price"
                                    value={state.data.price || ''}
                                    onChange={handlePriceChange}
                                    error={Boolean(validations && validations.price)}
                                    helperText={validations && validations.price && (validations.price[0] || validations.price)}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <Autocomplete
                                    variant="filled"
                                    fullWidth
                                    inputPlaceholder="Клиент"
                                    inputName="customer_name"
                                    inputIdName="customer_id"
                                    url="customers/all"
                                    selected={state.data?.customer || null}
                                    selectedId={state.clientId}
                                    freeSolo={false}
                                    getOptionLabel={option => option?.info?.name || option?.name || ''}
                                    renderOption={option => option?.info?.name || option?.name || ''}
                                    error={Boolean(validations && validations.customer_id)}
                                    helperText={validations && validations.customer_id && (validations.customer_id[0] || validations.customer_id)}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <RedditTextField
                                    fullWidth
                                    label="Описание"
                                    name="description"
                                    value={state.data.description || ''}
                                    multiline
                                    rows="3"
                                    onChange={handleInputChange}
                                    error={Boolean(validations && validations.description)}
                                    helperText={validations && validations.description && (validations.description[0] || validations.description)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {getPaymentPeriods().length > 0 &&
                <div className="row">
                    <div className="col">
                        <h6>
                            Периоди за плащане
                        </h6>
                        <div className="field">
                            <div className="row">
                                <div className="col">
                                    <table className="type-outer">
                                        <thead>
                                            <tr>
                                                <th style={{ width: '100px', textAlign: 'right' }}>
                                                    Период
                                                </th>
                                                <th>
                                                    Цена
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {getPaymentPeriods().map((p, i) =>
                                                <tr key={p.id}>
                                                    <td style={{ textAlign: 'right' }}>
                                                        {p.name}
                                                        <input type="hidden" name={`periods[${i}][id]`} value={state.data.periods?.[i]?.id || ''} />
                                                    </td>
                                                    <td>
                                                        <RedditTextField
                                                            type="number"
                                                            step="0.01"
                                                            size="small"
                                                            variant="standard"
                                                            margin="dense"
                                                            name={`periods[${i}][price]`}
                                                            value={state.data.periods?.[i]?.price || ''}
                                                            onChange={e => handlePriceInputChange(i, e.target.value)}
                                                            error={Boolean(validations && validations.periods && validations.periods[i] && validations.periods[i].price)}
                                                            helperText={validations && validations.periods && validations.periods[i] && validations.periods[i].price && (validations.periods[i].price[0] || validations.periods[i].price)}
                                                        />
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }

            <div className="row">
                <div className="col">
                    <h6>
                        Напомняне
                    </h6>
                    <div className="field">
                        <div className="row">
                            <div className="col">
                                <RedditTextField
                                    size="small"
                                    variant="filled"
                                    margin="dense"
                                    type="number"
                                    min="0"
                                    step="1"
                                    label="Кога"
                                    name="reminder_period"
                                    value={state.data.reminder_period || ''}
                                    onChange={handleInputChange}
                                    error={Boolean(validations && validations.reminder_period)}
                                    helperText={validations && validations.reminder_period && (validations.reminder_period[0] || validations.reminder_period)}
                                />
                            </div>
                            <div className="col">
                                <RedditSelect
                                    size="small"
                                    variant="filled"
                                    margin="dense"
                                    label="Период"
                                    name="reminder_period_type"
                                    value={state.data.reminder_period_type || ''}
                                    onChange={handleInputChange}
                                    error={Boolean(validations && validations.reminder_period_type)}
                                    helperText={validations && validations.reminder_period_type && (validations.reminder_period_type[0] || validations.reminder_period_type)}

                                >

                                    <MenuItem value={1}>
                                        {plural('dates.plural_periods.day', state.data?.reminder_period || 1)}
                                    </MenuItem>
                                    <MenuItem value={2}>
                                        {plural('dates.plural_periods.week', state.data?.reminder_period || 1)}
                                    </MenuItem>
                                    <MenuItem value={3}>
                                        {plural('dates.plural_periods.month', state.data?.reminder_period || 1)}
                                    </MenuItem>
                                </RedditSelect>
                            </div>
                        </div>
                        преди изтичане на абонамента
                    </div>
                </div>

            </div>
        </form>
    )
}

export default forwardRef(Index)