import Export from "components/modals/Export";
import Print from "components/modals/Print";
import { buildUrl } from "helpers/Url";
import { useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { Download, LayoutThreeColumns, PrinterFill, Table } from "react-bootstrap-icons";
import printJS from 'print-js'
import Columns from "components/modals/Columns";
import { useAuthDataContext } from "providers/Auth";
import SaveButton from 'components/misc/Button';
import { useAppContext } from "providers/App";
import { baseUrl } from "helpers/mode";

function TableOptions(props) {
    const auth = useAuthDataContext();
    const app = useAppContext();

    const exportModalRef = useRef();
    const printModalRef = useRef();
    const columnsModalRef = useRef();

    const [isPrintLoading, setIsPrintLoading] = useState(false);

    const handleExport = e => {
        e.preventDefault();

        let modal = exportModalRef.current;

        if (!modal) {
            return;
        }

        if (typeof props.onExport === 'function') {
            props.onExport();
        }

        modal.open();
    }

    // const handlePrint = e => {
    //     e.preventDefault();

    //     let modal = printModalRef.current;

    //     if (!modal) {
    //         return;
    //     }

    //     modal.open();
    // }

    const handlePrint = e => {
        e.preventDefault();

        let url = baseUrl + props.url;
        let filter = props.filter;

        let full = buildUrl(url, {
            ...filter,
            type: 'print-pdf',
            export: 1,
            token: auth.getToken()
        });

        // console.log(full);

        printJS({
            printable: full,
            onLoadingStart: () => {
                setIsPrintLoading(true);
            },
            onLoadingEnd: () => {
                setIsPrintLoading(false);
            },
            onError: () => {
                app.showError('Възникна грешка при заявката за принтиране');
            },
            onIncompatibleBrowser: () => {
                app.showError('Вашият браузър не поддържа тази функционалност');
            }
        });
    }

    const handleChangeColumns = e => {
        e.preventDefault();

        let modal = columnsModalRef.current;

        if (!modal) {
            return;
        }

        modal.open();

        modal.onSuccess(() => {
            props.refresh();
        });
    }

    return (
        <>
            <Export
                ref={exportModalRef}
                url={props.url}
                filter={props.filter}
            />

            {/* <Print
                ref={printModalRef}
                url={props.url}
                filter={props.filter}
            /> */}

            <Columns
                ref={columnsModalRef}
                tableKey={props.tableKey}
                columns={props.columns.all}
                details={props.columns.details}
                selected={props.columns.selected}
                description={props.columns.description}
                sortableColumns={props.columns.sortable}
                sortKey={props.columns.sort}
                sortDir={props.columns.order}
                limit={props.columns.limit}
            />

            <div className="table-options">
                {props.children &&
                    <div style={{ marginRight: '10px' }}>
                        {props.children}
                    </div>
                }

                <div className="btn-group" role="group">
                    <SaveButton
                        variant="dark"
                        // size="sm"
                        loading={isPrintLoading}
                        onClick={handlePrint}
                    >
                        <PrinterFill />
                    </SaveButton>
                    <Button
                        variant="dark"
                        // size="sm"
                        style={{
                            // marginLeft: '5px'
                        }}
                        onClick={handleExport}
                    >
                        <Download />
                    </Button>
                    <Button
                        variant="dark"
                        // size="sm"
                        style={{
                            // marginLeft: '5px'
                        }}
                        onClick={handleChangeColumns}
                    >
                        <LayoutThreeColumns />
                    </Button>
                </div>
            </div>
        </>
    )
}

export default TableOptions;