import { useState } from 'react'
import { Link } from 'react-router-dom'
import ClickAwayListener from 'react-click-away-listener'

//providers
import { useAuthDataContext } from 'providers/Auth'

//images
import pointerIcon from 'assets/img/header/icons/pointer.png'

//MUI components
import Tooltip from '@material-ui/core/Tooltip'

function User() {
    const auth = useAuthDataContext()

    const [state, setState] = useState({
        show: false
    })

    const showMenu = show => {
        setState(prev => ({
            ...prev,
            show
        }))
    }

    return (

        <div
            className="user"
            onClick={() => {
                showMenu(!state.show)
            }}
        >
            <Tooltip
                title="Моето меню"
            >
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <img src={auth.getUser()?.getAvatar() || ''} className="profile-pic" />
                    <p>
                        {auth.getUser()?.getName()}
                    </p>
                </div>
            </Tooltip>
            <img src={pointerIcon} />
            {state.show ?
                <ClickAwayListener
                    onClickAway={() => {
                        showMenu(false)
                    }}
                >
                    <div className="user-list">
                        <div className="head">
                            <h3>
                                Моето меню
                            </h3>
                        </div>
                        <ul>
                            <li>
                                <Tooltip
                                    title="Към моите моят профил"
                                >
                                    <Link to="/settings/profile">
                                        Моят профил
                                    </Link>
                                </Tooltip>
                            </li>
                            <li>
                                <Tooltip
                                    title="Към настройки"
                                >
                                    <Link to="/settings/numbers">
                                        Настройки
                                    </Link>
                                </Tooltip>
                            </li>
                            <li className="logout">
                                <Tooltip
                                    title="Изход от Вашият профил"
                                >
                                    <Link to="/logout">
                                        Изход
                                    </Link>
                                </Tooltip>
                            </li>
                        </ul>
                    </div>
                </ClickAwayListener>
                :
                <>
                </>
            }
        </div>
    )
}

export default User
