//libraries
import moment from "moment"

//constants
import { ADD_OPTIONS_TYPES } from "constants/calendar"

export function getStartHours() {
    let startHour = "00:00"
    const startHoursArr = [startHour]

    for (let i = 0; i < 47; i++) {
        startHour = moment(startHour, "HH:mm").add(30, "minutes").format("HH:mm")

        startHoursArr.push(startHour)
    }

    return startHoursArr
}

// end hours need to start from the selected start hour
export function getEndHours(selectedStartHour) {
    let startHour = selectedStartHour
    const startHoursArr = []

    for (let i = 0; i < 47; i++) {
        startHour = moment(startHour, "HH:mm").add(30, "minutes").format("HH:mm")
        if (startHour === "00:00") break

        startHoursArr.push(startHour)
    }

    return startHoursArr
}


// rounds the current hour to the nearest half hour
export function roundCurrentHourToNearestHalfHour(hour = null) {
    const currentHour = hour || moment().format("HH:mm")
    const currentHourMinutes = parseInt(currentHour.split(":")[1])

    if (currentHourMinutes < 30) {
        return moment(currentHour, "HH:mm").set("minute", 30).format("HH:mm")
    }

    return moment(currentHour, "HH:mm").set("minute", 0).add(1, "hour").format("HH:mm")
}


export function getSelectedEventType(eventType) {
    return getAddOptionsTypes().find(({ type }) => type === eventType)
}

export function getAddOptionsTypes() {
    const addOptionsTypes = []

    Object.values(ADD_OPTIONS_TYPES).map((option) => {
        if (option.sublist) addOptionsTypes.push(...option.sublist)

        addOptionsTypes.push(option)

        return option
    })

    return addOptionsTypes.filter(({ sublist }) => !sublist)
}