import Middleware from './Middleware';

class Authed extends Middleware {

    run(props, next) {
        const redirect = this.query.get('redirect') || props.location.pathname + props.location.search;
        const loginUrl = '/login?redirect=' + redirect;

        // console.log(this.auth.isLogged())

        if (!this.auth.isLogged()) {
            return this.redirect(loginUrl);
        }

        return next(this.app);


    }
}

export default Authed;