import { useState } from 'react'

//MUI components
import { Button, Menu, Tooltip } from '@material-ui/core'

//images
import settingsIcon from 'assets/img/app/icons/settings.png'

//images
import MenuIndicator from 'components/misc/MenuIndicator'

function MenuOptions({ placement, id, className, children, enableRightClick, hoverTooltipText = "Показване на опции", iconColor = "#aeb7c9", containsNestedLists = false }) {
    const [anchorEl, setAnchorEl] = useState(null)

    const handleClick = e => {
        setAnchorEl(e.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    return (
        <div key={id} className={className}>
            <Tooltip
                title={hoverTooltipText}
            >
                <Button
                    onClick={handleClick}
                    className="options-menu-button"
                    data-id={id}
                >
                    <MenuIndicator
                        active={Boolean(anchorEl)}
                        iconColor={iconColor}
                    />
                </Button>
            </Tooltip>
            <Menu
                disableScrollLock={false}
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                onClick={e => {
                    if (e.target.classList.contains('print-button-layout')) return

                    handleClose()
                }}
                className={`${containsNestedLists ? 'with-nested-lists' : ""} custom-menu-options ${enableRightClick ? 'with-right-click' : ''} id-${id} ${Boolean(anchorEl) ? 'menu-is-opened' : ''}`}
                anchorOrigin={{ vertical: placement || 'bottom' }}
            >
                {children}
            </Menu>
        </div>
    )
}

export default MenuOptions