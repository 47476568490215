import MenuItem from '@material-ui/core/MenuItem'

//images
import editIcon from 'assets/img/app/icons/edit.png'
import deleteIcon from 'assets/img/app/icons/delete.png'

function Options(props) {
    return (
        <>
            <MenuItem onClick={e => props.handleShow(props.data)}>
                <img src={editIcon} />
                Преглед
            </MenuItem>
            <MenuItem onClick={e => props.handleEdit(props.data)}>
                <img src={editIcon} />
                Редакция
            </MenuItem>
            <MenuItem onClick={e => props.handleDelete(props.data)}>
                <img src={deleteIcon} />
                Изтриване
            </MenuItem>
        </>
    )
}

export default Options