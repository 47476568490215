import { forwardRef, useImperativeHandle, useRef } from "react"
import closeIcon from "../../../../assets/img/modals/icons/close.png"
import { Button } from "@material-ui/core"
import SaveButton from "../../../misc/Button"
import { useNestedState } from "../../../../helpers/NestedState"
import { useClientContext } from "../Provider"
import Form from "./Form"
import Vies from "./Vies"
import RedditTextField from "components/misc/RedditTextField"
import { useValidation } from "helpers/Validation"
import { CUSTOMER_TYPE } from "constants/Customer"
import Autocomplete from "components/misc/Autocomplete"
import Api from "helpers/Api"
import Skeleton from "react-loading-skeleton"
import moment from "moment"

function AddOrEdit({ clientId }, ref) {
  const [validations, setValidations] = useValidation()

  const [state, setState] = useNestedState({
    show: false,
    id: null,
    edit: false,
    refresh: false,
    loading: false,
    loadingData: false,
    onSuccess: null,
    onEntering: null,
    onExiting: null,
    onClose: null,
    data: {
      type_id: CUSTOMER_TYPE.COMPANY,
    },
  })

  const context = useClientContext() || {}

  useImperativeHandle(ref, () => ({
    add: () => {
      handleShow()
    },
    edit: (id) => {
      edit(id)
    },
    hide: () => {
      hide()
    },
    onSuccess: (fn) => {
      setState((prev) => ({
        ...prev,
        onSuccess: fn,
      }))
    },
    onClose: (fn) => {
      setState((prev) => ({
        ...prev,
        onClose: fn,
      }))
    },
  }))

  const formRef = useRef(null)
  const viesModalRef = useRef(null)

  const show = (onEntering = null) => {
    setState((prev) => ({
      ...prev,
      show: true,
      onEntering: onEntering,
    }))
  }

  const hide = (onExiting = null) => {
    setState((prev) => ({
      ...prev,
      show: false,
      onExiting: onExiting,
      id: null,
      data: {
        type_id: CUSTOMER_TYPE.COMPANY,
      },
    }))
  }

  const handleShow = () => {
    show()
  }

  const handleHide = (e) => {
    e.preventDefault()

    close()
  }

  const close = () => {
    setState((prev) => ({
      ...prev,
      id: null,
    }))

    context?.resetData?.()

    if (typeof state.onClose === "function") {
      hide(state.onClose)
    } else {
      hide()
    }
  }

  const edit = (id) => {
    setState((prev) => ({
      ...prev,
      id: id,
      edit: moment().unix(),
    }))

    _fetchData(id)

    show()
  }

  function _fetchData(id) {
    setState((prev) => ({
      ...prev,
      loadingData: true,
    }))

    Api.get(`store/clients/objects/show?id=${id}`)
      .then((res) => {
        setState((prev) => ({
          ...prev,
          data: res.data,
        }))
      })
      .finally(() => {
        setState((prev) => ({
          ...prev,
          loadingData: false,
        }))
      })
  }

  const refresh = (reset = true) => {
    setState((prev) => ({
      ...prev,
      refresh: moment().unix(),
    }))
  }

  const onSuccess = (data) => {
    if (typeof state.onSuccess === "function") {
      state.onSuccess(data)
    }

    context?.resetData?.()

    hide()
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target

    setState(name, value, "data")
  }

  const handleCountryChange = (data, index) => {
    if (
      typeof data !== "object" ||
      Number(data?.id) !== Number(state.data?.country?.id)
    ) {
      setState((prev) => ({
        ...prev,
        data: {
          ...prev.data,
          district: null,
          municipality: null,
          settlement: null,
          area: null,
          street: null,
        },
      }))
    }

    setState((prev) => ({
      ...prev,
      data: {
        ...prev.data,
        country:
          typeof data === "object"
            ? data
            : {
                id: null,
                name: data,
              },
      },
    }))
  }

  const handleDistrictChange = (data, index) => {
    if (
      typeof data !== "object" ||
      Number(data?.id) !== Number(state.data?.district?.id)
    ) {
      setState((prev) => ({
        ...prev,
        data: {
          ...prev.data,
          municipality: null,
          settlement: null,
          area: null,
          street: null,
        },
      }))
    }

    setState((prev) => ({
      ...prev,
      data: {
        ...prev.data,
        district:
          typeof data === "object"
            ? data
            : {
                id: null,
                name: data,
              },
      },
    }))
  }

  const handleMunicipalityChange = (data, index) => {
    if (
      typeof data !== "object" ||
      Number(data?.id) !== Number(state.data?.municipality?.id)
    ) {
      setState((prev) => ({
        ...prev,
        data: {
          ...prev.data,
          settlement: null,
          area: null,
          street: null,
        },
      }))
    }

    setState((prev) => ({
      ...prev,
      data: {
        ...prev.data,
        municipality:
          typeof data === "object"
            ? data
            : {
                id: null,
                name: data,
              },
      },
    }))
  }

  const handleSettlementChange = (data, index) => {
    if (
      typeof data !== "object" ||
      Number(data?.id) !== Number(state.data?.settlement?.id)
    ) {
      setState((prev) => ({
        ...prev,
        data: {
          ...prev.data,
          area: null,
          street: null,
        },
      }))
    }

    setState((prev) => ({
      ...prev,
      data: {
        ...prev.data,
        settlement:
          typeof data === "object"
            ? data
            : {
                id: null,
                name: data,
              },
      },
    }))
  }

  const handleAreaChange = (data, index) => {
    if (
      typeof data !== "object" ||
      Number(data?.id) !== Number(state.data?.area?.id)
    ) {
      setState((prev) => ({
        ...prev,
        data: {
          ...prev.data,
          street: null,
        },
      }))
    }

    setState((prev) => ({
      ...prev,
      data: {
        ...prev.data,
        area:
          typeof data === "object"
            ? data
            : {
                id: null,
                name: data,
              },
      },
    }))
  }

  const handleStreetChange = (data, index) => {
    if (
      typeof data !== "object" ||
      Number(data?.id) !== Number(state.data?.street?.id)
    ) {
      setState((prev) => ({
        ...prev,
        data: {
          ...prev.data,
        },
      }))
    }

    setState((prev) => ({
      ...prev,
      data: {
        ...prev.data,
        street:
          typeof data === "object"
            ? data
            : {
                id: null,
                name: data,
              },
      },
    }))
  }

  const handleSave = () => {
    if (state.loading) {
      return
    }

    setState((prev) => ({
      ...prev,
      loading: true,
    }))

    setValidations(null)

    let url = state.id
      ? "store/clients/objects/edit"
      : "store/clients/objects/add"

    let data = new FormData(formRef.current)

    if (state.id) {
      data.append("id", state.id)
    }

    data.append("client_id", clientId)

    Api.post(url, data, {
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then((res) => {
        if (typeof state.onSuccess === "function") {
          state.onSuccess(res.data)
        }

        hide()
      })
      .catch((error) => {
        const _err = error.response

        if (_err && _err.status && _err.status === 422) {
          setValidations(_err.data.errors)
        }
      })
      .finally(() => {
        setState((prev) => ({
          ...prev,
          loading: false,
        }))
      })
  }

  return (
    <>
      <div className={`${state.show ? "visible" : ""} overlay`}>
        <div
          className={`popup-primary medium complex-modal trade-object-form-modal ${
            state.show ? "show" : ""
          }`}
        >
          <div className="header sky-blue">
            <b>{state.id ? "Редакция" : "Добавяне"} на адрес</b>
            <p>
              Lorem Ipsum е елементарен примерен
              <br /> текст, използван в печатарската
            </p>
          </div>

          {state.loadingData ? (
            <div
              style={{
                width: "100%",
                padding: "20px",
              }}
            >
              <Skeleton count={1} height={35} />
              <Skeleton count={1} height={35} />
              <Skeleton count={1} height={35} />
              <Skeleton count={1} height={35} />
              <Skeleton count={1} height={35} />
            </div>
          ) : (
            <form className="body" ref={formRef}>
              <input type="hidden" name="id" value={state.data?.id || ""} />
              <div className="col">
                <div className="row">
                  <div className="col">
                    <h6>
                      {" "}
                      {CUSTOMER_TYPE.COMPANY === state.data?.type_id
                        ? "Офис"
                        : "Адрес"}
                    </h6>
                    <div className="field">
                      <div className="row">
                        <div className="col">
                          <RedditTextField
                            margin="dense"
                            size="small"
                            fullWidth
                            label="Име"
                            name="name"
                            value={state.data.name || ""}
                            onChange={handleInputChange}
                            error={Boolean(
                              validations &&
                                validations.name &&
                                (validations.name[0] || validations.name)
                            )}
                            helperText={
                              validations &&
                              validations.name &&
                              (validations.name[0] || validations.name)
                            }
                            autofocus={!state.data?.id}
                          />
                        </div>
                        <div className="col">
                          <RedditTextField
                            margin="dense"
                            size="small"
                            fullWidth
                            label="МОЛ"
                            name="mol"
                            value={state.data.mol || ""}
                            onChange={handleInputChange}
                            error={Boolean(
                              validations &&
                                validations.mol &&
                                (validations.mol[0] || validations.mol)
                            )}
                            helperText={
                              validations &&
                              validations.mol &&
                              (validations.mol[0] || validations.mol)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <h6>Адрес</h6>
                    <div className="field">
                      <div className="row">
                        <div className="col no-border">
                          <Autocomplete
                            variant="filled"
                            inputPlaceholder="Държава"
                            inputName="country[name]"
                            inputIdName="country[id]"
                            url="countries/all"
                            selected={state.data.country}
                            error={Boolean(
                              validations &&
                                validations.country &&
                                (validations.country[0] || validations.country)
                            )}
                            helperText={
                              validations &&
                              validations.country &&
                              (validations.country[0] || validations.country)
                            }
                            onChange={(data) => handleCountryChange(data)}
                            onInputChange={(data) => handleCountryChange(data)}
                          />
                        </div>
                        <div className="col no-border">
                          <Autocomplete
                            variant="filled"
                            inputPlaceholder="Област"
                            inputName="district[name]"
                            inputIdName="district[id]"
                            url="districts/all"
                            params={{
                              country_id: state.data.country?.id,
                            }}
                            selected={state.data.district}
                            error={Boolean(
                              validations &&
                                validations.district &&
                                (validations.district[0] ||
                                  validations.district)
                            )}
                            helperText={
                              validations &&
                              validations.district &&
                              (validations.district[0] || validations.district)
                            }
                            onChange={(data) => handleDistrictChange(data)}
                            onInputChange={(data) => handleDistrictChange(data)}
                          />
                        </div>

                        <div className="col no-border">
                          <Autocomplete
                            variant="filled"
                            inputPlaceholder="Община"
                            inputName="municipality[name]"
                            inputIdName="municipality[id]"
                            url="municipalities/all"
                            params={{
                              district_id: state.data.district?.id,
                            }}
                            selected={state.data.municipality}
                            error={Boolean(
                              validations &&
                                validations.municipality &&
                                (validations.municipality[0] ||
                                  validations.municipality)
                            )}
                            helperText={
                              validations &&
                              validations.municipality &&
                              (validations.municipality[0] ||
                                validations.municipality)
                            }
                            onChange={(data) => handleMunicipalityChange(data)}
                            onInputChange={(data) =>
                              handleMunicipalityChange(data)
                            }
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col">
                          <Autocomplete
                            variant="filled"
                            inputPlaceholder="Населено място"
                            inputName="settlement[name]"
                            inputIdName="settlement[id]"
                            url="settlements/all"
                            params={{
                              municipality_id: state.data.municipality?.id,
                            }}
                            selected={state.data.settlement}
                            renderOption={(option) => {
                              return [
                                option.name,
                                option.municipality
                                  ? "общ. " + option.municipality.name
                                  : null,
                                option.municipality &&
                                option.municipality.district
                                  ? "обл. " + option.municipality.district.name
                                  : null,
                              ]
                                .filter((o) => {
                                  return o !== null
                                })
                                .join(", ")
                            }}
                            error={Boolean(
                              validations &&
                                validations.settlement &&
                                (validations.settlement[0] ||
                                  validations.settlement)
                            )}
                            helperText={
                              validations &&
                              validations.settlement &&
                              (validations.settlement[0] ||
                                validations.settlement)
                            }
                            onChange={(data) => handleSettlementChange(data)}
                            onInputChange={(data) =>
                              handleSettlementChange(data)
                            }
                          />
                        </div>

                        <div className="col">
                          <Autocomplete
                            variant="filled"
                            inputPlaceholder="Район"
                            inputName="area[name]"
                            inputIdName="area[id]"
                            url="areas/all"
                            params={{
                              settlement_id: state.data?.settlement?.id,
                            }}
                            selected={state.data?.area}
                            error={Boolean(
                              validations &&
                                validations.area &&
                                (validations.area[0] || validations.area)
                            )}
                            helperText={
                              validations &&
                              validations.area &&
                              (validations.area[0] || validations.area)
                            }
                            onInputChange={(data) => handleAreaChange(data)}
                          />
                        </div>
                        <div className="col small no-border">
                          <RedditTextField
                            margin="dense"
                            size="small"
                            fullWidth
                            label="ПК"
                            name="postcode"
                            value={state.data?.postcode || ""}
                            onChange={handleInputChange}
                            error={Boolean(
                              validations && validations?.postcode
                            )}
                            helperText={
                              validations &&
                              validations.postoce &&
                              validations.postcode[0]
                            }
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col">
                          <RedditTextField
                            margin="dense"
                            size="small"
                            fullWidth
                            label="Адрес"
                            name="address"
                            value={state.data?.address || ""}
                            onChange={handleInputChange}
                            error={Boolean(validations && validations?.address)}
                            helperText={
                              validations &&
                              validations.address &&
                              validations.address[0]
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <h6>Контакти</h6>
                    <div className="field">
                      <div className="row">
                        <div className="col">
                          <RedditTextField
                            margin="dense"
                            size="small"
                            fullWidth
                            label="Лице за контакти"
                            name="contact_name"
                            value={state.data.contact_name || ""}
                            onChange={handleInputChange}
                            error={Boolean(
                              validations &&
                                validations.contact_name &&
                                (validations.contact_name[0] ||
                                  validations.contact_name)
                            )}
                            helperText={
                              validations &&
                              validations.contact_name &&
                              (validations.contact_name[0] ||
                                validations.contact_name)
                            }
                          />
                        </div>
                        <div className="col small">
                          <RedditTextField
                            margin="dense"
                            size="small"
                            fullWidth
                            label="Тел. за връзка"
                            name="phone"
                            value={state.data.phone || ""}
                            onChange={handleInputChange}
                            error={Boolean(
                              validations &&
                                validations.phone &&
                                (validations.phone[0] || validations.phone)
                            )}
                            helperText={
                              validations &&
                              validations.phone &&
                              (validations.phone[0] || validations.phone)
                            }
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <RedditTextField
                            margin="dense"
                            size="small"
                            fullWidth
                            label="Е-поща"
                            name="email"
                            value={state.data.email || ""}
                            onChange={handleInputChange}
                            error={Boolean(
                              validations &&
                                validations.email &&
                                (validations.email[0] || validations.email)
                            )}
                            helperText={
                              validations &&
                              validations.email &&
                              (validations.email[0] || validations.email)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          )}

          <div className="footer">
            <Button className="cancel" onClick={handleHide}>
              Отказ
            </Button>
            <SaveButton
              loading={state.loading}
              className="save"
              onClick={handleSave}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default forwardRef(AddOrEdit)
