//helpers
import Api from "helpers/Api"

function useRemoveStore() {
    function removeStore(id, successCb) {
        Api.post("store/clients/objects/delete", {
            id,
        })
            .then(successCb)
    }

    return {
        removeStore
    }
}

export default useRemoveStore