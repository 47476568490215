//components
import Layout from "components/Layout"
import NoDocuments from "./components/NoDocuments"
import WithDocuments from "./components/WithDocuments"
import PageLoader from "components/misc/PageLoader"

//providers
import { useDashboardDataContext } from "./providers/dashboard"

//hooks
import useFetchDocuments from "./hooks/useFetchDocuments"

function Dashboard() {
  useFetchDocuments()

  const { getDocumentsCount, getLoadingDocuments } = useDashboardDataContext()

  function renderMainView() {
    if (getLoadingDocuments()) {
      return <PageLoader show={true} />
    }
    if (getDocumentsCount()) {
      return <WithDocuments />
    }

    return <NoDocuments />
  }

  return <Layout>{renderMainView()}</Layout>
}

export default Dashboard
