import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

//plugins
import { Modal, FloatingLabel, Form, Row, Col } from 'react-bootstrap'
import { Button, MenuItem } from '@material-ui/core'

//helpers
import Api from 'helpers/Api'
import { useValidation } from 'helpers/Validation'
import { useNestedState } from 'helpers/NestedState'

//providers
import { useLanguageContext } from 'providers/Language'

//misc
import SaveButton from 'components/misc/Button'
import Info from 'components/modals/Info'
import RedditTextField from 'components/misc/RedditTextField'
import RedditSelect from 'components/misc/RedditSelect'
import moment from 'moment'

function AddOrEdit(props, ref) {

    const langs = useLanguageContext()

    const defaultData = {
        langs: {}
    }

    const [state, setState] = useNestedState({
        show: false,
        id: null,
        edit: false,
        data: defaultData,
        loading: false,
        onSuccess: null,
        onEntering: null,
        onExiting: null,
    })

    const [companies, setCompanies] = useState([])

    const [validations, setValidations] = useValidation()

    const formRef = useRef(null)

    useImperativeHandle(ref, () => ({
        add: () => {
            handleShow()
        },
        edit: (id) => {
            edit(id)
        },
        hide: () => {
            hide()
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }))
        }
    }))

    useEffect(() => {
        loadData()
    }, [state.edit])

    useEffect(() => {

        Api.get('store/stores/companies')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setCompanies(res.data)
                }
            })

    }, [])

    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }))

        onEntering?.()
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }))

        onExiting?.()
    }

    const handleShow = () => {
        setState(prev => ({
            ...prev,
            data: defaultData,
            id: null,
        }))
        show()
    }

    const handleClose = e => {

        if (e) {
            e.preventDefault()
        }

        setState(prev => ({
            ...prev,
            data: defaultData,
            id: null,
        }))

        setValidations(null)

        hide()
    }

    const edit = id => {
        setState(prev => ({
            ...prev,
            id: id,
            edit: moment().unix()
        }))

        show()
    }

    const loadData = () => {
        if (!state.id) {
            return
        }

        Api.get('store/stores/edit?id=' + state.id)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    data: res.data
                }))
            })
    }


    const handleSave = () => {
        setState(prev => ({
            ...prev,
            loading: true,
        }))

        setValidations(null)

        let url = state.id ? 'store/stores/update' : 'store/stores/add'

        let data = new FormData(formRef.current)

        if (state.id) {
            data.append('id', state.id)
        }

        Api.post(url, data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(res => {
            if (typeof state.onSuccess === 'function') {
                state.onSuccess(res.data)
                window.location.reload()
            }

            hide()
        }).catch(error => {
            const _err = error.response
            if (_err && _err.status && _err.status === 422) {
                setValidations(_err.data.errors)
            }

        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false,
            }))
        })
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(name, value, 'data')
    }

    const handleDateChange = e => {
        const name = e.target.name + '_ymd'
        const value = e.target.value

        setState(name, value, 'data')
    }

    return (
        <>
            <div className={`${state.show ? "visible" : ""} overlay`}>
                <div
                    className={`popup-primary extra-large ${state.show ? "show" : ""}`}
                >
                    <div className="body">
                        <h3>
                            {`${state.id ? 'Редакция' : 'Добавяне'} на обект`}
                        </h3>
                        <br />
                        <form onSubmit={handleSave} ref={formRef}>
                            {langs.data.map(lang => (
                                <>
                                    <div className="row">
                                        <div className="col">
                                            <RedditTextField
                                                size="small"
                                                variant="filled"
                                                margin="dense"
                                                label="Вътрешно наименование"
                                                name={`langs[${lang.id}][name]`}
                                                value={state.data.langs[lang.id]?.name || ''}
                                                onChange={(e) => handleInputChange(e)}
                                                error={Boolean(validations?.langs && validations.langs[lang.id] && validations.langs[lang.id].name)}
                                                helperText={validations?.langs && validations.langs[lang.id] && validations.langs[lang.id].name && (validations.langs[lang.id].name[0] || validations.langs[lang.id].name)}
                                            />
                                        </div>
                                        <div className="col">
                                            <RedditTextField
                                                size="small"
                                                variant="filled"
                                                margin="dense"
                                                label="Публично наименование"
                                                name={`langs[${lang.id}][public_name]`}
                                                value={state.data.langs[lang.id]?.name || ''}
                                                onChange={(e) => handleInputChange(e)}
                                                error={Boolean(validations?.langs && validations.langs[lang.id] && validations.langs[lang.id].public_name)}
                                                helperText={validations?.langs && validations.langs[lang.id] && validations.langs[lang.id].public_name && (validations.langs[lang.id].public_name[0] || validations.langs[lang.id].public_name)}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <RedditTextField
                                                size="small"
                                                variant="filled"
                                                margin="dense"
                                                label="Адрес"
                                                name={`langs[${lang.id}][public_address]`}
                                                onChange={(e) => handleInputChange(e)}
                                                value={state.data.langs[lang.id]?.public_address || ''}
                                                error={Boolean(validations?.langs && validations.langs[lang.id] && validations.langs[lang.id].public_address)}
                                                helperText={validations?.langs && validations.langs[lang.id] && validations.langs[lang.id].public_address && (validations.langs[lang.id].public_address[0] || validations.langs[lang.id].public_address)}
                                            />
                                        </div>
                                        <div className="col">
                                            <RedditTextField
                                                size="small"
                                                variant="filled"
                                                margin="dense"
                                                label="Лице за контакти"
                                                name={`langs[${lang.id}][public_contact]`}
                                                onChange={(e) => handleInputChange(e)}
                                                value={state.data.langs[lang.id]?.public_contact || ''}
                                                error={Boolean(validations?.langs && validations.langs[lang.id] && validations.langs[lang.id].public_contact)}
                                                helperText={validations?.langs && validations.langs[lang.id] && validations.langs[lang.id].public_contact && (validations.langs[lang.id].public_contact[0] || validations.langs[lang.id].public_contact)}
                                            />
                                        </div>
                                        <div className="col">
                                            <RedditTextField
                                                size="small"
                                                variant="filled"
                                                margin="dense"
                                                label="Е-поща"
                                                name="public_email"
                                                value={state.data.public_email || ''}
                                                onChange={handleInputChange}
                                                error={Boolean(validations && validations.public_email)}
                                                helperText={validations && validations.public_email && (validations.public_email[0] || validations.public_email)}
                                            />
                                        </div>
                                        <div className="col">
                                            <RedditSelect
                                                name="company_id"
                                                value={state.data.company_id || ''}
                                                onChange={handleInputChange}
                                                label="Фирма за фактури*"
                                                error={Boolean(validations && validations.company_id)}
                                                helperText={validations && validations.company_id && (validations.company_id[0] || validations.company_id)}
                                            >
                                                {companies.map(c =>
                                                    <MenuItem key={c.id} value={c.id}>
                                                        {c.translation?.name || c.name}
                                                    </MenuItem>
                                                )}
                                            </RedditSelect>
                                        </div>
                                    </div>
                                </>
                            )

                            )}
                        </form>
                    </div>

                    <div className="footer">
                        <Button
                            variant="contained"
                            className="cancel"
                            onClick={handleClose}
                        >
                            Отказ
                        </Button>
                        <SaveButton
                            loading={state.loading}
                            className="save"
                            onClick={handleSave}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default forwardRef(AddOrEdit)
