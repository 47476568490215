import { useEffect, useRef, useState } from 'react'
import Api from 'helpers/Api'
import { buildSearchParams, buildUrl, useQuery } from 'helpers/Url'
import { useLocation, useHistory, Link } from 'react-router-dom'
import { Alert, Button, Table, OverlayTrigger, Tooltip } from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'

import Options from './partials/Options'

import Question from 'components/modals/Question'
import Filter from './partials/Filter'
import Layout from 'components/Layout'
import View from './view/View'

//misc
import MenuOptions from 'components/misc/MenuOptions'
import Pagination from 'components/misc/Pagination'
import Loader from 'components/misc/Loader'
import NoDataFound from 'components/misc/NoDataFound'
import { useAuthDataContext } from 'providers/Auth'
import TableHeader from 'components/misc/TableHeader'
import TableCol from 'components/misc/TableCol'
import TableOptions from 'components/misc/TableOptions'
import DynamicTableCol from 'components/partials/table/DynamicTableCol'
import DynamicTableBodyCol from 'components/partials/table/DynamicTableBodyCol'
import axios from 'axios'
import PaginationMini from 'components/misc/PaginationMini'
import AddOrEdit from './partials/AddOrEdit'
import moment from 'moment'

let timeout

function Index() {

    const location = useLocation()
    const navigate = useHistory()
    const query = useQuery()
    const auth = useAuthDataContext()

    const tableRef = useRef(null)
    const deleteModalRef = useRef(null)
    const closeModalRef = useRef(null)
    const sendModalRef = useRef(null)
    const viewModalRef = useRef(null)
    const addOrEditRef = useRef(null)

    const [state, setState] = useState({
        sorting: false,
        loading: true,
        refresh: false,
        setFilter: false,
        data: {},
        totals: {},
        headings: {},
        types: {},
        pages: 0,
        total: 0,
        filter: {
            page: 1,
        },
        columns: {
            all: {},
            details: {},
            selected: [],
            sortable: [],
            sort: null,
            order: null,
        },
        tableKey: '',
        request: null,
    })

    useEffect(() => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: query.get('page') || 1,
                limit: query.get('limit') || '',
                search: query.get('search') || '',
                status_id: query.get('status_id') || '',
                created_from_date: query.get('created_from_date') || '',
                created_to_date: query.get('created_to_date') || '',
                from_depot_id: query.getAll('from_depot_id[]') || [],
                to_depot_id: query.getAll('to_depot_id[]') || [],
                creator_id: query.get('creator_id') || '',
                acceptor_id: query.get('acceptor_id') || '',
                document_date: query.get('document_date') || '',
                deleted: query.get('deleted') || '',
                sort: query.get('sort') || '',
                order: query.get('order') || '',
            },
            refresh: moment().unix()
        }))
    }, [location.search])

    useEffect(() => {
        if (state.setFilter) {
            navigate.push('?' + buildSearchParams(query, state.filter))
        }

    }, [state.setFilter])

    useEffect(() => {
        if (state.refresh) {
            loadData()
        }
    }, [state.refresh])

    useEffect(() => {
        refresh()
    }, [auth.getUser()?.getStoreId()])

    const loadData = () => {

        if (state.request) {
            state.request.cancel()
        }

        let request = axios.CancelToken.source()

        setState(prev => ({
            ...prev,
            request: request,
            loading: true,
        }))

        let url = 'store/storemoves/all'

        Api.get(url, {
            params: state.filter,
            cancelToken: request.token
        }).then(res => {
            setState(prev => ({
                ...prev,
                currency: res.data.currency,
                data: res.data.items,
                total: res.data.total,
                pages: res.data.pages,
                totals: res.data.totals,
                headings: res.data.headings,
                types: res.data.types,
                columns: res.data.columns,
                tableKey: res.data.tableKey,
                loading: false,
                sorting: false,
                filter: {
                    ...prev.filter,
                    ...res.data.filter,
                }
            }))

            if (res.data.pages > 0 && state.filter.page > res.data.pages) {
                return handlePage({ selected: res.data.pages - 1 })
            }
        })
    }

    const loading = (loading) => {
        setState(prev => ({
            ...prev,
            loading: Boolean(loading)
        }))
    }

    const refresh = (reset = true) => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: reset ? 1 : prev.filter.page
            },
            setFilter: moment().unix(),
            refresh: moment().unix(),
        }))
    }

    const refreshTable = () => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                sort: '',
                order: '',
                page: 1,
                limit: '',
            },
            setFilter: moment().unix(),
            refresh: moment().unix(),
        }))
    }

    const handleAdd = () => {
        let modal = addOrEditRef.current

        modal.add()
        modal.onSuccess(() => {
            refresh(true)
        })
    }

    // update
    const handleEdit = data => {
        if (data.deleted) {
            return
        }

        let modal = addOrEditRef.current

        modal.edit(data.id)
        modal.onSuccess(() => {
            refresh(false)
        })
    }

    // delete
    const handleDelete = data => {
        let modal = deleteModalRef.current

        modal.open()
        modal.onSuccess(() => {
            handleDestroy(data.move_id)
        })
    }

    const handleDestroy = id => {
        Api.post('store/storemoves/delete', {
            id: id
        }).then(res => {
            if (res.data.success) {
                refresh(false)
            }
        })
    }

    const handleRestore = data => {
        Api.post('store/storemoves/restore', {
            id: data.move_id
        }).then(res => {
            if (res.data.success) {
                refresh(false)
            }
        })
    }

    const handleView = data => {
        let modal = viewModalRef.current

        modal.open(data.move_id)
    }

    // close
    const handleClose = data => {
        let modal = closeModalRef.current

        modal.open()
        modal.onSuccess(() => {
            close(data.move_id)
        })
    }

    const close = id => {
        Api.post('store/storemoves/close', {
            id: id
        }).then(res => {
            if (res.data.success) {
                refresh(false)
            }
        })
    }

    // send
    const handleSend = data => {
        let modal = sendModalRef.current

        modal.open()
        modal.onSuccess(() => {
            send(data.move_id)
        })
    }

    const send = id => {
        Api.post('store/storemoves/send', {
            id: id
        }).then(res => {
            if (res.data.success) {
                refresh(false)
            }
        })
    }

    // Search
    const handleSearch = (key, val, delay = 300) => {
        clearTimeout(timeout)

        if (typeof key === 'object') {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    ...key
                },
            }))
        } else {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    [key]: val,
                },
            }))
        }

        timeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    page: 1
                },
                setFilter: moment().unix()
            }))
        }, delay)
    }

    const handlePage = page => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: page.selected + 1,
            },
            setFilter: moment().unix()
        }))
    }

    const sorting = (sorting) => {
        setState(prev => ({
            ...prev,
            sorting: Boolean(sorting)
        }))
    }

    const handleSort = (sort, order) => {
        setState(prev => ({
            ...prev,
            sorting: true,
            filter: {
                ...prev.filter,
                sort: sort,
                order: order,
                page: 1,
            },
            setFilter: moment().unix()
        }))
    }

    const getStatusColor = (row) => {
        return {
            'open': 'blue-value',
            'receive': 'lightblue-value',
            'sent': 'green-value',
            'close': 'brown-value',
            'cancel': 'yellow-value',
        }[row.user_status?.code] || null
    }

    const handleLimitChange = limit => {
        handleSearch('limit', limit, 0)
    }

    return (
        <>

            <Question
                ref={deleteModalRef}
                mainMessage="Сигурни ли сте?"
                agreeBtnText="Изтриване"
                agreeBtnClass="remove"
            />

            <Question
                ref={closeModalRef}
                mainMessage="Сигурни ли сте, че искате да затворите това прехвърляне?"
                agreeBtnText="Да"
            />

            <Question
                ref={sendModalRef}
                mainMessage="Сигурни ли сте, че искате да изпратите това прехвърляне към другия склад?"
                agreeBtnText="Да"
            />

            <View
                ref={viewModalRef}
            />
            <AddOrEdit
                ref={addOrEditRef}
            />

            <Layout>
                <div className="store-moves">
                    <div className="head-with-heading">
                        <h3>Склад прехвърляния</h3>

                        <div className="buttons">
                            <Button
                                className="add"
                                onClick={handleAdd}
                                disabled={auth.getUser().permission('storemoves_edit') === false}
                            >
                                Ново прехвърляне
                            </Button>
                        </div>
                    </div>


                    <div
                        className="panel"
                        style={{
                            marginTop: 0,
                        }}
                    >
                        <div className="filter-holder">
                            <Filter
                                filter={state.filter}
                                handleSearch={handleSearch}
                            />
                            <PaginationMini
                                page={state.filter.page}
                                pages={state.pages}
                                handlePage={handlePage}
                                isLoading={state.loading}
                            />
                        </div>

                        {state.loading && !state.sorting
                            ?
                            <Skeleton
                                count={5}
                                height={60}
                            />
                            :
                            state.data.length === 0 ?
                                <NoDataFound />
                                :
                                <>
                                    <div className="pagination-with-options mb-3">
                                        <Pagination
                                            page={state.filter.page}
                                            pages={state.pages}
                                            handlePage={handlePage}
                                            limit={state.filter.limit}
                                            onLimitChange={handleLimitChange}
                                        />
                                    </div>

                                    <table className="type-outer" ref={tableRef}>
                                        <TableHeader
                                            tableRef={tableRef}
                                            activeSortKey={state.filter.sort}
                                            activeSortDir={state.filter.order}
                                            onSort={(col, dir) => handleSort(col, dir)}
                                        >
                                            {Object.entries(state.headings).map((heading, i) =>
                                                <DynamicTableCol
                                                    key={i}
                                                    type={state.types[heading[0]]}
                                                    name={heading[1]}
                                                    title={state.columns.description[heading[0]]}
                                                    sortKey={heading[0]}
                                                    sortable={state.columns.sortable.indexOf(heading[0]) > -1}
                                                />
                                            )}
                                            <TableCol className="options">Опции</TableCol>
                                        </TableHeader>
                                        <tbody>
                                            {state.data.map((c, index) =>
                                                <tr
                                                    key={'c-' + c.id + 'index-' + index}
                                                    className={c.deleted ? 'deleted' : ''}
                                                >
                                                    {Object.entries(state.headings).map((heading, i) =>
                                                        heading[0] === 'status'
                                                            ?
                                                            <td className={`text-center ${getStatusColor(c)}`} onClick={() => handleView(c)}>
                                                                {c.status}
                                                            </td>
                                                            :
                                                            <DynamicTableBodyCol
                                                                key={heading[0]}
                                                                type={state.types[heading[0]]}
                                                                name={c[heading[0]]}
                                                                data={c}
                                                                currency={state.currency}
                                                                onClick={() => handleView(c)}
                                                            />
                                                    )}
                                                    <td className="options">
                                                        <MenuOptions>
                                                            <Options
                                                                data={c}
                                                                handleEdit={handleEdit}
                                                                handleDelete={handleDelete}
                                                                handleRestore={handleRestore}
                                                                handleClose={handleClose}
                                                                handleSend={handleSend}
                                                                handleView={handleView}
                                                            />
                                                        </MenuOptions>
                                                    </td>
                                                </tr>
                                            )}
                                            {state.totals.map((c, i) =>
                                                <tr key={i} className="additional-options">
                                                    {Object.entries(c.items).map((heading, i) =>
                                                        <DynamicTableBodyCol
                                                            key={heading[0]}
                                                            type={state.types[heading[0]]}
                                                            name={heading[1]}
                                                            data={c.items}
                                                            currency={state.currency}
                                                            colspan={c.colspan[heading[0]]}
                                                            style={{
                                                                fontFamily: "SourceSansPro SemiBold"
                                                            }}
                                                        />
                                                    )}
                                                    <td>

                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>

                                    <Pagination
                                        className="mt-3"
                                        page={state.filter.page}
                                        pages={state.pages}
                                        total={state.total}
                                        handlePage={handlePage}
                                        limit={state.filter.limit}
                                        onLimitChange={handleLimitChange}
                                    />
                                </>
                        }

                    </div>
                </div>
            </Layout >
        </>
    )
}

export default Index
