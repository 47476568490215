import { useEffect } from 'react';
import Echo from 'laravel-echo';
import { useAuthDataContext } from 'providers/Auth';
// import pusher from 'pusher-js';

//helpers
import { isProd } from './mode'

window.Pusher = require('pusher-js');

const channels = {
    private: {},
    presence: {},
}

if (!window.channels) {
    window.channels = channels;
}

function createSocketConnection(token) {

    if (!window.Echo) {
        window.Echo = new Echo({
            broadcaster: 'pusher',
            key: 123,
            cluster: 'mt1',
            forceTLS: process.env[isProd ? 'REACT_APP_WS_FORCE_TLS' : 'REACT_APP_WS_FORCE_TLS_DEV'] === 'true',
            authEndpoint: process.env[isProd ? 'REACT_APP_WS_AUTH_ENDPOINT' : 'REACT_APP_WS_AUTH_ENDPOINT_DEV'],
            httpHost: process.env[isProd ? 'REACT_APP_WS_HTTP_HOST' : 'REACT_APP_WS_HTTP_HOST_DEV'],
            wsHost: process.env[isProd ? 'REACT_APP_WS_HOST' : 'REACT_APP_WS_HOST_DEV'],
            wsPort: process.env.REACT_APP_WS_PORT,
            wssPort: process.env.REACT_APP_WS_PORT,
            // enableStats: true,
            disableStats: true,
            auth: {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        });
    }

    return window.Echo;
}

function listen(channel, event = null, onEvent = null) {

    let instance = window.Echo.private(channel);

    let onEventCallback;

    if (event && onEvent) {
        onEventCallback = payload => {
            onEvent(payload);
        };

        instance.listen(event, onEventCallback);
    }

    window.channels.private[channel] = instance;

    return function cleanUp() {
        // window.Echo.leaveChannel(`private-${channel}`);
        // window.channels.private[channel] = null;

        if (onEventCallback) {
            instance.stopListening(event, onEventCallback);
        }
    };
}

function join(channel, event = null, onEvent = null, onJoin = null, onJoining = null, onLeaving = null) {

    let instance = window.channels.presence[channel] || null;

    if (instance === null) {
        instance = window.Echo.join(channel);
    }

    // console.log(instance)

    let onJoinCallback;

    if (onJoin) {

        onJoinCallback = (data) => {
            onJoin(Object.keys(data.members).map((k) => data.members[k]));
        };

        instance.on('pusher:subscription_succeeded', onJoinCallback);

    }

    let onJoiningCallback;

    if (onJoining) {

        onJoiningCallback = (member) => {
            onJoining(member.info);
        };

        instance.on('pusher:member_added', onJoiningCallback);
    }

    let onLeavingCallback;

    if (onLeaving) {

        onLeavingCallback = (member) => {
            onLeaving(member.info);
        };

        instance.on('pusher:member_removed', onLeavingCallback);
    }

    let onEventCallback;

    if (event && onEvent) {

        onEventCallback = payload => {
            onEvent(payload);
        };

        instance.listen(event, onEventCallback);
    }

    window.channels.presence[channel] = instance;

    return function cleanUp() {
        // window.Echo.leaveChannel(`presence-${channel}`);
        // window.channels.presence[channel] = null;

        if (onJoinCallback) {
            instance.subscription.unbind('pusher:subscription_succeeded', onJoinCallback);
        }

        if (onJoiningCallback) {
            instance.subscription.unbind('pusher:member_added', onJoiningCallback);
        }

        if (onLeavingCallback) {
            instance.subscription.unbind('pusher:member_removed', onLeavingCallback);
        }

        if (onEventCallback) {
            instance.stopListening(event, onEventCallback);
        }
    }
}

function notification(channel, type, onEvent) {

    let instance = window.Echo.private(channel);

    let callback = payload => {
        if (type) {
            if (payload.type === type) {
                onEvent(payload);
            }
        } else {
            onEvent(payload);
        }
    };

    instance.notification(callback);

    window.channels.private[channel] = instance;

    return function cleanUp() {
        instance.stopListening('.Illuminate\\Notifications\\Events\\BroadcastNotificationCreated', callback);
    }
}

export const usePrivateSocketChannel = ({ channel, channelId, event, onEvent }, deps = []) => {
    const auth = useAuthDataContext();

    const channelName = channel + '.' + channelId;

    useEffect(() => {

        if (typeof channelId === 'undefined') {
            return;
        }

        // const token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC8xOTIuMTY4LjAuMTJcL2JsYWNrYm94LWJhY2tlbmRcL3B1YmxpY1wvYXBpXC9hdXRoXC9sb2dpbiIsImlhdCI6MTY0Mzc4NjE4NCwibmJmIjoxNjQzNzg2MTg0LCJqdGkiOiIwNU5FZ1M3UHZVdktvMGpJIiwic3ViIjoxLCJwcnYiOiIyM2JkNWM4OTQ5ZjYwMGFkYjM5ZTcwMWM0MDA4NzJkYjdhNTk3NmY3In0.wPV0ow3bX4Si8oKjJjuj0_IC4ycaCmxoT_Snmmhw8zc';
        const token = auth.getToken();

        createSocketConnection(token);

        return listen(channelName, event, onEvent);
    }, deps);

    return window.channels.private[channelName];
};

export const usePresenceSocketChannel = ({ channel, channelId, event, onEvent, onJoin, onJoining, onLeaving }, deps = []) => {
    const auth = useAuthDataContext();

    const channelName = channel + '.' + channelId;

    useEffect(() => {

        if (typeof channelId === 'undefined') {
            return;
        }

        const token = auth.getToken();

        createSocketConnection(token);

        return join(channelName, event, onEvent, onJoin, onJoining, onLeaving);
    }, deps);

    return window.channels.presence[channelName];
};

export const useSocketNotification = ({ channel, channelId, type = null, callback }, deps = []) => {
    const auth = useAuthDataContext();

    const channelName = channel + '.' + channelId;

    useEffect(() => {

        if (typeof channelId === 'undefined') {
            return;
        }

        const token = auth.getToken();

        createSocketConnection(token);

        return notification(channelName, type, callback);
    }, deps);

    return window.channels.private[channelName];
}