import { forwardRef, useImperativeHandle, useState } from "react"
import { useHistory } from "react-router-dom"

//libraries
import { Menu, MenuItem } from "@material-ui/core"

//components
import { INVOICES_TABS } from "components/pages/documents/constants/tabs"

//constatns
import { DOCUMENT_TYPE } from "constants/Document"

function CreateDocumentMenu({ createDocumentButtonRef, externalAddDocument }, ref) {
  const history = useHistory()

  const [open, setOpen] = useState(false)

  useImperativeHandle(ref, () => ({
    open: showMenu,
  }))

  function closeMenu() {
    setOpen(false)
  }

  function showMenu() {
    setOpen(true)
  }

  function getTabs(exeptElementWithTypeIds = []) {
    if (exeptElementWithTypeIds.length) {
      return INVOICES_TABS.filter((t) => {
        return !exeptElementWithTypeIds.includes(t.typeId) && Number(t.typeId)
      })
    }

    return INVOICES_TABS
  }

  function addDocument(typeId) {
    if (externalAddDocument) {
      externalAddDocument(typeId)

      return
    }
    history.push("/invoices", {
      data: { typeId },
      mode: "new",
    })
  }

  return (
    <Menu
      anchorEl={createDocumentButtonRef.current}
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      keepMounted
      open={open}
      onClose={closeMenu}
      onClick={closeMenu}
      className="create-documents-menu"
    >
      {getTabs([3]).map((t, i) =>
        t.firstLetter ? (
          <MenuItem
            onClick={() => {
              addDocument(t.typeId)
            }}
          >
            <span className="image">{t.firstLetter}</span>
            {t.fullName}
          </MenuItem>
        ) : (
          <div></div>
        )
      )}
    </Menu>
  )
}

export default forwardRef(CreateDocumentMenu)
