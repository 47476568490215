import { useEffect, useRef, useState, useImperativeHandle, forwardRef } from 'react'
import ClickAwayListener from 'react-click-away-listener'

//MUI components
import { CircularProgress } from '@material-ui/core'
import RedditTextField from 'components/misc/RedditTextField'

//helpers
import Api from 'helpers/Api'
import { isEmail } from 'helpers/Validation'

//images
import removeIcon from 'assets/img/documents/icons/remove.png'

let timeout

const DOMAINS = [
    'com',
    'bg',
    'ru',
    'org',
    'uk',
    'net',
    'co.uk',
    'de',
    'gr',
    'ro',
    'tr'
]

function Index({ validations, relatedContacts, onChange }, ref) {
    const [state, setState] = useState({
        text: '',
        emails: [],
        paddingLeft: 0
    })

    const [contacts, setContacts] = useState({
        data: [],
        loading: false,
        show: false,
    })

    // const [hover, setHover] = useState({
    //     index: null,
    //     top: 0,
    //     left: 0,
    // })

    const refEl = useRef(null)
    const inputRef = useRef(null)

    useImperativeHandle(ref, () => ({
        setEmail: email => {
            setState(prev => ({
                ...prev,
                emails: [email]
            }))
        }
    }))

    useEffect(() => {
        clearTimeout(timeout)

        timeout = setTimeout(() => {
            loadContacts(state.text, 1)
        }, 300)
    }, [state.text])

    useEffect(() => {
        if (typeof onChange === 'function') onChange(state.emails)
    }, [state.emails])

    const handleInputChange = e => {
        let { value } = e.target

        setState(prev => ({
            ...prev,
            text: value
        }))

        if (isEmail(value)) {
            const splitedDomain = value.split('@')[1].split('.')
            let domain = ''
            for (let i = 0; i < splitedDomain.length; i++) {
                if (i > 0) {
                    if (i > 1) {
                        domain += '.'
                    }
                    domain += splitedDomain[i]
                }
            }

            if (DOMAINS.includes(domain)) saveEmail(value)
        }
    }

    const onKeyDown = e => {
        if (['Enter', 'Tab', ',', ' '].includes(e.key)) {
            e.preventDefault()

            let email = e.target.value.trim()

            saveEmail(email)
        }
    }

    const saveEmail = email => {
        if (!isEmail(email) || isInList(email)) return

        if (email) {
            setState(prev => ({
                ...prev,
                text: '',
                emails: prev.emails.concat(email)
            }))
        }
    }

    const isInList = email => {
        return state.emails.find(contact => {
            if (isContact(contact)) {
                return contact.email === email
            } else {
                return contact === email
            }
        })
    }

    const onBlur = e => {
        setTimeout(() => {
            toggleDropdown(false)
        }, 100)
    }

    const onFocus = () => {
        if (state.text.length && contacts.data.length) toggleDropdown(true)
    }

    const loadContacts = (text, page = 1) => {
        // if (text.length === 0) return

        setContacts(prev => ({
            ...prev,
            loading: true,
        }))
        // showContacts(true)

        Api.get('contacts/get', {
            params: {
                search: text
            }
        })
            .then(res => {
                setContacts(prev => ({
                    ...prev,
                    data: res.data.items,
                    loading: false,
                }))
                // showContacts(res.data.items.length > 0 ? true : false)
            })
    }

    const showContacts = show => {
        setContacts(prev => ({
            ...prev,
            show
        }))
    }

    const selectContact = contact => {
        if (isInList(contact.email)) return

        setState(prev => ({
            ...prev,
            text: '',
            emails: prev.emails.concat(contact),
        }))

        toggleDropdown(false)
    }

    const handleRemove = index => {
        setState(prev => ({
            ...prev,
            emails: prev.emails.filter((email, i) => i !== index)
        }))
    }

    const calculatePadding = () => {
        let chips = refEl.current.getElementsByClassName('chip')
        let latest = chips[chips.length - 1]

        if (!latest) {
            return 0
        }

        let padding = latest.offsetLeft + latest.offsetWidth

        return padding
    }

    // useEffect(() => {
    //     let padding = calculatePadding();

    //     setState(prev => ({
    //         ...prev,
    //         paddingLeft: padding
    //     }))
    // }, [state.emails]);

    const handleClickContainer = e => {
        const input = inputRef.current

        if (input) input.focus()
    }

    const toggleDropdown = (show = true) => {
        setContacts(prev => ({
            ...prev,
            show
        }))
    }

    const getContactEmail = (contact) => {
        if (isContact(contact)) {
            return contact.email
        }

        return contact
    }

    const isContact = contact => typeof contact === 'object'

    // const showCard = (e, index) => {
    //     const target = e.currentTarget

    //     const rect = target.getBoundingClientRect()

    //     const x = rect.left + window.scrollX
    //     const y = rect.top + window.scrollY + rect.height

    //     setHover(prev => ({
    //         ...prev,
    //         index: index,
    //         top: y,
    //         left: x
    //     }))
    // }

    // const hideCard = () => {
    //     setHover(prev => ({
    //         ...prev,
    //         index: null
    //     }))
    // }

    const getEmails = () => {
        return state.emails.map(contact => {
            if (isContact(contact)) {
                return contact.email
            }

            return contact
        })
    }

    return (
        <>
            <input name="email" value={getEmails().join(', ')} hidden />

            <div className={`email-input-wrapper`}>
                <div className={`email-input ${Boolean(validations && validations.email) ? 'val' : ''}`} ref={refEl} onClick={handleClickContainer}>
                    <div className={`email-chips`}>
                        {state.emails.map((contact, index) =>
                            <div
                                key={index}
                                className="chip"
                                // onMouseOver={e => {
                                //     showCard(e, index)
                                // }}
                                // onMouseOut={() => {
                                //     hideCard()
                                // }}
                                style={index == 0 ? {
                                    marginLeft: '0',
                                } : {}}
                            >
                                <span>
                                    {getContactEmail(contact)}
                                </span>
                                <img
                                    src={removeIcon}
                                    className="remove"
                                    onClick={() => {
                                        handleRemove(index)
                                    }}
                                />
                                {state.emails[index].name ?
                                    <div key={index} className="card">
                                        <picture>
                                            <img src={state.emails[index].avatar} alt="" />
                                        </picture>

                                        <div className="right">
                                            <div className="heading">
                                                {state.emails[index].name}
                                            </div>
                                            <div className="text">
                                                {state.emails[index].company_name}
                                            </div>
                                            <a href={`mailto:${state.emails[index].email}`} className="email link">
                                                {state.emails[index].email}
                                            </a>
                                        </div>
                                    </div>
                                    :
                                    <></>
                                }
                            </div>
                        )}
                        <ClickAwayListener
                            onClickAway={() => {
                                if (state.text.length) saveEmail(state.text)
                                showContacts(false)
                            }}
                        >
                            <div
                                className="input"
                                style={{ width: 'fit-content' }}
                            >
                                <input
                                    ref={inputRef}
                                    type="text"
                                    value={state.text || ''}
                                    onChange={handleInputChange}
                                    onKeyDown={onKeyDown}
                                    onFocus={onFocus}
                                    onBlur={onBlur}
                                    // autoFocus
                                    onClick={() => {
                                        showContacts(true)

                                    }}
                                />
                                {(state.text.length ? contacts.data : relatedContacts || []).length || contacts.loading ?
                                    <div className={`dropdown ${contacts.show && 'show'}`}>
                                        {contacts.loading
                                            ?
                                            <div className="loader">
                                                <CircularProgress color="inherit" />
                                            </div>
                                            :
                                            <ul>
                                                {(state.text.length ? contacts.data : relatedContacts || []).map((contact, i) =>
                                                    <li key={i} onClick={e => selectContact(contact)}>
                                                        <div className="heading">
                                                            <picture>
                                                                <img src={contact.avatar} alt="" />
                                                            </picture>
                                                            {contact.name}
                                                        </div>
                                                        <div className="text">
                                                            {contact.company_name}
                                                        </div>
                                                        <a href={`mailto:${contact.email}`} className="email link">
                                                            {contact.email}
                                                        </a>
                                                    </li>
                                                )}
                                            </ul>
                                        }
                                    </div>
                                    :
                                    <></>
                                }
                            </div>
                        </ClickAwayListener>
                    </div>
                    <RedditTextField
                        error={Boolean(validations && validations.email)}
                        helperText={validations && validations.email && validations.email[0]}
                    />
                </div>

                {/* {Boolean(validations && validations.email) &&
                    <div className="validate">
                        {validations && validations.email && (validations.email[0] || validations.email)}
                    </div>
                } */}

                {/* {state.emails.map((contact, index) => isContact(contact) &&
                    <div key={index} className={`card ${hover.index == index && 'show'}`} style={{ top: '150px', left: hover.left }}>
                        <picture>
                            <img src={contact.avatar} alt="" />
                        </picture>

                        <div className="right">
                            <div className="heading">
                                {contact.name}
                            </div>
                            <div className="text">
                                {contact.company_name}
                            </div>
                            <div className="email link">
                                {contact.email}
                            </div>
                        </div>
                    </div>
                )} */}
            </div>
        </>
    )
}

export default forwardRef(Index)