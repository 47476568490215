import { useEffect, useState } from 'react'

//providers
import { useAuthDataContext } from 'providers/Auth'

const useFinalScreenHook = () => {
    const auth = useAuthDataContext()
    const [state, setState] = useState({
        showFinalScreen: false
    })

    useEffect(() => {
        if (auth.isLogged()) {
            setTimeout(() => {
                setState(prev => ({
                    ...prev,
                    showFinalScreen: true
                }))
            }, 1000)
        }
    }, [auth])

    const handleNavigateToHome = () => {
        window.location.href = '/'
    }

    return {
        showFinalScreen: state.showFinalScreen,
        handleNavigateToHome
    }
}

export default useFinalScreenHook