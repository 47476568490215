import { useState, useImperativeHandle, forwardRef, useRef } from 'react'
import { Modal, Button, Tabs, Tab } from 'react-bootstrap';
import Loader from 'components/misc/Loader';
import Home from './Home';
import Refs from 'Refs';

function View(props, ref) {

    const [state, setState] = useState({
        show: false,
        params: null,
        id: null,
        onClose: null,
    });

    const articleModalRef = useRef(null);

    useImperativeHandle(ref, () => ({
        open: (id) => {
            handleShow(id);
        },
        close: () => {
            handleClose();
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }));
        }
    }));

    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }));
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }));
    }

    const handleShow = (id) => {
        setState(prev => ({
            ...prev,
            id: id,
        }));

        show();
    }

    const handleClose = () => {
        setState(prev => ({
            ...prev,
        }));

        if (typeof state.onClose === 'function') {
            hide(state.onClose);
        } else {
            hide();
        }

        setState(prev => ({
            ...prev,
            onClose: null,
        }));
    }

    const handleShowArticle = id => {
        let modal = Refs.getInstance().getRef('article');

        if (modal) {
            // hide(() => {
            //     modal.edit(id);
            // });

            // modal.onClose(() => {
            //     show();
            // });

            modal.open(id);
        }
    }

    return (
        <>
            <Modal size="xl" centered show={state.show} onHide={handleClose} onEntering={state.onEntering} onExiting={state.onExiting}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Проверка на наличности
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Home
                        id={state.id}
                        show={show}
                        hide={hide}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Затвори
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default forwardRef(View);