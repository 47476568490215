//components
import Layout from "components/Layout"
import CommunicationList from "components/pages/customers/partials/CommunicationList"

//libraries
import { Button } from "@material-ui/core"

//stores
import { useAddToCommunicationsModal } from "stores/customer/useAddToCommunicationsModal"

export default function Communication() {
  const { showModal } = useAddToCommunicationsModal()

  function openAddToCommunicationsModal() {
    showModal(true)
  }

  return (
    <Layout>
      <div className="container-fluid">
        <div className="head-with-heading">
          <h3>Комуникация</h3>
          <div className="buttons">
            <Button className="add" onClick={openAddToCommunicationsModal}>
              Нова комуникация
            </Button>
          </div>
        </div>
        <div
          className="panel"
          style={{
            marginTop: 0,
          }}
        >
          <div className="communication-holder">
            <CommunicationList />
          </div>
        </div>
      </div>
    </Layout>
  )
}
