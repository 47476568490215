import Middleware from './Middleware';

class NotVerified extends Middleware {
    run(props, next) {

        if (this.user && this.user.mustVerifyEmail() && this.user.isEmailVerified()) {
            return this.redirect('/');
        }

        return next(this.app);
    }
}

export default NotVerified;