

import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

//MUI components
import { Checkbox, FormControlLabel, Button } from '@material-ui/core'

//helpers
import { isEmail } from 'helpers/Validation'
import Api from 'helpers/Api'

//miscs
import SaveButton from 'components/misc/Button'

//images
import eyeIcon from 'assets/img/login/icons/eye.png'
import authIllustrationImage from 'assets/img/documents/login-illustration.png'

//providers
import { useAuthDataContext } from 'providers/Auth'
import { usePublicPreviewContext } from './PublicPreviewProvider'

const Register = props => {
    const history = useHistory()
    const publicPreview = usePublicPreviewContext()
    const auth = useAuthDataContext()

    const { handleEdit, handleShow } = props

    const [validation, setValidation] = useState(false)

    const [state, setState] = useState({
        currentAuthStep: 1,
        viewPw: false,
        loading: false,
        show: false
    })

    useEffect(() => {
        const viePwEls = document.querySelectorAll('.view-pw')

        if (viePwEls.length) {
            for (let i = 0; i < viePwEls.length; i++) appendPressHold(viePwEls[i])
        }
    }, [state.currentAuthStep])

    const handleSubmit = e => {
        e.preventDefault()
        setLoading(true)
        setValidation(false)

        const data = new FormData(e.target)

        Api.post('auth/login', data)
            .then(res => {
                if (res.status === 200 && res.data.user) {
                    auth.login(res.data)

                    history.push('/dashboard')
                }
            })
            .catch(() => {
                setValidation(true)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const setLoading = loading => {
        setState(prev => ({
            ...prev,
            loading
        }))
    }

    //Не се ползва
    const catchValidations = () => {
        handleValidations(null, null, true)

        const email = state.email
        const pw = state.password
        // const pwcf    = state.password_confirmation
        const remember = state.remember

        if (!email) handleValidations('email', 'Моля, въведете е-поща')
        else if (!isEmail(email)) handleValidations('email', 'Моля, въведете валидна е-поща')

        if (!pw) handleValidations('password', 'Моля, въведете парола')
        // if(!pwcf) handleValidations('password_confirmation', 'Моля, повторете паролата')

        // if((pw && pwcf) && (pw !== pwcf)) {
        if (pw) {
            handleValidations('password', 'Двете полета за парола трябва да съвпадата')
            handleValidations('password_confirmation', 'Двете полета за парола трябва да съвпадата')
        }

        if (!remember) handleValidations('remember', '')
    }

    const appendPressHold = item => {
        if (!item) return

        let timerID
        let counter = 0

        let pressHoldEvent = new CustomEvent("pressHold")

        let pressHoldDuration = 50

        item.addEventListener("mousedown", pressingDown, false)
        item.addEventListener("mouseup", notPressingDown, false)
        item.addEventListener("mouseleave", notPressingDown, false)

        item.addEventListener("touchstart", pressingDown, false)
        item.addEventListener("touchend", notPressingDown, false)

        item.addEventListener("pressHold", doSomething, false)

        function pressingDown(e) {
            requestAnimationFrame(timer)

            e.preventDefault()
            viewPw(true)
        }

        function notPressingDown(e) {
            cancelAnimationFrame(timerID)
            counter = 0
            viewPw(false)
        }


        function timer() {
            if (counter < pressHoldDuration) {
                timerID = requestAnimationFrame(timer);
                counter++;
            } else {
                item.dispatchEvent(pressHoldEvent);
            }
        }

        function doSomething(e) {

        }
    }

    const viewPw = viewPw => {
        setState(prev => ({
            ...prev,
            viewPw
        }))
    }

    /**
     * 
     * @returns дали полетата за регистрация са попълнени коректно
     */
    const isReadyToRegister = () => {
        const email = state.email
        const pw = state.password
        // const pwcf    = state.password_confirmation
        const remember = state.remember

        // if (email && pw && pwcf) {
        if (email && pw) {
            // if (pw === pwcf) {
            if (isEmail(email)) {
                return true
            }
            // }
        }

        return false
    }

    const handleChage = e => {
        let { name, value } = e.target

        if (name === 'remember') {
            value = !state.remember
        }

        setState(prev => ({
            ...prev,
            [name]: value
        }))
    }

    //Не се ползва
    const handleValidations = (name, value, reset = false) => {
        if (reset) {
            setValidation(false)

            return
        }

        setValidation(prev => ({
            ...prev,
            [name]: value
        }))
    }

    return (
        <form className="body-part login" onSubmit={handleSubmit}>
            <img src={authIllustrationImage} className="main" />
            <h2>
                Влезте в профила си
            </h2>
            <ul className="company-data">
                <li>
                    <b>
                        {publicPreview.getCustomerName()}
                    </b>
                </li>
            </ul>
            <div className="row">
                <div
                    className={`col ${validation ? 'validate' : ''}`}
                    data-holder="email"
                >
                    <input
                        required
                        fullWidth
                        id="email"
                        label="Е-поща"
                        name="email"
                        autoFocus
                        placeholder="Е-поща"
                        value={state.email}
                        onChange={handleChage}
                    />
                </div>
            </div>
            <div className="row">
                <div
                    className={`col ${validation ? 'validate' : ''}`}
                    data-holder="password"
                >
                    <input
                        required
                        fullWidth
                        name="password"
                        label="Парола"
                        type={state.viewPw ? 'text' : 'password'}
                        id="password"
                        placeholder="Парола"
                        value={state.password}
                        onChange={handleChage}
                    />
                    <span className={`view-pw ${state.viewPw ? 'on' : ''}`}>
                        <img src={eyeIcon} />
                    </span>
                </div>
            </div>
            <div className="row checkbox-holder">
                <FormControlLabel
                    control={
                        <Checkbox
                            style={{
                                padding: '0',
                                marginRight: '5px',
                            }}
                            name="remember"
                            checked={state.remember}
                            onChange={handleChage}
                        />
                    }
                    label={
                        <>
                            Запомни ме
                        </>
                    }
                />
                {/* <span className="link">
                    Забравена парола
                </span> */}
            </div>
            <SaveButton
                className={`add ${!isReadyToRegister() ? 'disabled' : 'done'}`}
                type="submit"
                loading={state.loading}
            >
                Започнете работа
            </SaveButton>
            <div className="profile-question">
                <span>
                    Нямате профил?
                </span>
            </div>
            <Button
                className="change-screen"
                onClick={() => {
                    publicPreview.setMode('register')
                }}
            >
                Регистрация
            </Button>
        </form>
    )
}

export default Register