import React, { useState, useEffect } from 'react'
import { CaretLeft, CaretDown, CaretRight, CaretDownFill } from 'react-bootstrap-icons'
import { FloatingLabel, Form, Dropdown, Row, Col } from 'react-bootstrap'

import Api from 'helpers/Api'
import Loader from 'components/misc/Loader'
import NoDataFound from 'components/misc/NoDataFound'

function Category(props) {

    const position = props.position || 'start';
    const variant = props.variant || null;
    const size = props.size || null;

    const [categories, setCategories] = useState({
        data: [],
        show: false,
        toggled: [],
        selected: [],
        loading: true,
    });

    useEffect(() => {
        Api.get('store/articles/categories')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setCategories(prev => ({
                        ...prev,
                        data: res.data,
                        loading: false,
                        toggled: res.data.filter(c => c.children.length < 10).map(c => c.id),
                    }));
                }
            });
    }, []);

    useEffect(() => {
        if (Array.isArray(props.selected)) {
            setCategories(prev => ({
                ...prev,
                selected: props.selected.map(s => Number(s))
            }));
        }
    }, [props.selected]);


    useEffect(() => {
        if (Array.isArray(props.selected)) {
            props.selected.map(id => {

                let category = findCategory(id, categories.data);

                if (category) {
                    while (category) {
                        category = category.parent;

                        if (category) {
                            expandCategory(category, false);
                        }
                    }
                }

            })
        }
    }, [props.selected, categories.data]);

    const findCategory = (id, categories, recursive = true) => {

        for (let i = 0; i < categories.length; i++) {
            let category = categories[i];

            if (Number(category.id) === Number(id)) {
                return category;
            }

            let found = findCategory(id, category.children);

            if (found) {
                return found;
            }

        }

    }

    const toggleCategories = () => {
        setCategories(prev => ({
            ...prev,
            show: !prev.show,
        }))
    }

    const expandCategory = (category, check = true) => {

        if (!category) {
            return;
        }

        if (check && isCategoryExpanded(category)) {
            setCategories(prev => ({
                ...prev,
                toggled: prev.toggled.filter(i => Number(i) !== Number(category.id))
            }));
        } else {
            setCategories(prev => ({
                ...prev,
                toggled: prev.toggled.concat(Number(category.id)),
            }));
        }
    }

    const isCategoryExpanded = category => {

        if (!category) {
            return false;
        }

        let expanded = categories.toggled.indexOf(Number(category.id)) > -1;

        if (expanded) {
            return true;
        }

        return false;
    }

    const isCategorySelected = (category, recursive = true) => {

        if (!category) {
            return false;
        }

        let selected = categories.selected.indexOf(Number(category.id)) > -1;

        if (selected) {
            return true;
        }

        if (recursive && category.parent) {
            return isCategorySelected(category.parent);
        }

        return false;
    }


    const toggleCategory = category => {

        if (isCategorySelected(category, true) && !isCategorySelected(category, false)) {

            // todo
        }

        let selected = [...categories.selected];

        if (isCategorySelected(category, false)) {
            selected = selected.filter(i => Number(i) !== Number(category.id));
        } else {
            selected = selected.concat(Number(category.id));
        }

        setCategories(prev => ({
            ...prev,
            selected: selected
        }));

        if (typeof props.onChange === 'function') {
            props.onChange(selected);
        }
    }

    const getCategoriesFilterValue = (def = null) => {
        if (categories.selected.length === 0) {
            return def || 'Няма избрани';
        }

        return categories.selected.length + ' избрани';
    }

    const renderCategories = (categories, level = 0) => {
        return categories.map(category =>
            <React.Fragment key={category.id}>
                <div style={{ display: level === 0 || isCategoryExpanded(category.parent) ? 'flex' : 'none', alignItems: 'center', paddingLeft: (level * 20) + 'px', }}>

                    <span
                        style={{ marginRight: '3px', marginBottom: '3px', cursor: 'pointer', width: '16px' }}
                        onClick={e => expandCategory(category)}
                    >
                        {category.children.length > 0
                            ?
                            isCategoryExpanded(category) ? <CaretDownFill size="13" /> : <CaretRight size="13" />
                            :
                            ''
                        }
                    </span>

                    <Form.Check
                        type="checkbox"
                        id={'cbc-' + category.id}
                        name="categories[]"
                        value={category.id}
                        label={category?.translation?.name || category.name}
                        checked={isCategorySelected(category)}
                        disabled={isCategorySelected(category.parent)}
                        onChange={e => toggleCategory(category)}
                        style={{
                            minHeight: 'auto',
                            margin: 0
                        }}
                    />
                </div>
                {isCategoryExpanded(category) && renderCategories(category.children, level + 1)}
            </React.Fragment>
        );
    }


    return (
        <Dropdown drop="down" onToggle={toggleCategories} show={categories.show}>
            {variant
                ?
                <Form.Control
                    variant={variant}
                    size={size}
                    type="text"
                    placeholder="Категории"
                    value={getCategoriesFilterValue('Категории')}
                    onClick={toggleCategories}
                    readOnly
                    style={{ cursor: 'pointer' }}
                />
                :
                <FloatingLabel label="Категории">
                    <Form.Control
                        type="text"
                        placeholder="Категории"
                        value={getCategoriesFilterValue()}
                        onClick={toggleCategories}
                        readOnly
                        style={{ cursor: 'pointer' }}
                    />
                </FloatingLabel>
            }
            <Dropdown.Menu align="start" className={position} style={{ padding: '15px', minWidth: '350px', maxHeight: '450px', overflow: 'auto' }}>
                {categories.loading
                    ?
                    <Loader />
                    :
                    categories.data.length === 0
                        ?
                        <NoDataFound size="sm">
                            Няма категории за показване!
                        </NoDataFound>
                        :
                        renderCategories(categories.data)
                }
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default Category;