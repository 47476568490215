import { useState } from "react"

function useSelectDocuments({ getDocuments }) {
    const [selectedDocumentsIds, setSelectedDocumentsIds] = useState([])

    function isSelected(documentId) {
        if (selectedDocumentsIds.includes(documentId)) {
            return true
        }

        return false
    }

    function selectDocument(documentId) {
        let ids = []
        if (isSelected(documentId)) {
            ids = selectedDocumentsIds.filter((sdi) => sdi !== documentId)
        } else {
            ids = [...selectedDocumentsIds, documentId]
        }

        setSelectedDocumentsIds(ids)
    }

    function areAllSelected() {
        if (selectedDocumentsIds.length === getDocuments().length) {
            return true
        }

        return false
    }

    function selectAllDocument() {
        let ids = []

        if (!areAllSelected()) {
            getDocuments().map((d) => {
                ids = [...ids, d.id]
            })
        }

        setSelectedDocumentsIds(ids)
    }

    function getSelectedCount() {
        return selectedDocumentsIds.length
    }

    function getSelectedDocumentsIds() {
        return selectedDocumentsIds
    }

    function getSelectedDocumentsTypeIds() {
        const typeIds = new Set()
        getDocuments().map((d) => {
            if (isSelected(d.id)) {
                typeIds.add(String(d.type_id))
            }
        })

        return Array.from(typeIds)
    }

    return {
        selectAllDocument,
        selectDocument,
        isSelected,
        getSelectedCount,
        getSelectedDocumentsIds,
        getSelectedDocumentsTypeIds,
        areAllSelected
    }
}

export default useSelectDocuments