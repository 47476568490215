import { useState, useImperativeHandle, forwardRef, useEffect } from "react"

//components
import RedditTextField from "components/misc/RedditTextField"

//librarires
import Skeleton from "react-loading-skeleton"
import { Tooltip } from "@material-ui/core"

//hooks
import useFetchCompaniesFromLocalDB from "../hooks/useFetchCompaniesFromLocalDB"
import useFetchCompaniesFromTradeRegistry from "../hooks/useFetchCompaniesFromTradeRegistry"

//images
import checkIcon from "assets/img/login/icons/check.png"

function FindCompany(
  {
    label = null, //Label на полето. Ако не се подаде label, автоматично взима името на полето от COMPANY_INPUT_TYPES
    name = null, //Име на полето
    initialValue = "", //Първоначална стойност на полето
    errorMessage = null, //Съобщение при грешка
    disabled = false,
    disableFetchFromTradeRegistry = false, //Дали да се изключи търсенето от търговския регистър
    disableFetchFromLocalDB = false, //Дали да се изключи търсенето от локалната база
    disableDropdown = false, //Дали да се изключи падащото меню
    localDBFetchURL = "autocomplete/find-customer", //API URL за търсене от локалната база
    tradeRegistryFetchURL = "autocomplete/trade-registry", //API URL за търсене от търговския регистър
    companyInputTypeId = 1, //Тип на търсене. 1 - по име на фирма, 2 - по ЕИК
    setInputValueCB, //Записва стойността в parent компонента
    setSelectedCompanyCB, //Записва избраната фирма в parent компонента
    inputIdName = null, //Име на полето за id на избраната фирма
    centeredLabel = false, //Дали да е центрирано label-а
    disableCheckOnSelectCompany = false, //Дали да се изключи зелената отметка, когато се избере фирма
    autoFocus = false,
    autoSelectWhenOneResult = false, //Автоматично да се избере фирма, ако има само един резултат
    companyEikOnly = false, //Дали да се търси само по ЕИК
  },
  ref
) {
  const COMPANY_INPUT_TYPES = {
    1: {
      name: "Име на фирма",
      readableField: "name",
    },
    2: {
      name: "ЕИК",
      readableField: "eikegn",
    },
  }

  const [inputValue, setInputValue] = useState(initialValue)
  const [showDropdown, setShowDropdown] = useState(false)
  const [selectedCompany, setSelectedCompany] = useState(null)

  const { getCompaniesFromLocalDB, loadingCompaniesFromLocalDB } =
    useFetchCompaniesFromLocalDB({
      fetchUrl: localDBFetchURL,
      inputValue,
      disableFetch: disableFetchFromLocalDB,
    })
  const { getCompaniesFromTradeRegistry, loadingCompaniesFromTradeRegistry } =
    useFetchCompaniesFromTradeRegistry({
      fetchUrl: tradeRegistryFetchURL,
      inputValue,
      disableFetch: disableFetchFromTradeRegistry,
    })

  useEffect(() => {
    if (!inputValue) {
      setInputValue(initialValue);
    }
  }, [initialValue])

  useEffect(() => {
    if (autoSelectWhenOneResult) {
      if (getCompaniesFromLocalDB()?.total === 1) {
        handleSelectCustomer((getCompaniesFromLocalDB()?.items || [])?.[0])

        return
      }

      if (getCompaniesFromTradeRegistry()?.total === 1) {
        handleSelectCustomer(
          (getCompaniesFromTradeRegistry()?.items || [])?.[0]
        )

        return
      }
    }
  }, [
    (getCompaniesFromLocalDB()?.items || [])?.length,
    (getCompaniesFromTradeRegistry()?.items || [])?.length,
    autoSelectWhenOneResult,
  ])

  useImperativeHandle(ref, () => ({
    getFoundResults: () => ({
      companiesFromLocalDB: getCompaniesFromLocalDB(),
      companiesFromTradeRegistry: getCompaniesFromTradeRegistry(),
    }),
    forceSetInputValue: (value) => {
      handleSetInputValue(value)
    },
  }))

  function handleSetInputValue(value) {
    if (companyEikOnly && isNaN(value)) return

    setInputValue(value)
    setInputValueCB?.(value)
  }

  function handleInputChange(e) {
    handleSetInputValue(e.target.value)
  }

  function shouldDropdownBeSmall() {
    if (!(getCompaniesFromLocalDB()?.total || 0) || disableFetchFromLocalDB) {
      return true
    }

    if (
      !(getCompaniesFromTradeRegistry()?.total || 0) ||
      disableFetchFromTradeRegistry
    ) {
      return true
    }

    return false
  }

  function handleSetShowDropdown(show = true) {
    if (disableDropdown) {
      return
    }

    if (show) {
      if (loadingCompaniesFromLocalDB || loadingCompaniesFromTradeRegistry) {
        setShowDropdown(true)

        return
      }

      if (disableFetchFromLocalDB && disableFetchFromTradeRegistry) {
        setShowDropdown(false)

        return
      }
      setShowDropdown(true)

      return
    }

    setShowDropdown(false)
  }

  function handleSelectCustomer(customer) {
    setSelectedCompany(customer)
    handleSetInputValue(
      customer?.info?.[!isNaN(inputValue) ? "eikegn" : "name"] //Ако въведената стойност е число, значи търсим по ЕИК и автоматично избира ЕИК
    )
    setSelectedCompanyCB?.(customer)
  }

  function isCompanySelected(companyId) {
    return !!(selectedCompany?.id === companyId)
  }

  return (
    <>
      <div className="company-field-container">
        <RedditTextField
          size="small"
          variant="filled"
          margin="dense"
          fullWidth
          label={label || COMPANY_INPUT_TYPES[companyInputTypeId].name}
          name={name}
          value={inputValue}
          onChange={handleInputChange}
          error={!!errorMessage}
          helperText={errorMessage}
          disabled={!!disabled}
          onFocus={handleSetShowDropdown}
          className={`${centeredLabel ? "centered-label" : ""} ${!disableCheckOnSelectCompany ? "with-chech" : ""
            }`}
          onBlur={() => {
            setTimeout(() => {
              handleSetShowDropdown(false)
            }, 200)
          }}
          autoFocus={autoFocus}
        />
        {selectedCompany?.id && !disableCheckOnSelectCompany ? (
          <Tooltip title="Компанията е избрана от списъка">
            <img src={checkIcon} className="check" />
          </Tooltip>
        ) : (
          <></>
        )}
        {!disableDropdown ? (
          <div
            className={`found-companies-dropdown ${showDropdown ? "show" : ""
              }  ${shouldDropdownBeSmall() ? "small" : ""} ${centeredLabel ? "centered-label" : ""
              }`}
          >
            {loadingCompaniesFromTradeRegistry ||
              loadingCompaniesFromLocalDB ? (
              <Skeleton count={5} height={30} />
            ) : !getCompaniesFromLocalDB()?.total &&
              !getCompaniesFromTradeRegistry()?.total ? (
              <p>Няма намерени резултати</p>
            ) : (
              <div className="row">
                {getCompaniesFromLocalDB()?.total ? (
                  <div className="col">
                    <div className="head">Моите клиенти</div>
                    <ul>
                      {(getCompaniesFromLocalDB().items || []).map(
                        (customer) => (
                          <li
                            key={"c-" + customer.id}
                            onClick={() => {
                              handleSelectCustomer(customer)
                            }}
                            className={
                              isCompanySelected(customer.id) ? "selected" : ""
                            }
                          >
                            {customer?.info?.name}
                            <span
                              style={{ display: "block", fontSize: "10px" }}
                            >
                              ЕИК: {customer?.info?.eikegn},{" "}
                              {customer.addresses[0]?.settlement?.name}
                            </span>
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                ) : (
                  <></>
                )}
                {getCompaniesFromTradeRegistry()?.total ? (
                  <div className="col">
                    <div className="head">От търговския регистър</div>
                    <ul>
                      {(getCompaniesFromTradeRegistry().items || []).map(
                        (customer) => (
                          <li
                            key={"c-" + customer.id}
                            onClick={() => {
                              handleSelectCustomer(customer)
                            }}
                            className={
                              isCompanySelected(customer.id) ? "selected" : ""
                            }
                          >
                            {customer?.info?.name}
                            <span
                              style={{ display: "block", fontSize: "10px" }}
                            >
                              ЕИК: {customer?.info?.eikegn},{" "}
                              {customer.addresses[0]?.settlement?.name}
                            </span>
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            )}
          </div>
        ) : (
          <></>
        )}
        {inputIdName && selectedCompany?.id ? (
          <input type="hidden" name={inputIdName} value={selectedCompany?.id} />
        ) : (
          <></>
        )}
      </div>
    </>
  )
}

export default forwardRef(FindCompany)
