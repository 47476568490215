import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import Api from 'helpers/Api'
import { buildSearchParams, useQuery } from 'helpers/Url'
import { useLocation, Link, useHistory } from 'react-router-dom'
import { useAuthDataContext } from 'providers/Auth'
import { Button } from '@material-ui/core'

import Options from './partials/Options'

import Question from 'components/modals/Question'
// import Filter from './partials/Filter'
import AddOrEdit from './partials/AddOrEdit'

//misc
import MenuOptions from 'components/misc/MenuOptions'
import Pagination from 'components/misc/Pagination'
import Loader from 'components/misc/Loader'
import NoDataFound from 'components/misc/NoDataFound'
import PriceValue from 'components/partials/PriceValue'
import moment from 'moment'

let timeout

function Index(props, ref) {

    const location = useLocation()
    const history = useHistory()
    const query = useQuery()
    const auth = useAuthDataContext()

    const addOrEditModalRef = useRef(null)
    const deleteModalRef = useRef(null)

    const [state, setState] = useState({
        loading: true,
        refresh: false,
        setFilter: false,
        data: [],
        pages: 0,
        total: 0,
        filter: {
            page: 1,
        },
    })

    useImperativeHandle(ref, () => ({
        add: () => {
            handleAdd()
        },

    }))

    useEffect(() => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: query.get('page') || 1,
                search: query.get('search') || ''
            },
            refresh: moment().unix()
        }))
    }, [location.search])

    useEffect(() => {
        if (state.setFilter) {
            history.push('?' + buildSearchParams(query, state.filter))
        }

    }, [state.setFilter])

    useEffect(() => {
        if (state.refresh) {
            loadData()
        }
    }, [state.refresh])

    useEffect(() => {
        refresh()
    }, [auth.getUser().getStoreId()])

    const loadData = () => {

        loading(true)

        let url = 'store/subscriptions/types/all'

        Api.get(url, {
            params: state.filter
        }).then(res => {
            setState(prev => ({
                ...prev,
                data: res.data.items,
                total: res.data.total,
                pages: res.data.pages
            }))

            if (res.data.pages > 0 && state.filter.page > res.data.pages) {
                return handlePage({ selected: res.data.pages - 1 })
            }

            loading(false)

        })
    }

    const loading = (loading) => {
        setState(prev => ({
            ...prev,
            loading: Boolean(loading)
        }))
    }

    const refresh = (reset = true) => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: reset ? 1 : prev.filter.page
            },
            setFilter: moment().unix(),
            refresh: moment().unix(),
        }))
    }

    const handleAdd = () => {
        let modal = addOrEditModalRef.current

        modal.add()

        modal.onSuccess(() => {
            refresh(true)
        })
    }

    // update
    const handleEdit = id => {
        let modal = addOrEditModalRef.current

        modal.edit(id)

        modal.onSuccess(() => {
            refresh(false)
        })
    }

    // delete
    const handleDelete = id => {
        let modal = deleteModalRef.current

        modal.open()
        modal.onSuccess(() => {
            handleDestroy(id)
        })
    }

    const handleDestroy = id => {
        Api.post('store/subscriptions/types/delete', {
            id: id
        }).then(res => {
            if (res.data.success) {
                refresh(false)
            }
        })
    }


    // Search
    const handleSearch = (key, val, delay = 300) => {
        clearTimeout(timeout)

        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                [key]: val,
            },
        }))

        timeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    page: 1
                },
                setFilter: moment().unix()
            }))
        }, delay)
    }

    const handlePage = page => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: page.selected + 1,
            },
            setFilter: moment().unix()
        }))
    }

    return (
        <>

            <AddOrEdit
                ref={addOrEditModalRef}
                refresh={refresh}
            />

            <Question
                ref={deleteModalRef}
                mainMessage="Сигурни ли сте?"
                agreeBtnText="Изтриване"
                agreeBtnClass="remove"
            />


            <div id="head">
                <div className="title">
                    Типове абонаменти
                </div>
                <Button className="add" onClick={handleAdd}>
                    Добави тип
                </Button>
            </div>
            <hr />

            {/* <Filter
                        filter={state.filter}
                        handleSearch={handleSearch}
                    /> */}

            {state.loading
                ?
                <Loader />
                :
                state.data.length === 0 ?
                    <NoDataFound />
                    :
                    <>
                        <table className="type-outer">
                            <thead>
                                <tr>
                                    <th className="id">ID</th>
                                    <th>Име</th>
                                    <th className="text-right">Цена</th>
                                    <th>Период</th>
                                    <th className="options">
                                        Опции
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {state.data.map(c =>
                                    <React.Fragment key={'c-' + c.id}>
                                        <tr

                                        >
                                            <td className="id">
                                                {c.id}
                                            </td>

                                            <td>
                                                <span className="link" onClick={e => handleEdit(c.id)}>
                                                    {c.name}
                                                </span>
                                            </td>

                                            <td className="text-right">
                                                <PriceValue>{c.price}</PriceValue>
                                            </td>

                                            <td>
                                                {c.period_text}
                                            </td>

                                            <td className="options" style={{ width: '65px' }}>
                                                <MenuOptions>
                                                    <Options
                                                        data={c}
                                                        handleEdit={handleEdit}
                                                        handleDelete={handleDelete}
                                                    />
                                                </MenuOptions>
                                            </td>
                                        </tr>

                                    </React.Fragment>
                                )}
                            </tbody>
                        </table>

                        <Pagination
                            className="mt-3"
                            page={state.filter.page}
                            pages={state.pages}
                            total={state.total}
                            handlePage={handlePage}
                        />
                    </>
            }



        </>
    )
}

export default forwardRef(Index)