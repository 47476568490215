import React, { useState, useImperativeHandle, forwardRef, useEffect, useRef } from 'react'
import { Modal, Button, FloatingLabel, Form, Row, Col } from 'react-bootstrap'
import SaveButton from 'components/misc/Button'
import Loader from 'components/misc/Loader'
import Api from 'helpers/Api'
import { useValidation } from 'helpers/Validation'
import Error from 'components/modals/Error'
import Info from 'components/misc/Info'
import DatePicker from 'components/partials/filter/DatePicker'
import { useAuthDataContext } from 'providers/Auth'
import RedditSelect from 'components/misc/RedditSelect'
import { MenuItem } from '@material-ui/core'
import RedditTextField from 'components/misc/RedditTextField'
import moment from 'moment'

function New(props, ref) {

    const auth = useAuthDataContext()

    const defaultData = {}

    const [state, setState] = useState({
        show: false,
        params: null,
        data: defaultData,
        loading: false,
        onSuccess: null,
        onClose: null,
    })

    const [depots, setDepots] = useState([])

    const [validations, setValidations] = useValidation()

    const errorModalRef = useRef(null)
    const formRef = useRef(null)

    useImperativeHandle(ref, () => ({
        open: () => {
            handleShow()
        },
        close: () => {
            handleClose()
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }))
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }))
        }
    }))

    useEffect(() => {
        Api.get('store/revisions/depots')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setDepots(res.data)
                }
            })
    }, [auth.getUser().getStoreId()])

    const handleShow = () => {
        setState(prev => ({
            ...prev,
            show: true,
        }))
    }

    const handleClose = () => {
        setState(prev => ({
            ...prev,
            show: false,
            data: defaultData
        }))

        setValidations(null)

        if (typeof state.onClose === 'function') {
            state.onClose()
        }
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: value
            }
        }))
    }

    const handleSave = () => {
        setValidations(null)

        setState(prev => ({
            ...prev,
            loading: true,
        }))

        let url = 'store/revisions/add'

        let data = new FormData(formRef.current)

        Api.post(url, data)
            .then(res => {
                if (typeof state.onSuccess === 'function') {
                    state.onSuccess(res.data)
                }
            })
            .catch(error => {
                const _err = error.response

                if (_err && _err.status) {
                    if (_err.status === 422) {
                        setValidations(_err.data.errors)
                    } else {
                        let modal = errorModalRef.current

                        if (modal) {
                            modal.open(_err.data.error || _err.data.message)
                        }
                    }
                }

            }).finally(() => {
                setState(prev => ({
                    ...prev,
                    loading: false,
                }))
            })
    }

    return (
        <>
            <div className={`${state.show ? "visible" : ""} overlay`}>
                <div
                    className={`popup-primary small complex-modal preview-storage-load-modal revision-modal ${state.show ? "show" : ""}`}
                >
                    <div className="header light-brown">
                        <b>
                            Нова ревизия
                        </b>
                        <p>
                            Lorem Ipsum е елементарен примерен<br /> текст, използван в печатарската
                        </p>
                    </div>
                    <div className="body">
                        <form
                            onSubmit={handleSave}
                            ref={formRef}
                            style={{
                                flex: 1,
                                paddingTop: '20px',
                                paddingBottom: '20px'
                            }}
                        >
                            <div className="row">
                                <div className="col">
                                    <RedditSelect
                                        name="depot_id"
                                        value={state.data.depot_id || ''}
                                        onChange={handleInputChange}
                                        label="Склад"
                                        error={Boolean(validations && validations.depot_id)}
                                        helperText={validations && validations.depot_id && (validations.depot_id[0] || validations.depot_id)}
                                    >
                                        {depots.map(o =>
                                            <MenuItem key={o.id} value={o.id}>
                                                {o?.store?.translation?.name || o?.store?.name} - {o?.translation?.name || o.name}
                                            </MenuItem>
                                        )}
                                    </RedditSelect>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <RedditSelect
                                        name="view_type_id"
                                        value={state.data.view_type_id || ''}
                                        onChange={handleInputChange}
                                        label="Вид"
                                        error={Boolean(validations && validations.view_type_id)}
                                        helperText={validations && validations.view_type_id && (validations.view_type_id[0] || validations.view_type_id)}
                                    >
                                        <MenuItem value={1}>
                                            Всички артикули по категории
                                        </MenuItem>
                                        <MenuItem value={2}>
                                            Избрани артикули
                                        </MenuItem>
                                        <MenuItem value={3}>
                                            Всички артикули с наличност
                                        </MenuItem>
                                        <MenuItem value={4}>
                                            Всички артикули
                                        </MenuItem>
                                    </RedditSelect>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <RedditSelect
                                        name="date_type"
                                        value={state.data.date_type || ''}
                                        onChange={handleInputChange}
                                        label="Наличност към"
                                    >
                                        <MenuItem value="now">
                                            Наличност към момента
                                        </MenuItem>
                                        <MenuItem value="date">
                                            Наличност към дата
                                        </MenuItem>
                                    </RedditSelect>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <RedditTextField
                                        size="small"
                                        variant="filled"
                                        margin="dense"
                                        fullWidth
                                        type="datetime-local"
                                        label="Към дата"
                                        name="to_date"
                                        value={state.data.date_type === 'date' && state.data.to_date ? state.data.to_date : moment().format('YYYY-MM-DDTHH:mm')}
                                        onChange={handleInputChange}
                                        error={Boolean(validations?.to_date)}
                                        helperText={validations?.to_date && (validations.to_date[0] || validations.to_date)}
                                        disabled={state.data.date_type === 'date' ? false : true}
                                    />
                                </div>
                                <div className="col">
                                    <RedditSelect
                                        name="price_mode_id"
                                        value={state.data.price_mode_id || ''}
                                        onChange={handleInputChange}
                                        label="Калкулация по"
                                        error={Boolean(validations && validations.price_mode_id)}
                                        helperText={validations && validations.price_mode_id && (validations.price_mode_id[0] || validations.price_mode_id)}
                                    >
                                        <MenuItem value={1}>
                                            Продажни цени
                                        </MenuItem>
                                        <MenuItem value={2}>
                                            Доставни цени
                                        </MenuItem>
                                    </RedditSelect>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="footer">
                        <Button
                            className="cancel"
                            onClick={handleClose}
                            style={{
                                marginLeft: 'auto',
                                minWidth: '100px'
                            }}
                        >
                            Отказ
                        </Button>
                        <SaveButton
                            loading={state.loading}
                            className="save"
                            onClick={handleSave}
                        />
                    </div>
                </div>
            </div>
            <Error
                ref={errorModalRef}
            />
        </>
    )
}

export default forwardRef(New)