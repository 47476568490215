//libraries
import MenuItem from "@material-ui/core/MenuItem"

//images
import editIcon from "assets/img/app/icons/edit.png"
import deleteIcon from "assets/img/app/icons/delete.png"
import cloneIcon from "assets/img/customers/icons/clone.png"

function CalendarEditOptions({ handleEdit, handleDelete, handleClone }) {
  return (
    <>
      <MenuItem onClick={handleEdit}>
        <img src={editIcon} />
        Редакция
      </MenuItem>
      <MenuItem onClick={handleClone}>
        <img src={cloneIcon} />
        Клонирай събитието
      </MenuItem>
      <MenuItem onClick={handleDelete}>
        <img src={deleteIcon} />
        Изтриване
      </MenuItem>
    </>
  )
}

export default CalendarEditOptions
