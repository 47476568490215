import { forwardRef, useState, useEffect, useRef, useImperativeHandle } from 'react'

//helpers
import Api from 'helpers/Api'

//components
import NoDataFound from 'components/misc/NoDataFound'

//providers
import { useAppContext } from 'providers/App'

//libraries
import { Tooltip, FormControlLabel, Checkbox } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

//images
import folderOpenedIcon from 'assets/img/articles/icons/folder-opened.png'
import folderIcon from 'assets/img/articles/icons/folder.png'
import houseIcon from 'assets/img/articles/icons/house.png'
import moment from 'moment'

let timeout
function Index({ id, onSuccess, handleStopLoadingSave, loadingSave }, ref) {
    const app = useAppContext()

    const formRef = useRef(null)

    const INITIAL_STATE = {
        data: null,
        pages: 0,
        total: 0,
        totals: {},
        loading: true,
        filter: {
            page: 1,
        },
        refresh: false,
    }
    const [state, setState] = useState(INITIAL_STATE)

    const [categories, setCategories] = useState([])
    const [expandedCategories, setExpandedCategories] = useState([])

    useEffect(() => {
        Api.get('store/articles/categories')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setCategories(res.data)
                }
            })
            .finally(() => {
                loading(false)
            })

        return () => {
            loading(true)
            setCategories([])
            setState(INITIAL_STATE)
            setExpandedCategories([])
        }
    }, [])

    useEffect(() => {
        loading(true)

        Api.get('store/articles/categorisation', {
            params: {
                id,
                ...state.filter,
            }
        }).then(res => {
            setState(prev => ({
                ...prev,
                data: res.data,
                filter: {
                    ...prev.filter,
                    ...res.data.filter,
                },
            }))

            if (res.data.pages > 0 && state.filter.page > res.data.pages) {
                return handlePage({ selected: res.data.pages - 1 })
            }

            sorting(false)
        })
    }, [id, state.refresh])

    useImperativeHandle(ref, () => ({
        save: () => {
            handleSave()
        }
    }))

    const loading = (loading) => {
        setState(prev => ({
            ...prev,
            loading: Boolean(loading)
        }))
    }

    const sorting = (sorting) => {
        setState(prev => ({
            ...prev,
            sorting: Boolean(sorting)
        }))
    }

    const handleSearch = (key, val, delay = 300) => {
        clearTimeout(timeout)

        if (typeof key === 'object') {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    ...key
                },
            }))
        } else {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    [key]: val,
                },
            }))
        }

        timeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    page: 1
                },
                refresh: moment().unix()
            }))
        }, delay)
    }

    const handlePage = page => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: page.selected + 1,
            },
            refresh: moment().unix()
        }))
    }

    const handleSort = (sort, order) => {
        setState(prev => ({
            ...prev,
            sorting: true,
            filter: {
                ...prev.filter,
                sort: sort,
                order: order,
                page: 1,
            },
            refresh: moment().unix()
        }))
    }

    const handleSave = () => {
        if (loadingSave) return

        const data = new FormData(formRef.current)

        data.append('id', id)
        data.append('master_cat_id', state.data.master_cat_id || '')

        Api.post('store/articles/categorisation', data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        })
            .then(res => {
                if (typeof onSuccess === 'function') onSuccess(res.data)

                app.showSuccess()
            })
            .catch(() => {
                app.showError()
            })
            .finally(() => {
                handleStopLoadingSave()
            })
    }

    // const renderCategories = (categories, level = 0) => {
    //     return categories.map(category =>
    //         <Fragment key={category.id}>
    //             <Row>
    //                 <Col style={{ paddingLeft: (level * 20) + 'px', display: 'flex', alignItems: 'center' }}>
    //                     <Form.Check
    //                         type="checkbox"
    //                         id={'acbc-' + category.id}
    //                         name="categories[]"
    //                         value={category.id}
    //                         label={category?.translation?.name || category.name}
    //                         disabled={category.children.length > 0}
    //                         checked={isCategorySelected(category.id)}
    //                         onChange={e => toggleCategory(category)}
    //                     />
    //                     {isCategorySelected(category.id) &&
    //                         <span className="master" style={{ marginLeft: '10px', }} onClick={e => setMasterCategory(category)}>
    //                             {isCategoryMaster(category.id) ? <HouseDoorFill /> : ''}
    //                         </span>
    //                     }
    //                 </Col>
    //             </Row>
    //             {renderCategories(category.children, level + 1)}
    //         </Fragment>
    //     );
    // }

    const renderCategories = (categories, level = 0) => {
        return categories.map(category =>
            <div className="row" key={category.id}>
                <div className="col">
                    {level === 0 || isCategoryExpanded(category.parent) ?
                        <div
                            className="category-row-holder"
                            style={{ paddingLeft: (level * 20) + 'px', }}
                        >
                            <div
                                onClick={() => {
                                    expandCategory(category)
                                }}
                                className="category-folder-indicator"
                            >

                                {category.children.length > 0
                                    ?
                                    <span className={`sign-indicator ${isCategoryExpanded(category) ? 'minus' : 'plus'}`}>
                                    </span>
                                    :
                                    <>
                                    </>
                                }
                                <img
                                    src={isCategoryExpanded(category) ? folderOpenedIcon : folderIcon}
                                    style={{
                                        heigh: '19px',
                                        objectFit: 'scale-down',
                                        flexShrink: 0,
                                        marginLeft: 'auto'
                                    }}
                                />
                            </div>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        style={{
                                            padding: "0",
                                            marginRight: "5px",
                                        }}
                                        checked={isCategorySelected(category.id)}
                                        color="primary"
                                        onChange={() => {
                                            toggleCategory(category)
                                        }}
                                        disabled={isCategorySelected(category.parent_id)}
                                    />
                                }
                                style={{
                                    marginLeft: 0,
                                    color: hasChildCategorySelected(category) ? '#0e61dd' : '#2c2c2c',
                                }}
                                label={category?.translation?.name || category.name}
                            />

                            {isCategorySelected(category.id) ?
                                <Tooltip title={`${isCategoryMaster(category.id) ? 'Премахни' : 'Направи'} главна категория`}>
                                    <span
                                        className={`master ${isCategoryMaster(category.id) ? 'active' : ''}`}
                                        onClick={() => {
                                            setMasterCategory(category)
                                        }}
                                    >
                                        {isCategoryMaster(category.id) ?
                                            <img
                                                src={houseIcon}
                                            />

                                            :
                                            <></>
                                        }
                                    </span>
                                </Tooltip>
                                :
                                <>
                                </>
                            }
                        </div>
                        :
                        <>
                        </>
                    }
                    {isCategoryExpanded(category) ?
                        renderCategories(category.children, level + 1)
                        :
                        <>
                        </>
                    }
                </div>
            </div>
        )
    }

    const toggleCategory = category => {
        if (isCategorySelected(category.id)) {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    categories: prev.data.categories.filter(c => Number(c.id) !== Number(category.id)),
                    master_cat_id: isCategoryMaster(category.id) ? null : prev.data.master_cat_id,
                }
            }))
        } else {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    categories: prev.data.categories.concat(category),
                    master_cat_id: prev.data.categories.length === 0 ? category.id : prev.data.master_cat_id,
                }
            }))
        }
    }

    const isCategorySelected = id => {
        return Boolean((state.data?.categories || []).find(c => Number(c.id) === Number(id)))
    }

    const isCategoryMaster = id => {
        return Number(state.data.master_cat_id) === Number(id)
    }

    const hasChildCategorySelected = category => {
        for (let i = 0; i < category.children.length; i++) {
            let child = category.children[i]

            if (isCategorySelected(child.id)) {
                return true
            }

            if (hasChildCategorySelected(child)) {
                return true
            }
        }

        return false
    }

    const setMasterCategory = category => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                master_cat_id: prev.data.master_cat_id === category.id ? null : category.id,
            }
        }))
    }

    const expandCategory = (category, check = true) => {
        if (!category) return

        if (!category.children.length) return

        if (check && isCategoryExpanded(category)) {
            setExpandedCategories(prev => prev.filter(i => Number(i) !== Number(category.id)))
        } else {
            setExpandedCategories(prev => prev.concat(Number(category.id)))
        }
    }

    const isCategoryExpanded = category => {

        if (!category) {
            return false
        }

        let expanded = expandedCategories.indexOf(Number(category.id)) > -1

        if (expanded) {
            return true
        }

        return false
    }

    function renderHiddenInputs() {
        return (
            (state.data?.categories || []).map(category => (
                <input
                    hidden
                    key={category.id}
                    value={category.id}
                    name="categories[]"
                />
            ))

        )
    }

    return (
        <div className="right-form-holder">
            {state.loading ?
                <>
                    <Skeleton count={1} height={35} />
                    <Skeleton count={1} height={35} />
                    <Skeleton count={1} height={35} />
                    <Skeleton count={1} height={35} />
                    <Skeleton count={1} height={35} />
                </>
                :
                categories.length === 0
                    ?
                    <NoDataFound size="sm">
                        Няма създадени категории!
                        <div style={{ fontSize: '0.85rem' }}>
                            Създайте категории и добавете артикула към тях.
                        </div>
                    </NoDataFound>
                    :
                    <div className="article-categories">
                        <form ref={formRef}>
                            {renderCategories(categories)}
                            {renderHiddenInputs()}
                        </form>
                    </div>
            }
        </div>
    )
}

export default forwardRef(Index)