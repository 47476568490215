import { useEffect, useState } from "react"

//plugins
import axios from "axios"

//helpers
import Api from 'helpers/Api'

//hooks
import useCompanyDataHook from "./useCompanyDataHook"

//providers
import { useRegisterContext } from "./RegisterProvider"

let timeout
const useCompanyVatFieldHook = () => {
    const register = useRegisterContext()
    const { companyData } = register
    const {
        handleChange
    } = useCompanyDataHook()

    const [vatFieldState, setVatFieldState] = useState({
        vatLoading: true,
        renewCheckVat: false
    })

    useEffect(() => {
        if(!companyData?.data?.eik) return
        
        clearTimeout(timeout)
        timeout = setTimeout(() => {
            handleCheckCustomerVatNumber()
        }, 500)
    }, [companyData?.eik, companyData?.data?.eik])

    const handleCheckCustomerVatNumber = () => {
        const vatNumber = companyData.data?.vatNumber || `BG${companyData?.data?.eik}` || null

        if (!vatNumber || !companyData?.eik) return

        checkVies(vatNumber)
    }

    const setVatNumberValidity = vatIsValid => {
        register.setCompanyDataState(prev => ({
            ...prev,
            vatIsValid
        }))
    }

    const setVatNumber = vatNumber => {
        register.setCompanyDataState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                vatNumber
            }
        }))
    }

    const checkVies = number => {
        if (vatFieldState.viesRequest) vatFieldState.viesRequest.cancel()

        const viesRequest = axios.CancelToken.source()

        setVatFieldState(prev => ({
            ...prev,
            viesRequest
        }))
        setVatLoading(true)

        Api.get(`vies/check?vat_number=${number}`, {
            cancelToken: viesRequest.token
        })
            .then(res => {
                const { valid } = res.data
                setVatNumberValidity(valid)
                if (valid) setVatNumber(number)
            })
            .finally(() => {
                setVatLoading(false)
                renewCheckVat(false)
                setTimeout(() => {
                    renewCheckVat(true)
                }, 3000)
            })
    }

    const setVatLoading = vatLoading => {
        register.setVatNumberLoading(vatLoading)
    }

    const renewCheckVat = renewCheckVat => {
        setVatFieldState(prev => ({
            ...prev,
            renewCheckVat
        }))
    }

    /**
 * 
 * @returns дали ИН ПО ЗДДС е валиден
 */
    const isVatNumberValid = () => {
        let valid = {
            isValid: false,
            hasVat: false
        }
        if (String(companyData?.vatIsValid).length > 0) {
            valid = {
                ...valid,
                hasVat: true
            }
            if (companyData?.vatIsValid) {
                valid = {
                    ...valid,
                    isValid: true
                }
            }
        }

        return valid
    }

    const handleInputChange = e => {
        handleChange(e)
    }

    return {
        companyData,
        vatFieldState,
        isVatNumberValid,
        handleInputChange,
        handleCheckCustomerVatNumber
    }
}

export default useCompanyVatFieldHook