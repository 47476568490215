import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

//plugins
import { Modal, FloatingLabel, Form, Row, Col, ButtonGroup, InputGroup } from 'react-bootstrap'
import { Button, MenuItem, InputAdornment, Tabs, Tab, FormControlLabel, Radio } from '@material-ui/core'
//helpers
import Api from 'helpers/Api'
import { useValidation } from 'helpers/Validation'
import { useNestedState } from 'helpers/NestedState'

//providers
import { useLanguageContext } from 'providers/Language'

//misc
import SaveButton from 'components/misc/Button'

import Success from 'components/modals/Success'
import Error from 'components/modals/Error'
import SelectPrepaidPayments from './SelectPrepaidPayments'
import { calcDiscount } from 'helpers/Price'
import Refs from 'Refs'
import Loader from 'components/misc/Loader'
import RedditTextField from 'components/misc/RedditTextField'
import RedditSelect from 'components/misc/RedditSelect'
import CloseBillForm from './CloseBillForm'
import moment from 'moment'

let timeout

function Close(props, ref) {

    const langs = useLanguageContext()

    const defaultData = {
        mix: false,
        fromClientBalance: null, // дали от портфейла ще се вади
        usages: {}, // от кои авансови плащания ще се вади и каква сума
        usedSum: null, // каква сума ще се вади от портфейла/авансовите плащания
    }

    const defaultStornoPaymentTabId = 'i-0d-0'

    const [state, setState] = useNestedState({
        show: false,
        id: null,
        data: defaultData,
        paymentsForStorno: [],
        stornoPaymentTabId: defaultStornoPaymentTabId,
        prepaidPayments: [],
        balance: null,
        currency: null,
        dataLoading: false,
        loading: false,
        onSuccess: null,
        onEntering: null,
        onExiting: null,
        onClose: null,
        onSave: null,
    })

    const [validations, setValidations] = useValidation()

    const [paymentMethods, setPaymentMethods] = useState([])

    const successModalRef = useRef(null)
    const errorModalRef = useRef(null)
    const selectPrepaidPaymentsModalRef = useRef(null)
    const formRef = useRef(null)

    useImperativeHandle(ref, () => ({
        open: (id) => {
            open(id)
        },
        hide: () => {
            hide()
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }))
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }))
        },
        onSave: fn => {
            setState(prev => ({
                ...prev,
                onSave: fn
            }))
        }
    }))

    useEffect(() => {
        loadData()
    }, [state.refresh])

    useEffect(() => {
        Api.get('store/accounts/payment-methods')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setPaymentMethods(res.data)
                }
            })
    }, [])

    useEffect(() => {
        setState(prev => ({
            ...prev,
            loading: Boolean(props.loading)
        }))
    }, [props.loading])

    useEffect(() => {
        setValidations(props.validations)
    }, [props.validations])


    function handleTabChange(_, tabId) {
        setStornoPaymentTabId(tabId)
    }

    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }))

        onEntering?.()
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }))

        onExiting?.()
    }

    const open = (id) => {
        setState(prev => ({
            ...prev,
            data: defaultData,
            id: id,
            stornoPaymentTabId: defaultStornoPaymentTabId,
            refresh: moment().unix()
        }))

        show()
    }

    const handleClose = e => {

        if (e) {
            e.preventDefault()
        }

        setState(prev => ({
            ...prev,
            data: defaultData,
            id: null,
            stornoPaymentTabId: defaultStornoPaymentTabId,
        }))

        setValidations(null)

        if (typeof state.onClose === 'function') {
            hide(state.onClose)
        } else {
            hide()
        }
    }

    const loadData = () => {
        if (!state.id) {
            return
        }

        setState(prev => ({
            ...prev,
            dataLoading: true,
        }))

        let request

        if (typeof state.id === 'object') {
            request = Api.post('store/accounts/close-by-data', state.id)
        } else {
            request = Api.get('store/accounts/close-by-id', {
                params: {
                    id: state.id,
                }
            })
        }

        request.then(res => {
            let account = res.data.account

            if (!account.paymethod_id) {
                account.paymethod_id = 1
            }

            account.pay_total = account.total_remain_sum

            let payments = res.data.payments_for_storno

            setState(prev => ({
                ...prev,
                data: account,
                paymentsForStorno: payments,
                prepaidPayments: res.data.prepaid_payments,
                balance: res.data.balance,
                currency: res.data.currency,
                dataLoading: false,
            }))
        })
    }

    const handleSave = () => {

        setState(prev => ({
            ...prev,
            loading: true,
        }))

        setValidations(null)

        let url = 'store/accounts/close'

        let data = new FormData(formRef.current)

        if (state.id) {
            data.append('id', state.id)
        }

        data.append('is_mix', isMix() ? 1 : 0)

        data.append('paymethod_id', state.data.paymethod_id)

        // портфейл/авансови
        if (getUsedSum() > 0) {
            data.append('used_sum', getUsedSum())

            data.append('from_client_balance', Number(state.data.fromClientBalance))

            if (state.data.usages) {
                for (let [paymentId, usedSum] of Object.entries(state.data.usages)) {
                    data.append('usages[' + paymentId + ']', Number(usedSum))
                }
            }
        }

        if (typeof state.onSave === 'function') {
            return state.onSave(data)
        }

        Api.post(url, data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(res => {
            if (res.data.success === true) {
                if (typeof state.onSuccess === 'function') {
                    state.onSuccess(res.data)
                }

                let modal = successModalRef.current

                if (modal) {
                    hide(() => {
                        modal.open('Сметката беше приключена!')
                    })

                    modal.onClose(state.onClose)
                }

            } else {
                let modal = errorModalRef.current

                if (modal) {
                    hide(() => {
                        modal.open('Възникна грешка при опита да бъде приключена сметката!')
                    })

                    modal.onClose(state.onClose)
                }
            }
        }).catch(error => {
            const _err = error.response

            if (_err && _err.status && _err.status === 422) {
                setValidations(_err.data.errors)
            }

        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false,
            }))
        })
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(name, value, 'data')
    }

    const handleNumberInputChange = e => {
        const name = e.target.name
        const value = Number(e.target.value)

        setState(name, value, 'data')
    }

    const handleStornoInputChange = (name, value, aIndex, pIndex) => {

        setState(prev => ({
            ...prev,
            paymentsForStorno: Object.values({
                ...prev.paymentsForStorno,
                [aIndex]: {
                    ...prev.paymentsForStorno[aIndex],
                    payments: Object.values({
                        ...prev.paymentsForStorno[aIndex].payments,
                        [pIndex]: {
                            ...prev.paymentsForStorno[aIndex].payments[pIndex],
                            [name]: value
                        }
                    })
                }
            })
        }))
    }

    const handleCheckboxChange = e => {
        const name = e.target.name

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: prev.data[name] ? false : true
            }
        }))
    }

    const handleDiscountChange = e => {
        let name = e.target.name
        let value = e.target.value

        if (!value.includes('+') && !value.includes('-') && value.length > 0) {
            value = '-' + value
        }

        if (value.includes('+')) {
            value = value.replace('-', '')
        }

        if (value < -100) {
            value = -100
        }

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                discount: value,
            }
        }))

    }

    useEffect(() => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                pay_total: getTotalReal() - getUsedSum()
            }
        }))
    }, [state.data.discount])

    const getTotalSum = () => {
        let price = parseFloat(state.data.total_sum || 0)

        if (price < 0) {
            return 0
        }

        return price
    }

    const getTotalDiscount = () => {
        return calcDiscount(getTotalSum() - getUsedSum(), state.data.discount)
    }

    const getTotalReal = () => {
        return getTotalSum() + getTotalDiscount()
    }

    const isDiscount = () => {
        return parseFloat(state.data.discount || 0) <= 0
    }

    const getPayTotal = () => {
        if (isMix()) {
            let total = 0

            let methods = state.data.methods

            if (methods) {
                Object.values(methods).map(price => {
                    total += parseFloat(price || 0)
                })
            }

            return total
        }

        let total = state.data.pay_total

        if (total < 0) {
            return 0
        }

        return total
    }

    const getUsedSum = () => {
        return parseFloat(state.data.usedSum || 0)
    }

    const getBalance = () => {
        let balance = getPayTotal() + getUsedSum() - getTotalReal()

        // if (balance > 0) {
        // return balance;
        // }

        return Math.abs(balance)

        return 0
    }

    const getPayTotalFromMethod = (methodId) => {
        let methods = state.data.methods

        if (methods) {
            if (typeof methods[methodId] !== 'undefined') {
                return methods[methodId]
            }
        }

        return ''
    }

    // при фокус да попълва автоматично дължимата сума
    // ако вече е попълнено за 1 начин
    // *При смесено плащане, когато се маркира единия начин на плащане,  автоматично при маркирането на втория начин се смята колко клиентът има да доплати
    const handleMixPaymentFocus = e => {
        let name = e.target.name

        // console.log(state.data.methods)

        if (state.data.methods) {
            let value = getBalance()

            if (value > 0) {
                setState(name, value, 'data')
            }
        }
    }

    const handlePayMethodChange = (methodId) => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                paymethod_id: methodId
            }
        }))
    }

    const handleMixChange = (e) => {
        const { value } = e.target

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                mix: !!Number(value),
            }
        }))
    }

    const isMix = () => {
        return Boolean(state.data.mix)
    }

    const setStornoPaymentTabId = id => {
        setState(prev => ({
            ...prev,
            stornoPaymentTabId: id,
        }))
    }

    // console.log(state.stornoPaymentTabId)

    const showAccount = accountId => {
        let modal = Refs.getInstance().getRef('account')

        if (!modal) {
            return
        }

        hide(() => {
            modal.open(accountId)
        })

        modal.onClose(() => {
            show()
        })
    }

    const showPrepaidPayments = (e) => {
        e.preventDefault()

        let modal = selectPrepaidPaymentsModalRef.current

        if (!modal) {
            return
        }

        hide(() => {
            modal.open({
                payments: state.prepaidPayments,
                balance: state.balance,
                currency: state.currency,
                totalSum: getTotalSum(),
            })
        })

        modal.onSave(data => {
            console.log(data)

            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    fromClientBalance: data.fromClientBalance,
                    usages: data.usages,
                    usedSum: data.usedSum,
                    pay_total: prev.data.total_remain_sum - data.usedSum
                }
            }))

            show()
        })

        modal.onClose(() => {
            show()
        })
    }

    const clearPrepaidPayments = (e) => {
        e.preventDefault()

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                fromClientBalance: null,
                usages: {},
                usedSum: null,
                pay_total: prev.data.total_remain_sum
            }
        }))
    }

    // console.log(validations)

    return (
        <>
            <Success
                ref={successModalRef}
            />

            <Error
                ref={errorModalRef}
            />

            <SelectPrepaidPayments
                ref={selectPrepaidPaymentsModalRef}
            />

            <div className={`${state.show ? "visible" : ""} overlay`}>
                <div
                    className={`popup-primary medium ${state.show ? "show" : ""}`}
                >

                    <div className="body">
                        <h3 style={{
                            marginBottom: '15px'
                        }}>
                            Приключване на сметка
                        </h3>
                        <form onSubmit={handleSave} ref={formRef}>
                            {state.dataLoading && <Loader />}

                            {!state.dataLoading &&
                                <>
                                    <div className="row">
                                        <div className="col">
                                            <RedditTextField
                                                size="small"
                                                variant="filled"
                                                margin="dense"
                                                fullWidth
                                                label={'Общо' + ('(' + (state.data.currency?.prefix || state.data.currency?.sufix || '-') + ')')}
                                                name="total_sum"
                                                value={getTotalSum()}
                                                onChange={handleInputChange}
                                                error={Boolean(validations && validations.total_sum)}
                                                helperText={validations && validations.total_sum && (validations.total_sum[0] || validations.total_sum)}
                                                disabled
                                            />
                                        </div>
                                        <div className="col" style={{
                                            justifyContent: 'center',
                                            maxWidth: '30px'
                                        }}>
                                            <b style={{
                                                fontSize: '25px',
                                                alignSelf: 'center'
                                            }}>
                                                +
                                            </b>
                                        </div>
                                        <div className="col">
                                            <RedditTextField
                                                size="small"
                                                variant="filled"
                                                margin="dense"
                                                fullWidth
                                                label={isDiscount() ? 'Отстъпка(%)' : 'Надценка(%)'}
                                                name="discount"
                                                value={state.data.discount || 0}
                                                onChange={handleDiscountChange}
                                                error={Boolean(validations && validations.discount)}
                                                helperText={validations && validations.discount && (validations.discount[0] || validations.discount)}
                                            />
                                        </div>
                                        <div className="col" style={{
                                            justifyContent: 'center',
                                            maxWidth: '30px'
                                        }}>
                                            <b style={{
                                                fontSize: '25px',
                                                alignSelf: 'center'
                                            }}>
                                                =
                                            </b>
                                        </div>
                                        <div className="col">
                                            <RedditTextField
                                                size="small"
                                                variant="filled"
                                                margin="dense"
                                                fullWidth
                                                label={'Сума' + ('(' + (state.data.currency?.prefix || state.data.currency?.sufix || '-') + ')')}
                                                name="total_real"
                                                value={getTotalReal()}
                                                onChange={handleInputChange}
                                                error={Boolean(validations && validations.total_real)}
                                                helperText={validations && validations.total_real && (validations.total_real[0] || validations.total_real)}
                                                disabled
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <RedditTextField
                                                size="small"
                                                variant="filled"
                                                margin="dense"
                                                fullWidth
                                                step="0.01"
                                                type="number"
                                                label={'Платени сега' + ('(' + (state.data.currency?.prefix || state.data.currency?.sufix || '-') + ')')}
                                                name="pay_total"
                                                value={getPayTotal()}
                                                onChange={handleNumberInputChange}
                                                error={Boolean(validations && validations.pay_total)}
                                                helperText={validations && validations.pay_total && (validations.pay_total[0] || validations.pay_total)}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <RedditTextField
                                                size="small"
                                                variant="filled"
                                                margin="dense"
                                                fullWidth
                                                step="0.01"
                                                type="number"
                                                label={(getTotalReal() - getUsedSum() <= getPayTotal() ? 'Ресто' : 'Дължими') + ('(' + (state.data.currency?.prefix || state.data.currency?.sufix || '-') + ')')}
                                                value={getBalance()}
                                                disabled
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <FormControlLabel
                                                control={
                                                    <Radio
                                                        checked={!isMix()}
                                                        onChange={handleMixChange}
                                                        name="payment_type"
                                                        value={0}
                                                        color="primary"
                                                        style={{
                                                            padding: "2px",
                                                        }}
                                                    />
                                                }
                                                label="Единично плащане"
                                                style={{
                                                    paddingLeft: "10px",
                                                }}
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Radio
                                                        checked={isMix()}
                                                        onChange={handleMixChange}
                                                        name="payment_type"
                                                        value={1}
                                                        color="primary"
                                                        style={{
                                                            padding: "2px",
                                                        }}
                                                    />
                                                }
                                                label="Смесено плащане"
                                                style={{
                                                    paddingLeft: "10px",
                                                }}
                                            />
                                        </div>
                                        <div
                                            className="col"
                                            style={{
                                                justifyContent: "center",
                                            }}
                                        >
                                            {state.data.mix ? (
                                                <>
                                                </>
                                            ) : (
                                                <div className="row">
                                                    <div className="col">
                                                        <RedditSelect
                                                            name="paymethod_id"
                                                            value={state.data.paymethod_id}
                                                            onChange={(e) => {
                                                                handlePayMethodChange(e.target.value)
                                                            }}
                                                            label="Начин на плащане"
                                                        >
                                                            {(paymentMethods || []).map((method) => (
                                                                <MenuItem key={method.id} value={method.id}>
                                                                    {method?.translation?.name || method.name}
                                                                </MenuItem>
                                                            ))}
                                                        </RedditSelect>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            {state.data.mix ?
                                                (paymentMethods || []).map((method) => (
                                                    <div className="row">
                                                        <div className="col">
                                                            <RedditTextField
                                                                size="small"
                                                                variant="filled"
                                                                margin="dense"
                                                                fullWidth
                                                                step="0.01"
                                                                type="number"
                                                                name={`methods[${method.id}]`}
                                                                label={method?.translation?.name || method.name}
                                                                value={getPayTotalFromMethod(method.id)}
                                                                onChange={handleNumberInputChange}
                                                                // onFocus={handleMixPaymentFocus}
                                                                InputProps={
                                                                    state?.currency
                                                                        ? {
                                                                            endAdornment: (
                                                                                <InputAdornment position="end">
                                                                                    {state.currency?.prefix ||
                                                                                        state.currency?.sufix ||
                                                                                        "-"}
                                                                                </InputAdornment>
                                                                            ),
                                                                        }
                                                                        : {}
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                ))
                                                :
                                                <>
                                                </>
                                            }
                                        </div>
                                    </div>
                                    {state.paymentsForStorno.length
                                        ?
                                        <>
                                            <div className="mt-3">
                                                {state.paymentsForStorno.map((p, i) =>
                                                    <div key={i}>
                                                        Плащания за сторниране по сметка <span className="link" onClick={e => showAccount(p.account_id)}>{p.account_id}</span>: <b>{parseFloat(p.calc_amount || 0).toFixed(2)}</b> {state.data.currency?.prefix || state.data.currency?.sufix || '-'}
                                                    </div>
                                                )}
                                            </div>
                                            <Tabs
                                                value={state.stornoPaymentTabId}
                                                onChange={handleTabChange}
                                                indicatorColor="primary"
                                                scrollButtons="auto"
                                                textColor="primary"
                                                variant="scrollable"
                                                aria-label="tabs"
                                            >
                                                {state.paymentsForStorno.map((account, i) =>
                                                    account.payments.map((payment, j) =>
                                                        <Tab key={'i-' + i + 'd-' + j} value={'i-' + i + 'd-' + j} label={`${payment.paymentmethod?.translation?.name} (${parseFloat(payment.amount || 0).toFixed(2)} ${state.data.currency?.prefix || state.data.currency?.sufix || '-'})`} />
                                                    ))}
                                            </Tabs>
                                            <br />
                                            {state.paymentsForStorno.map((account, i) =>
                                                account.payments.map((payment, d) =>
                                                    <>
                                                        <div className="row">
                                                            <div className="col">
                                                                <RedditSelect
                                                                    name={`storno_payments[${payment.id}][paymethod_id]`}
                                                                    value={payment.paymethod_id || ''}
                                                                    onChange={e => handleStornoInputChange('paymethod_id', e.target.value, i, d)}
                                                                    label="Начин на плащане"
                                                                    error={Boolean(validations && validations.storno_payments && validations.storno_payments[payment.id] && validations.storno_payments[payment.id].paymethod_id && (validations.storno_payments[payment.id].paymethod_id[0] || validations.storno_payments[payment.id].paymethod_id))}
                                                                    helperText={validations && validations.storno_payments && validations.storno_payments[payment.id] && validations.storno_payments[payment.id].paymethod_id && (validations.storno_payments[payment.id].paymethod_id[0] || validations.storno_payments[payment.id].paymethod_id)}
                                                                >
                                                                    {(paymentMethods || []).map(method =>
                                                                        <MenuItem key={method.id} value={method.id}>
                                                                            {method?.translation?.name || method.name}
                                                                        </MenuItem>
                                                                    )}
                                                                </RedditSelect>
                                                            </div>
                                                            <div className="col">
                                                                <RedditTextField
                                                                    size="small"
                                                                    variant="filled"
                                                                    margin="dense"
                                                                    fullWidth
                                                                    type="number"
                                                                    step="0.01"
                                                                    label={'Сума' + ('(' + (state.data.currency?.prefix || state.data.currency?.sufix || '-') + ')')}
                                                                    name={`storno_payments[${payment.id}][storno_amount]`}
                                                                    defaultValue={payment.calc_amount || 0}
                                                                    onChange={e => handleStornoInputChange('calc_amount', e.target.value, i, d)}
                                                                    error={Boolean(validations && validations.storno_payments && validations.storno_payments[payment.id] && validations.storno_payments[payment.id].storno_amount && (validations.storno_payments[payment.id].storno_amount[0] || validations.storno_payments[payment.id].storno_amount))}
                                                                    helperText={validations && validations.storno_payments && validations.storno_payments[payment.id] && validations.storno_payments[payment.id].storno_amount && (validations.storno_payments[payment.id].storno_amount[0] || validations.storno_payments[payment.id].storno_amount)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </>
                                                ))}
                                        </>
                                        :
                                        ''
                                    }
                                </>
                            }
                        </form>
                        {/* {state.show ?
                            <CloseBillForm
                                formRef={formRef}
                                id={state.id}
                            />
                            :
                            <>
                            </>
                        } */}
                    </div>

                    <div className="footer">
                        <SaveButton
                            loading={state.loading}
                            className="save"
                            onClick={handleSave}
                        >
                            Потвърди
                        </SaveButton>
                        <Button
                            className="cancel"
                            onClick={handleClose}
                        >
                            Отказ
                        </Button>
                    </div>

                </div>
            </div>
        </>
    )
}

export default forwardRef(Close)
