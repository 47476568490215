import { useState, createElement } from "react"

//components
import AllActivitiesDashboard from "./AllActivitiesDashboard"
import SubscriptionActivitiesDashboard from "./SubscriptionsActivitiesDashboard"
import DocumentActivitiesDashboard from "./DocumentsActivitiesDashboard"
import OutActivitiesDashboard from "./OutsActivitiesDashboard"
import SalesActivitiesDashboard from "./SalesActivitiesDashboard"
import IncomesBadge from "./IncomesStats"

//providers
import { useAuthDataContext } from "providers/Auth"

function ActivitiesDashboard({ clientId, handleClose }) {
  const { getUser } = useAuthDataContext()

  const [activeTabIndex, setActiveTabIndex] = useState(0)

  const tabs = [
    {
      name: "Всички",
      component: AllActivitiesDashboard,
    },
    getUser().hasSubscriptionAccess()
      ? {
          name: "Абонаменти",
          component: SubscriptionActivitiesDashboard,
        }
      : null,
    {
      name: "Документи",
      component: DocumentActivitiesDashboard,
    },
    getUser().hasStoreAccess()
      ? {
          name: "Изписвания",
          component: OutActivitiesDashboard,
        }
      : null,
    getUser().hasStoreAccess()
      ? {
          name: "Продажби",
          component: SalesActivitiesDashboard,
        }
      : null,
  ]

  function getTabs() {
    return tabs.filter((tab) => tab)
  }

  return (
    <>
      <h6>Хронология на действията</h6>
      <div className="row" style={{ position: "relative", marginBottom: "0" }}>
        <ul
          className="tabs"
          style={{
            marginBottom: "-1px",
            marginLeft: "-8px",
          }}
        >
          {getTabs().map((tab, index) => (
            <li
              className={activeTabIndex === index ? "active" : ""}
              onClick={() => {
                setActiveTabIndex(index)
              }}
            >
              <ul className="subtabs">
                <li className="active-subtab">
                  <span
                    style={{
                      display: "flex",
                    }}
                  >
                    {tab.name}
                  </span>
                </li>
              </ul>
            </li>
          ))}
        </ul>
        <IncomesBadge clientId={clientId} />
      </div>
      <div
        className="field"
        style={{
          borderTopLeftRadius: 0,
        }}
      >
        {createElement(getTabs()[activeTabIndex].component, {
          clientId,
          handleClose,
        })}
      </div>
    </>
  )
}

export default ActivitiesDashboard
