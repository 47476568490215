import { createContext, useContext, useMemo, useState } from 'react'
import { useNestedState } from 'helpers/NestedState'
import { useValidation } from 'helpers/Validation'
import { useLanguageContext } from 'providers/Language'
import { useCurrencyContext } from 'providers/Currency'

const ArticleContext = createContext({})

const Provider = props => {

    const langs = useLanguageContext()
    const currencies = useCurrencyContext()

    const defaultData = {
        langs: {},
        amount_unit: 1,
        is_for_sale: 0,
        is_public: 1,
        is_service: 0,
        files: [],
        storesdetails: {},
        depotsdetails: {},
        currency_id: currencies.getDefault()?.id || '',
        currency: currencies.getDefault(),
    }

    const [state, setState] = useNestedState({
        id: null,
        data: defaultData,
        initialData: defaultData,
        loading: false,
        dataLoading: false,
        mainTabId: 'main',
        activeTabIndex: 0,
    })

    const [validations, setValidations] = useValidation()

    const resetData = () => {
        setState(prev => ({
            ...prev,
            id: null,
            data: defaultData,
            initialData: defaultData,
            loading: false,
            dataLoading: false,
            mainTabId: 'main',
            activeTabIndex: 0,
        }))

        setValidations(null)
    }

    const value = {
        state,
        setState,
        validations,
        setValidations,
        resetData,
        defaultData
    }

    return <ArticleContext.Provider value={value} {...props} />
}

export const useArticleContext = () => useContext(ArticleContext)

export default Provider