import React, { useEffect, useRef } from 'react'
import Layout from '../../Layout'
import { useHistory, useParams } from 'react-router-dom'

//providers
import { useAppContext } from 'providers/App'
import { useDocumentContext } from '../documents/Provider'

//constants
import { PAYORDER_TYPE } from 'constants/Document'


//modals
import Question from '../../modals/Question'
import Success from '../../modals/Success'
import Error from '../../modals/Error'

//Misc
import SnackBar from '../../misc/SnackBar'
import SaveButton from '../../misc/Button'

//MUI components
import Button from '@material-ui/core/Button'

//helpers
import { useNestedState } from 'helpers/NestedState'
import Api from 'helpers/Api'
import { useValidation } from 'helpers/Validation'

//styles
import 'assets/scss/pages/documents.scss'

// forms
import CashOrderIncome from './forms/CashOrderIncome'

function Index() {
    const params = useParams()
    const app = useAppContext()
    const history = useHistory()
    const formRef = useRef(null)
    const successModalRef = useRef(null)
    const errorModalRef = useRef(null)
    const deleteModalRef = useRef(null)
    const sendModalRef = useRef(null)
    const previewModalRef = useRef(null)
    const errorSnackbarRef = useRef(null)

    const context = useDocumentContext()
    const { getSearchParam } = context

    const [state, setState] = useNestedState({
        loading: false,
        data: {},
    })

    const [validations, setValidations] = useValidation()

    const addMode = !params.id ? true : false
    const editMode = !addMode

    const titles = {
        [PAYORDER_TYPE.CASH_ORDER_INCOME]: {
            add: 'Нов приходен касов ордер',
            edit: 'Редактиране на приходен касов ордер'
        },
        [PAYORDER_TYPE.CASH_ORDER_DEBIT]: {
            add: 'Нов разходен касов ордер',
            edit: 'Редактиране на разходен касов ордер'
        }
    }

    const urls = {
        [PAYORDER_TYPE.CASH_ORDER_INCOME]: 'documents/cashorders/cash-order-income',
        [PAYORDER_TYPE.CASH_ORDER_DEBIT]: 'documents/cashorders/cash-order-debit',
    }

    const forms = {
        [PAYORDER_TYPE.CASH_ORDER_INCOME]: CashOrderIncome,
        [PAYORDER_TYPE.CASH_ORDER_DEBIT]: CashOrderIncome
    }

    if (addMode) {
        if (!getSearchParam('type_id')) history.goBack()
    }

    useEffect(() => {
        if (params.id) loadData()
    }, [params.id])

    const getTypeId = () => {
        if (editMode) {
            return Number(state?.data?.type_id)
        }

        return Number(getSearchParam('type_id'))
    }

    const checkTypeId = id => {
        return getTypeId() === Number(id)
    }

    const isCashOrderIncome = () => {
        return checkTypeId(PAYORDER_TYPE.CASH_ORDER_INCOME)
    }

    const isCashOrderDebit = () => {
        return checkTypeId(PAYORDER_TYPE.CASH_ORDER_DEBIT)
    }

    const getForm = () => {
        let type = getTypeId()

        if (type && forms[type]) {
            return React.createElement(forms[type], {
                state: state,
                setState: setState,
                validations: validations,
            })
        }

        return null
    }

    const loadData = () => {

        Api.get('documents/cashorders/show', {
            params: {
                id: params.id
            }
        })
            .then(res => {
                setState(prev => ({
                    ...prev,
                    data: res.data
                }))

            })
            .catch(error => {
                const _err = error.response

                if (_err && _err.status && _err.status === 404) {
                    // todo
                }
            })
            .finally(() => {
                setState(prev => ({
                    ...prev,
                    loading: false
                }))
            })
    }

    const handleInputChange = e => {
        const { name } = e.target
        const { value } = e.target

        setState(name, value, 'data')
    }

    const handleAddRow = e => {
        e.preventDefault()

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                rows: prev.data.rows.concat({})
            }
        }))
    }

    const handleRemoveRow = (e, index) => {
        e.preventDefault()

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                rows: prev.data.rows.filter((row, i) => i !== index)
            }
        }))
    }

    const handleBankChange = data => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                bank_id: data?.id || null,
                bank: data
            }
        }))
    }


    const handleSave = e => {
        e.preventDefault()

        setState(prev => ({
            ...prev,
            loading: true,
        }))

        setValidations(null)
        const url = urls[getTypeId()]
        const data = new FormData(e.target)

        if (params.id) {
            data.append('id', params.id)
        }

        Api.post(url, data)
            .then(() => {
                const modal = successModalRef.current

                modal.open()
                modal.onSuccess(() => {
                    history.push('/cashorders')
                })
            })
            .catch(error => {
                const _err = error.response

                if (_err && _err.status && _err.status === 422) {
                    setValidations(_err.data.errors)
                } else {
                    let modal = errorModalRef.current

                    modal.open()

                    modal.onSuccess(() => {
                        //
                    })
                }

            })
            .finally(() => {
                setState(prev => ({
                    ...prev,
                    loading: false,
                }))
            })
    }


    const handleDelete = e => {
        e.preventDefault()

        const modal = deleteModalRef.current

        modal.open()
        modal.onSuccess(() => {
            handleDestroy(state.data.id)
        })
    }

    const handleDestroy = id => {
        Api.post('documents/cashorders/delete', {
            id: id
        })
            .then(res => {
                if (res.data.success) {

                }
            })
    }

    const handlePreview = e => {
        e.preventDefault()

        previewModalRef.current.open(state.data)
    }

    const handleDownload = e => {
        e.preventDefault()

        previewModalRef.current.open(state.data, true)
    }

    const handleSend = e => {
        e.preventDefault()

        const modal = sendModalRef.current

        modal.open(state.data.id, state.data.customerinfo?.email)
        modal.onSuccess(() => {
            success()
        })

        modal.onError(() => {
            error()
        })
    }

    const success = (msg = null) => {
        app.handleSuccess(msg)
    }

    const error = () => {
        errorSnackbarRef.current.show()
    }

    return (
        <>

            <Question
                ref={deleteModalRef}
                mainMessage="Сигурни ли сте, че желаете да изтриете този документ?"
            />

            {/* <Send
                ref={sendModalRef}
            />

            <Preview
                ref={previewModalRef}
            /> */}

            <Success
                mainMessage="Действието е успешно!"
                ref={successModalRef}
            />

            <Error
                ref={errorModalRef}
                mainMessage="Нещо се обърка!"
            />

            <SnackBar
                ref={errorSnackbarRef}
                severity="error"
            >
                Действието не беше успешно!
            </SnackBar>

            <Layout>
                <section id="documents">

                    <h2 style={{ textAlign: 'center' }}>

                    </h2>

                    <form ref={formRef} onSubmit={handleSave}>

                        <div id="head">
                            <div className="title">
                                {getTypeId() ? titles[getTypeId()][editMode ? 'edit' : 'add'] : ''}
                            </div>

                            {editMode &&
                                <div className="buttons">

                                    <button
                                        className="normal download"
                                        onClick={handleDownload}
                                    >
                                        Изтегляне
                                    </button>

                                    <button
                                        className="print small"
                                        onClick={handlePreview}
                                    >

                                    </button>

                                    <button
                                        className="send small"
                                        onClick={handleSend}
                                    >

                                    </button>

                                    {state.data.allow_delete &&
                                        <button
                                            className="remove small"
                                            onClick={handleDelete}
                                        >

                                        </button>
                                    }

                                </div>
                            }
                        </div>

                        {getForm()}

                        <div id="footer">
                            <Button
                                className="cancel"
                                onClick={() => {
                                    history.goBack()
                                }}
                            >
                                Отказ
                            </Button>
                            <SaveButton
                                className="save"
                                loading={state.loading}
                            >
                                Запази
                            </SaveButton>
                        </div>
                    </form>
                </section>
            </Layout>
        </>
    )
}

export default Index