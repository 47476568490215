//librairies
import { create } from 'zustand'

//providers
import { useAuthDataContext } from 'providers/Auth'
import { useCurrencyContext } from 'providers/Currency'

export const useAddToComplaintsModal = create((set, get) => ({
    show: false,
    form: {},
    showModal: (show = true, initialForm = null) => set(state => ({
        show,
        form: initialForm
    })),
    setForm: (key, value) => set((state) => ({
        ...state,
        form: {
            ...state.form,
            [key]: value,
        }
    })),
    hideModal: () => set({ show: false, form: {} }),
    getForm: () => get().form,
    getShown: () => get().show
}))

export const useShowModal = () => {
    const { getUser } = useAuthDataContext()
    const { getDefault } = useCurrencyContext()
    const store = getUser().getStore()

    function showModal(show = true, initialForm = null) {
        if (!initialForm) {
            initialForm = {
                show: true,
                form: {
                    id: null,
                    statusId: null,
                },
                data: {
                    store: store,
                    user_id: getUser().getId(),
                    with_invoice: 0,
                    currency_id: getDefault()?.id || "",
                    currency_rate: getDefault()?.rate || "",
                    currency: getDefault(),
                    rows: [
                        {
                            isNew: true,
                        },
                    ],
                    allow_close: true,
                },

            }
        }
        useAddToComplaintsModal.getState().showModal(show, initialForm)
    }

    return {
        showModal
    }
}