import { useEffect, useRef, useState } from "react"

//helpers
import Api from "helpers/Api"

//libraries
import axios from "axios"

function useFetchOutsActivitiesDashboard(clientId = null) {
    const [loading, setLoading] = useState(true)
    const [activities, setActivities] = useState({})
    const [filters, setFilters] = useState({
        search: '',
        currentPage: 1
    })

    const reqCancelTokenRef = useRef(null)

    useEffect(() => {
        _fetchData()
    }, [filters, clientId])

    function _fetchData() {
        if (!clientId) {
            console.error('client id is required')

            return
        }

        if (reqCancelTokenRef.current) reqCancelTokenRef.current.cancel()
        reqCancelTokenRef.current = axios.CancelToken.source()

        setLoading(true)

        Api.get(`store/clients/dashboard/storeouts?client_id=${clientId}&search=${getSearchValue()}`, {
            cancelToken: reqCancelTokenRef.current.token
        })
            .then(res => {
                _setActivities(res.data || {})
            })
            .finally(() => {
                setLoading(false)
            })

    }

    function _setActivities(data) {
        setActivities(data)
    }

    function getActivities() {
        return activities?.items || []
    }

    function getCurrentPage() {
        return filters.currentPage
    }

    function getTotalPages() {
        return activities.pages
    }

    function getTotalItems() {
        return activities.total
    }

    function handlePage(page) {
        setFilters(prev => ({
            ...prev,
            currentPage: page.selected + 1
        }))
    }

    function handleSearch(e) {
        const { value } = e.target

        setFilters(prev => ({
            ...prev,
            search: value,
            currentPage: getCurrentPage() !== 1 ? 1 : prev.currentPage
        }))
    }

    function getSearchValue() {
        return filters?.search || ''
    }

    function refetchData() {
        if (getCurrentPage() === 1 && getSearchValue() === '') {
            _fetchData()

            return
        }

        setFilters(prev => ({
            ...prev,
            currentPage: 1,
            search: ''
        }))
    }

    return {
        getActivities,
        loadingActivities: loading,
        handleSearch,
        getSearchValue,
        getCurrentPage,
        getTotalPages,
        getTotalItems,
        handlePage,
        refetchData
    }
}

export default useFetchOutsActivitiesDashboard