import Middleware from './Middleware';

class Verified extends Middleware {
    run(props, next) {
        if (this.user && this.user.mustVerifyEmail() && !this.user.isEmailVerified()) {
            return this.redirect('/verify');
        }

        return next(this.app);
    }
}

export default Verified;