import { useState, useImperativeHandle, forwardRef, useEffect } from 'react'
import { Modal, Button, Row, Col, FloatingLabel, Form, Table } from 'react-bootstrap';
import { createPortal } from 'react-dom';

import SaveButton from 'components/misc/Button'
import { useAuthDataContext } from 'providers/Auth';
import { useAppContext } from 'providers/App';
import Api from 'helpers/Api';
import { useHistory } from 'react-router-dom';
import { buildUrl } from 'helpers/Url';

function MassOperationsColumns(props, ref) {

    const app = useAppContext();
    const navigate = useHistory();

    const defaultData = {
        selected: [],
        columns: {}
    }

    const [state, setState] = useState({
        show: false,
        params: null,
        data: defaultData,
        onSuccess: null,
        onClose: null,
        onEntering: null,
        onExiting: null,
    });

    useImperativeHandle(ref, () => ({
        open: () => {
            handleShow();
        },
        close: () => {
            handleClose();
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }));
        },
        onCancel: fn => {
            setState(prev => ({
                ...prev,
                onCancel: fn
            }));
        }
    }));

    useEffect(() => {
        if (!state.show) {
            return;
        }

        if (state.data.columns.length) {
            return;
        }

        Api.get('store/articles/mass-update-columns')
            .then(res => {
                setState(prev => ({
                    ...prev,
                    data: {
                        ...prev.data,
                        columns: res.data.columns
                    }
                }));
            }).catch(() => {
                // app.showError();
            });

    }, [state.show]);

    useEffect(() => {
        if (Array.isArray(props.selected)) {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    selected: props.selected
                }
            }));
        }
    }, [props.selected]);

    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }));
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }));
    }

    const handleShow = () => {
        show();
    }


    const handleClose = () => {
        hide();
    }

    const handleSave = e => {
        e.preventDefault();

        // setState(prev => ({
        //     ...prev,
        //     loading: true,
        // }));

        let url = buildUrl('/store/articles-mass-update', {
            columns: state.data.selected
        })

        // navigate(url);

        hide();

        if (typeof state.onSuccess === 'function') {
            state.onSuccess(state.data.selected);
        }
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: value
            }
        }))
    }

    const handleCheckboxChange = id => {
        if (isChecked(id)) {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    selected: prev.data.selected.filter(s => s !== id)
                }
            }));
        } else {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    selected: prev.data.selected.concat(id)
                }
            }));
        }
    }

    const isChecked = (id) => {
        return state.data.selected.indexOf(id) > -1;
    }

    const handleSelectAll = () => {
        if (isAllChecked()) {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    selected: []
                }
            }));
        } else {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    selected: Object.keys(prev.data.columns).map(c => c)
                }
            }));
        }
    }

    const isAllChecked = () => {
        let selected = true;

        Object.keys(state.data.columns).map(c => {
            if (!isChecked(c)) {
                selected = false;
            }
        });

        return selected;
    }


    const style = {
        button: {
            margin: '0 10px',
            minWidth: '120px'
        }
    }

    return createPortal(
        <Modal size="md" centered show={state.show} onHide={handleClose} onEntering={state.onEntering} onExiting={state.onExiting}>
            <form onSubmit={handleSave}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Избор на колони
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table size="sm" className="no-shadow" borderless>
                        <tbody>
                            <tr>
                                <td style={{ padding: '0.2rem' }}>
                                    <Form.Check
                                        id={`cb-all`}
                                        type="checkbox"
                                        label="Всички"
                                        value={1}
                                        checked={isAllChecked()}
                                        onChange={e => handleSelectAll()}
                                    />
                                </td>
                            </tr>
                            {Object.entries(state.data.columns).map(column =>
                                <tr key={column[0]} >
                                    <td style={{ padding: '0.2rem' }}>
                                        <Form.Check
                                            id={`cb-${column[0]}`}
                                            type="checkbox"
                                            name="columns[]"
                                            label={`${column[1]}`}
                                            value={column[0]}
                                            checked={isChecked(column[0])}
                                            onChange={e => handleCheckboxChange(column[0])}
                                        />
                                    </td>

                                </tr>
                            )}
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Отказ
                    </Button>
                    <SaveButton
                        loading={state.loading}
                        className="save"
                        disabled={state.data.selected.length === 0}
                    >
                        Продължи
                    </SaveButton>
                </Modal.Footer>
            </form>
        </Modal>,
        document.body
    )

}

export default forwardRef(MassOperationsColumns);