//components
import WeekCalendarColumn from "./WeekCalendarColumn"

function CalendarDaily({ calendar }) {
  // След всяко добавяне на нова бележка, трябва да се обновява целия календар за да се визуализират промените в неговите колони
  function getMaxTasksCount() {
    let max = 0

    calendar.forEach(({ days }) => {
      days.forEach(({ tasks }) => {
        if (tasks.length > max) {
          max = tasks.length
        }
      })
    })

    return max
  }

  function isDayOf(dayOfWeek) {
    return dayOfWeek === 6 || dayOfWeek === 7
  }

  return (
    <ul className="calendar-type-2">
      {calendar.map(({ id, day, days }, i) => (
        <li key={id}>
          <div className={`week-head ${isDayOf(i + 1) ? "day-off" : ""}`}>
            <b>{day}</b>
            <p>{i + 1}</p>
          </div>
          <ul>
            {days.map(({ tasks, date }) => (
              <WeekCalendarColumn
                tasks={tasks}
                date={date}
                maxTasksCount={getMaxTasksCount()}
              />
            ))}
          </ul>
        </li>
      ))}
    </ul>
  )
}

export default CalendarDaily
