import img1 from 'assets/img/tekaplast/img1.png';
import img2 from 'assets/img/tekaplast/img2.png';

function CRMLicense({ license }) {
    return (
        <div className="transactions-holder" style={{ marginBottom: '20px', background: '#EFF2F5' }}>
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}>
                <img src={img2} alt="" />
                <img src={img1} alt="" />
            </div>
            <p style={{ textAlign: 'center', marginTop: '10px', color: '#1c3a53', padding: '20px' }}>
                Тази система за управление на взаимоотношенията с клиенти
                (CRM система) е въведена по договор за финансиране
                No {license.contract} с финансовата подкрепа на
                Европейския съюз – NextGenerationEU.
            </p>
        </div>
    )
}

export default CRMLicense;