import { useEffect, useState } from "react"

//helpers
import Api from "helpers/Api"

//providers
import { useAuthDataContext } from "providers/Auth"

function useFetchLastDocuments() {
    const auth = useAuthDataContext()

    const [isLoading, setIsLoading] = useState(true)
    const [documents, setDocuments] = useState([])

    useEffect(() => {
        _fetchData()
    }, [])

    function _fetchData() {
        Api.get(`users/documents?user_id=${auth?.getUser()?.getId()}`)
            .then(res => {
                setDocuments(res.data.items)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    function getLastDocuments() {
        return documents
    }

    return {
        getLastDocuments,
        isLoading
    }
}

export default useFetchLastDocuments