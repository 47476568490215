import { useState, useEffect, useRef } from 'react'
import { Table, Form } from 'react-bootstrap';
import Api from 'helpers/Api';
import Loader from 'components/misc/Loader';
import { useAppContext } from 'providers/App';
import { useAuthDataContext } from 'providers/Auth';
import Rows from './Rows';

let timeout;

function Index(props) {

    const { show, hide } = props;

    const app = useAppContext();
    const auth = useAuthDataContext();

    const { handleShowArticle } = props;

    const defaultData = {
        rows: []
    };

    const [state, setState] = useState({
        data: defaultData,
        loading: false,
        dataLoading: false,
        rowsGroupped: null,
        rowsKey: null,
        onEntering: null,
        onExiting: null
    });

    useEffect(() => {
        loadFullData();
    }, [props.id]);

    const loadFullData = () => {
        loading(true);

        let p1 = loadData();
        // let p2 = loadRows();

        Promise.all([p1]).then(() => {
            loading(false);
        });
    }

    const loadData = async () => {
        let url = 'storemoves/checkup/show';

        return Api.get(url, {
            params: {
                id: props.id
            }
        }).then(res => {
            setDataResponse(res.data);
        }).catch(err => {
            console.log(err);
        })
    }

    const setDataResponse = data => {
        setState(prev => ({
            ...prev,
            data: data,
        }));
    }

    const loading = (loading) => {
        setState(prev => ({
            ...prev,
            loading: Boolean(loading)
        }));
    }

    const toggleRowsGroupped = () => {
        setState(prev => ({
            ...prev,
            rowsGroupped: !prev.rowsGroupped
        }));
    }

    const setRowsGroupped = (bool) => {
        setState(prev => ({
            ...prev,
            rowsGroupped: bool
        }));
    }

    return (

        state.loading
            ?
            <Loader />
            :
            <>
                <Table size="sm" bordered>
                    <tbody>
                        <tr>
                            <td className="th">
                                Артикули с недостиг
                            </td>
                            <td style={{ width: '15%' }}>
                                {state.data.minus_count}
                            </td>
                            <td className="th">
                                Артикули с излишък
                            </td>
                            <td style={{ width: '15%' }}>
                                {state.data.plus_count}
                            </td>
                            <td className="th">
                                Артикули с равни количества
                            </td>
                            <td style={{ width: '15%' }}>
                                {state.data.equal_count}
                            </td>
                        </tr>
                        <tr>
                            <td className="th">
                                Операция
                            </td>
                            <td colSpan={5}>
                                Прехвърляне №{state.data.move_id}
                            </td>
                        </tr>

                        <tr>
                            <td className="th">
                                Групиране по категория
                            </td>
                            <td colSpan={5}>
                                <Form.Check
                                    type="switch"
                                    value={1}
                                    checked={state.rowsGroupped === true}
                                    onChange={e => toggleRowsGroupped()}
                                />
                            </td>
                        </tr>
                    </tbody>
                </Table>

                <Rows
                    key={state.rowsKey}
                    id={state.data.id}
                    groupped={state.rowsGroupped}
                    setRowsGroupped={setRowsGroupped}
                    show={show}
                    hide={hide}
                />
            </>
    )
}

export default Index;