//MUI components
import MenuItem from '@material-ui/core/MenuItem'
import Tooltip from '@material-ui/core/Tooltip'

function Options(props) {

    const handlePreview = () => {
        window.open(props.data.signed_pdf_url);
    }

    return (
        <>
            <Tooltip
                title="Кликнете 'Редакция', за да редактирате документа"
            >
                <MenuItem
                    onClick={() => {
                        props.handleEdit(props.data.id)
                    }}
                >
                    Редакция
                </MenuItem>
            </Tooltip>
            <Tooltip
                title="Кликнете 'Преглед', за да визуализирате документа"
            >
                <MenuItem onClick={() => {
                    handlePreview(props.data)
                }}
                >
                    Преглед
                </MenuItem>
            </Tooltip>

            <Tooltip
                title="Кликнете 'Изпрати на клиента', за да изпратите на клиента"
            >
                <MenuItem
                    onClick={() => {
                        props.handleSend(props.data.id)
                    }}
                >
                    Изпрати на клиента
                </MenuItem>
            </Tooltip>

            {props.data.allow_delete &&
                <Tooltip
                    title="Изтрийте документа"
                >
                    <MenuItem
                        onClick={() => {
                            props.handleDelete(props.data.id)
                        }}
                    >
                        Изтриване
                    </MenuItem>
                </Tooltip>
            }
        </>
    )
}

export default Options;