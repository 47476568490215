import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

//plugins
import { Button, Col, Row, FloatingLabel, Form, } from 'react-bootstrap';

//helpers
import Api from 'helpers/Api'
import { useValidation } from 'helpers/Validation'
import { useNestedState } from 'helpers/NestedState'

//providers
import { useLanguageContext } from 'providers/Language'

//misc
import SaveButton from 'components/misc/Button'
import moment from 'moment'

function NewAccount(props, ref) {

    const defaultData = {
        with_movements: true,
        with_stock: false,
        with_invoice: true,
    }

    const [state, setState] = useNestedState({
        show: false,
        id: null,
        refresh: false,
        data: defaultData,
        loading: false,
        onSuccess: null,
        onEntering: null,
        onExiting: null,
        onClose: null,
    });

    const [validations, setValidations] = useValidation();

    useImperativeHandle(ref, () => ({
        open: (id) => {
            open(id);
        },
        hide: () => {
            hide();
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }));
        }
    }));


    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }));

        onEntering?.();
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }));

        onExiting?.();
    }

    const open = (id) => {
        setState(prev => ({
            ...prev,
            data: defaultData,
            id: id,
        }));

        show();
    }

    const handleClose = e => {

        if (e) {
            e.preventDefault();
        }

        setState(prev => ({
            ...prev,
            data: defaultData,
            id: null,
            subjectId: null,
            subjectType: null
        }));

        setValidations(null);

        if (typeof state.onClose === 'function') {
            hide(state.onClose);
        } else {
            hide();
        }
    }

    const refresh = (reset = true) => {
        setState(prev => ({
            ...prev,
            refresh: moment().unix(),
        }));
    }

    const handleSave = e => {
        e.preventDefault();

        setState(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url = 'store/orders/create-account';

        let data = new FormData(e.target);

        if (state.id) {
            data.append('id', state.id)
        }

        Api.post(url, data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(res => {
            if (typeof state.onSuccess === 'function') {
                state.onSuccess(res.data);
            }

            hide(state.onClose);
        }).catch(error => {
            const _err = error.response;

            if (_err && _err.status && _err.status === 422) {
                setValidations(_err.data.errors);
            }
        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false,
            }));
        });
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(name, value, 'data')
    }

    const handleCheckboxChange = e => {
        const name = e.target.name;

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: prev.data[name] ? false : true
            }
        }));
    }

    const handleDateChange = e => {
        const name = e.target.name + '_ymd'
        const value = e.target.value

        setState(name, value, 'data')
    }

    return (
        <>

            <div className={`${state.show ? "visible" : ""} overlay`}>
                <div
                    className={`popup-primary medium ${state.show ? "show" : ""}`}
                >
                    <form onSubmit={handleSave}>
                        <div className='body'>
                            <h3>
                                Нова продажба
                            </h3>
                            <br />
                            <Row>
                                <Col>
                                    <Form.Check
                                        type="switch"
                                        name="with_movements"
                                        label="Изписване от склада"
                                        className="mb-3 mt-3"
                                        checked={Number(state.data?.with_movements) === 1}
                                        onChange={handleCheckboxChange}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Check
                                        type="switch"
                                        name="with_stock"
                                        label="Издаване на стокова разписка"
                                        className="mb-3 mt-3"
                                        checked={Number(state.data?.with_stock) === 1}
                                        onChange={handleCheckboxChange}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Check
                                        type="switch"
                                        name="with_invoice"
                                        label="Издаване на фактура"
                                        className="mb-3 mt-3"
                                        checked={Number(state.data?.with_invoice) === 1}
                                        onChange={handleCheckboxChange}
                                    />
                                </Col>
                            </Row>
                        </div>
                        <div className="footer">
                            <Button
                                variant="contained"
                                className="cancel"
                                onClick={handleClose}
                            >
                                Отказ
                            </Button>
                            <SaveButton
                                loading={state.loading}
                                className="save"
                                text="Запази"
                            />
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default forwardRef(NewAccount);
