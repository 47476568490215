import { useRef } from "react"

//images
import statisticIcon from "assets/img/accounts/icons/statistic.svg"

//libraries
import { Button } from "@material-ui/core"

//components
import AddExpense from "components/pages/finances/expenses/partials/AddOrEdit"

//providers
import { useAuthDataContext } from "providers/Auth"

function NoExpenseData({ loadData }) {
  const auth = useAuthDataContext()

  const addExpenseModalRef = useRef()

  function handleAddExpense() {
    const modal = addExpenseModalRef.current
    modal.add()

    modal.onSuccess(() => {
      loadData()
    })
  }

  return (
    <>
      <AddExpense
        ref={addExpenseModalRef}
        companyId={auth?.getUser()?.getCompany()?.id}
      />
      <div className="no-data">
        <img src={statisticIcon} alt="" />
        <b>Няма налична статистика</b>
        <p>
          Можете да въведете първия си разход
          <br />
          от таб “Финанси” бутон “Добави разход”
        </p>
        <Button className="add expense" onClick={handleAddExpense}>
          Добави разход
        </Button>
      </div>
    </>
  )
}

export default NoExpenseData
