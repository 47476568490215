import { useEffect, useState } from 'react'

//plugins
import axios from 'axios'

//helpers
import Api from 'helpers/Api'

//providers
import { useRegisterContext } from './RegisterProvider'

//hooks
import useCompanyDataHook from './useCompanyDataHook'
import useCompanyVatFieldHook from './useCompanyVatFieldHook'

let timeout
const useCustomInputFieldHook = () => {
    const register = useRegisterContext()
    const { companyData } = register

    const [state, setState] = useState({
        token: null
    })

    const {
        resetCompanyDataState,
        setEditMode,
        setLoading,
        handleChange,
        setCompanyDataState
    } = useCompanyDataHook()

    const {
        handleCheckCustomerVatNumber
    } = useCompanyVatFieldHook()

    useEffect(() => {
        if ((companyData.eik >= 9) && !companyData.data) setEditMode(true)
    }, [companyData.eik])

    const handleInputChange = e => {
        const { name, value } = e.target
        handleChange(e)

        resetCompanyDataState()
        clearTimeout(timeout)
        setLoading(true)
        setTimeout(() => {
            getData(value)
        }, 300)
    }

    const getData = value => {
        if (state.request) state.request.cancel()
        const request = axios.CancelToken.source()

        setState(prev => ({
            ...prev,
            request
        }))
        Api.get(`trade-registry/find-by-number?name=${value}`, {
            cancelToken: request.token
        })
            .then(async res => {
                if (res.data.company) {
                    await setCompanyDataState(res.data.company, true)
                    handleCheckCustomerVatNumber()
                    setEditMode(false)
                }
                setLoading(false)
            })
            .catch(err => {
                if (!axios.isCancel(err)) setLoading(false)
            })
    }

    return {
        handleInputChange
    }
}

export default useCustomInputFieldHook