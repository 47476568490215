import React, { useEffect, useState } from 'react';
import { Route, Redirect, useLocation, useHistory } from 'react-router-dom'
import { useAuthDataContext } from '../providers/Auth';
import { useQuery } from '../helpers/Url';
import { useAppContext } from '../providers/App';
import PageLoader from './misc/PageLoader';
import Pipeline from '../middlewares/Pipeline';
import Container from '../Container';
import Access from 'middlewares/Access';

function ProtectedRoute({ component: Component, ...routeProps }) {

    const app = Container.getInstance();
    // const app = useAppContext();
    const location = useLocation();

    let middlewares = routeProps.middleware || [];

    if (!Array.isArray(middlewares)) {
        middlewares = [middlewares];
    }

    const [state, setState] = useState({
        processedMiddleware: false,
    });

    const processMiddleware = () => {

        middlewares.push(Access);

        (new Pipeline())
            .setPipes(middlewares)
            .setRouteProps(routeProps)
            .process(app, () => {
                setState(prev => ({
                    ...prev,
                    processedMiddleware: true
                }));
            });
    }

    useEffect(() => {
        processMiddleware();

        return () => {
            setState(prev => ({
                ...prev,
                processedMiddleware: false
            }));
        }
    }, [location.pathname]);

    // if (routeProps.provider) {
    //     return React.createElement(routeProps.provider, {
    //         children: <Component {...props} />
    //     })
    // }

    return (
        state.processedMiddleware
            ?
            <Route {...routeProps} render={props => {
                return <Component {...props} />
            }} />
            :
            <Route {...routeProps} render={props => {
                return <PageLoader show />
            }} />

    )
}

export default ProtectedRoute;
