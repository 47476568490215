import { useImperativeHandle, forwardRef, useState } from "react"

//libraries
import { Button, Menu, MenuItem } from "@material-ui/core"

//images
import checkIcon from "assets/img/app/icons/check-gray.png"

const LIMITS_PER_PAGE = [20, 30, 50]
function ItemsPerPageOption({}, ref) {
  const [anchorEl, setAnchorEl] = useState(null)

  useImperativeHandle(ref, () => ({
    openMenu: (e) => {
      openMenu(e)
    },
  }))

  //temp
  const selectedLimitCountPerPage = 20

  const closeMenu = () => {
    setAnchorEl(null)
  }

  const openMenu = (e) => {
    setAnchorEl(e.target)
  }

  return (
    <Menu
      anchorEl={anchorEl}
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={closeMenu}
      onClick={(e) => {
        // когато се избере елемент от менюто, трябва да се направи заявка и директно да се обновят резултатите.
        const dataValue = e.target.getAttribute("data-value")
        if (dataValue) closeMenu()
      }}
      className="additinal-pagination-menu"
    >
      <h3>Покажи по:</h3>
      {LIMITS_PER_PAGE.map((limit) => (
        <MenuItem data-value={limit} key={limit}>
          По {limit} на страница{" "}
          {selectedLimitCountPerPage === limit ? (
            <img src={checkIcon} />
          ) : (
            <></>
          )}
        </MenuItem>
      ))}
      <div className="bottom">
        <input type="number" placeholder="Друг брой" />
        <Button>Запази</Button>
      </div>
    </Menu>
  )
}

export default forwardRef(ItemsPerPageOption)
