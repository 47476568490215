import { MenuItem } from '@material-ui/core'

//images
import editIcon from 'assets/img/app/icons/edit.png'
import deleteIcon from 'assets/img/app/icons/delete.png'
import previewIcon from 'assets/img/articles/icons/preview.png'

function Options(props) {
    return (
        <>
            {props.data.deleted
                ?
                props.data.allow_edit ?
                    <MenuItem onClick={e => props.handleRestore(props.data)}>
                        <img src={editIcon} />
                        Възстанови
                    </MenuItem>
                    :
                    <>
                    </>
                :
                <>
                    {props.data.allow_view ?
                        <MenuItem onClick={e => props.handleView(props.data)}>
                            <img src={previewIcon} />
                            Преглед
                        </MenuItem>
                        :
                        <>
                        </>
                    }
                    {props.data.allow_edit ?
                        <MenuItem onClick={e => props.handleEdit(props.data)}>
                            <img src={editIcon} />
                            Редакция
                        </MenuItem>
                        :
                        <>
                        </>
                    }
                    {props.data.allow_delete ?
                        <MenuItem onClick={e => props.handleDelete(props.data)}>
                            <img src={deleteIcon} />
                            Изтриване
                        </MenuItem>
                        :
                        <>
                        </>
                    }
                </>
            }
        </>
    )
}

export default Options