//constatns
import { DOCUMENT_TYPE } from 'constants/Document'

const _HOME_TAB = {
    name: 'Начало',
    fullName: 'Нов документ',
    typeId: 'home',
    description: 'Към начало',
    info: 'Lorem Ipsum е елементарен примерен текст.',
    firstLetter: null,
    path: "/home"
}

export const INVOICES_TABS = [
    // _HOME_TAB,
    // {
    //     name: 'Оферти',
    //     fullName: 'Оферта',
    //     typeId: DOCUMENT_TYPE.OFFER,
    //     description: 'Създаване на оферта',
    //     homeListName: 'Оферти',
    //     info: 'Създайте оферта бързо и лесно, като натиснете върху квадратното поле.',
    //     notFoundText: 'За да създадете нова оферта натиснете зеления бутон отдолу.',
    //     firstLetter: 'O',
    //     path: '/invoices',
    // },
    {
        name: 'Проформа фактури',
        fullName: 'Проформа',
        typeId: DOCUMENT_TYPE.PROFORMA,
        description: 'Създаване на проформа',
        homeListName: 'Проформа фактури',
        info: 'Създайте Проформа фактура бързо и лесно, като натиснете върху квадратното поле.',
        notFoundText: 'За да създадете нова Проформа фактура натиснете зеления бутон отдолу.',
        firstLetter: 'PF',
        path: '/invoices',
    },
    {
        name: 'Фактури',
        fullName: 'Фактура',
        typeId: DOCUMENT_TYPE.INVOICE,
        description: 'Създаване на фактура',
        homeListName: 'Фактури',
        info: 'Създайте нова Фактура бързо и лесно, като натиснете върху квадратното поле.',
        notFoundText: 'За да създадете нова Фактура натиснете зеления бутон отдолу.',
        firstLetter: 'I',
        path: '/invoices',
    },
    {
        name: 'Кр. известия',
        fullName: 'Кредитно известие',
        typeId: DOCUMENT_TYPE.CREDIT_NOTE,
        description: 'Създаване на кредитно известие',
        homeListName: 'Кредитни известия',
        info: 'Създайте Кредитно известие бързо и лесно, като натиснете върху квадратното поле.',
        notFoundText: 'За да създадете ново Кредитно известие натиснете зеления бутон отдолу.',
        firstLetter: 'C',
        path: '/invoices',
    },
    {
        name: 'Дб. известия',
        fullName: 'Дебитно известие',
        typeId: DOCUMENT_TYPE.DEBIT_NOTE,
        description: 'Създаване на дебитно известие',
        homeListName: 'Дебитни известия',
        info: 'Създайте Дебитно известие бързо и лесно, като натиснете върху квадратното поле.',
        notFoundText: 'За да създадете ново Дебитно известие натиснете зеления бутон отдолу.',
        firstLetter: 'D',
        path: '/invoices',
    },
]

export const NOTES_TABS = [
    _HOME_TAB,
    {
        name: 'Ст. разписки',
        fullName: 'Стокова разписка',
        typeId: DOCUMENT_TYPE.STOCK,
        description: 'Създаване на стокова разписка',
        info: 'Lorem Ipsum е елементарен примерен текст, използван в печатарската и типографската индустрия.',
        firstLetter: 'S',
        docType: 'notes',
        path: '/notes',
    },
    {
        name: 'Протоколи',
        fullName: 'Протокол',
        typeId: DOCUMENT_TYPE.PROTOCOL,
        description: 'Създаване на протокол',
        info: 'Lorem Ipsum е елементарен примерен текст, използван в печатарската и типографската индустрия.',
        firstLetter: 'P',
        docType: 'notes',
        path: '/notes',
        tabIndex: 1,
        subtabs: [
            {
                name: null,
                deletable: false
            },
        ],
    },
]

export const OFFERS_TABS = [
    _HOME_TAB,
    {
        name: 'Оферти',
        fullName: 'Оферта',
        typeId: DOCUMENT_TYPE.OFFER,
        description: 'Създаване на оферта',
        info: 'Lorem Ipsum е елементарен примерен текст, използван в печатарската и типографската индустрия.',
        firstLetter: 'O',
        docType: 'offers',
        path: '/offers',
        tabIndex: 2,
        subtabs: [
            {
                number: null,
                date: null,
                deletable: false
            },
        ],
    }
]