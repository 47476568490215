export function translateToNumberField(value) {
    const reg = new RegExp(/[^0-9,.]+/)
    if (reg.test(value)) return ''

    const val = value.split('')

    const newVal = val.map(v => {
        if (v === ',') {
            v = '.'
        }

        return v
    })

    let periodCounter = 0
    const newNewValue = newVal.filter(nv => {
        if (periodCounter > 0) {
            return nv !== '.'
        }
        if (nv === '.') {
            periodCounter++
        }
        return nv
    })

    return newNewValue.join('')
}