import { useState, useEffect } from 'react'
import { Button, Dropdown, Table } from 'react-bootstrap'
import Api from 'helpers/Api'
import Loader from 'components/misc/Loader'
import moment from 'moment'
import { CurrencyDollar, Pencil, Printer, Upc, Upload } from 'react-bootstrap-icons'
import { useHistory } from 'react-router-dom'
import { buildUrl } from 'helpers/Url'
import { useAuthDataContext } from 'providers/Auth'
import PriceValue from 'components/partials/PriceValue'
import Rows from './Rows'
import Refs from 'Refs'
import { useAppContext } from 'providers/App'
import billIcon from 'assets/img/accounts/icons/bill.png'

function Index(props) {

    const { show, hide } = props

    const auth = useAuthDataContext()
    const app = useAppContext()
    const navigate = useHistory()

    const defaultData = {
        rows: []
    }

    const [state, setState] = useState({
        data: defaultData,
        loading: false,
    })

    const importModalRef = props.importModalRef

    useEffect(() => {
        setState(prev => ({
            ...prev,
            loading: true
        }))

        Api.get('store/storemoves/show', {
            params: {
                id: props.id
            }
        }).then(res => {
            setState(prev => ({
                ...prev,
                data: res.data,
                loading: false,
            }))
        })
    }, [props.id])

    const showArticle = id => {
        props.showArticle(id)
    }

    const handleEdit = () => {
        navigate('/storage/moves/' + state.data.id + '/edit')
    }

    const handlePrint = () => {
        window.open(buildUrl(state.data.stream_url, {
            token: auth.getToken()
        }))
    }

    const handleCreateAccount = () => {
        props.hide()

        navigate('/accounts/new?from_move_id=' + state.data.id)
    }

    const handleCreateStoreMove = () => {
        props.addStoreMove()
    }

    const handleCreateInvoice = () => {
        props.hide()

        navigate('/invoices/new?from_move_id=' + state.data.id)
    }

    const handleCreateDocument = () => {
        props.addDocument()
    }

    const handleCalcPrice = () => {
        let modal = Refs.getInstance().getRef('question')

        props.hide(() => {
            modal.open('Сигурни ли сте, че искате да преизчислите текущата продажна цена за всичи артикули в прехвърлянето?')
        })

        modal.onSuccess(() => {
            calculatePrice()
        })

        modal.onClose(() => {
            props.show()
        })
    }

    const calculatePrice = () => {
        Api.post('store/articles/calculate-price-from-move', {
            move_id: state.data.id,
        }).then(res => {
            let modal = Refs.getInstance().getRef('info')

            props.hide(() => {
                modal.open('Ще получите имейл, когато синхронизацията завърши!')
            })

            modal.onClose(() => {
                props.show()
            })
        }).catch(err => {
            app.showError()
        })
    }

    const handlePrintLabels = () => {
        props.hide()

        navigate('/articles-print-list-free?from_move_id=' + state.data.id)
    }

    const handleImport = () => {
        props.hide()

        let modal = importModalRef.current

        if (!modal) {
            return
        }

        modal.open()

        modal.setParams({
            move_id: state.data.id,
        })

        modal.onClose(() => {
            props.show()
        })

        modal.onSuccess((res) => {
            console.log(res)
            // alert(1);

            // modal.close();

            // timeout-а в импорта е 700мс
            setTimeout(() => {
                props.showCheckup(res.checkup_id)
            }, 1000)
        })
    }


    return (

        state.loading
            ?
            <Loader />
            :
            <div className="right-form-holder">
                <div className="preview-article-home">
                    <div className="row">
                        <div className="col">
                            <h6>
                                Основни данни
                            </h6>
                            <div className="field">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td className="th">
                                                ID
                                            </td>
                                            <td>
                                                {state.data.id}
                                            </td>
                                            <td className="th">
                                                Създал
                                            </td>
                                            <td>
                                                {state.data?.creator?.username}
                                            </td>
                                            <td className="th">
                                                Създадено на
                                            </td>
                                            <td>
                                                {state.data.created_at ? moment(state.data.created_at).format('DD.MM.YYYY HH:mm') : ''}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="th">
                                                От склад
                                            </td>
                                            <td>
                                                {state.data?.fromdepot?.store?.translation?.name || state.data?.fromdepot?.store?.name} - {state.data?.fromdepot?.name}
                                            </td>
                                            <td className="th">
                                                В склад
                                            </td>
                                            <td>
                                                {state.data?.todepot?.store?.translation?.name || state.data?.todepot?.store?.name} - {state.data?.todepot?.name}
                                            </td>
                                            <td className="th">
                                                Док. дата
                                            </td>
                                            <td>
                                                {state.data.document_date ? moment(state.data.document_date).format('DD.MM.YYYY HH:mm') : ''}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="th">
                                                Статус
                                            </td>
                                            <td>
                                                {state.data?.user_status?.translation?.name || state.data?.user_status?.name}
                                            </td>
                                            <td className="th">
                                                Заредил
                                            </td>
                                            <td>
                                                {state.data?.acceptor?.username}
                                            </td>
                                            <td className="th">
                                                Дата на промяна на статуса
                                            </td>
                                            <td>
                                                {state.data.status_updated_at ? moment(state.data.status_updated_at).format('DD.MM.YYYY HH:mm') : ''}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="th">
                                                Бележки
                                            </td>
                                            <td colSpan={5}>
                                                {state.data.description}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div className="options-bar">
                        <Button className="cancel" onClick={() => handlePrint()} style={{ marginLeft: '10px' }}>
                            <img src={billIcon} />
                            Печат протокол
                        </Button>
                    </div>

                    <div className="row">
                        <div className="col">
                            <h6>
                                Артикули
                            </h6>
                            <div className="field">
                                <Rows
                                    id={state.data.id}
                                    show={show}
                                    hide={hide}
                                />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
    )
}

export default Index