import NoDataFound from "components/misc/NoDataFound";
import Api from "helpers/Api";
import InfiniteScroll from 'react-infinite-scroller'
import { useState } from "react";
import { Card, Col, Form } from "react-bootstrap";
import Refs from "Refs";
import Loader from "components/misc/Loader";

let timeout;

function ArticleList(props) {

    const [state, setState] = useState({
        data: [],
        total: 0,
        pages: 0,
        currentPage: 0,
        hasMore: true,
        checked: [],
        search: '',
        loading: false
    });

    const category = props.category;

    const fetchItems = (page = 1, search = '', reset = false) => {
        Api.get(`store/categories/articles/all?page=${page}&search=${search}`)
            .then(res => {
                let hasMore = true;

                if (page >= res.data.pages) {
                    hasMore = false;
                }

                setState(prev => ({
                    ...prev,
                    data: reset ? res.data.items : prev.data.concat(res.data.items),
                    pages: res.data.pages,
                    total: res.data.total,
                    currentPage: page,
                    hasMore: hasMore,
                    loading: false
                }));
            });
    }

    const handleAddArticle = (article) => {

        if (props.deleteMode()) {
            return;
        }

        if (!category) {
            let modal = Refs.getInstance().getRef('error');

            modal.open("Моля изберете категория!");

            return;
        }

        if (!article.id) {
            return;
        }

        props.onAdd(article);
    }

    const handleSearchArticles = (e) => {
        clearTimeout(timeout);

        if (props.deleteMode()) {
            return;
        }

        loading(true)

        let value = e?.target?.value || ''
        let page = 1

        setState(prev => ({
            ...prev,
            search: value
        }));

        timeout = setTimeout(() => {
            fetchItems(page, value, true);
        }, 300);
    }

    const loading = (loading) => {
        setState(prev => ({
            ...prev,
            loading: Boolean(loading)
        }));
    }

    return (
        <Card style={{
            position: 'sticky',
            top: '10px'
        }}>
            <Card.Header>
                Артикули за добавяне
            </Card.Header>
            <Card.Body className={props.deleteMode() ? 'disabled' : ''}>
                <Col className="mb-3">
                    <Form.Label column="sm" className="text-left">
                        Търсене
                    </Form.Label>
                    <Form.Control
                        type="text"
                        name={`search`}
                        onChange={e => handleSearchArticles(e)}
                        value={state.search || ''}
                        size="sm"
                    />
                </Col>
                <div className="articles-list">
                    {state.loading ?
                        <Loader />
                        :
                        <InfiniteScroll
                            pageStart={0}
                            loadMore={() => fetchItems(state.currentPage + 1, state.search, false)}
                            hasMore={state.hasMore}
                            threshold={30}
                            useWindow={false}
                        >
                            {state.data.length
                                ?
                                <ul>
                                    {state.data.map((i, index) => (
                                        <li onClick={() => handleAddArticle(i)} key={index}>
                                            {i.article_name}
                                        </li>
                                    ))}
                                </ul>
                                :
                                <NoDataFound />
                            }
                        </InfiniteScroll>
                    }
                </div>
            </Card.Body>
        </Card>
    )
}

export default ArticleList;