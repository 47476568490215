import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

//plugins
import { Form, } from 'react-bootstrap'
import { Button, MenuItem } from '@material-ui/core'

//helpers
import Api from 'helpers/Api'
import { useValidation } from 'helpers/Validation'
import { useNestedState } from 'helpers/NestedState'

//providers
import { useLanguageContext } from 'providers/Language'

//misc
import SaveButton from 'components/misc/Button'
import RedditTextField from 'components/misc/RedditTextField'
import RedditSelect from 'components/misc/RedditSelect'
import moment from 'moment'

function AddOrEdit(props, ref) {

    const defaultData = {
        valid_to_type: 1,
        reusable: 0,
        groups: [],
        categories: []
    }

    const [state, setState] = useNestedState({
        show: false,
        id: null,
        edit: false,
        data: defaultData,
        loading: false,
        onSuccess: null,
        onEntering: null,
        onExiting: null,
    })

    const [taxGroups, setTaxGroups] = useState([]);

    const [validations, setValidations] = useValidation()

    const formRef = useRef(null)

    useImperativeHandle(ref, () => ({
        add: () => {
            handleShow()
        },
        edit: (id) => {
            edit(id)
        },
        hide: () => {
            hide()
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }))
        }
    }))

    useEffect(() => {
        loadData()
    }, [state.edit])

    useEffect(() => {

        Api.get('cards/types/tax-groups')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setTaxGroups(res.data);
                }
            });

    }, [])

    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }))

        onEntering?.()
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }))

        onExiting?.()
    }

    const handleShow = () => {
        setState(prev => ({
            ...prev,
            data: defaultData,
            id: null,
        }))
        show()
    }

    const handleClose = e => {

        if (e) {
            e.preventDefault()
        }

        setState(prev => ({
            ...prev,
            data: defaultData,
            id: null,
        }))

        setValidations(null)

        hide()
    }

    const edit = id => {
        setState(prev => ({
            ...prev,
            id: id,
            edit: moment().unix()
        }))

        show()
    }

    const loadData = () => {
        if (!state.id) {
            return
        }

        Api.get('store/cards/types/show?id=' + state.id)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    data: res.data,
                    mainTabId: 'main',
                }))
            });
    }


    const handleSave = () => {
        setState(prev => ({
            ...prev,
            loading: true,
        }))

        setValidations(null)

        let url = state.id ? 'store/cards/types/update' : 'store/cards/types/add'

        let data = new FormData(formRef.current)

        if (state.id) {
            data.append('id', state.id)
        }

        Api.post(url, data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(res => {
            if (typeof state.onSuccess === 'function') {
                state.onSuccess(res.data)
            }

            hide()
        }).catch(error => {
            const _err = error.response
            if (_err && _err.status && _err.status === 422) {
                setValidations(_err.data.errors)
            }

        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false,
            }))
        })
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(name, value, 'data')
    }

    const handleDateChange = e => {
        const name = e.target.name + '_ymd'
        const value = e.target.value

        setState(name, value, 'data')
    }

    const handleTaxChange = e => {
        const value = e.target.value

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                sale_article: {
                    ...prev.data.sale_article,
                    ['tax_id']: value
                }
            }
        }));
    }

    const handlePaymentTaxChange = e => {
        const value = e.target.value

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                payment_article: {
                    ...prev.data.payment_article,
                    ['tax_id']: value
                }
            }
        }));
    }

    const handleSaleBarcodeChange = e => {
        const value = e.target.value

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                sale_article: {
                    ...prev.data.sale_article,
                    ['barcode']: value
                }
            }
        }));
    }

    const handleCheckboxChange = e => {
        const name = e.target.name;

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: prev.data[name] ? false : true
            }
        }));
    }

    const handleDiscountChange = e => {
        const name = e.target.name
        let value = e.target.value

        if (!value.includes('+') && !value.includes('-') && value.length > 0) {
            value = '-' + value;
        }

        if (value.includes('+')) {
            value = value.replace('-', '')
        }

        setState(name, value, 'data')
    }

    return (
        <>
            <div className={`${state.show ? "visible" : ""} overlay`}>
                <div
                    className={`popup-primary medium ${state.show ? "show" : ""}`}
                >
                    <div className="body">
                        <h3>
                            {`${state.id ? 'Редакция' : 'Добавяне'} на вид карта`}
                        </h3>
                        <br />
                        <form onSubmit={handleSave} ref={formRef}>
                            <div className="row">
                                <div className="col">
                                    <RedditTextField
                                        size="small"
                                        variant="filled"
                                        margin="dense"
                                        label="Вътрешно наименование"
                                        name="name"
                                        value={state.data.name || ''}
                                        onChange={(e) => handleInputChange(e)}
                                        error={Boolean(validations?.name)}
                                        helperText={validations?.name && (validations.name[0] || validations.name)}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <Form.Check
                                        type="switch"
                                        name="sell_in_store"
                                        label="В продажба"
                                        className="mb-3 mt-3"
                                        checked={Number(state.data?.sell_in_store) === 1}
                                        onChange={handleCheckboxChange}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <RedditTextField
                                        size="small"
                                        variant="filled"
                                        margin="dense"
                                        label="Цена"
                                        type="number"
                                        name="price"
                                        value={state.data.price || ''}
                                        onChange={(e) => handleInputChange(e)}
                                        error={Boolean(validations?.price)}
                                        helperText={validations?.price && (validations.price[0] || validations.price)}
                                    />
                                </div>
                                <div className="col">
                                    <RedditSelect
                                        name="valid_from_type"
                                        value={state.data.valid_from_type || ''}
                                        onChange={handleInputChange}
                                        label="Важи от"
                                        error={Boolean(validations?.valid_from_type)}
                                        helperText={validations?.valid_from_type && (validations.valid_from_type[0] || validations.valid_from_type)}
                                    >

                                        <MenuItem value="1">
                                            Момент на продажбата
                                        </MenuItem>
                                        <MenuItem value="2">
                                            Начало на деня
                                        </MenuItem>
                                        <MenuItem value="3">
                                            Начало на седмицата
                                        </MenuItem>
                                        <MenuItem value="4">
                                            Начало на месеца
                                        </MenuItem>
                                        <MenuItem value="5">
                                            Начало на годината
                                        </MenuItem>
                                    </RedditSelect>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <RedditTextField
                                        size="small"
                                        variant="filled"
                                        margin="dense"
                                        label="Важи до"
                                        type="number"
                                        name="valid_to_num"
                                        value={state.data.valid_to_num || ''}
                                        onChange={(e) => handleInputChange(e)}
                                        error={Boolean(validations?.valid_to_num)}
                                        helperText={validations?.valid_to_num && (validations.valid_to_num[0] || validations.valid_to_num)}
                                        disabled={state.data.valid_to_type == 1 ? true : false}
                                    />
                                </div>
                                <div className="col">
                                    <RedditSelect
                                        name="valid_to_type"
                                        value={state.data.valid_to_type || ''}
                                        onChange={handleInputChange}
                                        label="Важи до (тип)"
                                        error={Boolean(validations?.valid_to_type)}
                                        helperText={validations?.valid_to_type && (validations.valid_to_type[0] || validations.valid_to_type)}
                                    >

                                        <MenuItem value="1">
                                            Без ограничение
                                        </MenuItem>
                                        <MenuItem value="2">
                                            Дни
                                        </MenuItem>
                                        <MenuItem value="3">
                                            Седмици
                                        </MenuItem>
                                        <MenuItem value="4">
                                            Месеца
                                        </MenuItem>
                                        <MenuItem value="5">
                                            Години
                                        </MenuItem>
                                    </RedditSelect>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <RedditTextField
                                        size="small"
                                        variant="filled"
                                        margin="dense"
                                        label="Отстъпка %"
                                        type="number"
                                        name="discount"
                                        value={state.data.discount || ''}
                                        onChange={(e) => handleDiscountChange(e)}
                                        error={Boolean(validations?.discount)}
                                        helperText={validations?.discount && (validations.discount[0] || validations.discount)}
                                    />
                                </div>
                                <div className="col">
                                    <RedditTextField
                                        size="small"
                                        variant="filled"
                                        margin="dense"
                                        label="Кредит сума"
                                        type="number"
                                        name="credit"
                                        value={state.data.credit || ''}
                                        onChange={(e) => handleInputChange(e)}
                                        error={Boolean(validations?.credit)}
                                        helperText={validations?.credit && (validations.credit[0] || validations.credit)}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <RedditSelect
                                        name="articles_price_type"
                                        value={state.data.articles_price_type || ''}
                                        onChange={handleInputChange}
                                        label="Важи за"
                                        error={Boolean(validations?.articles_price_type)}
                                        helperText={validations?.articles_price_type && (validations.articles_price_type[0] || validations.articles_price_type)}
                                    >

                                        <MenuItem value="1">
                                            Всички артикули
                                        </MenuItem>
                                        <MenuItem value="2">
                                            Артикули без намаление
                                        </MenuItem>
                                        <MenuItem value="3">
                                            Артикули с намаление
                                        </MenuItem>

                                    </RedditSelect>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <Form.Check
                                        type="switch"
                                        name="client_check"
                                        label="Проверка на клиент"
                                        className="mb-3 mt-3"
                                        checked={Number(state.data?.client_check) === 1}
                                        onChange={handleCheckboxChange}
                                    />
                                </div>
                                <div className="col">
                                    <Form.Check
                                        type="switch"
                                        name="auto_num"
                                        label="Автоматично генериране на код"
                                        className="mb-3 mt-3"
                                        checked={Number(state.data?.auto_num) === 1}
                                        onChange={handleCheckboxChange}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <RedditSelect
                                        name="price_round_type"
                                        value={state.data.price_round_type || ''}
                                        onChange={handleInputChange}
                                        label="Закръгляне"
                                        error={Boolean(validations?.price_round_type)}
                                        helperText={validations?.price_round_type && (validations.price_round_type[0] || validations.price_round_type)}
                                    >
                                        <MenuItem value="">
                                            Няма
                                        </MenuItem>
                                        <MenuItem value="1">
                                            До цял лев
                                        </MenuItem>
                                        <MenuItem value="2">
                                            Надолу до цял лев
                                        </MenuItem>
                                        <MenuItem value="3">
                                            Нагоре до цял лев
                                        </MenuItem>

                                    </RedditSelect>
                                </div>
                                <div className="col">
                                    <RedditTextField
                                        size="small"
                                        variant="filled"
                                        margin="dense"
                                        label="Брой използвания - общо"
                                        type="number"
                                        name="use_limit"
                                        value={state.data.use_limit || ''}
                                        onChange={(e) => handleInputChange(e)}
                                        error={Boolean(validations?.use_limit)}
                                        helperText={validations?.use_limit && (validations.use_limit[0] || validations.use_limit)}
                                    />
                                </div>
                            </div>
                            <div className="row">

                            </div>
                        </form>
                    </div>

                    <div className="footer">
                        <Button
                            variant="contained"
                            className="cancel"
                            onClick={handleClose}
                        >
                            Отказ
                        </Button>
                        <SaveButton
                            loading={state.loading}
                            className="save"
                            onClick={handleSave}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default forwardRef(AddOrEdit)
