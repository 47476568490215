import { useState, useRef } from 'react'
import ClickAwayListener from 'react-click-away-listener'
import Skeleton from 'react-loading-skeleton'

//MUI components
import { Button } from '@material-ui/core'

//helpers
import Api from 'helpers/Api'

//providers
import { useAuthDataContext } from 'providers/Auth'

//images
import brandImage from 'assets/img/app/brand-full.png'
import pointerIcon from 'assets/img/header/icons/pointer.png'
import pointerDarkIcon from 'assets/img/header/icons/pointer-dark.png'
import pointerGrayIcon from 'assets/img/header/icons/pointer-gray.png'
import addCompany from 'assets/img/header/icons/add-company.svg'

//MUI components
import Tooltip from '@material-ui/core/Tooltip'


import AddCompany from 'components/pages/settings/partials/companies/partials/Add'

function Company() {
    const auth = useAuthDataContext()

    const [state, setState] = useState({
        showCompaniesMenu: false,
        showStoresMenu: false,
        loading: false
    })

    const addOrEditModalRef = useRef(null)

    const showMenu = showCompaniesMenu => {
        setState(prev => ({
            ...prev,
            showCompaniesMenu
        }))
    }
    const showStoresMenu = showStoresMenu => {
        setState(prev => ({
            ...prev,
            showStoresMenu
        }))
    }
    const setActiveCompany = async (id, addressId = null) => {

        if (addressId) {
            if (id == auth?.getUser()?.getCompany()?.getId() && auth?.getUser()?.getCompanyAddress()?.id == addressId) {
                return
            }
        } else {
            if (id == auth?.getUser()?.getCompany()?.getId() && !auth?.getUser()?.getCompanyAddress()) {
                return
            }
        }

        setLoading(true)

        const { data } = await Api.post('auth/set-active-company', {
            id: id,
            address_id: addressId
        })

        // setLoading(false)

        //Обновяване на фирмата след смяна на активната фирма.
        // auth.setData(data)
        window.location.reload()
    }

    async function setActiveAddress(id) {
        setLoading(true)

        const { data } = await Api.post('auth/set-active-store', {
            id: id,
        })

        window.location.reload()
    }

    const setLoading = loading => {
        setState(prev => ({
            ...prev,
            loading
        }))
    }

    const getCompaniesCount = () => {
        const companiesCount = auth.getUser()?.getCompanies()?.length || 0

        return companiesCount
    }

    const getAddressesCount = () => {
        let count = 0

        auth.getUser()?.getCompanies().map(c => {
            count += c.getAddresses().length
        })

        return count
    }

    const canShowMenu = () => getCompaniesCount() > 1 || getAddressesCount() > 1

    return (
        <>
            <AddCompany
                ref={addOrEditModalRef}
            />
            <div
                className="brand"
                style={!canShowMenu() ? { cursor: 'default' } : {}}

            >
                <div className="logo">
                    <img src={brandImage} />
                </div>

                <div className="col">
                    <Tooltip
                        title={auth.getUser().getCompanies().length > 1 ? "Смяна на активната компания" : ''}
                    >
                        <h2
                            onClick={() => {
                                if (canShowMenu()) showMenu(!state.showCompaniesMenu)
                            }}
                        >
                            {state.loading ?
                                <Skeleton count={1} height={20} width={200} />
                                :
                                <>
                                    {auth?.getUser()?.getCompany()?.getName()}
                                    {auth?.getUser()?.getCompany().getAddresses().length > 1 ?
                                        <> - {auth?.getUser()?.getCompanyAddress()?.name || ''}</>
                                        :
                                        <>
                                        </>
                                    }
                                    {canShowMenu() ?
                                        <img src={pointerDarkIcon} />
                                        :
                                        <></>
                                    }

                                </>
                            }
                        </h2>
                    </Tooltip>
                    {auth.getUser()?.getStore() ?
                        <>
                            <Tooltip
                                title={(auth.getUser().getStores() || []).length > 1 ? "Смяна на активният обект" : ''}
                            >
                                <h2
                                    onClick={() => {
                                        showStoresMenu(!state.showStoresMenu)
                                    }}
                                    className="store"
                                >
                                    {state.loading ?
                                        <Skeleton count={1} height={20} width={200} />
                                        :
                                        <>
                                            {auth.getUser().getStore()?.name}
                                            {(auth.getUser().getStores() || [])?.length > 1 ?
                                                <img src={pointerGrayIcon} />
                                                :
                                                <></>
                                            }

                                        </>
                                    }
                                </h2>
                            </Tooltip>
                            {state.showStoresMenu && (auth.getUser().getStores() || []).length > 1 ?
                                <ClickAwayListener
                                    onClickAway={() => {
                                        showStoresMenu(false)
                                    }}
                                >
                                    <div className="companies-list">
                                        <div className="head">
                                            <h3>
                                                Моите обекти
                                            </h3>
                                        </div>
                                        <ul className="dropdown">
                                            {auth.getUser().getStores().map(c =>
                                                <li key={c.id}>
                                                    <Tooltip
                                                        title={c.id === auth.getUser().getStore()?.id ? `${c.name} е Вашият активен обект` : `Направете ${c.name} активен обект`}
                                                    >
                                                        <a
                                                            className={c.id === auth.getUser().getStore()?.id ? 'active' : ''}
                                                            onClick={() => {
                                                                setActiveAddress(c.id)
                                                            }}
                                                        >
                                                            {c.name || '-'}
                                                        </a>
                                                    </Tooltip>
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                </ClickAwayListener>
                                :
                                <>
                                </>
                            }
                        </>
                        :
                        <>
                        </>
                    }
                </div>

                {state.showCompaniesMenu && canShowMenu() ?
                    <ClickAwayListener
                        onClickAway={() => {
                            showMenu(false)
                        }}
                    >
                        <div className="companies-list">
                            <div className="head">
                                <h3>
                                    Моите фирми
                                </h3>
                            </div>
                            <ul className="dropdown">
                                {auth.getUser().getCompanies().map(c =>
                                    <li key={c.id}>
                                        <Tooltip
                                            title={c.id === auth?.getUser()?.getCompany()?.getId() ? `${c.getName()} е Вашата активна компания` : `Направете ${c.getName()} активна компания`}
                                        >
                                            <a
                                                className={c.id === auth?.getUser()?.getCompany()?.getId() ? 'active' : ''}
                                                onClick={() => {
                                                    setActiveCompany(c.id)
                                                }}
                                            >
                                                {c.getName() || '-'}
                                            </a>
                                        </Tooltip>

                                        {c.getAddresses().length > 1 &&
                                            <ul>
                                                {c.getAddresses().map(address =>
                                                    <li key={'add-' + address.id}>
                                                        <a
                                                            className={address.id === auth?.getUser()?.getCompanyAddress()?.id ? 'active' : ''}
                                                            onClick={() => {
                                                                setActiveCompany(c.id, address.id)
                                                            }}
                                                        >
                                                            {address.name}
                                                        </a>
                                                    </li>
                                                )}
                                            </ul>
                                        }
                                    </li>
                                )}
                            </ul>
                            <div className="bottom">
                                <img src={addCompany} />
                                <p>
                                    Създайте лесно нова фирма и управлявайте документите си разделно
                                </p>
                                <Button
                                    className="add"
                                    onClick={() => {
                                        addOrEditModalRef.current.add()
                                    }}
                                >
                                    Добавяне на нова фирма
                                </Button>
                            </div>
                        </div>
                    </ClickAwayListener>
                    :
                    <>
                    </>
                }
            </div>
        </>
    )
}

export default Company
