import { useEffect, useRef } from 'react'
import { Prompt } from 'react-router-dom'

//helpers
import Api from 'helpers/Api'
import { useNestedState } from 'helpers/NestedState'
import { useValidation } from 'helpers/Validation'
import { useQuery } from 'helpers/Url'

//MUI components
import { Alert, } from '@material-ui/lab'
import { Button, Tabs, Tab, Tooltip } from '@material-ui/core'

//partials
import Add from './partials/Add'
import Settings from './Settings'
import AddEditOffice from './partials/AddEditOffice'

//modals
import Question from 'components/modals/Question'
import Attention from 'components/modals/Error'

//misc
import SaveButton from 'components/misc/Button'

//providers
import { useAuthDataContext } from 'providers/Auth'
import { useAppContext } from 'providers/App'

//librarires
import axios from 'axios'
import Skeleton from 'react-loading-skeleton'

//images
import licenseIcon from 'assets/img/settings/icons/license.png'
import ViewLicense from './partials/ViewLicense'
import moment from 'moment'

function List() {
    const app = useAppContext()
    const auth = useAuthDataContext()
    const companies = auth.getUser().getCompanies()
    const query = useQuery()

    const [state, setState] = useNestedState({
        loading: true,
        loadingSave: false,
        refresh: false,
        data: {
            addresses: []
        },
        tab: getInitialActiveTabIndex(),
        disableSave: true
    })

    const [validations, setValidations] = useValidation()

    const addOrEditModalRef = useRef(null)
    const deleteModalRef = useRef(null)
    const attentionModalRef = useRef(null)
    const addOrEditOfficeModalRef = useRef(null)
    const viewLicenseModalRef = useRef(null)

    useEffect(() => {
        if (companies.length) loadData()
    }, [companies, state.tab, state.refresh])

    useEffect(() => {
        if (!state.data.addresses || (state.data.addresses && state.data.addresses.length === 0)) {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    addresses: [
                        {

                        }
                    ]
                }
            }))
        }
    }, [state.data.addresses])

    function getInitialActiveTabIndex() {
        let index = 0

        companies.map((c, i) => {
            if (c.id?.toString() === query.get('company_id')?.toString()) {
                index = i
            }
        })

        return index
    }

    const loadData = () => {
        setLoading(true)

        if (state.request) state.request.cancel()

        const request = axios.CancelToken.source()

        setState(prev => ({
            ...prev,
            request: request,
        }))

        Api.get(`companies/show?id=${companies[state.tab || 0]?.id || null}`, {
            cancelToken: request.token
        })
            .then(res => {
                setState(prev => ({
                    ...prev,
                    data: res.data,
                }))
            })
            .catch(() => {
                handleTabChange(null, 0)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const setLoading = loading => {
        setState(prev => ({
            ...prev,
            loading
        }))
    }

    const setLoadingSave = loadingSave => {
        setState(prev => ({
            ...prev,
            loadingSave
        }))
    }

    const refresh = () => {
        setState(prev => ({
            ...prev,
            refresh: moment().unix()
        }))
    }

    const handleAdd = () => {
        const modal = addOrEditModalRef.current

        modal.add()
        modal.onSuccess(() => {
            refresh(true)
        })
    }

    // delete
    const handleDelete = id => {
        const modal = deleteModalRef.current

        modal.open()
        modal.onSuccess(() => {
            app.handleInfo('Моля, изчакайте...')
            handleDestroy(id)
        })
    }

    const handleDestroy = id => {
        // setLoading(true)
        Api.post('companies/delete', {
            id
        })
            .then(() => {
                // if (res.data.success) refresh()
                app.handleHideInfo()
                attentionModalRef.current.open()
            })
        // .finally(() => {
        //     setLoading(false)
        // })
    }

    function handleTabChange(e, tab) {
        setState(prev => ({
            ...prev,
            tab
        }))

        handleDisableSave(true)
    }

    const handleInputChange = e => {
        const { name, value } = e.target

        setState(name, value, 'data')
        handleDisableSave(false)
    }

    const handleAddAddress = () => {
        // setState(prev => ({
        //     ...prev,
        //     data: {
        //         ...prev.data,
        //         addresses: prev.data.addresses.concat({})
        //     }
        // }))

        // handleDisableSave(false)
        addOrEditOfficeModalRef.current.open()
    }

    const handleEditOffice = office => {
        addOrEditOfficeModalRef.current.open(office)
    }

    const handleRemoveAddress = (index, id) => {
        const modal = deleteModalRef.current

        modal.open()
        modal.onSuccess(() => {
            handleDestroyAddress(index, id)
        })
    }
    const handleDestroyAddress = (index, id) => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                addresses: prev.data.addresses.filter((row, i) => i !== index)
            }
        }))
        Api.post('addresses/delete', {
            id,
            company_id: state.data.id
        })
            .then(() => {
                app.handleSuccess('Данните на Вашата фирма бяха запазени успешно.')
            })
            .catch(() => {
                app.handleError('Възникна грешка!')
            })
    }

    const handleDisableSave = disableSave => {
        setState(prev => ({
            ...prev,
            disableSave
        }))
    }

    const handleSave = e => {
        e.preventDefault()
        if (e.target.id === 'bankModal') return

        setLoadingSave(true)
        setValidations(null)

        const url = 'companies/edit'
        const data = new FormData(e.target)

        data.append('id', state.data.id)

        Api.post(url, data)
            .then(() => {
                app.handleSuccess('Данните на Вашата фирма бяха запазени успешно.')
                handleDisableSave(true)
            })
            .catch(error => {
                const _err = error.response

                if (_err && _err.status && _err.status === 422) setValidations(_err.data.errors)
                app.handleError('Възникна грешка!')
            })
            .finally(() => {
                setLoadingSave(false)
            })
    }

    const handleChangePicture = () => {
        document.querySelector('input[type="file"]').click()
        handleDisableSave(false)
    }

    const handleUploadPicture = e => {
        const reader = new FileReader()
        reader.addEventListener('load', ev => {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    picture: ev.target.result
                }
            }))
        })
        reader.readAsDataURL(e.target.files[0])
    }

    return (
        <section id="companies">
            <AddEditOffice
                ref={addOrEditOfficeModalRef}
                companyId={state.data.id}
                refresh={refresh}
            />
            <Add
                ref={addOrEditModalRef}
                refresh={refresh}
            />
            <Question
                ref={deleteModalRef}
                mainMessage="Потвърждавам изтриването"
                secondaryMessage="Изтритите от Вас данни не могат да бъдат възстановени."
                agreeBtnText="Изтриване"
                agreeBtnClass="remove"
            />
            <Attention
                ref={attentionModalRef}
                mainMessage="Изпратихме връзка за потвърждение на Вашата е-поща"
                secondaryMessage="Моля, посетете Вашта е-поща и потвърдете изтриването, за да завършите действието."
            />
            <Prompt
                when={!state.disableSave}
                message="Имате незапазени промени! Сигурни ли сте, че искате да напуснете страницата?"
            />
            <ViewLicense ref={viewLicenseModalRef} />
            <div id="head">
                <Tabs
                    value={state.tab}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    scrollButtons="auto"
                    textColor="primary"
                    variant="scrollable"
                    aria-label="tabs"
                >
                    {companies.map((c, i) =>
                        <Tab key={'lt-' + c.id} value={i} label={c?.info?.name} />
                    )}
                </Tabs>
                <Button
                    className="add"
                    onClick={handleAdd}
                >
                    Добави
                </Button>
            </div>
            <hr />
            {state.loading
                ?
                <Skeleton count={5} height={60} />
                :
                companies.length === 0
                    ?
                    <Alert severity="error">
                        Все още нямате добавени фирми!
                    </Alert>
                    :
                    <>
                        <form
                            noValidate
                            onSubmit={handleSave}
                            className="settings-body"
                        >
                            <input
                                type="file"
                                hidden
                                name="picture"
                                onChange={handleUploadPicture}
                                accept="image/*"
                            />
                            <div className="container my-companies">
                                <div className="left">
                                    <div className="row">
                                        <b>
                                            Данни на фирмата
                                        </b>
                                        {auth.getUser().hasCRMAccess() ?
                                            <Button className="license" onClick={() => viewLicenseModalRef.current.show()}>
                                                <img src={licenseIcon} alt="" />
                                                Лиценз
                                            </Button>
                                            :
                                            <>
                                            </>
                                        }
                                    </div>
                                    <div className="main-data">
                                        <Tooltip title={!state.data?.picture ? 'Добавяне на лого' : "Смяна на лого"}>
                                            <picture
                                                onClick={handleChangePicture}
                                            >
                                                {state.data?.picture ?
                                                    <img src={state.data?.picture} />
                                                    :
                                                    <p>
                                                        Добави лого
                                                    </p>
                                                }
                                            </picture>
                                        </Tooltip>
                                        <div className="right-part">
                                            <input
                                                type="text"
                                                defaultValue={state.data?.info?.name || ''}
                                                name="info[name]"
                                                onChange={handleInputChange}
                                                className={Boolean(validations && validations.info?.name) ? 'validate' : ''}
                                            />
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            ЕИК:
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                value={state.data?.info?.eikegn || ''}
                                                                name="info[eikegn]"
                                                                onChange={handleInputChange}
                                                                className={Boolean(validations && validations.info?.eikegn) ? 'validate' : ''}
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Е-поща:
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                value={state.data?.info?.email || ''}
                                                                name="info[email]"
                                                                onChange={handleInputChange}
                                                                className={Boolean(validations && validations.info?.email) ? 'validate' : ''}
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            ИН по ЗДДС:
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                value={state.data?.info?.vat_number || ''}
                                                                name="info[vat_number]"
                                                                onChange={handleInputChange}
                                                                className={Boolean(validations && validations.info?.vat_number) ? 'validate' : ''}
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            МОЛ:
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                value={state.data?.info?.mol || ''}
                                                                name="info[mol]"
                                                                onChange={handleInputChange}
                                                                className={Boolean(validations && validations.info?.mol) ? 'validate' : ''}
                                                            />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <b>
                                        Офиси на фирмата
                                        <Button
                                            className="add simple-add"
                                            onClick={handleAddAddress}
                                            style={{
                                                width: 'fit-content'
                                            }}
                                        >
                                            Нов офис
                                        </Button>
                                    </b>
                                    <ul className="offices-list">
                                        {state.data.addresses.map((address, index) =>
                                            <li key={`office-${address.id}-${index}`}>
                                                <Button
                                                    className={`small edit ${(state.data.addresses || []).length === 1 ? 'is-last' : ''}`}
                                                    onClick={() => {
                                                        handleEditOffice(address)
                                                    }}
                                                >
                                                </Button>
                                                {(state.data.addresses || []).length > 1 ?
                                                    <Button
                                                        className="small remove"
                                                        size="small"
                                                        onClick={() => {
                                                            handleRemoveAddress(index, address.id)
                                                        }}
                                                        style={{
                                                            marginLeft: '10px'
                                                        }}
                                                    >
                                                    </Button>
                                                    :
                                                    <>
                                                    </>
                                                }
                                                <b>
                                                    {address.name}
                                                </b>

                                                <div className="office-data">
                                                    <div className="col">
                                                        <table>
                                                            <tbody>

                                                                <tr>
                                                                    <td>
                                                                        Нас. място:
                                                                    </td>
                                                                    <td>
                                                                        <span>
                                                                            {address.settlement?.name || '-'}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Адрес:
                                                                    </td>
                                                                    <td>
                                                                        <span>
                                                                            {address?.address || '-'}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="col">
                                                        <table>
                                                            <tbody>
                                                                <tr>
                                                                    <td>
                                                                        МОЛ:
                                                                    </td>
                                                                    <td>
                                                                        <span>
                                                                            {address.mol || '-'}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </li>
                                        )}
                                    </ul>
                                    {companies.length > 1 ?
                                        <div className="row delete">
                                            <Button
                                                className="remove"
                                                onClick={() => {
                                                    handleDelete(state.data.id)
                                                }}
                                            >
                                                Изтриване
                                            </Button>
                                            <div className="right">
                                                <h6>
                                                    Изтриване на фирмата
                                                </h6>
                                                <p>
                                                    За да изтриете фирмата натиснете бутона "Изтриване" и потвърдете от електронната си поща.
                                                </p>
                                            </div>
                                        </div>

                                        :
                                        <>
                                        </>
                                    }
                                </div>
                                <div className="right">
                                    <Settings
                                        companyId={state.data.id}
                                        handleDisableSave={handleDisableSave}
                                        banks={state.data?.banks || []}
                                    />
                                </div>
                            </div>
                            <div id="footer">
                                {/* <Button
                                    className="cancel"
                                    onClick={() => {
                                        history.goBack()
                                    }}
                                >
                                    Отказ
                                </Button> */}
                                <SaveButton
                                    className="save"
                                    loading={state.loadingSave}
                                    disabled={state.disableSave}
                                >
                                    Запази
                                </SaveButton>
                            </div>
                        </form >
                    </>
            }
        </section>
    )
}

export default List