import { useState } from "react"

//libraries
import { Checkbox, FormControlLabel } from "@material-ui/core"

//images
import pdfIcon from "assets/img/accounts/icons/pdf.png"

//components
import PreviewBillDocumentButtons from "./PreviewBillDocumentButtons"

export default function PreviewBillDocumentOptions() {
  const [selectedDocuments, setSelectedDocuments] = useState({
    invoice: true,
    stock: true,
  })

  function handleSelectedDocuments({ checked, name }) {
    setSelectedDocuments({
      ...selectedDocuments,
      [name]: checked,
    })
  }

  function areOptionsDisabled() {
    return !selectedDocuments.invoice && !selectedDocuments.stock
  }

  return (
    <>
      <FormControlLabel
        control={<Checkbox name="invoice" value="1" color="primary" />}
        checked={selectedDocuments.invoice}
        onChange={(e) => handleSelectedDocuments(e.target)}
        label={
          <div className="label">
            <img src={pdfIcon} alt="" />
            Фактура №1000006458 / 15.04.2024.pdf
          </div>
        }
      />
      <FormControlLabel
        control={<Checkbox name="stock" value="1" color="primary" />}
        checked={selectedDocuments.stock}
        onChange={(e) => handleSelectedDocuments(e.target)}
        label={
          <div className="label">
            <img src={pdfIcon} alt="" />
            Стокова разписка №10058 / 15.04.2024.pdf
          </div>
        }
        style={{
          marginBottom: "15px",
        }}
      />
      <div
        className={`options-holder ${areOptionsDisabled() ? "disabled" : ""}`}
      >
        <PreviewBillDocumentButtons />
      </div>
    </>
  )
}
