import { useEffect, useState, useRef } from 'react'
import { useHistory, useLocation, Prompt } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'

//plugins
import { PDFReader } from 'reactjs-pdf-reader'

//misc
import SaveButton from 'components/misc/Button'
import NoSelectedCompany from 'components/misc/NoSelectedCompany'

//helpers
import Api from 'helpers/Api'
import { useQuery } from 'helpers/Url'

//MUI components
import Alert from '@material-ui/lab/Alert'
import { Button, Tabs, Tab, Tooltip } from '@material-ui/core'

//providers
import { useAuthDataContext } from 'providers/Auth'
import { useAppContext } from 'providers/App'

//constants
import { DOCUMENT_TYPE } from 'constants/Document'

//styles
import './partials/style.scss'

//images
import previewIcon from 'assets/img/app/icons/preview-white.png'
import checkIcon from 'assets/img/app/icons/check-white.png'
import moment from 'moment'

let timeout
const tabs = [
    // {
    //     name: 'Оферта',
    //     typeId: DOCUMENT_TYPE.OFFER
    // },
    {
        name: 'Проформа',
        typeId: DOCUMENT_TYPE.PROFORMA
    },
    {
        name: 'Фактура',
        typeId: DOCUMENT_TYPE.INVOICE
    },
    {
        name: 'Кредитно известие',
        typeId: DOCUMENT_TYPE.CREDIT_NOTE
    },
    {
        name: 'Дебитно известие',
        typeId: DOCUMENT_TYPE.DEBIT_NOTE
    },
    // {
    //     name: 'Протокол',
    //     typeId: DOCUMENT_TYPE.PROTOCOL
    // },
    // {
    //     name: 'Стокова разписка',
    //     typeId: DOCUMENT_TYPE.STOCK
    // }
]

function Index() {
    const app = useAppContext()
    const location = useLocation()
    const history = useHistory()
    const query = useQuery()
    const auth = useAuthDataContext()
    const companyId = auth?.getUser()?.getCompany()?.getId()

    const [state, setState] = useState({
        loading: true,
        refresh: false,
        data: [],
        pages: 0,
        total: 0,
        filter: {
            page: 1,
            type_id: DOCUMENT_TYPE.INVOICE,
        },
        loadingSave: false,
        activeTabPosition: 2,
        preview: null,
        disableSave: true,
    })

    useEffect(() => {
        window.onbeforeunload = function () {

            return
        }
    }, [])
    console.log(state.activeTabPosition)
    useEffect(() => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: query.get('page') || 1,
                type_id: query.get('type_id') || prev.filter.type_id
            },
            refresh: moment().unix()
        }))
    }, [location.search])

    useEffect(() => {
        // if (state.setFilter) {
        //     Object.entries(state.filter).map(filter => {
        //         // if (filter[1]) {
        //         query.set(filter[0], filter[1])
        //         // }
        //     })

        //     history.push('?' + query.toString())
        // }
        getData()
    }, [state.setFilter])

    // useEffect(() => {
    //     if (state.refresh) getData()
    // }, [state.refresh])

    const getData = () => {
        if (!companyId) return

        loading(true)

        let url = `documents/templates`

        Object.entries(state.filter).map(filter => {
            url += (url.indexOf('?') > -1 ? '&' : '?') + filter[0] + '=' + filter[1]
        })

        Api.get(url)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    data: [...state.data, ...res.data.items],
                    total: res.data.total,
                    pages: res.data.pages
                }))
            })
            .finally(() => {
                loading(false)
            })
    }

    const loading = loading => {
        setState(prev => ({
            ...prev,
            loading
        }))
    }

    const refresh = (reset = true) => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: reset ? 1 : prev.filter.page
            },
            refresh: moment().unix()
        }))
    }

    const handleSearch = (key, val, delay = 300) => {
        clearTimeout(timeout)

        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                [key]: val,
            },
        }))

        timeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    page: 1
                },
                setFilter: moment().unix()
            }))
        }, delay)
    }

    const handlePage = page => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: page + 1,
            },
            setFilter: moment().unix()
        }))
    }

    const handlePreview = preview => {
        setState(prev => ({
            ...prev,
            preview: `${preview.preview_url}&token=${auth.getToken()}`
        }))
    }

    const handleSelect = (newTemplate, selectedImageUrl) => {
        setState(prev => ({
            ...prev,
            data: prev.data.map(template => {
                if (Number(template.id) === Number(newTemplate.id)) {
                    template.selected = 1
                } else {
                    template.selected = 0
                }

                return template
            }),
            selectedImageUrl,
            disableSave: false
        }))
    }

    const handleSave = () => {
        setLoadingSave(true)

        const url = 'documents/templates/set'

        const selected = getSelected()

        const data = {
            template_id: selected?.id,
            type_id: state.filter.type_id,
        }

        Api.post(url, data)
            .then(() => {
                app.handleSuccess('Запазването на шаблона беше успешно!')
                setState(prev => ({
                    ...prev,
                    disableSave: true
                }))
            })
            .catch(() => {
                app.handleError('Запазването на шаблона беше неуспешно.')
            })
            .finally(() => {
                setLoadingSave(false)
            })
    }

    const setLoadingSave = loadingSave => {
        setState(prev => ({
            ...prev,
            loadingSave
        }))
    }

    const getSelected = () => state.data.find(t => t.selected)

    /**
     * 
     * @param {object} e референция към елемента
     * @param {number} activeTabPosition позицията на раздела започваща от 1
     */
    const handleTab = (e, activeTabPosition) => {
        setState(prev => ({
            ...prev,
            activeTabPosition,
            data: []
        }))

        const typeId = tabs[activeTabPosition - 1]?.typeId
        if (typeId) handleSearch('type_id', typeId, 0)
    }

    /**
     * 
     * @returns дали да се показва опция за странициране
     */
    const showLoadMore = () => {
        let show = false

        if ((Number(state.pages) > 1) && (Number(state.filter.page) < Number(state.pages))) {
            show = true
        }

        return show
    }

    return (
        <div id="templates">
            <div id="head">
                <div className="title">
                    Шаблони
                </div>
            </div>
            <Prompt
                when={!state.disableSave}
                message="Имате незапазени промени! Сигурни ли сте, че искате да напуснете страницата?"
            />
            <hr />
            <Tabs
                value={state.activeTabPosition}
                onChange={handleTab}
                indicatorColor="primary"
                scrollButtons="auto"
                textColor="primary"
                variant="scrollable"
            >
                {tabs.map(tab =>
                    <Tab
                        key={`tab-${tab.typeId}`}
                        value={tab.typeId}
                        label={tab.name}
                    />
                )}
            </Tabs>

            {!companyId
                ?
                <NoSelectedCompany />
                :
                state.loading
                    ?
                    <Skeleton
                        count={5}
                        height={60}
                    />
                    :
                    state.data.length === 0
                        ?
                        <Alert severity="error">
                            Няма намерена информация!
                        </Alert>
                        :
                        <>
                            <div className="templates-holder row">
                                <div className="col current-template">
                                    <h3 className="heading">
                                        Шаблон по подразбиране
                                    </h3>
                                    {getSelected() ?
                                        <PDFReader
                                            key={[getSelected(), state.preview]}
                                            url={`${state.preview || getSelected()?.previews[0]?.preview_url}&token=${auth.getToken()}`}
                                            showAllPage={true}
                                            width={450}
                                        />
                                        :
                                        <Skeleton
                                            count={20}
                                            height={30}
                                            width={450}
                                        />
                                    }
                                </div>
                                <div className="col templates-list">
                                    <h3 className="heading">
                                        Всички шаблони
                                    </h3>
                                    <ul className="list">
                                        {state.data.map(template =>
                                            template.previews.map(preview =>
                                                <li
                                                    key={'p-' + preview.id}
                                                    className={template.selected ? 'selected' : ''}
                                                >
                                                    <picture>
                                                        <img
                                                            src={preview.url}
                                                            alt="preview"
                                                        />
                                                        <div className="view">
                                                            <Tooltip title="Преглед на шаблона">
                                                                <span
                                                                    onClick={() => {
                                                                        handlePreview(preview)
                                                                    }}
                                                                >
                                                                    <img src={previewIcon} />
                                                                </span>
                                                            </Tooltip>
                                                            {!template.selected ?
                                                                <Tooltip title="Задаване като активен">
                                                                    <span
                                                                        onClick={() => {
                                                                            handleSelect(template, preview.url)
                                                                        }}
                                                                    >
                                                                        <img src={checkIcon} />
                                                                    </span>
                                                                </Tooltip>
                                                                :
                                                                <>
                                                                </>
                                                            }
                                                        </div>
                                                    </picture>
                                                    <p>
                                                        {template.name}
                                                    </p>
                                                </li>
                                            ))}
                                        {showLoadMore() ?
                                            <li
                                                style={{
                                                    width: '100%',
                                                    textAlign: 'center'
                                                }}
                                                className="link"
                                                onClick={() => {
                                                    handlePage(Number(state.filter.page))
                                                }}
                                            >
                                                Още шаблони
                                            </li>
                                            :
                                            <>
                                            </>
                                        }
                                    </ul>
                                </div>
                            </div>
                            <div id="footer">
                                <Button
                                    className="cancel"
                                    onClick={() => {
                                        history.goBack()
                                    }}
                                >
                                    Назад
                                </Button>
                                <SaveButton
                                    className="save"
                                    loading={state.loadingSave}
                                    disabled={state.disableSave}
                                    onClick={handleSave}
                                />
                            </div>
                        </>
            }
        </div>
    )
}

export default Index