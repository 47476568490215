import React, { useState, useImperativeHandle, forwardRef, useRef, useEffect } from 'react'
import { ChevronLeft, ChevronRight, Clipboard, ClipboardPlus, Trash } from 'react-bootstrap-icons'
import SaveButton from 'components/misc/Button'
import Loader from 'components/misc/Loader'
import Home from './Home'
import Edit from './Edit'
import Categorisation from './Categorisation'
import Prices from './Prices'
import Details from './Details'
import Suppliers from './Suppliers'
import Related from './Related'
import { Tabs, Tab, Button } from '@material-ui/core'

import { useArticleContext } from '../Provider'
import { useAuthDataContext } from 'providers/Auth'
import { useAppContext } from 'providers/App'

import Api from 'helpers/Api'
import Clone from '../partials/Clone'
import Question from 'components/modals/Question'

import Refs from 'Refs'
import LeftSideMenu from 'components/misc/LeftSideMenu'

//images
import viewIcon from 'assets/img/articles/icons/view.png'
import categoryIcon from 'assets/img/articles/icons/list.png'
import charsIcon from 'assets/img/articles/icons/chars.png'
import deliveryIcon from 'assets/img/articles/icons/delivery.png'
import moment from 'moment'

function View(props, ref) {
    const tabs = [
        {
            id: 'home',
            name: 'Преглед',
            component: Home,
            icon: viewIcon
        },
        // {
        //     id: 'edit',
        //     name: 'Редактиране',
        //     component: Edit,
        //     icon: editIcon
        // },
        {
            id: 'categorisation',
            name: 'Категоризация',
            component: Categorisation,
            icon: categoryIcon
        },
        // {
        //     id: 'price_rules',
        //     name: 'Ценови правила',
        //     component: Prices
        // },
        {
            id: 'details',
            name: 'Характеристики',
            component: Details,
            icon: charsIcon

        },
        {
            id: 'suppliers',
            name: 'Доставчици',
            component: Suppliers,
            icon: deliveryIcon
        },
        // {
        //     id: 'related',
        //     name: 'Свързани артикули',
        //     component: Related
        // },
    ]

    const app = useAppContext()
    const auth = useAuthDataContext()

    const [state, setState] = useState({
        show: false,
        params: null,
        id: null,
        tabId: null,
        tabIndex: 0,
        key: 1,
        onClose: null,
        onSuccess: null,
        onDelete: null,
        loadingSave: false,
    })

    const context = useArticleContext()

    const cloneModalRef = useRef(null)
    const deleteModalRef = useRef(null)
    const leftArrowRef = useRef(null)
    const rightArrowRef = useRef(null)
    const screenRef = useRef(null)

    useImperativeHandle(ref, () => ({
        open: (id, resetTab = true) => {
            handleShow(id, resetTab)
        },
        close: () => {
            hide()
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }))
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }))
        },
        onDelete: fn => {
            setState(prev => ({
                ...prev,
                onDelete: fn
            }))
        }
    }))

    useEffect(() => {
        if (!state.show) {
            return
        }

        if (auth.getUser().permission('articles_view') === false) {
            app.showError('Нямате право да преглеждате артикул!')

            hide(state.onClose)
        }

    }, [state.show])

    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }))

        onEntering?.()
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }))
        onExiting?.()
    }

    const edit = (id) => {
        setState(prev => ({
            ...prev,
            id: id,
        }))
    }

    const handleShow = (id, resetTab = true) => {
        setState(prev => ({
            ...prev,
            id: id,
            tabId: resetTab || !prev.tabId ? getDefaultActiveTab()?.id : prev.tabId,
        }))

        show()
    }

    const handleClose = () => {
        setState(prev => ({
            ...prev,
            show: false,
            tabId: tabs[0].id
        }))

        context.resetData()

        if (typeof state.onClose === 'function') {
            hide(state.onClose)
        } else {
            hide()
        }

        setState(prev => ({
            ...prev,
            onClose: null,
        }))
    }

    const setKey = id => {
        setState(prev => ({
            ...prev,
            tabId: id,
        }))
    }

    const hasTabPermission = key => {
        let map = {
            home: 'articles_view',
            edit: 'articles_edit',
            categorisation: 'articles_cats',
            price_rules: 'articles_pricelists',
            files: 'articles_edit',
            details: 'articles_edit_details',
            suppliers: 'articles_suppliers',
            related: 'articles_edit',
            // reviews: 'articles_edit'
        }

        let permission = map[key]

        if (permission && auth.getUser().permission(permission) === false) {
            return false
        }

        return true
    }

    const getDefaultActiveTab = () => {
        return tabs.find(tab => {
            return hasTabPermission(tab.id)
        })
    }

    const handleAddVariation = e => {
        e.preventDefault()

        Api.post('store/articles/add-variation', {
            id: state.id
        }).then(res => {
            if (res.data) {
                edit(res.data.id)

                app.showSuccess()

                if (typeof state.onSuccess === 'function') {
                    state.onSuccess(res.data)
                }
            }
        })
    }

    const handleClone = e => {
        e.preventDefault()

        let modal = cloneModalRef.current

        if (modal) {
            hide(() => {
                modal.open(state.id)

                modal.onClose(() => {
                    show()
                })

                modal.onSuccess(article => {
                    edit(article.id)

                    app.showSuccess()

                    if (typeof state.onSuccess === 'function') {
                        state.onSuccess()
                    }
                })

            })
        }
    }

    const handleDelete = e => {
        e.preventDefault()

        let modal = deleteModalRef.current

        if (modal) {
            hide(() => {
                modal.open()

                modal.onSuccess(() => {
                    handleDestroy(state.id)
                })

                modal.onCancel(() => {
                    show()
                })
            })
        }

    }

    const handleDestroy = id => {
        Api.post('store/articles/delete', {
            id: id
        }).then(res => {
            if (res.data.success) {
                app.showSuccess()

                if (typeof state.onDelete === 'function') {
                    state.onDelete()
                } else if (typeof state.onSuccess === 'function') {
                    state.onSuccess()
                }
            } else {
                app.showError()
            }
        }).catch(err => {
            app.showError()
        })
    }

    const refresh = () => {
        setState(prev => ({
            ...prev,
            key: moment().unix(),
        }))
    }

    const onSuccess = (data) => {
        refresh()

        if (typeof state.onSuccess === 'function') {
            state.onSuccess(data)
        }

        loadingSave(false)
    }

    function loadingSave(loadingSave) {
        setState(prev => ({
            ...prev,
            loadingSave
        }))
    }

    useEffect(() => {
        if (!state.show) {
            return
        }

        const onKeyPress = (e) => {
            if (e.key === 'ArrowRight') {
                rightArrowRef.current?.click()
            }

            if (e.key === 'ArrowLeft') {
                leftArrowRef.current?.click()
            }
        }

        window.addEventListener('keyup', onKeyPress)

        return () => {
            window.removeEventListener('keyup', onKeyPress)
        }
    }, [state.show])

    const handleShowNextItem = e => {
        e.preventDefault()

        if (props.showNextItem) {
            props.showNextItem(state.id)
        }
    }

    const handleShowPrevItem = e => {
        e.preventDefault()

        if (props.showPrevItem) {
            props.showPrevItem(state.id)
        }
    }

    const handleRefresh = e => {
        e.preventDefault()

        refresh()
    }

    const handleTabChange = (tabIndex) => {
        setState(prev => ({
            ...prev,
            tabIndex
        }))
    }

    return (
        <>

            <Clone
                ref={cloneModalRef}
            />

            {/* <Question
                ref={deleteModalRef}
                mainMessage="Сигурни ли сте?"
                agreeBtnText="Изтриване"
                agreeBtnClass="remove"
            /> */}

            <div className={`${state.show ? "visible" : ""} overlay`}>
                <div
                    className={`popup-primary extra-large complex-modal preview-article-modal price-modal ${state.show ? "show" : ""}`}
                >
                    <div className="header purple">
                        <b>
                            Преглед на артикул
                        </b>
                        <p>
                            Lorem Ipsum е елементарен примерен<br /> текст, използван в печатарската
                        </p>
                    </div>
                    <div className="body">
                        <div
                            className="row"
                            style={{
                                flex: 1,
                            }}
                        >
                            <LeftSideMenu
                                disabled={state.loadingSave}
                                tabsArr={tabs}
                                setActiveTabIndex={handleTabChange}
                                activeTabIndex={state.tabIndex}
                            />
                            {React.createElement(tabs[state.tabIndex].component, {
                                id: state.id,
                                show: show,
                                hide: hide,
                                onSuccess: onSuccess,
                                ref: screenRef,
                                loadingSave: state.loadingSave,
                                handleStopLoadingSave: () => {
                                    loadingSave(false)
                                },
                            })}
                        </div>
                    </div>
                    <div className="footer" >
                        <div
                            className="row"
                            style={{
                                justifyContent: 'space-between'
                            }}
                        >
                            <div>
                                <Button
                                    className="cancel"
                                    onClick={handleAddVariation}
                                    style={{
                                        minWidth: '100px'
                                    }}
                                >
                                    Създай разновидност
                                </Button>
                                <Button
                                    className="cancel"
                                    onClick={handleClone}
                                    style={{
                                        minWidth: '100px'
                                    }}
                                >
                                    Клонирай
                                </Button>
                            </div>
                            <div>
                                <Button
                                    className="cancel"
                                    onClick={handleClose}
                                    style={{
                                        minWidth: '100px'
                                    }}
                                >
                                    Назад
                                </Button>
                                {state.tabIndex !== 0 &&
                                    <SaveButton
                                        loading={state.loadingSave}
                                        className="save"
                                        onClick={() => {
                                            screenRef.current?.save()
                                            loadingSave(true)
                                        }}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default forwardRef(View)