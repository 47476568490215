import React, { useEffect, useRef, useState } from 'react'
import Layout from 'components/Layout'
import { useHistory, useParams } from 'react-router-dom'
import Incomes from './incomes/Index'
import Expenses from './expenses/Index'
import AddOrEditExpense from './expenses/partials/AddOrEdit'
import AddOrEditIncome from './incomes/partials/AddOrEdit'

import { Button } from '@material-ui/core'

function Index() {
    const history = useHistory()
    const params = useParams()

    const addOrEditExpenseModalRef = useRef()
    const addOrEditIncomeModalRef = useRef()

    const tabs = [
        {
            id: 'incomes',
            name: 'Приходи',
            component: Incomes
        },
        {
            id: 'expenses',
            name: 'Разходи',
            component: Expenses
        },

    ]

    const tab = params.tab

    const componentRef = useRef(null)

    const [state, setState] = useState({
        tab: tabs[0].id,
        index: 0,
    })

    useEffect(() => {
        setState(prev => ({
            ...prev,
            loading: true,
            tab: tab,
            index: findTabIndex(tab)
        }))
    }, [params.tab])

    const handleTab = (_, id) => {

        let index = findTabIndex(id)

        setState(prev => ({
            ...prev,
            tab: id,
            index: index
        }))

        history.push('/finances/' + id)
    }

    const findTabIndex = id => {
        let index = tabs.findIndex(t => {
            return t.id === id
        })

        return index
    }

    const handleAddExpense = () => {
        const modal = addOrEditExpenseModalRef.current

        modal.add()

        modal.onSuccess(() => {
            componentRef.current.refresh()
        })
    }

    const handleAddIncome = () => {
        const modal = addOrEditIncomeModalRef.current

        modal.add()

        modal.onSuccess(() => {
            componentRef.current.refresh()
        })
    }

    return (
        <>
            <AddOrEditExpense
                ref={addOrEditExpenseModalRef}
                handleTab={handleTab}
            />

            <AddOrEditIncome
                ref={addOrEditIncomeModalRef}
                handleTab={handleTab}
            />

            <Layout>

                <div id="tabs-head" className="row space-between">
                    <ul className="tabs">
                        {tabs.map(tab =>
                            <li
                                key={tab.id}
                                className={tabs[state.index] && tabs[state.index].id === tab.id ? 'active' : ''}
                                onClick={e => handleTab(e, tab.id)}
                            >
                                <ul className="subtabs">
                                    <li key={'tab-' + tab.id} className={tabs[state.index] && tabs[state.index].id === tab.id ? 'active-subtab' : ''}>
                                        <span>
                                            {tab.name}
                                        </span>
                                    </li>
                                </ul>
                            </li>
                        )}
                    </ul>

                    <div
                        className="row right"
                        style={{
                            width: 'fit-content'
                        }}
                    >
                        <Button
                            className="add income"
                            onClick={handleAddIncome}
                        >
                            Приход
                        </Button>
                        <Button
                            className="add expense"
                            onClick={handleAddExpense}
                            style={{
                                marginLeft: '10px'
                            }}
                        >
                            Разход
                        </Button>
                    </div>
                </div>

                <section id="accounts">
                    <div className="panel">
                        {tabs[state.index] &&
                            React.createElement(tabs[state.index].component, {
                                ref: componentRef
                            })
                        }
                    </div>
                </section>
            </Layout>
        </>
    )
}

export default Index