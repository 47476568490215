import { useState, useEffect, useRef } from 'react'
import { Button, Dropdown, FloatingLabel, Form, InputGroup, Table } from 'react-bootstrap'
import Api from 'helpers/Api'
import Loader from 'components/misc/Loader'
import moment from 'moment'
import NoDataFound from 'components/misc/NoDataFound'
import Pagination from 'components/misc/Pagination'
import TableHeader from 'components/misc/TableHeader'
import TableCol from 'components/misc/TableCol'
import PriceValue from 'components/partials/PriceValue'
import MenuOptions from 'components/misc/MenuOptions'
import Refs from 'Refs'
import RedditTextField from 'components/misc/RedditTextField'

let timeout

function Index(props) {

    const defaultData = []

    const tableRef = useRef(null)

    const [state, setState] = useState({
        data: defaultData,
        pages: 0,
        total: 0,
        totals: {},
        loading: false,
        filter: {
            page: 1,
            search: '',
        },
        refresh: false,
    })

    useEffect(() => {

        loading(true)

        Api.get('store/accounts/history', {
            params: {
                id: props.id,
                ...state.filter,
            }
        }).then(res => {
            setState(prev => ({
                ...prev,
                currency: res.data.account.currency,
                data: res.data.items,
                total: res.data.total,
                pages: res.data.pages,
                totals: res.data.totals,
                filter: {
                    ...prev.filter,
                    ...res.data.filter,
                },
            }))

            if (res.data.pages > 0 && state.filter.page > res.data.pages) {
                return handlePage({ selected: res.data.pages - 1 })
            }

            loading(false)
            sorting(false)
        })
    }, [props.id, state.refresh])

    const loading = (loading) => {
        setState(prev => ({
            ...prev,
            loading: Boolean(loading)
        }))
    }

    const sorting = (sorting) => {
        setState(prev => ({
            ...prev,
            sorting: Boolean(sorting)
        }))
    }

    const handleSearch = (key, val, delay = 300) => {
        clearTimeout(timeout)

        if (typeof key === 'object') {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    ...key
                },
            }))
        } else {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    [key]: val,
                },
            }))
        }

        timeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    page: 1
                },
                refresh: moment().unix()
            }))
        }, delay)
    }

    const handlePage = page => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: page.selected + 1,
            },
            refresh: moment().unix()
        }))
    }

    const handleSort = (sort, order) => {
        setState(prev => ({
            ...prev,
            sorting: true,
            filter: {
                ...prev.filter,
                sort: sort,
                order: order,
                page: 1,
            },
            refresh: moment().unix()
        }))
    }

    const showArticle = id => {
        props.showArticle(id)
    }

    const showStornoAccount = row => {

        props.hide(() => {
            let stornorows = row.stornorows

            if (stornorows.length > 0) {
                let ids = stornorows.map(l => l.account_id)

                if (stornorows.length > 1) {
                    showChooseAccount(ids)
                } else {
                    showAccount(ids[0])
                }
            } else {
                showNoStornoAccount()
            }
        })
    }

    const showAccount = (id, back = true) => {
        let modal = Refs.getInstance().getRef('account')

        if (!modal) {
            return
        }

        modal.open(id)

        if (back) {
            modal.onClose(() => {
                showAccount(props.id, false)
                // props.show();
            })
        }
    }

    const showNoStornoAccount = () => {
        let modal = Refs.getInstance().getRef('error')

        if (!modal) {
            return
        }

        modal.open('Няма свързан номер на сметка!')

        modal.onClose(() => {
            props.show()
        })
    }

    const showChooseAccount = (ids) => {
        let modal = Refs.getInstance().getRef('error')

        if (!modal) {
            return
        }

        modal.open(
            <div style={{ textAlign: 'center' }}>
                Изберете номер на сметка, която искате да видите:
                <br />
                <br />
                <div style={{ display: 'flex', justifyContent: 'center', }}>
                    {ids.map(id =>
                        <Button key={id} onClick={e => {
                            modal.close(() => {
                                showAccount(id)
                            })
                        }} style={{ marginRight: '5px' }}>
                            {id}
                        </Button>
                    )}
                </div>
            </div>
        )

        modal.onClose(() => {
            props.show()
        })
    }

    const showStorno = (id) => {
        props.hide(() => {
            showAccount(id)
        })
    }

    const hasStorno = row => {
        return row.storno_amount < 0 || row.stornorows.length > 0
    }

    return (
        <div className="preview-bill-history">
            <div className="row">
                <div className="col">
                    <h6>
                        История
                    </h6>
                    <div className="field">
                        <div className="filter">
                            <RedditTextField
                                size="small"
                                variant="filled"
                                margin="dense"
                                label="Търсене..."
                                name="search"
                                value={state.filter.search || ''}
                                onChange={e => handleSearch(e.target.name, e.target.value)}
                            />
                        </div>

                        {state.loading && !state.sorting
                            ?
                            <Loader />
                            :
                            state.data.length === 0 ?
                                <NoDataFound />
                                :
                                <>
                                    <table className="type-doc" ref={tableRef}>
                                        <TableHeader
                                            tableRef={tableRef}
                                            activeSortKey={state.filter.sort}
                                            activeSortDir={state.filter.order}
                                            onSort={(col, dir) => handleSort(col, dir)}
                                        >
                                            <TableCol sortable sortKey="id" style={{ width: '5%' }}>ID</TableCol>
                                            <TableCol sortable sortKey="date">Дата</TableCol>
                                            <TableCol sortable sortKey="article">Артикул</TableCol>
                                            <TableCol className="text-right" sortable sortKey="amount">К-во</TableCol>
                                            <TableCol className="text-right" sortable sortKey="current_price">Ед. цена</TableCol>
                                            <TableCol className="text-right" sortable sortKey="total_real">Общо</TableCol>
                                            <TableCol sortable sortKey="depot">От склад</TableCol>
                                            <TableCol className="text-center">Причина</TableCol>
                                            <TableCol className="text-center">Изтеглено</TableCol>
                                        </TableHeader>
                                        <tbody>
                                            {state.data.map((r, i) =>
                                                <tr key={i}>
                                                    <td>
                                                        {r.id}
                                                    </td>
                                                    <td>
                                                        {r.created_at ? moment(r.created_at).format('DD.MM.YYYY HH:mm') : ''}
                                                    </td>
                                                    <td>
                                                        <div style={{ display: 'inline-flex', gap: '3px', backgroundColor: hasStorno(r) ? '#ddd275' : '' }}>
                                                            <span className="link" onClick={e => showArticle(r.article_id)}>
                                                                {r?.article?.article_name}
                                                            </span>
                                                            {hasStorno(r) &&
                                                                <span className="link" onClick={e => showStornoAccount(r)}>
                                                                    [сторнирана]
                                                                </span>
                                                            }
                                                        </div>
                                                    </td>
                                                    <td className={`text-right ${r.amount < 0 && 'negative-value'}`}>
                                                        {r.amount} {r?.article?.amount_type_short}
                                                    </td>

                                                    <td className={`text-right ${r.current_price < 0 && 'negative-value'}`}>
                                                        {state.currency?.prefix} <PriceValue>{r.current_price}</PriceValue> {state.currency?.sufix}
                                                    </td>
                                                    <td className={`text-right ${r.total_real < 0 && 'negative-value'}`}>
                                                        {state.currency?.prefix} <PriceValue>{r.total_real}</PriceValue> {state.currency?.sufix}
                                                    </td>
                                                    <td>
                                                        {r.depot_name}
                                                    </td>
                                                    <td className={`text-center ${r.amount < 0 ? 'yellow-value' : ''}`}>
                                                        {r.amount > 0
                                                            ?
                                                            'Поръчка'
                                                            :
                                                            r.amount < 0
                                                                ?
                                                                <span className="link" title={`От сметка ${r.storno?.account_id}`} onClick={e => showStorno(r.storno?.account_id)}>
                                                                    Сторно
                                                                </span>
                                                                :
                                                                ''
                                                        }
                                                    </td>
                                                    <td className={`text-center ${r.amount > 0 ? 'negative-value' : (r.amount < 0 ? 'positive-value' : '')} `}>
                                                        {r.amount > 0
                                                            ?
                                                            'Изтеглено'
                                                            :
                                                            r.amount < 0
                                                                ?
                                                                'Върнато'
                                                                :
                                                                ''
                                                        }
                                                    </td>
                                                </tr>
                                            )}
                                            <tr className="total">
                                                <td colSpan={3}>
                                                    Общо
                                                </td>
                                                <td className={`text-right ${state.totals?.amount < 0 && 'negative-value'}`}>
                                                    {state.totals?.amount} ме
                                                </td>
                                                <td></td>
                                                <td className={`text-right ${state.totals?.total_real < 0 && 'negative-value'}`}>
                                                    <PriceValue prefix={state.currency?.prefix} sufix={state.currency?.sufix}>{state.totals?.total_real}</PriceValue>
                                                </td>
                                                <td>

                                                </td>
                                                <td>

                                                </td>
                                                <td>

                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <Pagination
                                        className="mt-3"
                                        page={state.filter.page}
                                        pages={state.pages}
                                        total={state.total}
                                        handlePage={handlePage}
                                    />
                                </>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Index