import { createContext, useContext, useEffect, useState } from 'react'

//constants
import { INVOICES_TABS } from 'components/pages/documents/constants/tabs'
import { DOCUMENT_TYPE } from 'constants/Document'

//libraries
import moment from 'moment'

//providers
import { useAuthDataContext } from './Auth'

//hooks
import useFetchDocumentNextNumber from 'hooks/useFetchDocumentNextNumber'

export const DocumentsTabsContext = createContext({})

const DocumentsTabsProvider = props => {
    const auth = useAuthDataContext()

    const [activeTabTypeId, setActiveTabTypeId] = useState(DOCUMENT_TYPE.INVOICE)
    const [activeSubtabId, setActiveSubtabId] = useState(null)
    const [subtabs, setSubtabs] = useState({})
    const [nextNumbers, setNextNumbers] = useState({})
    const [tabUnrepeatableActions, setTabUnrepeatableActions] = useState({}) // Действия, които да не се повтарят при смяна на таб. Примерно: заявки.

    const { fetchNextNumber } = useFetchDocumentNextNumber()

    useEffect(() => {
        appendNewSubtabNextNumber(getActiveTabTypeId())
    }, [getActiveTabTypeId(), auth?.getUser()?.getCompany()?.id])

    useEffect(() => {
        recalculateTabNumber()
    }, [subtabs])

    async function appendNewSubtabNextNumber(docTypeId) {
        const nextNumber = await fetchNextNumber(docTypeId)
        handleSetNextNumberForDocType(docTypeId, nextNumber)
    }

    function handleSetNextNumberForDocType(docTypeId, nextNumber) {
        setNextNumbers(prev => ({
            ...prev,
            [docTypeId]: nextNumber
        }))
    }

    function handleRemoveSubtab(docTypeId = getActiveTabTypeId(), subtabId = getActiveSubtabId()) {
        triggerTabChangeOnRemove()

        const clonedSubtabs = { ...getSubtabsForCurrentTabObj(docTypeId) }

        delete clonedSubtabs[subtabId]

        handleSetSubtabs(docTypeId, clonedSubtabs)
        _handleRemoveAllTabUnrepeatableActions(subtabId)
    }

    function triggerTabChangeOnRemove() {
        handleSetActiveSubtabId(null)
    }

    //MODES
    // 'new',
    // 'preview',
    // 'edit',
    function handleAddNewSubtab(subtab = {}, mode = 'new') {
        // Ако има отворен нов раздел от съответния тип документи, да се фокусира, вместо да се създава следващ нов.
        if (mode === "new" && subtabs[getActiveTabTypeId()]) {
            const existingNewSubtab = Object.values(subtabs[getActiveTabTypeId()]).find(s => s.mode === 'new')
            if (existingNewSubtab) {
                handleSetActiveTabTypeId(Number(getActiveTabTypeId()))
                handleSetActiveSubtabId(existingNewSubtab.id)

                return
            }
        }

        const clonedSubtab = { ...subtab }

        if (!clonedSubtab.typeId) {
            clonedSubtab['typeId'] = clonedSubtab.type_id || getActiveTabTypeId()
        }
        if (!clonedSubtab.date) {
            clonedSubtab['date'] = moment().format('DD.MM.YYYY')
        }
        if (!clonedSubtab.id) {
            clonedSubtab['id'] = moment().unix()
        }
        if (!clonedSubtab.mode) {
            clonedSubtab['mode'] = mode
        }

        handleSetActiveTabTypeId(Number(clonedSubtab.typeId))
        handleSetActiveSubtabId(clonedSubtab.id)

        const subtabsObj = {
            ...subtabs[clonedSubtab.typeId],
            [clonedSubtab.id]: {
                ...clonedSubtab,
            }
        }

        setSubtabs(prev => ({
            ...prev,
            [clonedSubtab.typeId]: subtabsObj
        }))
    }


    function changeDocumentMode(subtabId, mode) {
        const clonedSubtabs = { ...getSubtabsForCurrentTabObj(getActiveTabTypeId()) }

        clonedSubtabs[subtabId]['mode'] = mode

        handleSetSubtabs(subtabId, clonedSubtabs)
    }

    function recalculateTabNumber() {
        const nextNumbersArr = Object.keys(nextNumbers)
        const subtabsArr = Object.values(subtabs)

        if (nextNumbersArr.length && subtabsArr.length) {
            Object.keys(nextNumbers).map((keys, nIndex) => {
                if (subtabs[keys]) {
                    // Ако няма s.hash, значи е ново създаден документ.
                    // Само за новите документи се пресмята номера.
                    Object.values(subtabs[keys]).filter(s => !s.hash).map((subtab, sIndex) => {
                        if (!subtab['no']) {
                            subtab['no'] = String(Number(Object.values(nextNumbers)[nIndex]) + sIndex).padStart(10, '0')

                            handleSetSubtab(getActiveTabTypeId(), subtab.id, subtab)
                        }
                    })
                }
            })
        }
    }

    function handleSetActiveTabTypeId(docTypeId) {
        if (docTypeId === getActiveTabTypeId()) return

        setActiveTabTypeId(docTypeId)
    }

    function getActiveTabTypeId() {
        return activeTabTypeId
    }

    function getActiveTab() {
        return INVOICES_TABS.find(tab => tab.typeId === activeTabTypeId)
    }

    function getSubtabsForCurrentTab(docTypeId) {
        return Object.values(subtabs[docTypeId] || {})
    }

    function getSubtabsForCurrentTabObj(docTypeId) {
        return subtabs[docTypeId] || {}
    }

    function getActiveSubtab() {
        return getSubtabsForCurrentTab(getActiveTabTypeId()).find(subtab => subtab.id === getActiveSubtabId()) || {}
    }

    function getActiveSubtabId() {
        return activeSubtabId
    }

    function handleSetActiveSubtabId(subtabId) {
        setActiveSubtabId(subtabId)
    }

    function handleSetSubtabs(docTypeId, subtabs) {
        setSubtabs(prev => ({
            ...prev,
            [docTypeId]: subtabs
        }))
    }

    function handleSetSubtab(docTypeId, subtabId, subtab) {
        setSubtabs(prev => ({
            ...prev,
            [docTypeId]: {
                ...prev[docTypeId],
                [subtabId]: subtab
            }
        }))
    }

    function getTabUnrepeatableActions(subtabId, action) {
        return tabUnrepeatableActions?.[subtabId]?.[action] || false
    }

    function handleSetTabUnrepeatableActions(subtabId, action, value = true) {
        setTabUnrepeatableActions(prev => ({
            ...prev,
            [subtabId]: {
                [action]: value
            }
        }))
    }

    function _handleRemoveAllTabUnrepeatableActions(subtabId) {
        const clonedTabUnrepeatableActions = { ...tabUnrepeatableActions }

        delete clonedTabUnrepeatableActions[subtabId]

        setTabUnrepeatableActions(clonedTabUnrepeatableActions)
    }

    const exportedData = {
        handleAddNewSubtab,
        handleSetActiveTabTypeId,
        getActiveTabTypeId,
        getActiveTab,
        getSubtabsForCurrentTab,
        getActiveSubtabId,
        handleSetActiveSubtabId,
        handleRemoveSubtab,
        appendNewSubtabNextNumber,
        getActiveSubtab,
        handleSetSubtab,
        changeDocumentMode,
        getTabUnrepeatableActions,
        handleSetTabUnrepeatableActions
    }

    return <DocumentsTabsContext.Provider value={exportedData} {...props} />
}

export const useDocumentsTabsContext = () => useContext(DocumentsTabsContext)

export default DocumentsTabsProvider