import { createContext, useContext, useState } from "react"

//helpers
import { usePresenceSocketChannel, usePrivateSocketChannel } from "helpers/Socket"
import Api from "helpers/Api"

//poviders
import { useAuthDataContext } from "./Auth"
// import User from "../models/User"

export const AppContext = createContext({})

let infoTimeout
let successTimeout
let successMessageTimeout
let errorTimeout
let errorMessageTimeout
const AppProvider = props => {
    const auth = useAuthDataContext()

    const [state, setState] = useState({
        messages: {
            success: {
                message: '',
                show: false,
            },
            error: {
                message: '',
                show: false,
            },
            info: {
                message: 'Създаване на дубликат на Вашият документ',
                show: false,
            }
        },
        paymentMethods: [],
        startedDocumentActivities: []
    })

    /**
     * 
     * @param {boolean} action дали да се махне или добави от/в масива с започнати дейности
     * @param {string} startedActivity индекса на раздела, в който е започната дейност
     */
    const setStartedDocumentActivities = (action, startedActivity) => {
        let { startedDocumentActivities } = state
        if (action) startedDocumentActivities.push(String(startedActivity))
        else {
            startedDocumentActivities = startedDocumentActivities.filter(s => {
                return s !== String(startedActivity)
            })
        }
        setState(prev => ({
            ...prev,
            startedDocumentActivities
        }))
    }

    /**
     * 
     * @param {string} docId 
     * @returns дали има започната активност, към текущия докуемнт
     */
    const isStartedDocumentActivity = docId => {
        if ((state.startedDocumentActivities || []).includes(String(docId))) {
            return true
        }

        return false
    }

    /**
     * 
     * @param {string} message съобщение, което да се визуализира 
     */
    const handleSuccess = (message = 'Действието беше успешно!') => {
        setState(prev => ({
            ...prev,
            messages: {
                ...prev.messages,
                success: {
                    ...prev.messages.success,
                    message,
                    show: true
                }
            }
        }))

        clearTimeout(successTimeout)
        successTimeout = setTimeout(() => {
            handleHideSuccess()
        }, 3000)
    }

    const handleHideSuccess = () => {
        setState(prev => ({
            ...prev,
            messages: {
                ...prev.messages,
                success: {
                    ...prev.messages.success,
                    show: false
                }
            }
        }))

        clearTimeout(successMessageTimeout)
        successMessageTimeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                messages: {
                    ...prev.messages,
                    success: {
                        ...prev.messages.success,
                        message: '',
                    }
                }
            }))
        }, 1000)
    }

    /**
     * 
     * @param {string} message съобщение, което да се визуализира 
     */
    const handleError = (message = 'Нещо се обърка!') => {
        setState(prev => ({
            ...prev,
            messages: {
                ...prev.messages,
                error: {
                    ...prev.messages.error,
                    message,
                    show: true
                }
            }
        }))

        clearTimeout(errorTimeout)
        errorTimeout = setTimeout(() => {
            handleHideError()
        }, 3000)
    }

    const handleHideError = () => {
        setState(prev => ({
            ...prev,
            messages: {
                ...prev.messages,
                error: {
                    ...prev.messages.error,
                    show: false
                }
            }
        }))

        clearTimeout(errorMessageTimeout)
        errorMessageTimeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                messages: {
                    ...prev.messages,
                    error: {
                        ...prev.messages.error,
                        message: '',
                    }
                }
            }))
        }, 1000)
    }

    /**
     * 
     * @param {string} message съобщение, което да се визуализира 
     */
    const handleInfo = (message = 'Нещо се обърка!') => {
        setState(prev => ({
            ...prev,
            messages: {
                ...prev.messages,
                info: {
                    ...prev.messages.info,
                    message,
                    show: true
                }
            }
        }))
    }

    const handleHideInfo = () => {
        setState(prev => ({
            ...prev,
            messages: {
                ...prev.messages,
                info: {
                    ...prev.messages.info,
                    show: false
                }
            }
        }))

        clearTimeout(infoTimeout)
        infoTimeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                messages: {
                    ...prev.messages,
                    info: {
                        ...prev.messages.info,
                        message: '',
                    }
                }
            }))
        }, 1000)
    }

    const showSuccess = (text = 'Действието е успешно!') => {
        handleSuccess(text);
    }

    const showError = (text = 'Нещо се обърка!') => {
        handleError(text);
    }

    const showWarning = (text = '') => {
        handleError(text);
    }

    /**
     * 
     * @returns методи на плащане
     */
    const getPaymentMethods = async () => {
        let paymentMethods = state.paymentMethods

        if (paymentMethods.length) {
            return state.paymentMethods
        }

        await Api.get('documents/payment-methods')
            .then(res => {
                paymentMethods = res.data
                setState(prev => ({
                    ...prev,
                    paymentMethods
                }))
            })

        return paymentMethods
    }

    usePrivateSocketChannel({
        channel: 'users',
        channelId: auth.getUser()?.getId(),
        event: 'User\\ProfileUpdated',
        onEvent: payload => {
            if (payload.profile) {
                auth.setData(payload.profile)
            }
        }
    }, [auth])

    const companyChannel = usePresenceSocketChannel({
        channel: 'companies',
        channelId: auth?.getUser()?.getCompany()?.getId(),
        onJoin: members => {
        },
        onJoining: member => {
        },
        onLeaving: member => {
        }
    }, [auth])

    const exportedData = {
        handleSuccess,
        handleHideSuccess,
        handleError,
        handleHideError,
        showSuccess,
        showError,
        showWarning,
        getPaymentMethods,
        state,
        handleInfo,
        handleHideInfo,
        setStartedDocumentActivities,
        isStartedDocumentActivity
    }

    return <AppContext.Provider value={exportedData} {...props} />
}

export const useAppContext = () => useContext(AppContext)

export default AppProvider