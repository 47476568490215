import { useRef } from "react"

import { useHistory } from "react-router-dom"

//libraries
import { Button, Checkbox, Tooltip } from "@material-ui/core"
import printJS from "print-js"

//images
import scrollIcon from "assets/img/dashboard/icons/scroll.png"
import loader from "assets/img/loader.svg"
import dropArrowIcon from "assets/img/app/icons/drop-arrow-white.png"

//components
import CreateDocumentMenu from "./CreateDocumentMenu"
import Config from "components/pages/documents/partials/payment/Config"
import Question from "components/modals/Question"
import MultipleActions from "components/pages/documents/partials/MultipleActions"
import MenuOptions from "components/misc/MenuOptions"
// import Options from "components/pages/customers/partials/Options"
import Options from "components/pages/documents/partials/Options"
import MakePaid from "components/pages/documents/partials/payment/MakePaid"
import AddPayment from "components/pages/documents/partials/payment/AddPayment"
import Send from "components/partials/documents/Send"
import Export from "components/pages/documents/partials/export/Export"
import RepeatOptions from "components/pages/documents/partials/RepeatOptions"

//providers
import { useDashboardDataContext } from "../providers/dashboard"
import { useDocumentsTabsContext } from "providers/DocumentsTabs"
import { useAppContext } from "providers/App"

//hooks
import useDocumentPayments from "../hooks/useDocumentPayments"
import useSelectDocuments from "../hooks/useSelectDocuments"
import useConvertToDebitNote from "components/pages/documents/hooks/useConvertToDebitNote"
import useConvertToCreditNote from "components/pages/documents/hooks/useConvertToCreditNote"
import useConvertToProforma from "components/pages/documents/hooks/useConvertToProforma"
import useConvertToInvoice from "components/pages/documents/hooks/useConvertToInvoice"
import useRightClick from "hooks/useRightClick"

//constants
import { DOCUMENT_COLUMNS } from "../constants/document"
import { DOCUMENT_TYPE } from "constants/Document"

//helpers
import { splitLongNumbers } from "helpers/numbers"
import Api from "helpers/Api"

function DocumentsTable() {
  const { handleAddNewSubtab, getActiveTabTypeId, getActiveSubtabId } =
    useDocumentsTabsContext()
  const { handleSuccess, handleError, handleHideInfo, handleInfo } =
    useAppContext()

  const history = useHistory()

  const makeUnpaidModalRef = useRef(null)
  const createButtonRef = useRef(null)
  const createDocumentMenuRef = useRef(null)
  const deleteModalRef = useRef(null)
  const makePaidModalRef = useRef(null)
  const addPaymentModalRef = useRef(null)
  const sendModalRef = useRef(null)
  const exportModalRef = useRef(null)
  const repeatOptionsModalRef = useRef(null)

  const { appendDataFromPaymentReq, handleMakeUnpaid } = useDocumentPayments({
    makeUnpaidModalRef,
  })
  const { getDocuments, loadingDocuments, refreshData } =
    useDashboardDataContext()
  const { handleConvertToDebitNote } = useConvertToDebitNote()
  const { handleConvertToCreditNote } = useConvertToCreditNote()
  const { handleConvertToProforma } = useConvertToProforma()
  const { handleConvertToInvoice } = useConvertToInvoice()
  const {
    selectAllDocument,
    selectDocument,
    isSelected,
    getSelectedCount,
    getSelectedDocumentsIds,
    getSelectedDocumentsTypeIds,
    areAllSelected,
  } = useSelectDocuments({
    getDocuments,
  })
  const { handlePositionMenuOptions } = useRightClick()

  if (loadingDocuments) {
    return <img src={loader} alt="loading" />
  }

  if (!loadingDocuments && !getDocuments().length) {
    return (
      <p className="no-data">Няма добавени документи през Вашият профил.</p>
    )
  }

  function createDifferentDocument() {
    createDocumentMenuRef.current.open()
  }

  function addInvoice(e) {
    if (e.target.classList.contains("menu")) return

    history.push("/invoices", {
      data: { typeId: DOCUMENT_TYPE.INVOICE },
      mode: "new",
    })
  }

  function viewDocument(data) {
    history.push("/invoices", { data, mode: "preview" })
  }

  function renderMultipleActions() {
    if (getSelectedCount()) {
      return (
        <MultipleActions
          getSelectedCount={getSelectedCount}
          getSelectedDocumentsIds={getSelectedDocumentsIds}
          getDocuments={getDocuments}
          typeId={getSelectedDocumentsTypeIds()}
        />
      )
    }

    return <></>
  }

  function handleDelete(id) {
    const modal = deleteModalRef.current

    modal.open()
    modal.onSuccess(() => {
      handleDestroy(id)
    })
  }

  function handleDestroy(id) {
    Api.post("documents/delete", {
      id,
    })
      .then(() => {
        refreshData("DOCUMENTS")
        refreshData("TRANSACTIONS")
        handleSuccess("Документа беше изтрит успешно!")
      })
      .catch(() => {
        handleError()
      })
  }

  function handleEdit(data) {
    history.push("/invoices", {
      data,
      mode: "edit",
    })
  }

  function handleMakePaid(id) {
    const modal = makePaidModalRef.current

    modal.open(id)
    modal.onSuccess(() => {
      refreshData("DOCUMENTS")
      refreshData("TRANSACTIONS")
      handleSuccess()
    })
  }

  function handleAddPayment(id) {
    const modal = addPaymentModalRef.current

    modal.open(id)
    modal.onSuccess(() => {
      refreshData("DOCUMENTS")
      refreshData("TRANSACTIONS")
      handleSuccess()
    })
  }

  function handleSend(id) {
    let email = null

    if (!Array.isArray(id)) {
      const document = (getDocuments() || []).find(
        (d) => Number(d.id) === Number(id)
      )

      if (document) {
        email = document?.customerinfo?.email
      }
    }

    const modal = sendModalRef.current

    modal.open(id, email)
    modal.onSuccess(() => {
      handleSuccess()
    })

    modal.onError(() => {
      handleError()
    })
  }

  function handleExport(id) {
    const modal = exportModalRef.current

    modal.open(id)
    modal.onSuccess(() => {
      handleSuccess("Експортирането завърши успешно!")
    })

    modal.onError(() => {
      handleError()
    })
  }

  function handlePreview(data) {
    // handleAddNewSubtab(data, "preview")

    history.push("/invoices", {
      data,
      mode: "preview",
    })
  }

  function handleShowRepeatOptions(data) {
    const modal = repeatOptionsModalRef.current

    modal.open(data)
    modal.onSuccess(() => {
      handleSuccess()
    })

    modal.onError(() => {
      handleError()
    })
  }

  function handleSuccessTooltip(id, message) { }

  function handlePrint(pdfUrl) {
    printJS({
      printable: pdfUrl,
      onError: (error) => {
        console.log(error)
      },
    })
  }

  function handleDublicate(data) {
    handleInfo("Издаване на дубликат...")

    Api.get(`documents/duplicate?id=${data.id}`)
      .then((res) => {
        handleSuccess("Документа беше дублиран.")
        handlePreview(res.data)
      })
      .catch(() => {
        handleError()
      })
      .finally(() => {
        handleHideInfo()
      })
  }

  function download(pdfUrl) {
    window.open(pdfUrl)
  }

  return (
    <>
      {renderMultipleActions()}
      <Question
        ref={makeUnpaidModalRef}
        mainMessage="Върни за плащане"
        secondaryMessage="След Вашето потвърждение, всички плащания към документа ще бъдат премахнати от системата."
        agreeBtnText="Продължете"
      />
      <Question
        ref={deleteModalRef}
        mainMessage="Потвърждавам изтриването"
        secondaryMessage="Изтритите от Вас данни не могат да бъдат възстановени."
        agreeBtnText="Изтриване"
        agreeBtnClass="remove"
      />
      <MakePaid ref={makePaidModalRef} />
      <AddPayment ref={addPaymentModalRef} />
      <Send ref={sendModalRef} documentsType="documents" />
      <Export
        ref={exportModalRef}
        typeId={getActiveTabTypeId()}
        getSelectedCount={getSelectedCount}
      />
      <RepeatOptions ref={repeatOptionsModalRef} />
      <div className="head">
        <div className="left">
          <img src={scrollIcon} alt="" />
          <div className="r">
            <b>Последни документи</b>
            <p>Проследете последно създадените<br /> документи в системата.</p>
          </div>
        </div>
        <CreateDocumentMenu
          createDocumentButtonRef={createButtonRef}
          ref={createDocumentMenuRef}
        />
        <Button
          className="add with-dropdown new-btn-primary"
          ref={createButtonRef}
          onClick={addInvoice}
        >
          Фактура
          <i className="menu" onClick={createDifferentDocument}>
            <img src={dropArrowIcon} />
          </i>
        </Button>
      </div>
      <table className="type-outer">
        <thead>
          <tr>
            <th className="text-center">
              <Tooltip title="Маркиране на всички документи">
                <Checkbox
                  checked={areAllSelected()}
                  color="primary"
                  onClick={selectAllDocument}
                />
              </Tooltip>
            </th>
            {DOCUMENT_COLUMNS.map((d) => (
              <th key={d.name} className={d.className}>
                {d.name}
              </th>
            ))}
            <th className="text-center">Опции</th>
          </tr>
        </thead>
        <tbody>
          {getDocuments().map((d) => (
            <tr
              key={d.id}
              data-id={d.id}
              data-download-pdf-url={d?.download_pdf_url || ""}
              onMouseDown={(e) => {
                handlePositionMenuOptions(e, d)
              }}
              onContextMenu={(e) => {
                e.preventDefault()
              }}
            >
              <td className="text-center">
                <Tooltip
                  title={`Маркиране на ${d.type?.translation?.name.toLowerCase()}`}
                >
                  <Checkbox
                    checked={isSelected(d.id)}
                    color="primary"
                    onClick={() => {
                      selectDocument(d.id)
                    }}
                  />
                </Tooltip>
              </td>
              <td
                onClick={() => {
                  viewDocument(d)
                }}
              >
                {d.type.translation.name}
              </td>
              <td
                onClick={() => {
                  viewDocument(d)
                }}
              >
                {d.no}
              </td>
              <td
                onClick={() => {
                  viewDocument(d)
                }}
              >
                {d.date_dmy}
              </td>
              <td
                onClick={() => {
                  viewDocument(d)
                }}
              >
                {d.customer.info.name}
              </td>
              <td
                className="text-right"
                onClick={() => {
                  viewDocument(d)
                }}
              >
                {splitLongNumbers(d.gross_price_formatted)} {d.currency.name}
              </td>
              <td className="text-center">
                <Config
                  doc={d}
                  handleMakeUnpaid={handleMakeUnpaid}
                  appendData={appendDataFromPaymentReq}
                  successCB={() => {
                    refreshData("DOCUMENTS")
                    refreshData("TRANSACTIONS")
                  }}
                />
              </td>
              <td className="text-center">
                <MenuOptions id={d.id} enableRightClick={true}>
                  <Options
                    data={d}
                    handleEdit={() => {
                      handleEdit(d)
                    }}
                    activeSubtabIndex={getActiveSubtabId()}
                    handleDelete={handleDelete}
                    handleMakePaid={handleMakePaid}
                    handleMakeUnpaid={handleMakeUnpaid}
                    handleAddPayment={handleAddPayment}
                    handleSend={handleSend}
                    handleExport={handleExport}
                    handlePreview={() => {
                      handlePreview(d)
                    }}
                    handleConvertToProforma={handleConvertToProforma}
                    handleConvertToInvoice={() => {
                      handleConvertToInvoice(d.id)
                    }}
                    handleConvertToCreditNote={handleConvertToCreditNote}
                    handleConvertToDebitNote={handleConvertToDebitNote}
                    handleShowRepeatOptions={handleShowRepeatOptions}
                    handleSuccessCopied={handleSuccessTooltip}
                    handlePrint={handlePrint}
                    handleDublicate={() => {
                      handleDublicate(d)
                    }}
                    handleDownload={(pdfUrl) => {
                      download(pdfUrl)
                    }}
                  />
                </MenuOptions>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  )
}

export default DocumentsTable
