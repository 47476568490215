import React, { useState, useImperativeHandle, forwardRef, useEffect, useRef } from 'react'
import { Tabs, Tab, Button } from '@material-ui/core'
import SaveButton from 'components/misc/Button'
import Loader from 'components/misc/Loader'
import Home from './Home'
import StorageMovements from './StorageMovements'
import Refs from 'Refs'
import NewDocument from '../partials/NewDocument'
import LeftSideMenu from 'components/misc/LeftSideMenu'

//images
import viewIcon from 'assets/img/articles/icons/view.png'
import movementsIcon from 'assets/img/accounts/icons/movements.png'

function View(props, ref) {

    const tabs = [
        {
            id: 'home',
            name: 'Преглед',
            component: Home,
            icon: viewIcon
        },
        {
            id: 'storage',
            name: 'Складови движения',
            component: StorageMovements,
            icon: movementsIcon
        }
    ]

    const [state, setState] = useState({
        show: false,
        params: null,
        id: null,
        tabId: tabs[0].id,
        onClose: null,
        tabIndex: 0
    })

    const newDocumentModalRef = useRef(null)
    const importModalRef = useRef(null)

    useImperativeHandle(ref, () => ({
        open: (id) => {
            handleShow(id)
        },
        close: () => {
            handleClose()
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }))
        }
    }))

    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }))

        onEntering?.()
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }))

        onExiting?.()
    }

    const handleShow = (id) => {
        setState(prev => ({
            ...prev,
            id: id,
        }))

        show()
    }

    const handleClose = () => {
        setState(prev => ({
            ...prev,
            show: false,
            tabId: tabs[0].id
        }))

        if (typeof state.onClose === 'function') {
            hide(state.onClose)
        } else {
            hide()
        }

        setState(prev => ({
            ...prev,
            onClose: null,
        }))
    }

    const setKey = id => {
        setState(prev => ({
            ...prev,
            tabId: id,
        }))
    }

    const showArticle = id => {
        if (!id) {
            return
        }

        let modal = Refs.getInstance().getRef('article')

        if (!modal) {
            return
        }

        hide(() => {
            modal.open(id)
        })

        modal.onClose(() => {
            show()
        })
    }

    const addDocument = () => {
        let modal = newDocumentModalRef.current

        if (!modal) {
            return
        }

        hide(() => {
            modal.open(state.id)
        })

        modal.onSuccess(() => {
            // 
        })

        modal.onClose(() => {
            show()
        })
    }

    const handleTabChange = (tabIndex) => {
        setState(prev => ({
            ...prev,
            tabIndex
        }))
    }

    return (
        <>
            <NewDocument
                ref={newDocumentModalRef}
            />

            <div className={`${state.show ? "visible" : ""} overlay`}>
                <div
                    className={`popup-primary extra-large complex-modal preview-storage-load-modal bill-modal ${state.show ? "show" : ""}`}
                >
                    <div className="header sky-blue">
                        <b>
                            Преглед на изписване
                        </b>
                        <p>
                            Lorem Ipsum е елементарен примерен<br /> текст, използван в печатарската
                        </p>
                    </div>
                    <div className="body">
                        <div
                            className="row"
                            style={{
                                flex: 1,
                            }}
                        >
                            <LeftSideMenu
                                disabled={state.loadingSave}
                                tabsArr={tabs}
                                setActiveTabIndex={handleTabChange}
                                activeTabIndex={state.tabIndex}
                            />
                            {React.createElement(tabs[state.tabIndex].component, {
                                id: state.id,
                                importModalRef: importModalRef,
                                show: show,
                                hide: hide,
                                showArticle: showArticle,
                                addDocument: addDocument,
                            })}
                        </div>
                    </div>
                    <div className="footer">
                        <Button
                            className="cancel"
                            onClick={handleClose}
                            style={{
                                marginLeft: 'auto',
                                minWidth: '100px'
                            }}
                        >
                            Назад
                        </Button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default forwardRef(View)