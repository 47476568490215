import { useState, useRef } from 'react'

//MUI components
import { Button, MenuItem, Tooltip, Menu } from '@material-ui/core'

//providers
import { useDocumentsTabsContext } from 'providers/DocumentsTabs'

//images
import dropArrowIcon from 'assets/img/app/icons/drop-arrow-white.png'

//constants
import { INVOICES_TABS } from '../constants/tabs'

function AddButton() {
    const { handleAddNewSubtab, getActiveTab, getActiveTabTypeId } = useDocumentsTabsContext()

    const [anchorEl, setAnchorEl] = useState(null)
    const addBtnRef = useRef(null)

    const openMenu = () => {
        setAnchorEl(addBtnRef.current)
    }

    const closeMenu = () => {
        setAnchorEl(null)
    }

    return (
        <div>
            <Tooltip
                title="Създаване на нов документ"
            >
                <Button
                    onClick={e => {
                        if (!e.target.classList.contains('menu')) {
                            // if (isHomeActive) {
                            //     openMenu()

                            //     return
                            // }

                            handleAddNewSubtab()
                        }
                    }}
                    className="add with-dropdown"
                    ref={addBtnRef}
                >
                    {getActiveTab()?.fullName}
                    <i
                        className="menu"
                        onClick={openMenu}
                    >
                        <img src={dropArrowIcon} alt="" />
                    </i>
                </Button>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                elevation={0}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={closeMenu}
                onClick={closeMenu}
                className="create-documents-menu"
            >
                {INVOICES_TABS.map((t, i) =>
                    t.firstLetter ?
                        <Tooltip
                            title={t.description}
                            key={t.typeId}
                        >
                            <MenuItem
                                onClick={() => {
                                    handleAddNewSubtab(t)
                                }}
                                className={getActiveTabTypeId() === t.typeId ? 'current' : ''}
                            >
                                <span className="image">
                                    {t.firstLetter}
                                </span>
                                {t.fullName}
                            </MenuItem>
                        </Tooltip>
                        :
                        <div>
                            
                        </div>
                )}
            </Menu>
        </div>
    )
}

export default AddButton