import { useRef } from "react"

//libraries
import { Button } from "@material-ui/core"

//images
import leftArrowIcon from "assets/img/app/icons/left-arrow-pages.png"
import rightArrowIcon from "assets/img/app/icons/right-arrow-pages.png"

//components
import ItemsPerPageOption from "./ItemsPerPageOption"

function PaginationMini({ page, pages, handlePage, isLoading }) {
  const itemsPerPageOptionRef = useRef(null)

  //temp
  const selectedLimitCountPerPage = 20

  function isPrevDisabled() {
    if (String(page) === "1") {
      return true
    }

    return false
  }
  function isNextDisabled() {
    if (String(pages) === String(page)) {
      return true
    }

    return false
  }

  function handleNextPage() {
    if (isNextDisabled()) {
      return
    }

    handlePage({
      selected: Number(page - 1) + 1,
    })
  }

  function handlePrevPage() {
    if (isPrevDisabled()) {
      return
    }

    handlePage({
      selected: Number(page - 1) - 1,
    })
  }

  if (Number(pages || 0) <= 1) {
    return <></>
  }

  function openMenu(e) {
    itemsPerPageOptionRef.current.openMenu(e)
  }

  return (
    <>
      <div className={`pagination-mini ${isLoading ? "disabled" : ""}`}>
        {/* DS */}
        {/* Показани на страница:
        <Button onClick={openMenu} className="items-per-page">
          20
        </Button>
        {page} от {pages} */}
        <Button
          disabled={isPrevDisabled()}
          onClick={handlePrevPage}
          className="pages prev-page"
        >
          <img src={leftArrowIcon} alt="" />
        </Button>
        <Button
          disabled={isNextDisabled()}
          onClick={handleNextPage}
          className="pages next-page"
        >
          <img src={rightArrowIcon} alt="" />
        </Button>
      </div>
      <ItemsPerPageOption ref={itemsPerPageOptionRef} />
    </>
  )
}

export default PaginationMini
