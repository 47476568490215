import { useRef } from "react"
import { useHistory } from "react-router-dom"

//helpers
import { splitLongNumbers } from "helpers/numbers"

//libraries
import Skeleton from "react-loading-skeleton"
import moment from "moment"
import { Button } from "@material-ui/core"

//components
import NoDataFound from "components/misc/NoDataFound"
import View from "components/pages/accounts/view/View"

function SalesActivitiesDashboardTable({
  loadingActivities,
  getActivities,
  clientId,
  handleClose,
}) {
  const navigate = useHistory()

  const viewModalRef = useRef(null)

  function handleClickAddButton() {
    navigate.push(`/accounts/new?client_id=${clientId}`)

    handleClose()
  }

  function handleView(data) {
    let modal = viewModalRef.current

    modal.open(data.account_id)
  }

  if (loadingActivities) {
    return (
      <div
        style={{
          width: "100%",
        }}
      >
        <Skeleton count={1} height={35} />
        <Skeleton count={1} height={35} />
        <Skeleton count={1} height={35} />
        <Skeleton count={1} height={35} />
        <Skeleton count={1} height={35} />
      </div>
    )
  }

  if (!loadingActivities && !getActivities().length) {
    return (
      <>
        <NoDataFound
          style={{
            marginBottom: "-10px",
          }}
        >
          <h2>Няма налични записи</h2>
          <p>Може да създадете нова продажба от бутона по-долу</p>
          <Button
            className="add new-btn-primary"
            onClick={handleClickAddButton}
          >
            Продажба
          </Button>
        </NoDataFound>
      </>
    )
  }

  return (
    <>
      <View ref={viewModalRef} />
      <table className="type-outer">
        <thead>
          <tr>
            <th>№</th>
            <th>Статус</th>
            <th>Отворена</th>
            <th>Затворена</th>
            <th className="text-right">Сума</th>
          </tr>
        </thead>
        <tbody>
          {getActivities().map((activity) => (
            <tr
              key={activity.id}
              onClick={() => {
                handleView(activity)
              }}
            >
              <td>{activity.id}</td>
              <td>{activity.status}</td>
              <td>
                {activity.created_at
                  ? `${moment(activity.created_at, "YYYY-MM-DDTHH:mm").format(
                      "DD.MM.YYYY"
                    )} г.`
                  : "-"}
              </td>
              <td>
                {activity.closed_at
                  ? `${moment(activity.closed_at, "YYYY-MM-DDTHH:mm").format(
                      "DD.MM.YYYY"
                    )} г.`
                  : "-"}
              </td>
              <td className="text-right">
                {splitLongNumbers((activity.total_real || 0).toFixed(2))}{" "}
                {activity.currency.name}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <hr />
      <Button className="add new-btn-primary" onClick={handleClickAddButton}>
        Продажба
      </Button>
    </>
  )
}

export default SalesActivitiesDashboardTable
