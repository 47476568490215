import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

import { MenuItem } from '@material-ui/core'

//helpers
import Api from 'helpers/Api'

//providers
import { useLanguageContext } from 'providers/Language'
import { useClientContext } from '../Provider'
import { useAppContext } from 'providers/App'

//misc
import Autocomplete from 'components/misc/Autocomplete'
import HelpInfo from 'components/misc/Info'
import SaveButton from 'components/misc/Button'
import Refs from 'Refs'
import RedditTextField from 'components/misc/RedditTextField'
import RedditSelect from 'components/misc/RedditSelect'

function Index(props, ref) {

    const app = useAppContext()
    const langs = useLanguageContext()
    const context = useClientContext()

    const { state, setState } = context
    const { validations, setValidations } = context

    const [vies, setVies] = useState({
        loading: false,
        data: {}
    })

    useImperativeHandle(ref, () => ({
        save: (e) => {
            handleSave(e)
        },
    }))

    const formRef = useRef(null)
    const inputFileRef = useRef(null)

    useEffect(() => {
        loadData()
    }, [props.id])

    useEffect(() => {
        //    
    }, [])

    const loadData = () => {
        if (!props.id) {
            return
        }

        if (state.data.id === props.id) {
            return
        }

        Api.get('store/clients/show?id=' + props.id)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    data: {
                        ...prev.data,
                        ...res.data,
                    },
                    initialData: res.data,
                }))
            })
    }

    const handleSave = e => {
        if (e) e.preventDefault()

        setState(prev => ({
            ...prev,
            loading: true,
        }))

        setValidations(null)

        let url = state.data.id ? 'store/clients/update' : 'store/clients/add'

        let data = new FormData(formRef.current)

        if (state.data.file?.was_recently_attached) {
            data.append('image', state.data.file)
        }

        if (state.data.id) {
            data.append('id', state.data.id)
        }

        Api.post(url, data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(res => {
            if (typeof props.onSuccess === 'function') {
                props.onSuccess(res.data)
            }

            app.showSuccess()
        }).catch(error => {
            const _err = error.response

            if (_err && _err.status && _err.status === 422) {

                setValidations(_err.data.errors)

                // props.hide(() => {
                //     let modal = Refs.getInstance().getRef('info')

                //     if (modal) {
                //         modal.open('Моля попълнете всички полета означени с червено')

                //         modal.onClose(() => {
                //             props.show()
                //         })
                //     }
                // })
            } else {
                app.showError()
            }
        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false,
            }))

            props?.handleStopLoadingSave?.()
        })
    }

    const setMainTab = key => {
        setState(prev => ({
            ...prev,
            mainTabId: key
        }))
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(name, value, 'data')
    }

    const handleDiscountChange = e => {
        const name = e.target.name
        let value = e.target.value

        if (!value.includes('+') && !value.includes('-') && value.length > 0) {
            value = '-' + value
        }

        if (value.includes('+')) {
            value = value.replace('-', '')
        }

        setState(name, value, 'data')
    }

    const handleDateChange = e => {
        const name = e.target.name + '_ymd'
        const value = e.target.value

        setState(name, value, 'data')
    }

    const handleCheckboxChange = e => {
        const name = e.target.name
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: prev.data[name] ? false : true
            }
        }))
    }

    const handleClientChange = (data) => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                client_name: data.name,
                client_id: data.id
            }
        }))
    }


    const handleAttach = e => {
        let file = e.target.files[0]
        let url = URL.createObjectURL(file)

        file.id = Math.random().toString(7).substring(2)
        file.was_recently_attached = true
        file.url = url

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                file: file
            }
        }))
    }

    const handleShowAttach = e => {
        e.preventDefault()

        inputFileRef.current.click()
    }

    const checkVatNumber = e => {
        e.preventDefault()

        let num = state.data.vat_num

        if (!num) {
            return
        }

        setVies(prev => ({
            ...prev,
            loading: true,
        }))

        Api.get('vies/search', {
            params: {
                vat_number: num
            }
        }).then(res => {
            if (res.data.valid) {
                let modal = props.viesModalRef.current

                if (!modal) {
                    return
                }

                let data = res.data

                props.hide(() => {
                    modal.open(data)
                })

                modal.onClose(() => {
                    props.show()
                })

                modal.onSuccess(() => {
                    let address = parseViesAddress(data)

                    setState(prev => ({
                        ...prev,
                        data: {
                            ...prev.data,
                            company_name: data.name,
                            town: address.town,
                            address: address.address,
                            zip: address.zip
                        }
                    }))
                })
            }
        }).finally(() => {
            setVies(prev => ({
                ...prev,
                loading: false,
            }))
        })
    }

    const parseViesAddress = data => {
        let full = data.address

        let town = (full.split(',').pop().split(/(\d)/)[0] || '').trim();

        ['гр.', 'с.',].forEach(el => {
            if (town.startsWith(el)) {
                town = town.replace(el, '')
            }
        })

        let zip = full.split(' ').pop()

        let address = (full.split('обл')[0] || '').trim()

        return {
            town: town,
            zip: zip,
            address: address,
        }
    }

    function handleTypeChange(e) {
        const { name, value } = e.target

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: value
            }
        }))
    }

    return (
        <form ref={formRef} onSubmit={handleSave} style={{ width: '100%' }}>
            <div className="row">
                <div className="col">
                    <h6>
                        Персонални данни
                    </h6>
                    <div className="field">
                        <div className="row">
                            <div className="col">
                                <RedditSelect
                                    size="small"
                                    variant="filled"
                                    margin="dense"
                                    label="Тип"
                                    name="type_id"
                                    value={state.data.type_id || 2}
                                    onChange={handleTypeChange}
                                    error={Boolean(validations && validations.type_id)}
                                    helperText={validations && validations.type_id && (validations.type_id[0] || validations.type_id)}

                                >
                                    <MenuItem value={2}>
                                        Юридическо лице
                                    </MenuItem>
                                    <MenuItem value={1}>
                                        Физическо лице
                                    </MenuItem>
                                </RedditSelect>
                            </div>
                            <div className="col">
                                <RedditTextField
                                    fullWidth
                                    label="Наименование"
                                    name="info[name]"
                                    value={state.data?.info?.name || ''}
                                    onChange={handleInputChange}
                                    error={Boolean(validations && validations.info && validations.info.name)}
                                    helperText={validations && validations.info && validations.info.name && (validations.info.name[0] || validations.info.name)}
                                />
                            </div>

                        </div>
                        <div className="row">
                            <div className="col">
                                <RedditTextField
                                    fullWidth
                                    label="ЕИК"
                                    name="info[eikegn]"
                                    value={state.data.info?.eikegn || ''}
                                    onChange={handleInputChange}
                                    error={Boolean(validations && validations.info && validations.info.eikegn)}
                                    helperText={validations && validations.info && validations.info.eikegn && (validations.info.eikegn[0] || validations.info.eikegn)}
                                />
                            </div>
                            <div className="col">
                                <RedditTextField
                                    fullWidth
                                    label="ДДС номер"
                                    name="info[vat_number]"
                                    value={state.data.info?.vat_number || ''}
                                    onChange={handleInputChange}
                                    error={Boolean(validations && validations.info && validations.info.vat_number)}
                                    helperText={validations && validations.info && validations.info.vat_number && (validations.info.vat_number[0] || validations.info.vat_number)}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <RedditTextField
                                    fullWidth
                                    label="Е-поща"
                                    name="info[email]"
                                    value={state.data?.info?.email || ''}
                                    onChange={handleInputChange}
                                    error={Boolean(validations && validations.info && validations.info.email)}
                                    helperText={validations && validations.info && validations.info.email && (validations.info.email[0] || validations.info.email)}
                                />
                            </div>
                            <div className="col">
                                <RedditTextField
                                    fullWidth
                                    label="Телефон"
                                    name="info[phone]"
                                    value={state.data.info?.phone || ''}
                                    onChange={handleInputChange}
                                    error={Boolean(validations && validations.info && validations.info.phone)}
                                    helperText={validations && validations.info && validations.info.phone && (validations.info.phone[0] || validations.info.phone)}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <RedditTextField
                                    fullWidth
                                    label="Шифър в Ажур"
                                    name="ajur_id"
                                    type="number"
                                    min="0"
                                    step="0.001"
                                    value={state.data?.ajur_id || ''}
                                    onChange={handleInputChange}
                                    error={Boolean(validations && validations.info && validations.info.ajur_id)}
                                    helperText={validations && validations.info && validations.info.ajur_id && (validations.info.ajur_id[0] || validations.info.ajur_id)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default forwardRef(Index)