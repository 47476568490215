import { Button } from "@material-ui/core";
import axios from "axios";
import Loader from "components/misc/Loader";
import NoDataFound from "components/misc/NoDataFound";
import Pagination from "components/misc/Pagination";
import RedditTextField from "components/misc/RedditTextField";
import TableHeader from "components/misc/TableHeader";
import TableOptions from "components/misc/TableOptions";
import DynamicTableBodyCol from "components/partials/table/DynamicTableBodyCol";
import DynamicTableCol from "components/partials/table/DynamicTableCol";
import Api from "helpers/Api";
import moment from "moment"
import { useAppContext } from "providers/App";
import { useAuthDataContext } from "providers/Auth";
import { Fragment, useEffect, useRef, useState } from "react";
import { Dash, Plus } from "react-bootstrap-icons";
import { useHistory } from "react-router-dom";

let timeout;

function Rows(props) {

    const app = useAppContext();
    const auth = useAuthDataContext();
    const navigate = useHistory();

    const { show, hide } = props;

    const tableRef = useRef(null);

    const [state, setState] = useState({
        sorting: false,
        loading: true,
        refresh: false,
        data: {},
        totals: {},
        headings: {},
        types: {},
        pages: 0,
        total: 0,
        filter: {
            page: 1,
        },
        columns: {
            all: {},
            details: {},
            selected: [],
            sortable: [],
            sort: null,
            order: null,
        },
        tableKey: '',
        request: null,
    });

    const [expanded, setExpanded] = useState([]);

    useEffect(() => {
        loadData();
    }, [props.id, state.refresh]);

    const getUrl = () => {
        return 'store/orders/rows?order_id=' + props.id;
    }

    const loadData = () => {

        if (!props.id) {
            return;
        }

        if (state.request) {
            state.request.cancel();
        }

        let request = axios.CancelToken.source();

        setState(prev => ({
            ...prev,
            request: request,
            loading: true,
        }));

        let url = getUrl();

        Api.get(url, {
            params: state.filter,
            cancelToken: request.token
        }).then(res => {
            setState(prev => ({
                ...prev,
                currency: res.data.currency,
                data: res.data.items,
                total: res.data.total,
                pages: res.data.pages,
                totals: res.data.totals,
                headings: res.data.headings,
                types: res.data.types,
                columns: res.data.columns,
                tableKey: res.data.tableKey,
                loading: false,
                sorting: false,
                filter: {
                    ...prev.filter,
                    ...res.data.filter,
                }
            }));

            setExpanded(res.data.items.map(store => store.id));

            if (res.data.pages > 0 && state.filter.page > res.data.pages) {
                return handlePage({ selected: res.data.pages - 1 });
            }
        });
    }

    const refresh = (reset = true) => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: reset ? 1 : prev.filter.page
            },
            refresh: moment().unix(),
        }));
    }

    const refreshTable = () => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                sort: '',
                order: '',
                page: 1
            },
            refresh: moment().unix(),
        }));
    }

    // Search
    const handleSearch = (key, val, delay = 300) => {
        clearTimeout(timeout);

        if (typeof key === 'object') {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    ...key
                },
            }));
        } else {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    [key]: val,
                },
            }));
        }

        timeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    page: 1
                },
                refresh: moment().unix()
            }));
        }, delay);
    }

    const handlePage = page => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: page.selected + 1,
            },
            refresh: moment().unix()
        }));
    }

    const handleSort = (sort, order) => {
        setState(prev => ({
            ...prev,
            sorting: true,
            filter: {
                ...prev.filter,
                sort: sort,
                order: order,
                page: 1,
            },
            refresh: moment().unix()
        }));
    }

    const handleChangeRowStatus = row => {
        if (auth.getUser().permission('clientorders_detail_status_change') === false) {
            return app.showError('Нямате право да редактирате статуса на артикула');
        }

        Api.post('store/orders/set-row-status', {
            id: row.order_id,
            row_id: row.id,
        }).then(res => {
            refresh(false);
        });
    }

    const expand = id => {
        if (isExpanded(id)) {
            setExpanded(prev => prev.filter(c => c !== id));
        } else {
            setExpanded(prev => prev.concat(id));
        }
    }

    const isExpanded = (id) => {
        return expanded.indexOf(id) > -1;
    }

    const isVisible = id => {
        return isExpanded(id);
    }

    const handleCreateAccount = storeId => {
        // auth.setActiveStore(storeId)
        //     .finally(() => {
        //         navigate.push('/accounts/new?from_order_id=' + props.id + '&store_id=' + storeId + '&sid=' + storeId);
        //     });

        navigate.push('/accounts/new?from_order_id=' + props.id + '&store_id=' + storeId + '&sid=' + storeId);
    }

    return (
        <div className="mt-3">
            <div className="filter">
                {/* <InputGroup size="sm"> */}
                <div className="filter-holder">
                    {/* <InputGroup size="sm"> */}
                    <RedditTextField
                        size="small"
                        variant="filled"
                        margin="dense"
                        label="Търсене..."
                        name="search"
                        value={state.filter.search || ''}
                        onChange={e => handleSearch(e.target.name, e.target.value)}
                    />
                </div>
                {/* </InputGroup> */}

                {/* <TableOptions
                    url={getUrl()}
                    filter={state.filter}
                    columns={state.columns}
                    tableKey={state.tableKey}
                    refresh={refreshTable}
                // onExport={hide}
                /> */}
            </div>

            {state.loading && !state.sorting
                ?
                <div style={{ padding: '150px 0' }}>
                    <Loader />
                </div>
                :
                state.data.length === 0 ?
                    <NoDataFound />
                    :
                    <>
                        <table ref={tableRef} className="type-doc caption">
                            <TableHeader
                                tableRef={tableRef}
                                activeSortKey={state.filter.sort}
                                activeSortDir={state.filter.order}
                                onSort={(col, dir) => handleSort(col, dir)}
                            >
                                {Object.entries(state.headings).map((heading, i) =>
                                    <DynamicTableCol
                                        key={i}
                                        type={state.types[heading[0]]}
                                        name={heading[1]}
                                        title={state.columns.description[heading[0]]}
                                        sortKey={heading[0]}
                                        sortable={state.columns.sortable.indexOf(heading[0]) > -1}
                                    />
                                )}
                            </TableHeader>
                            <tbody>
                                {state.data.map((store, index) =>
                                    <Fragment
                                        key={'c-' + store.id}
                                    >
                                        <tr style={{ background: 'cornsilk' }}>
                                            <td
                                                colSpan={store.colspan}
                                                style={{
                                                    padding: '0.3rem',
                                                    fontSize: '12px',
                                                    cursor: 'pointer'
                                                }}
                                                onClick={() => expand(store.id)}
                                            >
                                                <div style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'space-between'
                                                }}>
                                                    <div>
                                                        {isExpanded(store.id) ? <Dash /> : <Plus />} <b style={{ color: '#505050' }}>{store.name}</b> - <i>{store.total} ред(а)</i>
                                                    </div>
                                                    {/* <Button
                                                        className="add"
                                                        onClick={e => handleCreateAccount(store.id)}
                                                        style={{
                                                            // fontSize: '0.8rem'
                                                        }}
                                                    >
                                                        Направи сметка от артикулите
                                                    </Button> */}
                                                </div>
                                            </td>
                                        </tr>
                                        {store.items.map((c, rowIndex) =>
                                            <tr
                                                key={rowIndex}
                                                className={c.deleted ? 'deleted' : ''}
                                                style={{ display: isVisible(store.id) ? 'table-row' : 'none' }}
                                            >
                                                {Object.entries(state.headings).map((heading, i) =>
                                                    heading[0] === 'status'
                                                        ?
                                                        <td className="text-center" style={{ backgroundColor: c.row?.status?.color || '#fff', cursor: 'pointer' }} onClick={e => handleChangeRowStatus(c.row)}>
                                                            <span style={{ color: c.row?.status?.color || '#fff', mixBlendMode: 'difference' }}>
                                                                {c.row?.status ?
                                                                    c.row?.status?.translation?.name || c.row?.status?.name
                                                                    :
                                                                    'няма избран'
                                                                }
                                                            </span>
                                                        </td>
                                                        :
                                                        <DynamicTableBodyCol
                                                            key={heading[0]}
                                                            type={state.types[heading[0]]}
                                                            name={c[heading[0]]}
                                                            data={c}
                                                            currency={state.currency}
                                                            onShowArticle={hide}
                                                            onHideArticle={show}
                                                        />
                                                )}
                                            </tr>
                                        )}
                                        {store.totals.map((c, i) =>
                                            <tr key={i} className="total-secondary" style={{ display: isVisible(store.id) ? 'table-row' : 'none' }}>
                                                {Object.entries(c.items).map((heading, i) =>
                                                    <DynamicTableBodyCol
                                                        key={heading[0]}
                                                        type={state.types[heading[0]]}
                                                        name={heading[1]}
                                                        data={c.items}
                                                        currency={state.currency}
                                                        colspan={c.colspan[heading[0]]}
                                                    />
                                                )}
                                            </tr>
                                        )}
                                    </Fragment>

                                )}
                                {state.totals.map((c, i) =>
                                    <tr key={i} className="total">
                                        {Object.entries(c.items).map((heading, i) =>
                                            <DynamicTableBodyCol
                                                key={heading[0]}
                                                type={state.types[heading[0]]}
                                                name={heading[1]}
                                                data={c.items}
                                                currency={state.currency}
                                                colspan={c.colspan[heading[0]]}
                                            />
                                        )}
                                    </tr>
                                )}
                            </tbody>
                        </table>

                        <Pagination
                            className="mt-3"
                            page={state.filter.page}
                            pages={state.pages}
                            total={state.total}
                            handlePage={handlePage}
                        />
                    </>
            }
        </div>
    )
}

export default Rows;