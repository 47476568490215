//libraries
import { Pie } from "@ant-design/plots"

function PaymentPie({ paidPercentage }) {
  const data = [
    {
      type: "Платени",
      value: paidPercentage,
    },
    {
      type: "Неплатени",
      value: 100 - paidPercentage,
    },
  ]
  const config = {
    data,
    angleField: "value",
    colorField: "type",
    radius: 0.9,
    width: 30,
    height: 30,
    legend: false,
    tooltip: false,
    label: false,
    color: ["#1FC66C", "#fff"],
  }

  return (
    <div>
      <Pie {...config} />
    </div>
  )
}

export default PaymentPie
