import { useState, useImperativeHandle, forwardRef, useRef } from "react"

//libraries
import { Button } from "@material-ui/core"

//images
import updateIcon from "assets/img/modals/icons/update.png"

//providers
import { useAuthDataContext } from "providers/Auth"

function Update({ versionCode }, ref) {
  const { getUser } = useAuthDataContext()

  const [state, setState] = useState({
    overlay: false,
    modal: false,
  })

  useImperativeHandle(ref, () => ({
    open: () => {
      setState((prev) => ({
        ...prev,
        overlay: true,
      }))

      setTimeout(() => {
        setState((prev) => ({
          ...prev,
          modal: true,
        }))
      }, 50)
    },
  }))

  const handleClose = () => {
    setState((prev) => ({
      ...prev,
      modal: false,
    }))

    setTimeout(() => {
      setState((prev) => ({
        ...prev,
        overlay: false,
      }))
    }, 100)

    localStorage.setItem("versionCode", versionCode)
  }

  return (
    <div className={`${state.overlay ? "visible" : ""} overlay`}>
      <div
        className={`popup-primary large update ${state.modal ? "show" : ""}`}
      >
        <div className="body">
          <div className="top">
            <img src={updateIcon} alt="" />
            <div className="right">
              <h2>Направихме подобрения,</h2>
              <b>спомагащи за по-добрата Ви работа</b>
              <p>Версия {versionCode}</p>
            </div>
          </div>
          <ul className="bottom">
            {getUser().hasSubscriptionAccess() ? (
              <li>
                <b>Модул “Абонаменти”</b>
                {/* <p>
                Ние ти предоставяме бърз и ясен обзор на ключови бизнес
                параметри. Сега можете лесно да следите важни данни и
                статистики, които да Ви помогнат в бързите бизнес решения.
              </p> */}
              </li>
            ) : (
              <></>
            )}
            <li>
              <b>Модул “Клиенти”</b>
              {/* <p>
                Само с едно действие можете бързо да извършите различни операции
                върху документи и платежни процеси, оптимизирайки Вашето работно
                време.
              </p> */}
            </li>
            {getUser().hasStoreAccess() ? (
              <li>
                <b>Модул “Изписвания”</b>
                {/* <p>
                Само с едно действие можете бързо да извършите различни операции
                върху документи и платежни процеси, оптимизирайки Вашето работно
                време.
              </p> */}
              </li>
            ) : (
              <></>
            )}
            {getUser().hasStoreAccess() ? (
              <li>
                <b>Актуализация на Експорт/Принтиране/Изтегляне на документи</b>
                {/* <p>
                Само с едно действие можете бързо да извършите различни операции
                върху документи и платежни процеси, оптимизирайки Вашето работно
                време.
              </p> */}
              </li>
            ) : (
              <></>
            )}
          </ul>
        </div>
        <div className="footer">
          <Button
            variant="contained"
            color="primary"
            className="save"
            onClick={handleClose}
          >
            Разбрах
          </Button>
        </div>
      </div>
    </div>
  )
}

export default forwardRef(Update)
