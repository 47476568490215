import { createElement, useCallback, useEffect, useState } from 'react'
import { useHistory, Redirect } from 'react-router-dom'
import Layout from '../../Layout'

//partials
import MyProfile from './partials/profile/Index'
import NumbersMain from './partials/numbers/Index'
import Categories from './partials/categories/Index'
import Comapnies from './partials/companies/Index'
import Integrations from './partials/integrations/Index'
import Users from './partials/users/Index'
import Templates from './partials/templates/Index'
import MailSigns from './partials/mail-signs/Index'
import Payments from './partials/payments/Index'
import Notifications from './partials/notifications/Index'
import Stores from './partials/stores/Index'
import Depots from './partials/depots/Index'
import StoreoutsDeals from './partials/storeouts_deals/Index'
import SubscriptionsTypes from './partials/subscriptions_types/Index'
import CardTypes from './partials/card_types/Index'
import PackageCards from './partials/package_cards/Index'
import OrdersStatuses from './partials/orders_statuses/Index'
import Reviews from './partials/article_reviews/Index'

//styles
import 'assets/scss/pages/settings.scss'

//images
import profileIcon from 'assets/img/settings/icons/profile.png'
import numbersIcon from 'assets/img/settings/icons/numbers.png'
import accountIcon from 'assets/img/settings/icons/account.png'
import categoriesIcon from 'assets/img/settings/icons/categories.png'
import companiesIcon from 'assets/img/settings/icons/companies.png'
import integrationsIcon from 'assets/img/settings/icons/integrations.png'
import paymentsIcon from 'assets/img/settings/icons/payments.png'
import signaturesIcon from 'assets/img/settings/icons/signatures.png'
import templatesIcon from 'assets/img/settings/icons/templates.png'
import notificationsIcon from 'assets/img/settings/icons/notifications.png'
import storesIcon from 'assets/img/settings/icons/stores.png'
import depotsIcon from 'assets/img/settings/icons/depots.png'
import catelogueIcon from 'assets/img/header/icons/catalogue.png'

//providers
import { useAuthDataContext } from 'providers/Auth'

function Index() {
    const auth = useAuthDataContext()
    const history = useHistory()
    const isAdmin = auth.getUser()?.isAdmin()
    const route = history.location.pathname.replaceAll('/settings/', '') || 'numbers'

    const MENU_ITEMS = [
        {
            id: 'numbers',
            title: 'Регистър на номера',
            icon: numbersIcon,
            component: NumbersMain,
            hasAccess: true,
        },
        {
            id: 'templates',
            title: 'Шаблони',
            icon: templatesIcon,
            component: Templates,
            hasAccess: true,
        },
        {
            id: 'signatures',
            title: 'Подписи',
            icon: signaturesIcon,
            component: MailSigns,
            hasAccess: true,
        },
        {
            id: 'profile',
            title: 'Моят профил',
            icon: profileIcon,
            component: MyProfile,
            hasAccess: true,
        },
        // {
        //     id: 'notifications',
        //     title: 'Известия',
        //     icon: notificationsIcon,
        // },
        {
            id: 'categories/incomes',
            submenuIds: ['categories/incomes', 'categories/expenses'],
            title: 'Категории',
            icon: categoriesIcon,
            hasAccess: auth.getUser().permission("incomes:read") ? true : false,
            submenus: [
                {
                    id: 'categories/incomes',
                    title: 'Приходи',
                    component: Categories,
                    hasAccess: auth.getUser().permission("incomes:read") ? true : false,
                },
                {
                    id: 'categories/expenses',
                    title: 'Разходи',
                    component: Categories,
                    hasAccess: auth.getUser().permission("incomes:read") ? true : false,
                },
            ]
        },
        {
            id: 'catalogue',
            title: 'Каталог',
            icon: catelogueIcon,
            component: () => {
                history.push('/articles')

                return <></>
            },
            hasAccess: auth.getUser().hasStoreAccess() || auth.getUser().hasCRMAccess(),
        },
        {
            id: 'companies',
            title: 'Моите фирми',
            icon: companiesIcon,
            component: Comapnies,
            hasAccess: isAdmin,
        },
        {
            id: 'stores',
            title: 'Търговски обекти',
            icon: storesIcon,
            component: Stores,
            hasAccess: auth.getUser().hasStoreAccess(),
        },
        {
            id: 'depots',
            title: 'Складове',
            icon: depotsIcon,
            component: Depots,
            hasAccess: auth.getUser().hasStoreAccess(),
        },
        {
            id: 'storeouts_deals',
            title: 'Причини изписване',
            icon: depotsIcon,
            component: StoreoutsDeals,
            hasAccess: auth.getUser().hasStoreAccess(),
        },
        {
            id: 'subscriptions_types',
            title: 'Типове абонаменти',
            icon: depotsIcon,
            component: SubscriptionsTypes,
            hasAccess: auth.getUser().hasStoreAccess(),
        },
        {
            id: 'cards_types',
            title: 'Видове пакетни карти',
            icon: depotsIcon,
            component: CardTypes,
            hasAccess: auth.getUser().hasStoreAccess(),
        },
        {
            id: 'package_cards',
            title: 'Пакетни карти',
            icon: depotsIcon,
            component: PackageCards,
            hasAccess: auth.getUser().hasStoreAccess(),
        },
        {
            id: 'orders_statuses',
            title: 'Статуси на поръчки',
            icon: depotsIcon,
            component: OrdersStatuses,
            hasAccess: auth.getUser().hasStoreAccess(),
        },
        {
            id: 'article_reviews',
            title: 'Ревюта',
            icon: depotsIcon,
            component: Reviews,
            hasAccess: auth.getUser().hasStoreAccess(),
        },
        {
            id: 'integrations',
            title: 'Интеграции',
            icon: integrationsIcon,
            component: Integrations,
            hasAccess: auth.getUser().hasCRMAccess() // за тека пласт и фернет
        },
        {
            id: 'users',
            title: 'Потребители',
            icon: accountIcon,
            component: Users,
            hasAccess: isAdmin,
        },
        {
            id: 'payments',
            title: 'Плащания',
            icon: paymentsIcon,
            component: Payments,
            hasAccess: true,
        },
    ]


    // const currentItem = MENU_ITEMS.find(item => {
    //     if (item.submenus) {
    //         return item.submenus.find(i => i.id === route);
    //     } else {
    //         return item.id === route;
    //     }
    // });

    const getCurrentItem = () => {
        for (let i = 0; i < MENU_ITEMS.length; i++) {
            let item = MENU_ITEMS[i]

            if (item.submenus) {
                for (let j = 0; j < item.submenus.length; j++) {
                    let subitem = item.submenus[j]

                    if (subitem.id === route) {
                        return subitem
                    }
                }
            } else {
                if (item.id === route) {
                    return item
                }
            }
        }
    }

    const currentItem = getCurrentItem()

    /**
     * 
     * @param {number} activeMenuId id на екрана
     */
    const handleMenu = activeMenuId => {
        history.push(`/settings/${activeMenuId}`)
    }

    /**
     * 
     * @param {number} screenId id на екрана 
     * @returns дали има достъп до екрана
     */
    // const checkAccess = screenId => {
    //     const checkIsAdmin = () => isAdmin

    //     if ((screenId === 'categories/incomes') && !auth.getUser().permission("incomes:read")) {
    //         return false
    //     }

    //     if ((screenId === 'categories/expenses') && !auth.getUser().permission("expenses:read")) {
    //         return false
    //     }

    //     if (screenId === 'companies') {
    //         //Моите фирми
    //         return checkIsAdmin()
    //     } else if (screenId === 'users') {
    //         //Потребители
    //         return checkIsAdmin()
    //     } else {
    //         return true
    //     }
    // }

    const handleClickMenuOption = (id, submenus) => {
        if (route !== id) {
            if (submenus) handleMenu(submenus[0].id)
            else handleMenu(id)
        }
    }

    const handleClickSubmenuOption = id => {
        if (route !== id) handleMenu(id)
    }

    /**
     * 
     * @param {number} screenId id на екрана
     * @returns дали екрана е активен
     */
    const checkScreen = screenId => {
        let access = false

        if (Array.isArray(screenId)) {
            for (let i = 0; i < screenId.length; i++) {
                if (!access) {
                    if (route === screenId[i]) {
                        access = true
                    }
                }
            }
        } else {
            if (route === screenId) {
                access = true
            }
        }

        return access
    }

    // const renderContent = useCallback(() => {
    //     return <div className="content">
    //         {
    //             checkScreen('numbers') ?
    //                 <NumbersMain />
    //                 :
    //                 checkScreen('templates')
    //                     ?
    //                     <Templates />
    //                     :
    //                     checkScreen('signatures')
    //                         ?
    //                         <MailSigns />
    //                         :
    //                         checkScreen('profile') ?
    //                             <MyProfile />
    //                             :
    //                             checkScreen(['categories', 'categories/incomes', 'categories/expenses']) ?
    //                                 <Categories
    //                                     screen={route}
    //                                 />
    //                                 :
    //                                 checkScreen('companies') && isAdmin ?
    //                                     <Comapnies />
    //                                     :
    //                                     checkScreen('integrations') ?
    //                                         <Integrations />
    //                                         :
    //                                         checkScreen('users') && isAdmin ?
    //                                             <Users />
    //                                             :
    //                                             checkScreen('payments') && isAdmin ?
    //                                                 <Payments />
    //                                                 :
    //                                                 checkScreen('notifications') ?
    //                                                     <Notifications />
    //                                                     :
    //                                                     <Redirect
    //                                                         to="/settings/numbers"
    //                                                     />
    //         }
    //     </div>
    // }, [route])

    return (
        <Layout>
            <section id="settings">
                <div id="head" className="row space-between">
                    <div className="title">
                        Настройки
                    </div>
                </div>
                <div className="settings-panel">
                    <ul className="menu">
                        {MENU_ITEMS.map(m =>
                            m.hasAccess ?
                                <li
                                    key={m.id}
                                    className={checkScreen(m.submenuIds || m.id) ? 'active' : ''}
                                >

                                    <span
                                        onClick={() => {
                                            handleClickMenuOption(m.id, m?.submenus)
                                        }}
                                    >
                                        <img src={m.icon} />
                                        {m.title}
                                    </span>
                                    {m?.submenus && checkScreen(m.submenuIds) ?
                                        <ul className="submenu">
                                            {m.submenus.map(s =>
                                                s.hasAccess ?
                                                    <li
                                                        key={s.id}
                                                        onClick={() => {
                                                            handleClickSubmenuOption(s.id)
                                                        }}
                                                        className={route === s.id ? 'active' : ''}
                                                    >
                                                        <span>
                                                            {s.title}
                                                        </span>
                                                    </li>
                                                    :
                                                    <>
                                                    </>
                                            )}
                                        </ul>
                                        :
                                        <>
                                        </>
                                    }
                                </li>
                                :
                                <>
                                </>
                        )}
                    </ul>
                    {/* {renderContent()} */}

                    <div className="content">
                        {currentItem && currentItem.component && createElement(currentItem.component, {
                            screen: route
                        })}
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default Index